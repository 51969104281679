const myLogements = [
    {
        "idLogement": 1,
        "refLogement": "A001",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 120000,
        "prixMax": 150000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:20.042Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 51259,
                "typeIndexe": "energie",
                "ancien": 10966,
                "nouveau": 11056,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 23,
                "montant": 11477,
                "idIndexe": 51258,
                "typeIndexe": "eau",
                "ancien": 6081,
                "nouveau": 6104,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 51125,
                "typeIndexe": "energie",
                "ancien": 10876,
                "nouveau": 10966,
                "periode": "2023-10-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.717Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 17964,
                "idIndexe": 51124,
                "typeIndexe": "eau",
                "ancien": 6045,
                "nouveau": 6081,
                "periode": "2023-10-01",
                "avance": 17964,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.720Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50985,
                "typeIndexe": "energie",
                "ancien": 10791,
                "nouveau": 10876,
                "periode": "2023-09-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.712Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50984,
                "typeIndexe": "eau",
                "ancien": 6026,
                "nouveau": 6045,
                "periode": "2023-09-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.715Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50853,
                "typeIndexe": "energie",
                "ancien": 10691,
                "nouveau": 10791,
                "periode": "2023-08-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.708Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50852,
                "typeIndexe": "eau",
                "ancien": 6017,
                "nouveau": 6026,
                "periode": "2023-08-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.705Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50723,
                "typeIndexe": "energie",
                "ancien": 10591,
                "nouveau": 10691,
                "periode": "2023-07-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.699Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50722,
                "typeIndexe": "eau",
                "ancien": 6014,
                "nouveau": 6017,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.702Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50586,
                "typeIndexe": "eau",
                "ancien": 6003,
                "nouveau": 6014,
                "periode": "2023-06-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50587,
                "typeIndexe": "energie",
                "ancien": 10491,
                "nouveau": 10591,
                "periode": "2023-06-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.676Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50452,
                "typeIndexe": "energie",
                "ancien": 10391,
                "nouveau": 10491,
                "periode": "2023-05-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:33.977Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 17465,
                "idIndexe": 50451,
                "typeIndexe": "eau",
                "ancien": 5968,
                "nouveau": 6003,
                "periode": "2023-05-01",
                "avance": 17465,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:13.891Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 50322,
                "typeIndexe": "energie",
                "ancien": 10241,
                "nouveau": 10391,
                "periode": "2023-04-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:21.454Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50321,
                "typeIndexe": "eau",
                "ancien": 5949,
                "nouveau": 5968,
                "periode": "2023-04-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:05.853Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 177,
                "montant": 19470,
                "idIndexe": 50190,
                "typeIndexe": "energie",
                "ancien": 10064,
                "nouveau": 10241,
                "periode": "2023-03-01",
                "avance": 19470,
                "reste": 0,
                "datePaiement": "2023-04-28T13:33:45.538Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50189,
                "typeIndexe": "eau",
                "ancien": 5929,
                "nouveau": 5949,
                "periode": "2023-03-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-04-28T13:33:45.621Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50068,
                "typeIndexe": "eau",
                "ancien": 5913,
                "nouveau": 5929,
                "periode": "2023-02-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-03-29T07:03:03.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 50069,
                "typeIndexe": "energie",
                "ancien": 9914,
                "nouveau": 10064,
                "periode": "2023-02-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-03-29T07:03:03.428Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 49947,
                "typeIndexe": "energie",
                "ancien": 9764,
                "nouveau": 9914,
                "periode": "2023-01-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.672Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49946,
                "typeIndexe": "eau",
                "ancien": 5900,
                "nouveau": 5913,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.674Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 149,
                "montant": 16390,
                "idIndexe": 49839,
                "typeIndexe": "energie",
                "ancien": 9615,
                "nouveau": 9764,
                "periode": "2022-12-01",
                "avance": 16390,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.670Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49838,
                "typeIndexe": "eau",
                "ancien": 5887,
                "nouveau": 5900,
                "periode": "2022-12-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.667Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 23,
            "montant": 11477,
            "idIndexe": 51258,
            "typeIndexe": "eau",
            "ancien": 6081,
            "nouveau": 6104,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 1,
            "idOccupation": 324,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 90,
            "montant": 9900,
            "idIndexe": 51259,
            "typeIndexe": "energie",
            "ancien": 10966,
            "nouveau": 11056,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 1,
            "idOccupation": 324,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 324,
            "loyerBase": 150000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-09-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.042Z",
            "idLocataire": 294,
            "idLogement": 1,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 294,
                "nomLocataire": "ACHETKOUOGNIGNI",
                "prenomLocataire": "JUNIE C.",
                "cniLocataire": "",
                "tel": "694678903",
                "tel2": "650901505",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.294Z",
                "updatedAt": "2022-03-02T11:54:45.294Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                6104,
                6081
            ],
            "energie": [
                11056,
                10966
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                6104,
                6081
            ],
            "energie": [
                11056,
                10966
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 36,
                "montant": 17964,
                "idIndexe": 51124,
                "typeIndexe": "eau",
                "ancien": 6045,
                "nouveau": 6081,
                "periode": "2023-10-01",
                "avance": 17964,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.720Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50984,
                "typeIndexe": "eau",
                "ancien": 6026,
                "nouveau": 6045,
                "periode": "2023-09-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.715Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50852,
                "typeIndexe": "eau",
                "ancien": 6017,
                "nouveau": 6026,
                "periode": "2023-08-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.705Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50722,
                "typeIndexe": "eau",
                "ancien": 6014,
                "nouveau": 6017,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.702Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50586,
                "typeIndexe": "eau",
                "ancien": 6003,
                "nouveau": 6014,
                "periode": "2023-06-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 17465,
                "idIndexe": 50451,
                "typeIndexe": "eau",
                "ancien": 5968,
                "nouveau": 6003,
                "periode": "2023-05-01",
                "avance": 17465,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:13.891Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50321,
                "typeIndexe": "eau",
                "ancien": 5949,
                "nouveau": 5968,
                "periode": "2023-04-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:05.853Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50189,
                "typeIndexe": "eau",
                "ancien": 5929,
                "nouveau": 5949,
                "periode": "2023-03-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-04-28T13:33:45.621Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50068,
                "typeIndexe": "eau",
                "ancien": 5913,
                "nouveau": 5929,
                "periode": "2023-02-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-03-29T07:03:03.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49946,
                "typeIndexe": "eau",
                "ancien": 5900,
                "nouveau": 5913,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.674Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49838,
                "typeIndexe": "eau",
                "ancien": 5887,
                "nouveau": 5900,
                "periode": "2022-12-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.667Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 51125,
                "typeIndexe": "energie",
                "ancien": 10876,
                "nouveau": 10966,
                "periode": "2023-10-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.717Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50985,
                "typeIndexe": "energie",
                "ancien": 10791,
                "nouveau": 10876,
                "periode": "2023-09-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.712Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50853,
                "typeIndexe": "energie",
                "ancien": 10691,
                "nouveau": 10791,
                "periode": "2023-08-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.708Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50723,
                "typeIndexe": "energie",
                "ancien": 10591,
                "nouveau": 10691,
                "periode": "2023-07-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.699Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50587,
                "typeIndexe": "energie",
                "ancien": 10491,
                "nouveau": 10591,
                "periode": "2023-06-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-11-27T15:17:07.676Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50452,
                "typeIndexe": "energie",
                "ancien": 10391,
                "nouveau": 10491,
                "periode": "2023-05-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:33.977Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 50322,
                "typeIndexe": "energie",
                "ancien": 10241,
                "nouveau": 10391,
                "periode": "2023-04-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-06-27T08:41:21.454Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 177,
                "montant": 19470,
                "idIndexe": 50190,
                "typeIndexe": "energie",
                "ancien": 10064,
                "nouveau": 10241,
                "periode": "2023-03-01",
                "avance": 19470,
                "reste": 0,
                "datePaiement": "2023-04-28T13:33:45.538Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 50069,
                "typeIndexe": "energie",
                "ancien": 9914,
                "nouveau": 10064,
                "periode": "2023-02-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-03-29T07:03:03.428Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 150,
                "montant": 16500,
                "idIndexe": 49947,
                "typeIndexe": "energie",
                "ancien": 9764,
                "nouveau": 9914,
                "periode": "2023-01-01",
                "avance": 16500,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.672Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 149,
                "montant": 16390,
                "idIndexe": 49839,
                "typeIndexe": "energie",
                "ancien": 9615,
                "nouveau": 9764,
                "periode": "2022-12-01",
                "avance": 16390,
                "reste": 0,
                "datePaiement": "2023-02-23T06:18:40.670Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 1,
                "idOccupation": 324,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -6104,
            "energie": -11056
        },
        "montant": {
            "eau": -3045896,
            "energie": -1216160
        }
    },
    {
        "idLogement": 2,
        "refLogement": "A002",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 110000,
        "prixMax": 150000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.957Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 50,
                "montant": 24950,
                "idIndexe": 51260,
                "typeIndexe": "eau",
                "ancien": 31993,
                "nouveau": 3206,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51261,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 44,
                "montant": 21956,
                "idIndexe": 51126,
                "typeIndexe": "eau",
                "ancien": 31949,
                "nouveau": 31993,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51127,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50987,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 33433,
                "idIndexe": 50986,
                "typeIndexe": "eau",
                "ancien": 31882,
                "nouveau": 31949,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50855,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50854,
                "typeIndexe": "eau",
                "ancien": 31817,
                "nouveau": 31882,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 23952,
                "idIndexe": 50724,
                "typeIndexe": "eau",
                "ancien": 31769,
                "nouveau": 31817,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50725,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50589,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 44,
                "montant": 21956,
                "idIndexe": 50588,
                "typeIndexe": "eau",
                "ancien": 31725,
                "nouveau": 31769,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50454,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50453,
                "typeIndexe": "eau",
                "ancien": 31660,
                "nouveau": 31725,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 40419,
                "idIndexe": 50323,
                "typeIndexe": "eau",
                "ancien": 31579,
                "nouveau": 31660,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50324,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 50191,
                "typeIndexe": "eau",
                "ancien": 31537,
                "nouveau": 31579,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50192,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 106,
                "montant": 52894,
                "idIndexe": 50070,
                "typeIndexe": "eau",
                "ancien": 31431,
                "nouveau": 31537,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50071,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 29940,
                "idIndexe": 49948,
                "typeIndexe": "eau",
                "ancien": 31371,
                "nouveau": 31431,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49949,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 32934,
                "idIndexe": 49840,
                "typeIndexe": "eau",
                "ancien": 31305,
                "nouveau": 31371,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49841,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 50,
            "montant": 24950,
            "idIndexe": 51260,
            "typeIndexe": "eau",
            "ancien": 31993,
            "nouveau": 32043,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 2,
            "idOccupation": 297,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51261,
            "typeIndexe": "energie",
            "ancien": 31192,
            "nouveau": 31192,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 2,
            "idOccupation": 297,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 297,
            "loyerBase": 150000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-02-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.956Z",
            "idLocataire": 2,
            "idLogement": 2,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 2,
                "nomLocataire": "ONANA",
                "prenomLocataire": "Jean Magloire",
                "cniLocataire": "",
                "tel": "699906514",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.023Z",
                "updatedAt": "2022-03-02T11:54:44.023Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                32043,
                31993
            ],
            "energie": [
                31192,
                31192
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                32043,
                31993
            ],
            "energie": [
                31192,
                31192
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 44,
                "montant": 21956,
                "idIndexe": 51126,
                "typeIndexe": "eau",
                "ancien": 31949,
                "nouveau": 31993,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 33433,
                "idIndexe": 50986,
                "typeIndexe": "eau",
                "ancien": 31882,
                "nouveau": 31949,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50854,
                "typeIndexe": "eau",
                "ancien": 31817,
                "nouveau": 31882,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 23952,
                "idIndexe": 50724,
                "typeIndexe": "eau",
                "ancien": 31769,
                "nouveau": 31817,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 44,
                "montant": 21956,
                "idIndexe": 50588,
                "typeIndexe": "eau",
                "ancien": 31725,
                "nouveau": 31769,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50453,
                "typeIndexe": "eau",
                "ancien": 31660,
                "nouveau": 31725,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 40419,
                "idIndexe": 50323,
                "typeIndexe": "eau",
                "ancien": 31579,
                "nouveau": 31660,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 50191,
                "typeIndexe": "eau",
                "ancien": 31537,
                "nouveau": 31579,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 106,
                "montant": 52894,
                "idIndexe": 50070,
                "typeIndexe": "eau",
                "ancien": 31431,
                "nouveau": 31537,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 29940,
                "idIndexe": 49948,
                "typeIndexe": "eau",
                "ancien": 31371,
                "nouveau": 31431,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 32934,
                "idIndexe": 49840,
                "typeIndexe": "eau",
                "ancien": 31305,
                "nouveau": 31371,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51127,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50987,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50855,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50725,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50589,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50454,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50324,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50192,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50071,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49949,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49841,
                "typeIndexe": "energie",
                "ancien": 31192,
                "nouveau": 31192,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 2,
                "idOccupation": 297,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -32043,
            "energie": -31192
        },
        "montant": {
            "eau": -15989457,
            "energie": -3431120
        }
    },
    {
        "idLogement": 3,
        "refLogement": "A003",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 70000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.935Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51262,
                "typeIndexe": "eau",
                "ancien": 140,
                "nouveau": 142,
                "periode": "2023-11-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.877Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 33,
                "montant": 3630,
                "idIndexe": 51263,
                "typeIndexe": "energie",
                "ancien": 2191,
                "nouveau": 2224,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51128,
                "typeIndexe": "eau",
                "ancien": 135,
                "nouveau": 140,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 51129,
                "typeIndexe": "energie",
                "ancien": 2145,
                "nouveau": 2191,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50988,
                "typeIndexe": "eau",
                "ancien": 131,
                "nouveau": 135,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 50989,
                "typeIndexe": "energie",
                "ancien": 2094,
                "nouveau": 2145,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 50857,
                "typeIndexe": "energie",
                "ancien": 2043,
                "nouveau": 2094,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50856,
                "typeIndexe": "eau",
                "ancien": 127,
                "nouveau": 131,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50727,
                "typeIndexe": "energie",
                "ancien": 2001,
                "nouveau": 2043,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50726,
                "typeIndexe": "eau",
                "ancien": 124,
                "nouveau": 127,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50593,
                "typeIndexe": "energie",
                "ancien": 1955,
                "nouveau": 2001,
                "periode": "2023-06-01",
                "avance": 57,
                "reste": 5003,
                "datePaiement": "2023-12-26T10:38:15.264Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50591,
                "typeIndexe": "eau",
                "ancien": 121,
                "nouveau": 124,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.873Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50456,
                "typeIndexe": "energie",
                "ancien": 1902,
                "nouveau": 1955,
                "periode": "2023-05-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.869Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50455,
                "typeIndexe": "eau",
                "ancien": 119,
                "nouveau": 121,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.865Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50325,
                "typeIndexe": "eau",
                "ancien": 117,
                "nouveau": 119,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.859Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50326,
                "typeIndexe": "energie",
                "ancien": 1862,
                "nouveau": 1902,
                "periode": "2023-04-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.862Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50194,
                "typeIndexe": "energie",
                "ancien": 1816,
                "nouveau": 1862,
                "periode": "2023-03-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.852Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50193,
                "typeIndexe": "eau",
                "ancien": 115,
                "nouveau": 117,
                "periode": "2023-03-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.855Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50072,
                "typeIndexe": "eau",
                "ancien": 113,
                "nouveau": 115,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.848Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50073,
                "typeIndexe": "energie",
                "ancien": 1782,
                "nouveau": 1816,
                "periode": "2023-02-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.844Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 49951,
                "typeIndexe": "energie",
                "ancien": 1700,
                "nouveau": 1782,
                "periode": "2023-01-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.840Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49950,
                "typeIndexe": "eau",
                "ancien": 111,
                "nouveau": 113,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.837Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 49843,
                "typeIndexe": "energie",
                "ancien": 1669,
                "nouveau": 1700,
                "periode": "2022-12-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.833Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49842,
                "typeIndexe": "eau",
                "ancien": 109,
                "nouveau": 111,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.828Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51262,
            "typeIndexe": "eau",
            "ancien": 140,
            "nouveau": 142,
            "periode": "2023-11-01",
            "avance": 998,
            "reste": 0,
            "datePaiement": "2023-12-26T10:37:51.877Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 3,
            "idOccupation": 293,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 33,
            "montant": 3630,
            "idIndexe": 51263,
            "typeIndexe": "energie",
            "ancien": 2191,
            "nouveau": 2224,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 3,
            "idOccupation": 293,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 293,
            "loyerBase": 60000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-01-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.934Z",
            "idLocataire": 268,
            "idLogement": 3,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 268,
                "nomLocataire": "TSANE",
                "prenomLocataire": "JIMMY",
                "cniLocataire": "Juriste",
                "tel": "699846687",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:46.177Z",
                "updatedAt": "2022-03-02T11:54:46.177Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                142,
                140
            ],
            "energie": [
                2224,
                2191
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                142,
                140
            ],
            "energie": [
                2224,
                2191
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51128,
                "typeIndexe": "eau",
                "ancien": 135,
                "nouveau": 140,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50988,
                "typeIndexe": "eau",
                "ancien": 131,
                "nouveau": 135,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50856,
                "typeIndexe": "eau",
                "ancien": 127,
                "nouveau": 131,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50726,
                "typeIndexe": "eau",
                "ancien": 124,
                "nouveau": 127,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50591,
                "typeIndexe": "eau",
                "ancien": 121,
                "nouveau": 124,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.873Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50455,
                "typeIndexe": "eau",
                "ancien": 119,
                "nouveau": 121,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.865Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50325,
                "typeIndexe": "eau",
                "ancien": 117,
                "nouveau": 119,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.859Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50193,
                "typeIndexe": "eau",
                "ancien": 115,
                "nouveau": 117,
                "periode": "2023-03-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.855Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50072,
                "typeIndexe": "eau",
                "ancien": 113,
                "nouveau": 115,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.848Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49950,
                "typeIndexe": "eau",
                "ancien": 111,
                "nouveau": 113,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.837Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49842,
                "typeIndexe": "eau",
                "ancien": 109,
                "nouveau": 111,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.828Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 51129,
                "typeIndexe": "energie",
                "ancien": 2145,
                "nouveau": 2191,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 50989,
                "typeIndexe": "energie",
                "ancien": 2094,
                "nouveau": 2145,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 50857,
                "typeIndexe": "energie",
                "ancien": 2043,
                "nouveau": 2094,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50727,
                "typeIndexe": "energie",
                "ancien": 2001,
                "nouveau": 2043,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50593,
                "typeIndexe": "energie",
                "ancien": 1955,
                "nouveau": 2001,
                "periode": "2023-06-01",
                "avance": 57,
                "reste": 5003,
                "datePaiement": "2023-12-26T10:38:15.264Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50456,
                "typeIndexe": "energie",
                "ancien": 1902,
                "nouveau": 1955,
                "periode": "2023-05-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.869Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50326,
                "typeIndexe": "energie",
                "ancien": 1862,
                "nouveau": 1902,
                "periode": "2023-04-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.862Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50194,
                "typeIndexe": "energie",
                "ancien": 1816,
                "nouveau": 1862,
                "periode": "2023-03-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.852Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50073,
                "typeIndexe": "energie",
                "ancien": 1782,
                "nouveau": 1816,
                "periode": "2023-02-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.844Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 49951,
                "typeIndexe": "energie",
                "ancien": 1700,
                "nouveau": 1782,
                "periode": "2023-01-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.840Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 49843,
                "typeIndexe": "energie",
                "ancien": 1669,
                "nouveau": 1700,
                "periode": "2022-12-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": "2023-12-26T10:37:51.833Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 3,
                "idOccupation": 293,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -142,
            "energie": -2224
        },
        "montant": {
            "eau": -70858,
            "energie": -244640
        }
    },
    {
        "idLogement": 4,
        "refLogement": "A004",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 65000,
        "prixMax": 70000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.827Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51264,
                "typeIndexe": "eau",
                "ancien": 208,
                "nouveau": 209,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T10:38:37.844Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 29,
                "montant": 3190,
                "idIndexe": 51265,
                "typeIndexe": "energie",
                "ancien": 1609,
                "nouveau": 1638,
                "periode": "2023-11-01",
                "avance": 3190,
                "reste": 0,
                "datePaiement": "2023-12-26T10:38:37.848Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 33,
                "montant": 3630,
                "idIndexe": 51131,
                "typeIndexe": "energie",
                "ancien": 1576,
                "nouveau": 1609,
                "periode": "2023-10-01",
                "avance": 3630,
                "reste": 0,
                "datePaiement": "2023-11-25T07:47:57.894Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51130,
                "typeIndexe": "eau",
                "ancien": 206,
                "nouveau": 208,
                "periode": "2023-10-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T07:47:57.913Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 23,
                "montant": 2530,
                "idIndexe": 50991,
                "typeIndexe": "energie",
                "ancien": 1553,
                "nouveau": 1576,
                "periode": "2023-09-01",
                "avance": 2530,
                "reste": 0,
                "datePaiement": "2023-10-28T14:20:27.029Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50990,
                "typeIndexe": "eau",
                "ancien": 205,
                "nouveau": 206,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:20:27.053Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50858,
                "typeIndexe": "eau",
                "ancien": 201,
                "nouveau": 205,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T05:53:37.297Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50859,
                "typeIndexe": "energie",
                "ancien": 1528,
                "nouveau": 1553,
                "periode": "2023-08-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-09-28T05:53:37.314Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50728,
                "typeIndexe": "eau",
                "ancien": 196,
                "nouveau": 201,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T05:56:09.702Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50729,
                "typeIndexe": "energie",
                "ancien": 1504,
                "nouveau": 1528,
                "periode": "2023-07-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-08-30T05:56:14.913Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 50595,
                "typeIndexe": "energie",
                "ancien": 1473,
                "nouveau": 1504,
                "periode": "2023-06-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": "2023-07-26T12:15:30.146Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50594,
                "typeIndexe": "eau",
                "ancien": 187,
                "nouveau": 196,
                "periode": "2023-06-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-26T12:15:30.164Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50458,
                "typeIndexe": "energie",
                "ancien": 1441,
                "nouveau": 1473,
                "periode": "2023-05-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-06-27T08:45:03.195Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50457,
                "typeIndexe": "eau",
                "ancien": 184,
                "nouveau": 187,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-06-27T08:45:03.192Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50327,
                "typeIndexe": "eau",
                "ancien": 182,
                "nouveau": 184,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T09:13:40.013Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50328,
                "typeIndexe": "energie",
                "ancien": 1411,
                "nouveau": 1441,
                "periode": "2023-04-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2023-05-30T09:13:47.701Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50195,
                "typeIndexe": "eau",
                "ancien": 171,
                "nouveau": 182,
                "periode": "2023-03-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-04-28T13:35:42.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50196,
                "typeIndexe": "energie",
                "ancien": 1402,
                "nouveau": 1411,
                "periode": "2023-03-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-04-28T13:35:42.399Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50074,
                "typeIndexe": "eau",
                "ancien": 167,
                "nouveau": 171,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T07:26:39.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50075,
                "typeIndexe": "energie",
                "ancien": 1378,
                "nouveau": 1402,
                "periode": "2023-02-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-03-29T07:26:39.445Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 49953,
                "typeIndexe": "energie",
                "ancien": 1326,
                "nouveau": 1378,
                "periode": "2023-01-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-02-23T06:20:20.620Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49952,
                "typeIndexe": "eau",
                "ancien": 164,
                "nouveau": 167,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-23T06:20:20.617Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 49845,
                "typeIndexe": "energie",
                "ancien": 1294,
                "nouveau": 1326,
                "periode": "2022-12-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-02-22T10:55:27.997Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49844,
                "typeIndexe": "eau",
                "ancien": 162,
                "nouveau": 164,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T10:55:27.994Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51264,
            "typeIndexe": "eau",
            "ancien": 208,
            "nouveau": 209,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T10:38:37.844Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 4,
            "idOccupation": 271,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 29,
            "montant": 3190,
            "idIndexe": 51265,
            "typeIndexe": "energie",
            "ancien": 1609,
            "nouveau": 1638,
            "periode": "2023-11-01",
            "avance": 3190,
            "reste": 0,
            "datePaiement": "2023-12-26T10:38:37.848Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 4,
            "idOccupation": 271,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 271,
            "loyerBase": 65000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-06-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.826Z",
            "idLocataire": 379,
            "idLogement": 4,
            "createdBy": null,
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 379,
                "nomLocataire": "Amougou NNAMA",
                "prenomLocataire": "Julie",
                "cniLocataire": "",
                "tel": "697058888",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mlle",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-09-29T10:58:57.408Z",
                "updatedAt": "2022-09-29T10:58:57.408Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                209,
                208
            ],
            "energie": [
                1638,
                1609
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                209,
                208
            ],
            "energie": [
                1638,
                1609
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51130,
                "typeIndexe": "eau",
                "ancien": 206,
                "nouveau": 208,
                "periode": "2023-10-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T07:47:57.913Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50990,
                "typeIndexe": "eau",
                "ancien": 205,
                "nouveau": 206,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:20:27.053Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50858,
                "typeIndexe": "eau",
                "ancien": 201,
                "nouveau": 205,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T05:53:37.297Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50728,
                "typeIndexe": "eau",
                "ancien": 196,
                "nouveau": 201,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T05:56:09.702Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50594,
                "typeIndexe": "eau",
                "ancien": 187,
                "nouveau": 196,
                "periode": "2023-06-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-26T12:15:30.164Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50457,
                "typeIndexe": "eau",
                "ancien": 184,
                "nouveau": 187,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-06-27T08:45:03.192Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50327,
                "typeIndexe": "eau",
                "ancien": 182,
                "nouveau": 184,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T09:13:40.013Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50195,
                "typeIndexe": "eau",
                "ancien": 171,
                "nouveau": 182,
                "periode": "2023-03-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-04-28T13:35:42.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50074,
                "typeIndexe": "eau",
                "ancien": 167,
                "nouveau": 171,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T07:26:39.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49952,
                "typeIndexe": "eau",
                "ancien": 164,
                "nouveau": 167,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-23T06:20:20.617Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49844,
                "typeIndexe": "eau",
                "ancien": 162,
                "nouveau": 164,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T10:55:27.994Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 33,
                "montant": 3630,
                "idIndexe": 51131,
                "typeIndexe": "energie",
                "ancien": 1576,
                "nouveau": 1609,
                "periode": "2023-10-01",
                "avance": 3630,
                "reste": 0,
                "datePaiement": "2023-11-25T07:47:57.894Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 23,
                "montant": 2530,
                "idIndexe": 50991,
                "typeIndexe": "energie",
                "ancien": 1553,
                "nouveau": 1576,
                "periode": "2023-09-01",
                "avance": 2530,
                "reste": 0,
                "datePaiement": "2023-10-28T14:20:27.029Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50859,
                "typeIndexe": "energie",
                "ancien": 1528,
                "nouveau": 1553,
                "periode": "2023-08-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-09-28T05:53:37.314Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50729,
                "typeIndexe": "energie",
                "ancien": 1504,
                "nouveau": 1528,
                "periode": "2023-07-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-08-30T05:56:14.913Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 50595,
                "typeIndexe": "energie",
                "ancien": 1473,
                "nouveau": 1504,
                "periode": "2023-06-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": "2023-07-26T12:15:30.146Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50458,
                "typeIndexe": "energie",
                "ancien": 1441,
                "nouveau": 1473,
                "periode": "2023-05-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-06-27T08:45:03.195Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50328,
                "typeIndexe": "energie",
                "ancien": 1411,
                "nouveau": 1441,
                "periode": "2023-04-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2023-05-30T09:13:47.701Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50196,
                "typeIndexe": "energie",
                "ancien": 1402,
                "nouveau": 1411,
                "periode": "2023-03-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-04-28T13:35:42.399Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50075,
                "typeIndexe": "energie",
                "ancien": 1378,
                "nouveau": 1402,
                "periode": "2023-02-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-03-29T07:26:39.445Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 49953,
                "typeIndexe": "energie",
                "ancien": 1326,
                "nouveau": 1378,
                "periode": "2023-01-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-02-23T06:20:20.620Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 49845,
                "typeIndexe": "energie",
                "ancien": 1294,
                "nouveau": 1326,
                "periode": "2022-12-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-02-22T10:55:27.997Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 4,
                "idOccupation": 271,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -209,
            "energie": -1638
        },
        "montant": {
            "eau": -104291,
            "energie": -180180
        }
    },
    {
        "idLogement": 5,
        "refLogement": "A005",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-11-27T15:18:59.505Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51266,
                "typeIndexe": "eau",
                "ancien": 785,
                "nouveau": 785,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51267,
                "typeIndexe": "energie",
                "ancien": 1966,
                "nouveau": 1967,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51133,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1966,
                "periode": "2023-10-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-25T10:52:26.390Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51132,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 785,
                "periode": "2023-10-01",
                "avance": 1990,
                "reste": 505,
                "datePaiement": "2023-11-27T15:18:39.279Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50993,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50992,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50995,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50994,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50860,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50861,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50730,
                "typeIndexe": "eau",
                "ancien": 777,
                "nouveau": 780,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-25T10:52:26.383Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50731,
                "typeIndexe": "energie",
                "ancien": 1947,
                "nouveau": 1965,
                "periode": "2023-07-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-11-27T15:18:25.856Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50719,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50718,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49147,
                "typeIndexe": "energie",
                "ancien": 1405,
                "nouveau": 1405,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -737,
                "montant": 0,
                "idIndexe": 49256,
                "typeIndexe": "eau",
                "ancien": 737,
                "nouveau": 0,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -1405,
                "montant": 0,
                "idIndexe": 49257,
                "typeIndexe": "energie",
                "ancien": 1405,
                "nouveau": 0,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49146,
                "typeIndexe": "eau",
                "ancien": 737,
                "nouveau": 737,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 49013,
                "typeIndexe": "energie",
                "ancien": 1363,
                "nouveau": 1405,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49012,
                "typeIndexe": "eau",
                "ancien": 734,
                "nouveau": 737,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 48878,
                "typeIndexe": "energie",
                "ancien": 1333,
                "nouveau": 1363,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 48877,
                "typeIndexe": "eau",
                "ancien": 733,
                "nouveau": 734,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 48737,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 733,
                "periode": "2022-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-05-27T21:41:44.707Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 48833,
                "typeIndexe": "energie",
                "ancien": 1345,
                "nouveau": 1363,
                "periode": "2022-04-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2022-05-27T21:41:44.709Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51266,
            "typeIndexe": "eau",
            "ancien": 785,
            "nouveau": 785,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 5,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 1,
            "montant": 110,
            "idIndexe": 51267,
            "typeIndexe": "energie",
            "ancien": 1966,
            "nouveau": 1967,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 5,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                785,
                785
            ],
            "energie": [
                1967,
                1966
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                785,
                785
            ],
            "energie": [
                1967,
                1966
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51132,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 785,
                "periode": "2023-10-01",
                "avance": 1990,
                "reste": 505,
                "datePaiement": "2023-11-27T15:18:39.279Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50993,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50992,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50860,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 780,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50730,
                "typeIndexe": "eau",
                "ancien": 777,
                "nouveau": 780,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-25T10:52:26.383Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49146,
                "typeIndexe": "eau",
                "ancien": 737,
                "nouveau": 737,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49012,
                "typeIndexe": "eau",
                "ancien": 734,
                "nouveau": 737,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 48877,
                "typeIndexe": "eau",
                "ancien": 733,
                "nouveau": 734,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 48737,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 733,
                "periode": "2022-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-05-27T21:41:44.707Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51133,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1966,
                "periode": "2023-10-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-25T10:52:26.390Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50995,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50994,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50861,
                "typeIndexe": "energie",
                "ancien": 1965,
                "nouveau": 1965,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50731,
                "typeIndexe": "energie",
                "ancien": 1947,
                "nouveau": 1965,
                "periode": "2023-07-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-11-27T15:18:25.856Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 455,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49147,
                "typeIndexe": "energie",
                "ancien": 1405,
                "nouveau": 1405,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 49013,
                "typeIndexe": "energie",
                "ancien": 1363,
                "nouveau": 1405,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 48878,
                "typeIndexe": "energie",
                "ancien": 1333,
                "nouveau": 1363,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 48833,
                "typeIndexe": "energie",
                "ancien": 1345,
                "nouveau": 1363,
                "periode": "2022-04-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2022-05-27T21:41:44.709Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 5,
                "idOccupation": 347,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -785,
            "energie": -1967
        },
        "montant": {
            "eau": -391715,
            "energie": -216370
        }
    },
    {
        "idLogement": 7,
        "refLogement": "A007",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 60000,
        "prixMax": 65000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-08-30T19:28:13.568Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51268,
                "typeIndexe": "eau",
                "ancien": 1999,
                "nouveau": 2001,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 51269,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 13502,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51135,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 13496,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51134,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 1999,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50997,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 13496,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50996,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 1998,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50864,
                "typeIndexe": "eau",
                "ancien": 1996,
                "nouveau": 1998,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50865,
                "typeIndexe": "energie",
                "ancien": 13468,
                "nouveau": 13496,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50734,
                "typeIndexe": "eau",
                "ancien": 1994,
                "nouveau": 1996,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:05:34.951Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 71,
                "montant": 7810,
                "idIndexe": 50735,
                "typeIndexe": "energie",
                "ancien": 13397,
                "nouveau": 13468,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50599,
                "typeIndexe": "energie",
                "ancien": 13315,
                "nouveau": 13397,
                "periode": "2023-06-01",
                "avance": 6673,
                "reste": 2347,
                "datePaiement": "2023-07-26T12:20:15.453Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50598,
                "typeIndexe": "eau",
                "ancien": 1989,
                "nouveau": 1994,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.570Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50459,
                "typeIndexe": "eau",
                "ancien": 1981,
                "nouveau": 1989,
                "periode": "2023-05-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.562Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 50460,
                "typeIndexe": "energie",
                "ancien": 13228,
                "nouveau": 13315,
                "periode": "2023-05-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.566Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50330,
                "typeIndexe": "energie",
                "ancien": 13144,
                "nouveau": 13228,
                "periode": "2023-04-01",
                "avance": 9240,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.559Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50329,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1981,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-05-30T09:33:28.529Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50197,
                "typeIndexe": "eau",
                "ancien": 1971,
                "nouveau": 1975,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-05-30T09:33:09.493Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 50198,
                "typeIndexe": "energie",
                "ancien": 13071,
                "nouveau": 13144,
                "periode": "2023-03-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.548Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50076,
                "typeIndexe": "eau",
                "ancien": 1962,
                "nouveau": 1971,
                "periode": "2023-02-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-27T23:02:43.446Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 8910,
                "idIndexe": 50077,
                "typeIndexe": "energie",
                "ancien": 12990,
                "nouveau": 13071,
                "periode": "2023-02-01",
                "avance": 8910,
                "reste": 0,
                "datePaiement": "2023-02-27T23:03:21.207Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49954,
                "typeIndexe": "eau",
                "ancien": 1957,
                "nouveau": 1962,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-03-29T07:31:27.984Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 105,
                "montant": 11550,
                "idIndexe": 49955,
                "typeIndexe": "energie",
                "ancien": 12885,
                "nouveau": 12990,
                "periode": "2023-01-01",
                "avance": 9092,
                "reste": 2458,
                "datePaiement": "2023-08-30T06:05:55.996Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49846,
                "typeIndexe": "eau",
                "ancien": 1950,
                "nouveau": 1957,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-23T06:23:32.401Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 49847,
                "typeIndexe": "energie",
                "ancien": 12798,
                "nouveau": 12885,
                "periode": "2022-12-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-05-30T09:34:00.707Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51268,
            "typeIndexe": "eau",
            "ancien": 1999,
            "nouveau": 2001,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 7,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 6,
            "montant": 660,
            "idIndexe": 51269,
            "typeIndexe": "energie",
            "ancien": 13496,
            "nouveau": 13502,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 7,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2001,
                1999
            ],
            "energie": [
                13502,
                13496
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2001,
                1999
            ],
            "energie": [
                13502,
                13496
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51134,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 1999,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50996,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 1998,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50864,
                "typeIndexe": "eau",
                "ancien": 1996,
                "nouveau": 1998,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50734,
                "typeIndexe": "eau",
                "ancien": 1994,
                "nouveau": 1996,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:05:34.951Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50598,
                "typeIndexe": "eau",
                "ancien": 1989,
                "nouveau": 1994,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.570Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50459,
                "typeIndexe": "eau",
                "ancien": 1981,
                "nouveau": 1989,
                "periode": "2023-05-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.562Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50329,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1981,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-05-30T09:33:28.529Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50197,
                "typeIndexe": "eau",
                "ancien": 1971,
                "nouveau": 1975,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-05-30T09:33:09.493Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50076,
                "typeIndexe": "eau",
                "ancien": 1962,
                "nouveau": 1971,
                "periode": "2023-02-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-27T23:02:43.446Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49954,
                "typeIndexe": "eau",
                "ancien": 1957,
                "nouveau": 1962,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-03-29T07:31:27.984Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49846,
                "typeIndexe": "eau",
                "ancien": 1950,
                "nouveau": 1957,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-23T06:23:32.401Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51135,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 13496,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50997,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 13496,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50865,
                "typeIndexe": "energie",
                "ancien": 13468,
                "nouveau": 13496,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 71,
                "montant": 7810,
                "idIndexe": 50735,
                "typeIndexe": "energie",
                "ancien": 13397,
                "nouveau": 13468,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50599,
                "typeIndexe": "energie",
                "ancien": 13315,
                "nouveau": 13397,
                "periode": "2023-06-01",
                "avance": 6673,
                "reste": 2347,
                "datePaiement": "2023-07-26T12:20:15.453Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 50460,
                "typeIndexe": "energie",
                "ancien": 13228,
                "nouveau": 13315,
                "periode": "2023-05-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.566Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50330,
                "typeIndexe": "energie",
                "ancien": 13144,
                "nouveau": 13228,
                "periode": "2023-04-01",
                "avance": 9240,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.559Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 50198,
                "typeIndexe": "energie",
                "ancien": 13071,
                "nouveau": 13144,
                "periode": "2023-03-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-07-26T12:19:43.548Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 8910,
                "idIndexe": 50077,
                "typeIndexe": "energie",
                "ancien": 12990,
                "nouveau": 13071,
                "periode": "2023-02-01",
                "avance": 8910,
                "reste": 0,
                "datePaiement": "2023-02-27T23:03:21.207Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 105,
                "montant": 11550,
                "idIndexe": 49955,
                "typeIndexe": "energie",
                "ancien": 12885,
                "nouveau": 12990,
                "periode": "2023-01-01",
                "avance": 9092,
                "reste": 2458,
                "datePaiement": "2023-08-30T06:05:55.996Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 49847,
                "typeIndexe": "energie",
                "ancien": 12798,
                "nouveau": 12885,
                "periode": "2022-12-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-05-30T09:34:00.707Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 7,
                "idOccupation": 358,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2001,
            "energie": -13502
        },
        "montant": {
            "eau": -998499,
            "energie": -1485220
        }
    },
    {
        "idLogement": 9,
        "refLogement": "A009",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-07-31T11:38:02.222Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 51270,
                "typeIndexe": "eau",
                "ancien": 756,
                "nouveau": 771,
                "periode": "2023-11-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-12-26T10:39:57.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51271,
                "typeIndexe": "energie",
                "ancien": 250,
                "nouveau": 252,
                "periode": "2023-11-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-12-26T10:39:57.817Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51137,
                "typeIndexe": "energie",
                "ancien": 243,
                "nouveau": 250,
                "periode": "2023-10-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-11-25T08:09:03.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51136,
                "typeIndexe": "eau",
                "ancien": 751,
                "nouveau": 756,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:09:03.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50999,
                "typeIndexe": "energie",
                "ancien": 241,
                "nouveau": 243,
                "periode": "2023-09-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-10-28T14:21:36.641Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50998,
                "typeIndexe": "eau",
                "ancien": 746,
                "nouveau": 751,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T14:21:36.637Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50866,
                "typeIndexe": "eau",
                "ancien": 742,
                "nouveau": 746,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:19.055Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50867,
                "typeIndexe": "energie",
                "ancien": 240,
                "nouveau": 241,
                "periode": "2023-08-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:19.061Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50737,
                "typeIndexe": "energie",
                "ancien": 235,
                "nouveau": 240,
                "periode": "2023-07-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-08-30T06:08:05.144Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50736,
                "typeIndexe": "eau",
                "ancien": 733,
                "nouveau": 742,
                "periode": "2023-07-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-08-30T06:08:05.140Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50601,
                "typeIndexe": "energie",
                "ancien": 217,
                "nouveau": 235,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50600,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 733,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50463,
                "typeIndexe": "energie",
                "ancien": 208,
                "nouveau": 217,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50462,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50464,
                "typeIndexe": "energie",
                "ancien": 208,
                "nouveau": 217,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50461,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50332,
                "typeIndexe": "energie",
                "ancien": 196,
                "nouveau": 208,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50331,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 731,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50201,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:47.490Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50200,
                "typeIndexe": "eau",
                "ancien": 713,
                "nouveau": 730,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50202,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:33.730Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50203,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 52,
                "reste": 7648,
                "datePaiement": "2023-06-27T08:47:39.201Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50199,
                "typeIndexe": "eau",
                "ancien": 713,
                "nouveau": 730,
                "periode": "2023-03-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:25.009Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 707,
                "montant": 352793,
                "idIndexe": 50078,
                "typeIndexe": "eau",
                "ancien": 6,
                "nouveau": 713,
                "periode": "2023-02-01",
                "avance": 352793,
                "reste": 0,
                "datePaiement": "2023-03-29T11:40:39.716Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 15,
            "montant": 7485,
            "idIndexe": 51270,
            "typeIndexe": "eau",
            "ancien": 756,
            "nouveau": 771,
            "periode": "2023-11-01",
            "avance": 7485,
            "reste": 0,
            "datePaiement": "2023-12-26T10:39:57.822Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 9,
            "idOccupation": 90,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 2,
            "montant": 220,
            "idIndexe": 51271,
            "typeIndexe": "energie",
            "ancien": 250,
            "nouveau": 252,
            "periode": "2023-11-01",
            "avance": 220,
            "reste": 0,
            "datePaiement": "2023-12-26T10:39:57.817Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 9,
            "idOccupation": 90,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 90,
            "loyerBase": 30000,
            "caution": 100000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-07-10T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-07-31T11:38:01.401Z",
            "idLocataire": 409,
            "idLogement": 9,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 409,
                "nomLocataire": "nguenebonn",
                "prenomLocataire": "elisabeth",
                "cniLocataire": "",
                "tel": "690596885",
                "tel2": "651231222",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-07-31T10:47:09.827Z",
                "updatedAt": "2023-07-31T10:47:09.827Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                771,
                756
            ],
            "energie": [
                252,
                250
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                771,
                756
            ],
            "energie": [
                252,
                250
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51136,
                "typeIndexe": "eau",
                "ancien": 751,
                "nouveau": 756,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:09:03.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50998,
                "typeIndexe": "eau",
                "ancien": 746,
                "nouveau": 751,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T14:21:36.637Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50866,
                "typeIndexe": "eau",
                "ancien": 742,
                "nouveau": 746,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:19.055Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50736,
                "typeIndexe": "eau",
                "ancien": 733,
                "nouveau": 742,
                "periode": "2023-07-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-08-30T06:08:05.140Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50600,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 733,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50462,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50461,
                "typeIndexe": "eau",
                "ancien": 731,
                "nouveau": 731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50331,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 731,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50200,
                "typeIndexe": "eau",
                "ancien": 713,
                "nouveau": 730,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50199,
                "typeIndexe": "eau",
                "ancien": 713,
                "nouveau": 730,
                "periode": "2023-03-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:25.009Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 707,
                "montant": 352793,
                "idIndexe": 50078,
                "typeIndexe": "eau",
                "ancien": 6,
                "nouveau": 713,
                "periode": "2023-02-01",
                "avance": 352793,
                "reste": 0,
                "datePaiement": "2023-03-29T11:40:39.716Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51137,
                "typeIndexe": "energie",
                "ancien": 243,
                "nouveau": 250,
                "periode": "2023-10-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-11-25T08:09:03.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50999,
                "typeIndexe": "energie",
                "ancien": 241,
                "nouveau": 243,
                "periode": "2023-09-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-10-28T14:21:36.641Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50867,
                "typeIndexe": "energie",
                "ancien": 240,
                "nouveau": 241,
                "periode": "2023-08-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:19.061Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50737,
                "typeIndexe": "energie",
                "ancien": 235,
                "nouveau": 240,
                "periode": "2023-07-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-08-30T06:08:05.144Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 90,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50601,
                "typeIndexe": "energie",
                "ancien": 217,
                "nouveau": 235,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50463,
                "typeIndexe": "energie",
                "ancien": 208,
                "nouveau": 217,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50464,
                "typeIndexe": "energie",
                "ancien": 208,
                "nouveau": 217,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50332,
                "typeIndexe": "energie",
                "ancien": 196,
                "nouveau": 208,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50201,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:47.490Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50202,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-03-29T11:41:33.730Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50203,
                "typeIndexe": "energie",
                "ancien": 126,
                "nouveau": 196,
                "periode": "2023-03-01",
                "avance": 52,
                "reste": 7648,
                "datePaiement": "2023-06-27T08:47:39.201Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 9,
                "idOccupation": 443,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -771,
            "energie": -252
        },
        "montant": {
            "eau": -384729,
            "energie": -27720
        }
    },
    {
        "idLogement": 10,
        "refLogement": "A010",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 80000,
        "prixMax": 90000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.878Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51272,
                "typeIndexe": "eau",
                "ancien": 4765,
                "nouveau": 4773,
                "periode": "2023-11-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-12-26T10:40:29.287Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 51273,
                "typeIndexe": "energie",
                "ancien": 25847,
                "nouveau": 25917,
                "periode": "2023-11-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-12-26T10:40:29.291Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 51139,
                "typeIndexe": "energie",
                "ancien": 25764,
                "nouveau": 25847,
                "periode": "2023-10-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-12-26T10:40:45.199Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 51138,
                "typeIndexe": "eau",
                "ancien": 4750,
                "nouveau": 4765,
                "periode": "2023-10-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-11-25T10:06:10.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51034,
                "typeIndexe": "eau",
                "ancien": 4740,
                "nouveau": 4750,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:09.432Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 9460,
                "idIndexe": 51035,
                "typeIndexe": "energie",
                "ancien": 25678,
                "nouveau": 25764,
                "periode": "2023-09-01",
                "avance": 9460,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:09.435Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50868,
                "typeIndexe": "eau",
                "ancien": 4733,
                "nouveau": 4740,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:56.261Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 50869,
                "typeIndexe": "energie",
                "ancien": 25616,
                "nouveau": 25678,
                "periode": "2023-08-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:56.266Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50739,
                "typeIndexe": "energie",
                "ancien": 25563,
                "nouveau": 25616,
                "periode": "2023-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-08-30T06:09:21.718Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50738,
                "typeIndexe": "eau",
                "ancien": 4725,
                "nouveau": 4733,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:09:21.715Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50602,
                "typeIndexe": "eau",
                "ancien": 4712,
                "nouveau": 4725,
                "periode": "2023-06-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-07-26T12:21:53.369Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 69,
                "montant": 7590,
                "idIndexe": 50603,
                "typeIndexe": "energie",
                "ancien": 25494,
                "nouveau": 25563,
                "periode": "2023-06-01",
                "avance": 7590,
                "reste": 0,
                "datePaiement": "2023-07-26T12:21:53.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50465,
                "typeIndexe": "eau",
                "ancien": 4701,
                "nouveau": 4712,
                "periode": "2023-05-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.886Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50466,
                "typeIndexe": "energie",
                "ancien": 25433,
                "nouveau": 25494,
                "periode": "2023-05-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50333,
                "typeIndexe": "eau",
                "ancien": 4689,
                "nouveau": 4701,
                "periode": "2023-04-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.847Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50334,
                "typeIndexe": "energie",
                "ancien": 25380,
                "nouveau": 25433,
                "periode": "2023-04-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.851Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50204,
                "typeIndexe": "eau",
                "ancien": 4680,
                "nouveau": 4689,
                "periode": "2023-03-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-04-28T13:45:39.209Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 50205,
                "typeIndexe": "energie",
                "ancien": 25328,
                "nouveau": 25380,
                "periode": "2023-03-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:09.031Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50080,
                "typeIndexe": "eau",
                "ancien": 4669,
                "nouveau": 4680,
                "periode": "2023-02-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-03-29T07:51:22.105Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50081,
                "typeIndexe": "energie",
                "ancien": 25267,
                "nouveau": 25328,
                "periode": "2023-02-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-03-29T07:51:22.101Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 49961,
                "typeIndexe": "energie",
                "ancien": 25190,
                "nouveau": 25267,
                "periode": "2023-01-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-02-23T06:25:31.387Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49960,
                "typeIndexe": "eau",
                "ancien": 4659,
                "nouveau": 4669,
                "periode": "2023-01-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-23T06:25:31.383Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 49848,
                "typeIndexe": "eau",
                "ancien": 4645,
                "nouveau": 4659,
                "periode": "2022-12-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-02-22T10:57:22.317Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 94,
                "montant": 10340,
                "idIndexe": 49849,
                "typeIndexe": "energie",
                "ancien": 25096,
                "nouveau": 25190,
                "periode": "2022-12-01",
                "avance": 10340,
                "reste": 0,
                "datePaiement": "2023-02-22T10:57:22.320Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 8,
            "montant": 3992,
            "idIndexe": 51272,
            "typeIndexe": "eau",
            "ancien": 4765,
            "nouveau": 4773,
            "periode": "2023-11-01",
            "avance": 3992,
            "reste": 0,
            "datePaiement": "2023-12-26T10:40:29.287Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 10,
            "idOccupation": 136,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 70,
            "montant": 7700,
            "idIndexe": 51273,
            "typeIndexe": "energie",
            "ancien": 25847,
            "nouveau": 25917,
            "periode": "2023-11-01",
            "avance": 7700,
            "reste": 0,
            "datePaiement": "2023-12-26T10:40:29.291Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 10,
            "idOccupation": 136,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 136,
            "loyerBase": 80000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.869Z",
            "idLocataire": 136,
            "idLogement": 10,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 136,
                "nomLocataire": "DIPITA EBOULE",
                "prenomLocataire": "D.",
                "cniLocataire": "",
                "tel": "696575068",
                "tel2": "655570021",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.178Z",
                "updatedAt": "2022-03-02T11:54:44.178Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                4773,
                4765
            ],
            "energie": [
                25917,
                25847
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                4773,
                4765
            ],
            "energie": [
                25917,
                25847
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 51138,
                "typeIndexe": "eau",
                "ancien": 4750,
                "nouveau": 4765,
                "periode": "2023-10-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-11-25T10:06:10.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51034,
                "typeIndexe": "eau",
                "ancien": 4740,
                "nouveau": 4750,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:09.432Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50868,
                "typeIndexe": "eau",
                "ancien": 4733,
                "nouveau": 4740,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:56.261Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50738,
                "typeIndexe": "eau",
                "ancien": 4725,
                "nouveau": 4733,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:09:21.715Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50602,
                "typeIndexe": "eau",
                "ancien": 4712,
                "nouveau": 4725,
                "periode": "2023-06-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-07-26T12:21:53.369Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50465,
                "typeIndexe": "eau",
                "ancien": 4701,
                "nouveau": 4712,
                "periode": "2023-05-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.886Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50333,
                "typeIndexe": "eau",
                "ancien": 4689,
                "nouveau": 4701,
                "periode": "2023-04-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.847Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50204,
                "typeIndexe": "eau",
                "ancien": 4680,
                "nouveau": 4689,
                "periode": "2023-03-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-04-28T13:45:39.209Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50080,
                "typeIndexe": "eau",
                "ancien": 4669,
                "nouveau": 4680,
                "periode": "2023-02-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-03-29T07:51:22.105Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49960,
                "typeIndexe": "eau",
                "ancien": 4659,
                "nouveau": 4669,
                "periode": "2023-01-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-23T06:25:31.383Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 49848,
                "typeIndexe": "eau",
                "ancien": 4645,
                "nouveau": 4659,
                "periode": "2022-12-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-02-22T10:57:22.317Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 51139,
                "typeIndexe": "energie",
                "ancien": 25764,
                "nouveau": 25847,
                "periode": "2023-10-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-12-26T10:40:45.199Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 9460,
                "idIndexe": 51035,
                "typeIndexe": "energie",
                "ancien": 25678,
                "nouveau": 25764,
                "periode": "2023-09-01",
                "avance": 9460,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:09.435Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 50869,
                "typeIndexe": "energie",
                "ancien": 25616,
                "nouveau": 25678,
                "periode": "2023-08-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2023-09-28T05:54:56.266Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50739,
                "typeIndexe": "energie",
                "ancien": 25563,
                "nouveau": 25616,
                "periode": "2023-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-08-30T06:09:21.718Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 69,
                "montant": 7590,
                "idIndexe": 50603,
                "typeIndexe": "energie",
                "ancien": 25494,
                "nouveau": 25563,
                "periode": "2023-06-01",
                "avance": 7590,
                "reste": 0,
                "datePaiement": "2023-07-26T12:21:53.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50466,
                "typeIndexe": "energie",
                "ancien": 25433,
                "nouveau": 25494,
                "periode": "2023-05-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50334,
                "typeIndexe": "energie",
                "ancien": 25380,
                "nouveau": 25433,
                "periode": "2023-04-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-06-27T08:48:54.851Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 50205,
                "typeIndexe": "energie",
                "ancien": 25328,
                "nouveau": 25380,
                "periode": "2023-03-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:09.031Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50081,
                "typeIndexe": "energie",
                "ancien": 25267,
                "nouveau": 25328,
                "periode": "2023-02-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-03-29T07:51:22.101Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 49961,
                "typeIndexe": "energie",
                "ancien": 25190,
                "nouveau": 25267,
                "periode": "2023-01-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-02-23T06:25:31.387Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 94,
                "montant": 10340,
                "idIndexe": 49849,
                "typeIndexe": "energie",
                "ancien": 25096,
                "nouveau": 25190,
                "periode": "2022-12-01",
                "avance": 10340,
                "reste": 0,
                "datePaiement": "2023-02-22T10:57:22.320Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 10,
                "idOccupation": 136,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -4773,
            "energie": -25917
        },
        "montant": {
            "eau": -2381727,
            "energie": -2850870
        }
    },
    {
        "idLogement": 11,
        "refLogement": "A011",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-07-29T15:43:41.209Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 51275,
                "typeIndexe": "energie",
                "ancien": 908,
                "nouveau": 391,
                "periode": "2023-11-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-12-26T10:41:16.398Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51274,
                "typeIndexe": "eau",
                "ancien": 914,
                "nouveau": 916,
                "periode": "2023-11-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:41:16.395Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 51141,
                "typeIndexe": "energie",
                "ancien": 891,
                "nouveau": 908,
                "periode": "2023-10-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-11-25T10:25:09.270Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51140,
                "typeIndexe": "eau",
                "ancien": 914,
                "nouveau": 914,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 51001,
                "typeIndexe": "energie",
                "ancien": 871,
                "nouveau": 891,
                "periode": "2023-09-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-11-25T10:25:09.249Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51000,
                "typeIndexe": "eau",
                "ancien": 913,
                "nouveau": 914,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:31.977Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50870,
                "typeIndexe": "eau",
                "ancien": 912,
                "nouveau": 913,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T05:55:29.762Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 2310,
                "idIndexe": 50871,
                "typeIndexe": "energie",
                "ancien": 850,
                "nouveau": 871,
                "periode": "2023-08-01",
                "avance": 2310,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:31.974Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50740,
                "typeIndexe": "eau",
                "ancien": 910,
                "nouveau": 912,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:10:17.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50741,
                "typeIndexe": "energie",
                "ancien": 830,
                "nouveau": 850,
                "periode": "2023-07-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T05:55:29.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50604,
                "typeIndexe": "eau",
                "ancien": 908,
                "nouveau": 910,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:33.544Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50605,
                "typeIndexe": "energie",
                "ancien": 810,
                "nouveau": 830,
                "periode": "2023-06-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-08-30T06:10:17.746Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50468,
                "typeIndexe": "energie",
                "ancien": 790,
                "nouveau": 810,
                "periode": "2023-05-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:33.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50467,
                "typeIndexe": "eau",
                "ancien": 907,
                "nouveau": 908,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.865Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50335,
                "typeIndexe": "eau",
                "ancien": 905,
                "nouveau": 907,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.862Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50336,
                "typeIndexe": "energie",
                "ancien": 770,
                "nouveau": 790,
                "periode": "2023-04-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:54.378Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50207,
                "typeIndexe": "energie",
                "ancien": 750,
                "nouveau": 770,
                "periode": "2023-03-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.859Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50206,
                "typeIndexe": "eau",
                "ancien": 904,
                "nouveau": 905,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T13:46:27.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50083,
                "typeIndexe": "energie",
                "ancien": 725,
                "nouveau": 750,
                "periode": "2023-02-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T13:46:27.537Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50082,
                "typeIndexe": "eau",
                "ancien": 902,
                "nouveau": 904,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:04.345Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49963,
                "typeIndexe": "energie",
                "ancien": 700,
                "nouveau": 725,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:04.342Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49962,
                "typeIndexe": "eau",
                "ancien": 900,
                "nouveau": 902,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.987Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49850,
                "typeIndexe": "eau",
                "ancien": 898,
                "nouveau": 900,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.981Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49851,
                "typeIndexe": "energie",
                "ancien": 675,
                "nouveau": 700,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.984Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51274,
            "typeIndexe": "eau",
            "ancien": 914,
            "nouveau": 916,
            "periode": "2023-11-01",
            "avance": 998,
            "reste": 0,
            "datePaiement": "2023-12-26T10:41:16.395Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 11,
            "idOccupation": 185,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 16,
            "montant": 1760,
            "idIndexe": 51275,
            "typeIndexe": "energie",
            "ancien": 908,
            "nouveau": 924,
            "periode": "2023-11-01",
            "avance": 1760,
            "reste": 0,
            "datePaiement": "2023-12-26T10:41:16.398Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 11,
            "idOccupation": 185,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 185,
            "loyerBase": 30000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2016-11-30T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.333Z",
            "idLocataire": 177,
            "idLogement": 11,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 177,
                "nomLocataire": "LAKONDJI .M",
                "prenomLocataire": "Thierry Georges",
                "cniLocataire": "",
                "tel": "673277820",
                "tel2": "699676037",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.325Z",
                "updatedAt": "2022-03-02T11:54:45.325Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                916,
                914
            ],
            "energie": [
                924,
                908
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                916,
                914
            ],
            "energie": [
                924,
                908
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51140,
                "typeIndexe": "eau",
                "ancien": 914,
                "nouveau": 914,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51000,
                "typeIndexe": "eau",
                "ancien": 913,
                "nouveau": 914,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:31.977Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50870,
                "typeIndexe": "eau",
                "ancien": 912,
                "nouveau": 913,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T05:55:29.762Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50740,
                "typeIndexe": "eau",
                "ancien": 910,
                "nouveau": 912,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:10:17.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50604,
                "typeIndexe": "eau",
                "ancien": 908,
                "nouveau": 910,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:33.544Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50467,
                "typeIndexe": "eau",
                "ancien": 907,
                "nouveau": 908,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.865Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50335,
                "typeIndexe": "eau",
                "ancien": 905,
                "nouveau": 907,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.862Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50206,
                "typeIndexe": "eau",
                "ancien": 904,
                "nouveau": 905,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T13:46:27.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50082,
                "typeIndexe": "eau",
                "ancien": 902,
                "nouveau": 904,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:04.345Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49962,
                "typeIndexe": "eau",
                "ancien": 900,
                "nouveau": 902,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.987Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49850,
                "typeIndexe": "eau",
                "ancien": 898,
                "nouveau": 900,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.981Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 51141,
                "typeIndexe": "energie",
                "ancien": 891,
                "nouveau": 908,
                "periode": "2023-10-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-11-25T10:25:09.270Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 51001,
                "typeIndexe": "energie",
                "ancien": 871,
                "nouveau": 891,
                "periode": "2023-09-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-11-25T10:25:09.249Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 2310,
                "idIndexe": 50871,
                "typeIndexe": "energie",
                "ancien": 850,
                "nouveau": 871,
                "periode": "2023-08-01",
                "avance": 2310,
                "reste": 0,
                "datePaiement": "2023-10-28T14:22:31.974Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50741,
                "typeIndexe": "energie",
                "ancien": 830,
                "nouveau": 850,
                "periode": "2023-07-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T05:55:29.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50605,
                "typeIndexe": "energie",
                "ancien": 810,
                "nouveau": 830,
                "periode": "2023-06-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-08-30T06:10:17.746Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50468,
                "typeIndexe": "energie",
                "ancien": 790,
                "nouveau": 810,
                "periode": "2023-05-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:33.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50336,
                "typeIndexe": "energie",
                "ancien": 770,
                "nouveau": 790,
                "periode": "2023-04-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:22:54.378Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50207,
                "typeIndexe": "energie",
                "ancien": 750,
                "nouveau": 770,
                "periode": "2023-03-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-06-27T08:59:42.859Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50083,
                "typeIndexe": "energie",
                "ancien": 725,
                "nouveau": 750,
                "periode": "2023-02-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T13:46:27.537Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49963,
                "typeIndexe": "energie",
                "ancien": 700,
                "nouveau": 725,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:04.342Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49851,
                "typeIndexe": "energie",
                "ancien": 675,
                "nouveau": 700,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-23T06:26:15.984Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 11,
                "idOccupation": 185,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -916,
            "energie": -924
        },
        "montant": {
            "eau": -457084,
            "energie": -101640
        }
    },
    {
        "idLogement": 13,
        "refLogement": "A013",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-11-29T15:48:41.555Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51276,
                "typeIndexe": "eau",
                "ancien": 1812,
                "nouveau": 1815,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51277,
                "typeIndexe": "energie",
                "ancien": 2250,
                "nouveau": 2536,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 51143,
                "typeIndexe": "energie",
                "ancien": 2215,
                "nouveau": 2250,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51142,
                "typeIndexe": "eau",
                "ancien": 1806,
                "nouveau": 1812,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51002,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1806,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 51003,
                "typeIndexe": "energie",
                "ancien": 2180,
                "nouveau": 2215,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50872,
                "typeIndexe": "eau",
                "ancien": 1803,
                "nouveau": 1804,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 50873,
                "typeIndexe": "energie",
                "ancien": 2123,
                "nouveau": 2180,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50743,
                "typeIndexe": "energie",
                "ancien": 2099,
                "nouveau": 2123,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50742,
                "typeIndexe": "eau",
                "ancien": 1801,
                "nouveau": 1803,
                "periode": "2023-07-01",
                "avance": 195,
                "reste": 803,
                "datePaiement": "2023-11-27T18:53:27.144Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50606,
                "typeIndexe": "eau",
                "ancien": 1797,
                "nouveau": 1801,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 50607,
                "typeIndexe": "energie",
                "ancien": 2061,
                "nouveau": 2099,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50469,
                "typeIndexe": "eau",
                "ancien": 1795,
                "nouveau": 1797,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 102,
                "montant": 11220,
                "idIndexe": 50470,
                "typeIndexe": "energie",
                "ancien": 1959,
                "nouveau": 2061,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50337,
                "typeIndexe": "eau",
                "ancien": 1793,
                "nouveau": 1795,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 50338,
                "typeIndexe": "energie",
                "ancien": 1921,
                "nouveau": 1959,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50208,
                "typeIndexe": "eau",
                "ancien": 1791,
                "nouveau": 1793,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50209,
                "typeIndexe": "energie",
                "ancien": 1891,
                "nouveau": 1921,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50085,
                "typeIndexe": "energie",
                "ancien": 1851,
                "nouveau": 1891,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50084,
                "typeIndexe": "eau",
                "ancien": 1787,
                "nouveau": 1791,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49964,
                "typeIndexe": "eau",
                "ancien": 1781,
                "nouveau": 1787,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 49965,
                "typeIndexe": "energie",
                "ancien": 1810,
                "nouveau": 1851,
                "periode": "2023-01-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.484Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 49831,
                "typeIndexe": "energie",
                "ancien": 1769,
                "nouveau": 1810,
                "periode": "2022-12-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.480Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49830,
                "typeIndexe": "eau",
                "ancien": 1777,
                "nouveau": 1781,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.476Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51276,
            "typeIndexe": "eau",
            "ancien": 1812,
            "nouveau": 1815,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 13,
            "idOccupation": 438,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 9,
            "montant": 990,
            "idIndexe": 51277,
            "typeIndexe": "energie",
            "ancien": 2250,
            "nouveau": 2259,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 13,
            "idOccupation": 438,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 438,
            "loyerBase": 30000,
            "caution": 40000,
            "modePaiement": "prepayer",
            "dateDeb": "2022-11-04T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-11-29T15:42:28.566Z",
            "idLocataire": 391,
            "idLogement": 13,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 391,
                "nomLocataire": "NOUHOU ADAMOU",
                "prenomLocataire": "ben",
                "cniLocataire": "",
                "tel": "656922177",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-11-29T15:04:25.596Z",
                "updatedAt": "2022-11-29T15:04:25.596Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1815,
                1812
            ],
            "energie": [
                2259,
                2250
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1815,
                1812
            ],
            "energie": [
                2259,
                2250
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51142,
                "typeIndexe": "eau",
                "ancien": 1806,
                "nouveau": 1812,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51002,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1806,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50872,
                "typeIndexe": "eau",
                "ancien": 1803,
                "nouveau": 1804,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50742,
                "typeIndexe": "eau",
                "ancien": 1801,
                "nouveau": 1803,
                "periode": "2023-07-01",
                "avance": 195,
                "reste": 803,
                "datePaiement": "2023-11-27T18:53:27.144Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50606,
                "typeIndexe": "eau",
                "ancien": 1797,
                "nouveau": 1801,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50469,
                "typeIndexe": "eau",
                "ancien": 1795,
                "nouveau": 1797,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50337,
                "typeIndexe": "eau",
                "ancien": 1793,
                "nouveau": 1795,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50208,
                "typeIndexe": "eau",
                "ancien": 1791,
                "nouveau": 1793,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50084,
                "typeIndexe": "eau",
                "ancien": 1787,
                "nouveau": 1791,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49964,
                "typeIndexe": "eau",
                "ancien": 1781,
                "nouveau": 1787,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49830,
                "typeIndexe": "eau",
                "ancien": 1777,
                "nouveau": 1781,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.476Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 51143,
                "typeIndexe": "energie",
                "ancien": 2215,
                "nouveau": 2250,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 51003,
                "typeIndexe": "energie",
                "ancien": 2180,
                "nouveau": 2215,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 50873,
                "typeIndexe": "energie",
                "ancien": 2123,
                "nouveau": 2180,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 50743,
                "typeIndexe": "energie",
                "ancien": 2099,
                "nouveau": 2123,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 50607,
                "typeIndexe": "energie",
                "ancien": 2061,
                "nouveau": 2099,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 102,
                "montant": 11220,
                "idIndexe": 50470,
                "typeIndexe": "energie",
                "ancien": 1959,
                "nouveau": 2061,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 50338,
                "typeIndexe": "energie",
                "ancien": 1921,
                "nouveau": 1959,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50209,
                "typeIndexe": "energie",
                "ancien": 1891,
                "nouveau": 1921,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50085,
                "typeIndexe": "energie",
                "ancien": 1851,
                "nouveau": 1891,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 49965,
                "typeIndexe": "energie",
                "ancien": 1810,
                "nouveau": 1851,
                "periode": "2023-01-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.484Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 49831,
                "typeIndexe": "energie",
                "ancien": 1769,
                "nouveau": 1810,
                "periode": "2022-12-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-11-27T15:28:15.480Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 13,
                "idOccupation": 438,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1815,
            "energie": -2259
        },
        "montant": {
            "eau": -905685,
            "energie": -248490
        }
    },
    {
        "idLogement": 14,
        "refLogement": "A014",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-08-26T10:54:15.321Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 121,
                "montant": 13310,
                "idIndexe": 51279,
                "typeIndexe": "energie",
                "ancien": 20860,
                "nouveau": 20981,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51278,
                "typeIndexe": "eau",
                "ancien": 3855,
                "nouveau": 3864,
                "periode": "2023-11-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-12-26T10:42:05.694Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51144,
                "typeIndexe": "eau",
                "ancien": 3848,
                "nouveau": 3855,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-12-26T10:42:05.691Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 51145,
                "typeIndexe": "energie",
                "ancien": 20765,
                "nouveau": 20860,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 126,
                "montant": 13860,
                "idIndexe": 51005,
                "typeIndexe": "energie",
                "ancien": 20639,
                "nouveau": 20765,
                "periode": "2023-09-01",
                "avance": 956,
                "reste": 12904,
                "datePaiement": "2023-12-26T10:42:20.612Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 51004,
                "typeIndexe": "eau",
                "ancien": 3828,
                "nouveau": 3848,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 146,
                "montant": 16060,
                "idIndexe": 50875,
                "typeIndexe": "energie",
                "ancien": 20493,
                "nouveau": 20639,
                "periode": "2023-08-01",
                "avance": 16060,
                "reste": 0,
                "datePaiement": "2023-12-26T10:42:05.688Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50874,
                "typeIndexe": "eau",
                "ancien": 3821,
                "nouveau": 3828,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T05:56:54.599Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 50745,
                "typeIndexe": "energie",
                "ancien": 20398,
                "nouveau": 20493,
                "periode": "2023-07-01",
                "avance": 10450,
                "reste": 0,
                "datePaiement": "2023-09-28T05:56:54.595Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50744,
                "typeIndexe": "eau",
                "ancien": 3814,
                "nouveau": 3821,
                "periode": "2023-07-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.630Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 102,
                "montant": 11220,
                "idIndexe": 50609,
                "typeIndexe": "energie",
                "ancien": 20296,
                "nouveau": 20398,
                "periode": "2023-06-01",
                "avance": 6531,
                "reste": 4689,
                "datePaiement": "2023-09-28T05:57:12.596Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50608,
                "typeIndexe": "eau",
                "ancien": 3807,
                "nouveau": 3814,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.628Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50471,
                "typeIndexe": "eau",
                "ancien": 3797,
                "nouveau": 3807,
                "periode": "2023-05-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.492Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 114,
                "montant": 12540,
                "idIndexe": 50472,
                "typeIndexe": "energie",
                "ancien": 20182,
                "nouveau": 20296,
                "periode": "2023-05-01",
                "avance": 12540,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.625Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50339,
                "typeIndexe": "eau",
                "ancien": 3790,
                "nouveau": 3797,
                "periode": "2023-04-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.485Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 50340,
                "typeIndexe": "energie",
                "ancien": 20087,
                "nouveau": 20182,
                "periode": "2023-04-01",
                "avance": 10450,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.489Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50210,
                "typeIndexe": "eau",
                "ancien": 3782,
                "nouveau": 3790,
                "periode": "2023-03-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-04-28T13:47:53.256Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50211,
                "typeIndexe": "energie",
                "ancien": 20026,
                "nouveau": 20087,
                "periode": "2023-03-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-04-28T13:47:53.260Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50087,
                "typeIndexe": "energie",
                "ancien": 19960,
                "nouveau": 20026,
                "periode": "2023-02-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.208Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50086,
                "typeIndexe": "eau",
                "ancien": 3775,
                "nouveau": 3782,
                "periode": "2023-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.205Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 49967,
                "typeIndexe": "energie",
                "ancien": 19900,
                "nouveau": 19960,
                "periode": "2023-01-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.200Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 49966,
                "typeIndexe": "eau",
                "ancien": 3766,
                "nouveau": 3775,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.198Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49852,
                "typeIndexe": "eau",
                "ancien": 3759,
                "nouveau": 3766,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-22T10:58:34.409Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 49853,
                "typeIndexe": "energie",
                "ancien": 19827,
                "nouveau": 19900,
                "periode": "2022-12-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-02-22T10:58:34.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 9,
            "montant": 4491,
            "idIndexe": 51278,
            "typeIndexe": "eau",
            "ancien": 3855,
            "nouveau": 3864,
            "periode": "2023-11-01",
            "avance": 4491,
            "reste": 0,
            "datePaiement": "2023-12-26T10:42:05.694Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 14,
            "idOccupation": 270,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 121,
            "montant": 13310,
            "idIndexe": 51279,
            "typeIndexe": "energie",
            "ancien": 20860,
            "nouveau": 20981,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 14,
            "idOccupation": 270,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 270,
            "loyerBase": 75000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-06-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.821Z",
            "idLocataire": 222,
            "idLogement": 14,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 222,
                "nomLocataire": "NGOMO",
                "prenomLocataire": "Blaise",
                "cniLocataire": "",
                "tel": "697736788",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.937Z",
                "updatedAt": "2022-03-02T11:54:45.937Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                3864,
                3855
            ],
            "energie": [
                20981,
                20860
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                3864,
                3855
            ],
            "energie": [
                20981,
                20860
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51144,
                "typeIndexe": "eau",
                "ancien": 3848,
                "nouveau": 3855,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-12-26T10:42:05.691Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 51004,
                "typeIndexe": "eau",
                "ancien": 3828,
                "nouveau": 3848,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50874,
                "typeIndexe": "eau",
                "ancien": 3821,
                "nouveau": 3828,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T05:56:54.599Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50744,
                "typeIndexe": "eau",
                "ancien": 3814,
                "nouveau": 3821,
                "periode": "2023-07-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.630Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50608,
                "typeIndexe": "eau",
                "ancien": 3807,
                "nouveau": 3814,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.628Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50471,
                "typeIndexe": "eau",
                "ancien": 3797,
                "nouveau": 3807,
                "periode": "2023-05-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.492Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50339,
                "typeIndexe": "eau",
                "ancien": 3790,
                "nouveau": 3797,
                "periode": "2023-04-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.485Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50210,
                "typeIndexe": "eau",
                "ancien": 3782,
                "nouveau": 3790,
                "periode": "2023-03-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-04-28T13:47:53.256Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50086,
                "typeIndexe": "eau",
                "ancien": 3775,
                "nouveau": 3782,
                "periode": "2023-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.205Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 49966,
                "typeIndexe": "eau",
                "ancien": 3766,
                "nouveau": 3775,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.198Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49852,
                "typeIndexe": "eau",
                "ancien": 3759,
                "nouveau": 3766,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-22T10:58:34.409Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 51145,
                "typeIndexe": "energie",
                "ancien": 20765,
                "nouveau": 20860,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 126,
                "montant": 13860,
                "idIndexe": 51005,
                "typeIndexe": "energie",
                "ancien": 20639,
                "nouveau": 20765,
                "periode": "2023-09-01",
                "avance": 956,
                "reste": 12904,
                "datePaiement": "2023-12-26T10:42:20.612Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 146,
                "montant": 16060,
                "idIndexe": 50875,
                "typeIndexe": "energie",
                "ancien": 20493,
                "nouveau": 20639,
                "periode": "2023-08-01",
                "avance": 16060,
                "reste": 0,
                "datePaiement": "2023-12-26T10:42:05.688Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 50745,
                "typeIndexe": "energie",
                "ancien": 20398,
                "nouveau": 20493,
                "periode": "2023-07-01",
                "avance": 10450,
                "reste": 0,
                "datePaiement": "2023-09-28T05:56:54.595Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 102,
                "montant": 11220,
                "idIndexe": 50609,
                "typeIndexe": "energie",
                "ancien": 20296,
                "nouveau": 20398,
                "periode": "2023-06-01",
                "avance": 6531,
                "reste": 4689,
                "datePaiement": "2023-09-28T05:57:12.596Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 114,
                "montant": 12540,
                "idIndexe": 50472,
                "typeIndexe": "energie",
                "ancien": 20182,
                "nouveau": 20296,
                "periode": "2023-05-01",
                "avance": 12540,
                "reste": 0,
                "datePaiement": "2023-08-30T06:11:52.625Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 95,
                "montant": 10450,
                "idIndexe": 50340,
                "typeIndexe": "energie",
                "ancien": 20087,
                "nouveau": 20182,
                "periode": "2023-04-01",
                "avance": 10450,
                "reste": 0,
                "datePaiement": "2023-06-27T09:02:50.489Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50211,
                "typeIndexe": "energie",
                "ancien": 20026,
                "nouveau": 20087,
                "periode": "2023-03-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-04-28T13:47:53.260Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50087,
                "typeIndexe": "energie",
                "ancien": 19960,
                "nouveau": 20026,
                "periode": "2023-02-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.208Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 49967,
                "typeIndexe": "energie",
                "ancien": 19900,
                "nouveau": 19960,
                "periode": "2023-01-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-03-29T07:52:51.200Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 49853,
                "typeIndexe": "energie",
                "ancien": 19827,
                "nouveau": 19900,
                "periode": "2022-12-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-02-22T10:58:34.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 14,
                "idOccupation": 270,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -3864,
            "energie": -20981
        },
        "montant": {
            "eau": -1928136,
            "energie": -2307910
        }
    },
    {
        "idLogement": 15,
        "refLogement": "A015",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-08-26T10:59:45.106Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 148,
                "montant": 16280,
                "idIndexe": 51281,
                "typeIndexe": "energie",
                "ancien": 21390,
                "nouveau": 21538,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51280,
                "typeIndexe": "eau",
                "ancien": 600,
                "nouveau": 604,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 0,
                "idIndexe": 51146,
                "typeIndexe": "eau",
                "ancien": 592,
                "nouveau": 600,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 51147,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21390,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51007,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51006,
                "typeIndexe": "eau",
                "ancien": 588,
                "nouveau": 592,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:25:36.255Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50877,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50876,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 588,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:25:36.253Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50746,
                "typeIndexe": "eau",
                "ancien": 580,
                "nouveau": 584,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.426Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50747,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50610,
                "typeIndexe": "eau",
                "ancien": 576,
                "nouveau": 580,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.423Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50611,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50474,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50473,
                "typeIndexe": "eau",
                "ancien": 571,
                "nouveau": 576,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50342,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50341,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 571,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.407Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50212,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50213,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50088,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50089,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49969,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49968,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49854,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49855,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51280,
            "typeIndexe": "eau",
            "ancien": 600,
            "nouveau": 604,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 15,
            "idOccupation": 272,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 148,
            "montant": 16280,
            "idIndexe": 51281,
            "typeIndexe": "energie",
            "ancien": 21390,
            "nouveau": 21538,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 15,
            "idOccupation": 272,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 272,
            "loyerBase": 75000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-06-30T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.832Z",
            "idLocataire": 250,
            "idLogement": 15,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 250,
                "nomLocataire": "NNA",
                "prenomLocataire": "JOSUE ROLAND",
                "cniLocataire": "",
                "tel": "699922616",
                "tel2": "674033481",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:46.087Z",
                "updatedAt": "2022-03-02T11:54:46.087Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                604,
                600
            ],
            "energie": [
                21538,
                21390
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                604,
                600
            ],
            "energie": [
                21538,
                21390
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 8,
                "montant": 0,
                "idIndexe": 51146,
                "typeIndexe": "eau",
                "ancien": 592,
                "nouveau": 600,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51006,
                "typeIndexe": "eau",
                "ancien": 588,
                "nouveau": 592,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:25:36.255Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50876,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 588,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:25:36.253Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50746,
                "typeIndexe": "eau",
                "ancien": 580,
                "nouveau": 584,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.426Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50610,
                "typeIndexe": "eau",
                "ancien": 576,
                "nouveau": 580,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.423Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50473,
                "typeIndexe": "eau",
                "ancien": 571,
                "nouveau": 576,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50341,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 571,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:12:47.407Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50212,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50088,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49968,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49854,
                "typeIndexe": "eau",
                "ancien": 567,
                "nouveau": 567,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 51147,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21390,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51007,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50877,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50747,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50611,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50474,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50342,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50213,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50089,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49969,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49855,
                "typeIndexe": "energie",
                "ancien": 21374,
                "nouveau": 21374,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 15,
                "idOccupation": 272,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -604,
            "energie": -21538
        },
        "montant": {
            "eau": -301396,
            "energie": -2369180
        }
    },
    {
        "idLogement": 16,
        "refLogement": "A016",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 35000,
        "prixMax": 40000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-04-29T13:18:39.654Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51282,
                "typeIndexe": "eau",
                "ancien": 1178,
                "nouveau": 1179,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T10:43:15.663Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51283,
                "typeIndexe": "energie",
                "ancien": 919,
                "nouveau": 926,
                "periode": "2023-11-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-12-26T10:43:15.667Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51148,
                "typeIndexe": "eau",
                "ancien": 1176,
                "nouveau": 1178,
                "periode": "2023-10-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T10:01:11.262Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51149,
                "typeIndexe": "energie",
                "ancien": 907,
                "nouveau": 919,
                "periode": "2023-10-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-12-26T10:43:32.080Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51011,
                "typeIndexe": "energie",
                "ancien": 895,
                "nouveau": 907,
                "periode": "2023-09-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-11-25T10:01:52.397Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51009,
                "typeIndexe": "eau",
                "ancien": 1172,
                "nouveau": 1176,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:28:46.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50879,
                "typeIndexe": "energie",
                "ancien": 880,
                "nouveau": 895,
                "periode": "2023-08-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-09-28T05:58:52.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50878,
                "typeIndexe": "eau",
                "ancien": 1169,
                "nouveau": 1172,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T05:59:03.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50749,
                "typeIndexe": "energie",
                "ancien": 865,
                "nouveau": 880,
                "periode": "2023-07-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-08-30T06:14:52.266Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50748,
                "typeIndexe": "eau",
                "ancien": 1162,
                "nouveau": 1169,
                "periode": "2023-07-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:14:52.270Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 12475,
                "idIndexe": 50612,
                "typeIndexe": "eau",
                "ancien": 1137,
                "nouveau": 1162,
                "periode": "2023-06-01",
                "avance": 12475,
                "reste": 0,
                "datePaiement": "2023-07-31T17:19:29.883Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50613,
                "typeIndexe": "energie",
                "ancien": 845,
                "nouveau": 865,
                "periode": "2023-06-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:24:58.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50476,
                "typeIndexe": "energie",
                "ancien": 831,
                "nouveau": 845,
                "periode": "2023-05-01",
                "avance": 1540,
                "reste": 0,
                "datePaiement": "2023-06-27T09:05:28.174Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50475,
                "typeIndexe": "eau",
                "ancien": 1133,
                "nouveau": 1137,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T09:05:28.170Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50344,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 831,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50343,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1133,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50444,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 831,
                "periode": "2023-04-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-05-30T09:43:04.442Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50443,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1133,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-05-30T09:42:57.001Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50214,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1127,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50215,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50090,
                "typeIndexe": "eau",
                "ancien": 1126,
                "nouveau": 1126,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50091,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49971,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 244,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49970,
                "typeIndexe": "eau",
                "ancien": 1126,
                "nouveau": 1126,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 244,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51282,
            "typeIndexe": "eau",
            "ancien": 1178,
            "nouveau": 1179,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T10:43:15.663Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 16,
            "idOccupation": 450,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 7,
            "montant": 770,
            "idIndexe": 51283,
            "typeIndexe": "energie",
            "ancien": 919,
            "nouveau": 926,
            "periode": "2023-11-01",
            "avance": 770,
            "reste": 0,
            "datePaiement": "2023-12-26T10:43:15.667Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 16,
            "idOccupation": 450,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 450,
            "loyerBase": 35000,
            "caution": 35000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-04-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-04-29T13:18:37.626Z",
            "idLocataire": 403,
            "idLogement": 16,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 403,
                "nomLocataire": "NDJOCK",
                "prenomLocataire": "patrick Frank",
                "cniLocataire": "",
                "tel": "691317817",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": "2023-04-29T00:00:00.000Z",
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-04-29T13:16:19.689Z",
                "updatedAt": "2023-05-31T09:49:01.699Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1179,
                1178
            ],
            "energie": [
                926,
                919
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1179,
                1178
            ],
            "energie": [
                926,
                919
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51148,
                "typeIndexe": "eau",
                "ancien": 1176,
                "nouveau": 1178,
                "periode": "2023-10-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T10:01:11.262Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51009,
                "typeIndexe": "eau",
                "ancien": 1172,
                "nouveau": 1176,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T14:28:46.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50878,
                "typeIndexe": "eau",
                "ancien": 1169,
                "nouveau": 1172,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T05:59:03.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50748,
                "typeIndexe": "eau",
                "ancien": 1162,
                "nouveau": 1169,
                "periode": "2023-07-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:14:52.270Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 12475,
                "idIndexe": 50612,
                "typeIndexe": "eau",
                "ancien": 1137,
                "nouveau": 1162,
                "periode": "2023-06-01",
                "avance": 12475,
                "reste": 0,
                "datePaiement": "2023-07-31T17:19:29.883Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50475,
                "typeIndexe": "eau",
                "ancien": 1133,
                "nouveau": 1137,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T09:05:28.170Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50343,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1133,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50443,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1133,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-05-30T09:42:57.001Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50214,
                "typeIndexe": "eau",
                "ancien": 1127,
                "nouveau": 1127,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50090,
                "typeIndexe": "eau",
                "ancien": 1126,
                "nouveau": 1126,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49970,
                "typeIndexe": "eau",
                "ancien": 1126,
                "nouveau": 1126,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 244,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51149,
                "typeIndexe": "energie",
                "ancien": 907,
                "nouveau": 919,
                "periode": "2023-10-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-12-26T10:43:32.080Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51011,
                "typeIndexe": "energie",
                "ancien": 895,
                "nouveau": 907,
                "periode": "2023-09-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-11-25T10:01:52.397Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50879,
                "typeIndexe": "energie",
                "ancien": 880,
                "nouveau": 895,
                "periode": "2023-08-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-09-28T05:58:52.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50749,
                "typeIndexe": "energie",
                "ancien": 865,
                "nouveau": 880,
                "periode": "2023-07-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-08-30T06:14:52.266Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50613,
                "typeIndexe": "energie",
                "ancien": 845,
                "nouveau": 865,
                "periode": "2023-06-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-07-26T12:24:58.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50476,
                "typeIndexe": "energie",
                "ancien": 831,
                "nouveau": 845,
                "periode": "2023-05-01",
                "avance": 1540,
                "reste": 0,
                "datePaiement": "2023-06-27T09:05:28.174Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50344,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 831,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50444,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 831,
                "periode": "2023-04-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-05-30T09:43:04.442Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50215,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50091,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 450,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49971,
                "typeIndexe": "energie",
                "ancien": 826,
                "nouveau": 826,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 16,
                "idOccupation": 244,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1179,
            "energie": -926
        },
        "montant": {
            "eau": -588321,
            "energie": -101860
        }
    },
    {
        "idLogement": 17,
        "refLogement": "A017",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.367Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 97,
                "montant": 10670,
                "idIndexe": 51285,
                "typeIndexe": "energie",
                "ancien": 9884,
                "nouveau": 9981,
                "periode": "2023-11-01",
                "avance": 10670,
                "reste": 0,
                "datePaiement": "2023-12-26T10:44:02.975Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51284,
                "typeIndexe": "eau",
                "ancien": 3164,
                "nouveau": 3165,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T10:44:02.971Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51150,
                "typeIndexe": "eau",
                "ancien": 3159,
                "nouveau": 3164,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:23:15.526Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 51151,
                "typeIndexe": "energie",
                "ancien": 9774,
                "nouveau": 9884,
                "periode": "2023-10-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2023-11-25T08:23:15.532Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51012,
                "typeIndexe": "eau",
                "ancien": 3156,
                "nouveau": 3159,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T14:31:11.323Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 105,
                "montant": 11550,
                "idIndexe": 51013,
                "typeIndexe": "energie",
                "ancien": 9669,
                "nouveau": 9774,
                "periode": "2023-09-01",
                "avance": 11550,
                "reste": 0,
                "datePaiement": "2023-12-26T10:44:21.093Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50880,
                "typeIndexe": "eau",
                "ancien": 3152,
                "nouveau": 3156,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-27T18:54:11.481Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 120,
                "montant": 13200,
                "idIndexe": 50881,
                "typeIndexe": "energie",
                "ancien": 9549,
                "nouveau": 9669,
                "periode": "2023-08-01",
                "avance": 13200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:05:15.594Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50750,
                "typeIndexe": "eau",
                "ancien": 3144,
                "nouveau": 3152,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:15:45.692Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 50751,
                "typeIndexe": "energie",
                "ancien": 9450,
                "nouveau": 9549,
                "periode": "2023-07-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-08-30T06:15:45.695Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 50615,
                "typeIndexe": "energie",
                "ancien": 9351,
                "nouveau": 9450,
                "periode": "2023-06-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-07-26T12:26:01.690Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50614,
                "typeIndexe": "eau",
                "ancien": 3133,
                "nouveau": 3144,
                "periode": "2023-06-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-07-26T12:26:01.685Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 97,
                "montant": 10670,
                "idIndexe": 50478,
                "typeIndexe": "energie",
                "ancien": 9254,
                "nouveau": 9351,
                "periode": "2023-05-01",
                "avance": 10670,
                "reste": 0,
                "datePaiement": "2023-07-26T12:29:52.932Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50477,
                "typeIndexe": "eau",
                "ancien": 3127,
                "nouveau": 3133,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.247Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 50346,
                "typeIndexe": "energie",
                "ancien": 9164,
                "nouveau": 9254,
                "periode": "2023-04-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.241Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50345,
                "typeIndexe": "eau",
                "ancien": 3109,
                "nouveau": 3127,
                "periode": "2023-04-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.245Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 47,
                "montant": 23453,
                "idIndexe": 50216,
                "typeIndexe": "eau",
                "ancien": 3079,
                "nouveau": 3126,
                "periode": "2023-03-01",
                "avance": 23453,
                "reste": 0,
                "datePaiement": "2023-04-29T14:51:34.371Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50217,
                "typeIndexe": "energie",
                "ancien": 9158,
                "nouveau": 9164,
                "periode": "2023-03-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.238Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50092,
                "typeIndexe": "eau",
                "ancien": 3051,
                "nouveau": 3116,
                "periode": "2023-02-01",
                "avance": 32435,
                "reste": 0,
                "datePaiement": "2023-07-26T12:30:12.165Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 7150,
                "idIndexe": 50093,
                "typeIndexe": "energie",
                "ancien": 9093,
                "nouveau": 9158,
                "periode": "2023-02-01",
                "avance": 7150,
                "reste": 0,
                "datePaiement": "2023-04-29T14:52:36.259Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 49973,
                "typeIndexe": "energie",
                "ancien": 9026,
                "nouveau": 9093,
                "periode": "2023-01-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:45.581Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 49972,
                "typeIndexe": "eau",
                "ancien": 3155,
                "nouveau": 3175,
                "periode": "2023-01-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:55.385Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 55,
                "montant": 6050,
                "idIndexe": 49859,
                "typeIndexe": "energie",
                "ancien": 8971,
                "nouveau": 9026,
                "periode": "2022-12-01",
                "avance": 6050,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:33.194Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49858,
                "typeIndexe": "eau",
                "ancien": 3145,
                "nouveau": 3155,
                "periode": "2022-12-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-23T06:27:34.234Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51284,
            "typeIndexe": "eau",
            "ancien": 3164,
            "nouveau": 3165,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T10:44:02.971Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 17,
            "idOccupation": 16,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 97,
            "montant": 10670,
            "idIndexe": 51285,
            "typeIndexe": "energie",
            "ancien": 9884,
            "nouveau": 9981,
            "periode": "2023-11-01",
            "avance": 10670,
            "reste": 0,
            "datePaiement": "2023-12-26T10:44:02.975Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 17,
            "idOccupation": 16,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 16,
            "loyerBase": 70000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.365Z",
            "idLocataire": 16,
            "idLogement": 17,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 16,
                "nomLocataire": "JULES",
                "prenomLocataire": "Alain",
                "cniLocataire": "",
                "tel": "691577700",
                "tel2": "677164859",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.573Z",
                "updatedAt": "2022-05-08T14:51:54.923Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                3165,
                3164
            ],
            "energie": [
                9981,
                9884
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                3165,
                3164
            ],
            "energie": [
                9981,
                9884
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51150,
                "typeIndexe": "eau",
                "ancien": 3159,
                "nouveau": 3164,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:23:15.526Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51012,
                "typeIndexe": "eau",
                "ancien": 3156,
                "nouveau": 3159,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T14:31:11.323Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50880,
                "typeIndexe": "eau",
                "ancien": 3152,
                "nouveau": 3156,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-27T18:54:11.481Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50750,
                "typeIndexe": "eau",
                "ancien": 3144,
                "nouveau": 3152,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:15:45.692Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50614,
                "typeIndexe": "eau",
                "ancien": 3133,
                "nouveau": 3144,
                "periode": "2023-06-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-07-26T12:26:01.685Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50477,
                "typeIndexe": "eau",
                "ancien": 3127,
                "nouveau": 3133,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.247Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50345,
                "typeIndexe": "eau",
                "ancien": 3109,
                "nouveau": 3127,
                "periode": "2023-04-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.245Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 47,
                "montant": 23453,
                "idIndexe": 50216,
                "typeIndexe": "eau",
                "ancien": 3079,
                "nouveau": 3126,
                "periode": "2023-03-01",
                "avance": 23453,
                "reste": 0,
                "datePaiement": "2023-04-29T14:51:34.371Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 32435,
                "idIndexe": 50092,
                "typeIndexe": "eau",
                "ancien": 3051,
                "nouveau": 3116,
                "periode": "2023-02-01",
                "avance": 32435,
                "reste": 0,
                "datePaiement": "2023-07-26T12:30:12.165Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 49972,
                "typeIndexe": "eau",
                "ancien": 3155,
                "nouveau": 3175,
                "periode": "2023-01-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:55.385Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49858,
                "typeIndexe": "eau",
                "ancien": 3145,
                "nouveau": 3155,
                "periode": "2022-12-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-23T06:27:34.234Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 51151,
                "typeIndexe": "energie",
                "ancien": 9774,
                "nouveau": 9884,
                "periode": "2023-10-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2023-11-25T08:23:15.532Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 105,
                "montant": 11550,
                "idIndexe": 51013,
                "typeIndexe": "energie",
                "ancien": 9669,
                "nouveau": 9774,
                "periode": "2023-09-01",
                "avance": 11550,
                "reste": 0,
                "datePaiement": "2023-12-26T10:44:21.093Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 120,
                "montant": 13200,
                "idIndexe": 50881,
                "typeIndexe": "energie",
                "ancien": 9549,
                "nouveau": 9669,
                "periode": "2023-08-01",
                "avance": 13200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:05:15.594Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 50751,
                "typeIndexe": "energie",
                "ancien": 9450,
                "nouveau": 9549,
                "periode": "2023-07-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-08-30T06:15:45.695Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 50615,
                "typeIndexe": "energie",
                "ancien": 9351,
                "nouveau": 9450,
                "periode": "2023-06-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-07-26T12:26:01.690Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 97,
                "montant": 10670,
                "idIndexe": 50478,
                "typeIndexe": "energie",
                "ancien": 9254,
                "nouveau": 9351,
                "periode": "2023-05-01",
                "avance": 10670,
                "reste": 0,
                "datePaiement": "2023-07-26T12:29:52.932Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 50346,
                "typeIndexe": "energie",
                "ancien": 9164,
                "nouveau": 9254,
                "periode": "2023-04-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.241Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50217,
                "typeIndexe": "energie",
                "ancien": 9158,
                "nouveau": 9164,
                "periode": "2023-03-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-06-27T09:09:00.238Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 65,
                "montant": 7150,
                "idIndexe": 50093,
                "typeIndexe": "energie",
                "ancien": 9093,
                "nouveau": 9158,
                "periode": "2023-02-01",
                "avance": 7150,
                "reste": 0,
                "datePaiement": "2023-04-29T14:52:36.259Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 49973,
                "typeIndexe": "energie",
                "ancien": 9026,
                "nouveau": 9093,
                "periode": "2023-01-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:45.581Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 55,
                "montant": 6050,
                "idIndexe": 49859,
                "typeIndexe": "energie",
                "ancien": 8971,
                "nouveau": 9026,
                "periode": "2022-12-01",
                "avance": 6050,
                "reste": 0,
                "datePaiement": "2023-02-23T06:28:33.194Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 17,
                "idOccupation": 16,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -3165,
            "energie": -9981
        },
        "montant": {
            "eau": -1579335,
            "energie": -1097910
        }
    },
    {
        "idLogement": 20,
        "refLogement": "A020",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 35000,
        "prixMax": 40000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-09-28T19:57:55.672Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51287,
                "typeIndexe": "eau",
                "ancien": 947,
                "nouveau": 951,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 51289,
                "typeIndexe": "energie",
                "ancien": 7,
                "nouveau": 12,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51155,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 7,
                "periode": "2023-10-01",
                "avance": 505,
                "reste": 265,
                "datePaiement": "2023-12-26T10:48:12.182Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51153,
                "typeIndexe": "eau",
                "ancien": 942,
                "nouveau": 947,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T10:47:48.970Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51154,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51152,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 42415,
                "idIndexe": 48899,
                "typeIndexe": "eau",
                "ancien": 852,
                "nouveau": 937,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 48900,
                "typeIndexe": "energie",
                "ancien": 6712,
                "nouveau": 6713,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 48752,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 852,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 48753,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6712,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48639,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 851,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48640,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6711,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48534,
                "typeIndexe": "eau",
                "ancien": 852,
                "nouveau": 852,
                "periode": "2022-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48476,
                "typeIndexe": "energie",
                "ancien": 6720,
                "nouveau": 6720,
                "periode": "2022-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 41634,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 852,
                "periode": "2022-01-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.888Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 48419,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6720,
                "periode": "2022-01-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.890Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41562,
                "typeIndexe": "eau",
                "ancien": 848,
                "nouveau": 851,
                "periode": "2021-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.887Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 48355,
                "typeIndexe": "energie",
                "ancien": 6703,
                "nouveau": 6711,
                "periode": "2021-12-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.966Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 41496,
                "typeIndexe": "eau",
                "ancien": 844,
                "nouveau": 848,
                "periode": "2021-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.886Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 48288,
                "typeIndexe": "energie",
                "ancien": 6683,
                "nouveau": 6710,
                "periode": "2021-11-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.885Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41430,
                "typeIndexe": "eau",
                "ancien": 841,
                "nouveau": 844,
                "periode": "2021-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.882Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 48221,
                "typeIndexe": "energie",
                "ancien": 6734,
                "nouveau": 6808,
                "periode": "2021-10-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.883Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 41365,
                "typeIndexe": "eau",
                "ancien": 840,
                "nouveau": 841,
                "periode": "2021-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.964Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 48157,
                "typeIndexe": "energie",
                "ancien": 6704,
                "nouveau": 6734,
                "periode": "2021-09-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.965Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51287,
            "typeIndexe": "eau",
            "ancien": 947,
            "nouveau": 951,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 20,
            "idOccupation": 91,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 5,
            "montant": 550,
            "idIndexe": 51289,
            "typeIndexe": "energie",
            "ancien": 7,
            "nouveau": 12,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 20,
            "idOccupation": 91,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 91,
            "loyerBase": 35000,
            "caution": 35000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-09-12T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-09-28T19:57:54.756Z",
            "idLocataire": 410,
            "idLogement": 20,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 410,
                "nomLocataire": "EKOTTO",
                "prenomLocataire": "Gerald Thomas",
                "cniLocataire": "",
                "tel": "692373775",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-09-28T19:49:37.040Z",
                "updatedAt": "2023-09-28T19:49:37.040Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                951,
                947
            ],
            "energie": [
                12,
                7
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                951,
                947
            ],
            "energie": [
                12,
                7
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51153,
                "typeIndexe": "eau",
                "ancien": 942,
                "nouveau": 947,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T10:47:48.970Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 42415,
                "idIndexe": 48899,
                "typeIndexe": "eau",
                "ancien": 852,
                "nouveau": 937,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 48752,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 852,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48639,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 851,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48534,
                "typeIndexe": "eau",
                "ancien": 852,
                "nouveau": 852,
                "periode": "2022-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 41634,
                "typeIndexe": "eau",
                "ancien": 851,
                "nouveau": 852,
                "periode": "2022-01-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.888Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41562,
                "typeIndexe": "eau",
                "ancien": 848,
                "nouveau": 851,
                "periode": "2021-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.887Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 41496,
                "typeIndexe": "eau",
                "ancien": 844,
                "nouveau": 848,
                "periode": "2021-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.886Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41430,
                "typeIndexe": "eau",
                "ancien": 841,
                "nouveau": 844,
                "periode": "2021-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.882Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 41365,
                "typeIndexe": "eau",
                "ancien": 840,
                "nouveau": 841,
                "periode": "2021-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.964Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51155,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 7,
                "periode": "2023-10-01",
                "avance": 505,
                "reste": 265,
                "datePaiement": "2023-12-26T10:48:12.182Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": 91,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 48900,
                "typeIndexe": "energie",
                "ancien": 6712,
                "nouveau": 6713,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 48753,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6712,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48640,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6711,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48476,
                "typeIndexe": "energie",
                "ancien": 6720,
                "nouveau": 6720,
                "periode": "2022-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 48419,
                "typeIndexe": "energie",
                "ancien": 6711,
                "nouveau": 6720,
                "periode": "2022-01-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.890Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 48355,
                "typeIndexe": "energie",
                "ancien": 6703,
                "nouveau": 6711,
                "periode": "2021-12-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.966Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 48288,
                "typeIndexe": "energie",
                "ancien": 6683,
                "nouveau": 6710,
                "periode": "2021-11-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.885Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 48221,
                "typeIndexe": "energie",
                "ancien": 6734,
                "nouveau": 6808,
                "periode": "2021-10-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:56.883Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 48157,
                "typeIndexe": "energie",
                "ancien": 6704,
                "nouveau": 6734,
                "periode": "2021-09-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2022-03-04T09:38:30.965Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 20,
                "idOccupation": 243,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -951,
            "energie": -12
        },
        "montant": {
            "eau": -474549,
            "energie": -1320
        }
    },
    {
        "idLogement": 22,
        "refLogement": "A022",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 75000,
        "prixMax": 80000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-10-28T08:03:45.642Z",
        "idBatiment": 1,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51290,
                "typeIndexe": "eau",
                "ancien": 364,
                "nouveau": 763,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.833Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 51291,
                "typeIndexe": "energie",
                "ancien": 18922,
                "nouveau": 18992,
                "periode": "2023-11-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.835Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51156,
                "typeIndexe": "eau",
                "ancien": 359,
                "nouveau": 364,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.826Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51157,
                "typeIndexe": "energie",
                "ancien": 18840,
                "nouveau": 18922,
                "periode": "2023-10-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.830Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 51015,
                "typeIndexe": "energie",
                "ancien": 18767,
                "nouveau": 18840,
                "periode": "2023-09-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.824Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51014,
                "typeIndexe": "eau",
                "ancien": 352,
                "nouveau": 359,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 50883,
                "typeIndexe": "energie",
                "ancien": 18674,
                "nouveau": 18767,
                "periode": "2023-08-01",
                "avance": 10230,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.819Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50882,
                "typeIndexe": "eau",
                "ancien": 348,
                "nouveau": 352,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.815Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50753,
                "typeIndexe": "energie",
                "ancien": 18595,
                "nouveau": 18674,
                "periode": "2023-07-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.813Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50752,
                "typeIndexe": "eau",
                "ancien": 342,
                "nouveau": 348,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.810Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50616,
                "typeIndexe": "eau",
                "ancien": 336,
                "nouveau": 342,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:49.771Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50617,
                "typeIndexe": "energie",
                "ancien": 18510,
                "nouveau": 18595,
                "periode": "2023-06-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.806Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50479,
                "typeIndexe": "eau",
                "ancien": 330,
                "nouveau": 336,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.747Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50480,
                "typeIndexe": "energie",
                "ancien": 18427,
                "nouveau": 18510,
                "periode": "2023-05-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.743Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 50348,
                "typeIndexe": "energie",
                "ancien": 18350,
                "nouveau": 18427,
                "periode": "2023-04-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.740Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50347,
                "typeIndexe": "eau",
                "ancien": 322,
                "nouveau": 330,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.737Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 50219,
                "typeIndexe": "energie",
                "ancien": 18300,
                "nouveau": 18350,
                "periode": "2023-03-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.735Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50218,
                "typeIndexe": "eau",
                "ancien": 310,
                "nouveau": 322,
                "periode": "2023-03-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.731Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50095,
                "typeIndexe": "energie",
                "ancien": 18189,
                "nouveau": 18229,
                "periode": "2023-02-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.728Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50094,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 310,
                "periode": "2023-02-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.713Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49974,
                "typeIndexe": "eau",
                "ancien": 278,
                "nouveau": 291,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-05-30T09:46:25.135Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 49975,
                "typeIndexe": "energie",
                "ancien": 18103,
                "nouveau": 18145,
                "periode": "2023-01-01",
                "avance": 4071,
                "reste": 549,
                "datePaiement": "2023-12-27T07:27:59.866Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -18187,
                "montant": -2000570,
                "idIndexe": 49867,
                "typeIndexe": "energie",
                "ancien": 18187,
                "nouveau": null,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49866,
                "typeIndexe": "eau",
                "ancien": 268,
                "nouveau": 278,
                "periode": "2022-12-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-22T10:21:56.672Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51290,
            "typeIndexe": "eau",
            "ancien": 364,
            "nouveau": 369,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-27T07:27:32.833Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 22,
            "idOccupation": 439,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 70,
            "montant": 7700,
            "idIndexe": 51291,
            "typeIndexe": "energie",
            "ancien": 18922,
            "nouveau": 18992,
            "periode": "2023-11-01",
            "avance": 7700,
            "reste": 0,
            "datePaiement": "2023-12-27T07:27:32.835Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 22,
            "idOccupation": 439,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 439,
            "loyerBase": 80000,
            "caution": 880000,
            "modePaiement": "prepayer",
            "dateDeb": "2022-12-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-11-29T15:48:41.485Z",
            "idLocataire": 392,
            "idLogement": 22,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 392,
                "nomLocataire": "NGONO MENGONG",
                "prenomLocataire": "rodrigue brice",
                "cniLocataire": "",
                "tel": "697598946",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-11-29T15:09:29.529Z",
                "updatedAt": "2022-11-29T15:09:29.529Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                369,
                364
            ],
            "energie": [
                18992,
                18922
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                369,
                364
            ],
            "energie": [
                18992,
                18922
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51156,
                "typeIndexe": "eau",
                "ancien": 359,
                "nouveau": 364,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.826Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51014,
                "typeIndexe": "eau",
                "ancien": 352,
                "nouveau": 359,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50882,
                "typeIndexe": "eau",
                "ancien": 348,
                "nouveau": 352,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.815Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50752,
                "typeIndexe": "eau",
                "ancien": 342,
                "nouveau": 348,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.810Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50616,
                "typeIndexe": "eau",
                "ancien": 336,
                "nouveau": 342,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:49.771Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50479,
                "typeIndexe": "eau",
                "ancien": 330,
                "nouveau": 336,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.747Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50347,
                "typeIndexe": "eau",
                "ancien": 322,
                "nouveau": 330,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.737Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50218,
                "typeIndexe": "eau",
                "ancien": 310,
                "nouveau": 322,
                "periode": "2023-03-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.731Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 50094,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 310,
                "periode": "2023-02-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.713Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49974,
                "typeIndexe": "eau",
                "ancien": 278,
                "nouveau": 291,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-05-30T09:46:25.135Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49866,
                "typeIndexe": "eau",
                "ancien": 268,
                "nouveau": 278,
                "periode": "2022-12-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-02-22T10:21:56.672Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51157,
                "typeIndexe": "energie",
                "ancien": 18840,
                "nouveau": 18922,
                "periode": "2023-10-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.830Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 51015,
                "typeIndexe": "energie",
                "ancien": 18767,
                "nouveau": 18840,
                "periode": "2023-09-01",
                "avance": 8030,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.824Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 50883,
                "typeIndexe": "energie",
                "ancien": 18674,
                "nouveau": 18767,
                "periode": "2023-08-01",
                "avance": 10230,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.819Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50753,
                "typeIndexe": "energie",
                "ancien": 18595,
                "nouveau": 18674,
                "periode": "2023-07-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.813Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50617,
                "typeIndexe": "energie",
                "ancien": 18510,
                "nouveau": 18595,
                "periode": "2023-06-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-12-27T07:27:32.806Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50480,
                "typeIndexe": "energie",
                "ancien": 18427,
                "nouveau": 18510,
                "periode": "2023-05-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.743Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 50348,
                "typeIndexe": "energie",
                "ancien": 18350,
                "nouveau": 18427,
                "periode": "2023-04-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.740Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 50219,
                "typeIndexe": "energie",
                "ancien": 18300,
                "nouveau": 18350,
                "periode": "2023-03-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.735Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50095,
                "typeIndexe": "energie",
                "ancien": 18189,
                "nouveau": 18229,
                "periode": "2023-02-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-11-27T15:31:09.728Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 49975,
                "typeIndexe": "energie",
                "ancien": 18103,
                "nouveau": 18145,
                "periode": "2023-01-01",
                "avance": 4071,
                "reste": 549,
                "datePaiement": "2023-12-27T07:27:59.866Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 22,
                "idOccupation": 439,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -369,
            "energie": -18992
        },
        "montant": {
            "eau": -184131,
            "energie": -2089120
        }
    },
    {
        "idLogement": 24,
        "refLogement": "B002",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.410Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51293,
                "typeIndexe": "energie",
                "ancien": 3345,
                "nouveau": 3354,
                "periode": "2023-11-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-12-26T10:49:50.485Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 9481,
                "idIndexe": 51292,
                "typeIndexe": "eau",
                "ancien": 2232,
                "nouveau": 2251,
                "periode": "2023-11-01",
                "avance": 9481,
                "reste": 0,
                "datePaiement": "2023-12-26T10:50:00.662Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 51158,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2232,
                "periode": "2023-10-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-12-26T10:49:56.791Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 51159,
                "typeIndexe": "energie",
                "ancien": 3320,
                "nouveau": 3345,
                "periode": "2023-10-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-12-26T10:49:46.019Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51036,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51037,
                "typeIndexe": "energie",
                "ancien": 3320,
                "nouveau": 3320,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50887,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3320,
                "periode": "2023-08-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T06:16:23.873Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50886,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50757,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3319,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50756,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50620,
                "typeIndexe": "eau",
                "ancien": 2213,
                "nouveau": 2215,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50621,
                "typeIndexe": "energie",
                "ancien": 3318,
                "nouveau": 3319,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.910Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50484,
                "typeIndexe": "energie",
                "ancien": 3301,
                "nouveau": 3318,
                "periode": "2023-05-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50483,
                "typeIndexe": "eau",
                "ancien": 2204,
                "nouveau": 2213,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.885Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50351,
                "typeIndexe": "eau",
                "ancien": 2196,
                "nouveau": 2204,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.797Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50352,
                "typeIndexe": "energie",
                "ancien": 3287,
                "nouveau": 3301,
                "periode": "2023-04-01",
                "avance": 1540,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.881Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50223,
                "typeIndexe": "energie",
                "ancien": 3269,
                "nouveau": 3287,
                "periode": "2023-03-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.334Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 19960,
                "idIndexe": 50222,
                "typeIndexe": "eau",
                "ancien": 2156,
                "nouveau": 2196,
                "periode": "2023-03-01",
                "avance": 19960,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.330Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50099,
                "typeIndexe": "energie",
                "ancien": 3244,
                "nouveau": 3269,
                "periode": "2023-02-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.326Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50098,
                "typeIndexe": "eau",
                "ancien": 2155,
                "nouveau": 2156,
                "periode": "2023-02-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.323Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 19461,
                "idIndexe": 49978,
                "typeIndexe": "eau",
                "ancien": 2116,
                "nouveau": 2155,
                "periode": "2023-01-01",
                "avance": 19461,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.316Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49979,
                "typeIndexe": "energie",
                "ancien": 3219,
                "nouveau": 3244,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.319Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 49863,
                "typeIndexe": "energie",
                "ancien": 3203,
                "nouveau": 3219,
                "periode": "2022-12-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.312Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49862,
                "typeIndexe": "eau",
                "ancien": 2115,
                "nouveau": 2116,
                "periode": "2022-12-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.307Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 19,
            "montant": 9481,
            "idIndexe": 51292,
            "typeIndexe": "eau",
            "ancien": 2232,
            "nouveau": 2251,
            "periode": "2023-11-01",
            "avance": 9481,
            "reste": 0,
            "datePaiement": "2023-12-26T10:50:00.662Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 24,
            "idOccupation": 22,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 9,
            "montant": 990,
            "idIndexe": 51293,
            "typeIndexe": "energie",
            "ancien": 3345,
            "nouveau": 3354,
            "periode": "2023-11-01",
            "avance": 990,
            "reste": 0,
            "datePaiement": "2023-12-26T10:49:50.485Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 24,
            "idOccupation": 22,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 22,
            "loyerBase": 35000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.409Z",
            "idLocataire": 22,
            "idLogement": 24,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 22,
                "nomLocataire": "MBOLLE",
                "prenomLocataire": "Lise",
                "cniLocataire": "",
                "tel": "658484804",
                "tel2": "697373708",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.261Z",
                "updatedAt": "2022-09-28T08:02:09.158Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2251,
                2232
            ],
            "energie": [
                3354,
                3345
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2251,
                2232
            ],
            "energie": [
                3354,
                3345
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 51158,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2232,
                "periode": "2023-10-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-12-26T10:49:56.791Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51036,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50886,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50756,
                "typeIndexe": "eau",
                "ancien": 2215,
                "nouveau": 2215,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50620,
                "typeIndexe": "eau",
                "ancien": 2213,
                "nouveau": 2215,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50483,
                "typeIndexe": "eau",
                "ancien": 2204,
                "nouveau": 2213,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.885Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50351,
                "typeIndexe": "eau",
                "ancien": 2196,
                "nouveau": 2204,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.797Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 19960,
                "idIndexe": 50222,
                "typeIndexe": "eau",
                "ancien": 2156,
                "nouveau": 2196,
                "periode": "2023-03-01",
                "avance": 19960,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.330Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50098,
                "typeIndexe": "eau",
                "ancien": 2155,
                "nouveau": 2156,
                "periode": "2023-02-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.323Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 19461,
                "idIndexe": 49978,
                "typeIndexe": "eau",
                "ancien": 2116,
                "nouveau": 2155,
                "periode": "2023-01-01",
                "avance": 19461,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.316Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49862,
                "typeIndexe": "eau",
                "ancien": 2115,
                "nouveau": 2116,
                "periode": "2022-12-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.307Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 51159,
                "typeIndexe": "energie",
                "ancien": 3320,
                "nouveau": 3345,
                "periode": "2023-10-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-12-26T10:49:46.019Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51037,
                "typeIndexe": "energie",
                "ancien": 3320,
                "nouveau": 3320,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50887,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3320,
                "periode": "2023-08-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T06:16:23.873Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50757,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3319,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50621,
                "typeIndexe": "energie",
                "ancien": 3318,
                "nouveau": 3319,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.910Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50484,
                "typeIndexe": "energie",
                "ancien": 3301,
                "nouveau": 3318,
                "periode": "2023-05-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50352,
                "typeIndexe": "energie",
                "ancien": 3287,
                "nouveau": 3301,
                "periode": "2023-04-01",
                "avance": 1540,
                "reste": 0,
                "datePaiement": "2023-06-30T13:20:39.881Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50223,
                "typeIndexe": "energie",
                "ancien": 3269,
                "nouveau": 3287,
                "periode": "2023-03-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.334Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50099,
                "typeIndexe": "energie",
                "ancien": 3244,
                "nouveau": 3269,
                "periode": "2023-02-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.326Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49979,
                "typeIndexe": "energie",
                "ancien": 3219,
                "nouveau": 3244,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.319Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 49863,
                "typeIndexe": "energie",
                "ancien": 3203,
                "nouveau": 3219,
                "periode": "2022-12-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-04-28T14:10:34.312Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 24,
                "idOccupation": 22,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2251,
            "energie": -3354
        },
        "montant": {
            "eau": -1123249,
            "energie": -368940
        }
    },
    {
        "idLogement": 25,
        "refLogement": "B003",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-09-28T20:21:04.803Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51294,
                "typeIndexe": "eau",
                "ancien": 4249,
                "nouveau": 4252,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51295,
                "typeIndexe": "energie",
                "ancien": 4249,
                "nouveau": 4250,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51160,
                "typeIndexe": "eau",
                "ancien": 4246,
                "nouveau": 4249,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51161,
                "typeIndexe": "energie",
                "ancien": 4240,
                "nouveau": 4249,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51017,
                "typeIndexe": "eau",
                "ancien": 4246,
                "nouveau": 4246,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51019,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51018,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51016,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49981,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49983,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49980,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49982,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 49747,
                "typeIndexe": "energie",
                "ancien": 4289,
                "nouveau": 4300,
                "periode": "2022-11-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-02-21T20:46:20.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49746,
                "typeIndexe": "eau",
                "ancien": 2622,
                "nouveau": 2629,
                "periode": "2022-11-01",
                "avance": 1790,
                "reste": 1703,
                "datePaiement": "2023-02-21T20:46:37.818Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49608,
                "typeIndexe": "eau",
                "ancien": 2617,
                "nouveau": 2622,
                "periode": "2022-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2022-11-29T11:44:30.231Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 49609,
                "typeIndexe": "energie",
                "ancien": 4276,
                "nouveau": 4289,
                "periode": "2022-10-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2022-11-29T11:44:30.235Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 49413,
                "typeIndexe": "energie",
                "ancien": 4249,
                "nouveau": 4276,
                "periode": "2022-09-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2022-10-28T07:27:02.213Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49412,
                "typeIndexe": "eau",
                "ancien": 2610,
                "nouveau": 2617,
                "periode": "2022-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2022-10-28T07:27:02.208Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49298,
                "typeIndexe": "eau",
                "ancien": 2604,
                "nouveau": 2611,
                "periode": "2022-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "1970-01-01T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 2420,
                "idIndexe": 49299,
                "typeIndexe": "energie",
                "ancien": 4227,
                "nouveau": 4249,
                "periode": "2022-08-01",
                "avance": 2420,
                "reste": 0,
                "datePaiement": "2022-09-27T15:22:36.783Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 49173,
                "typeIndexe": "energie",
                "ancien": 4174,
                "nouveau": 4227,
                "periode": "2022-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2022-09-27T15:22:53.980Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49172,
                "typeIndexe": "eau",
                "ancien": 2598,
                "nouveau": 2604,
                "periode": "2022-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2022-08-25T09:42:04.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 49036,
                "typeIndexe": "energie",
                "ancien": 4114,
                "nouveau": 4174,
                "periode": "2022-06-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2022-07-28T11:03:38.972Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49035,
                "typeIndexe": "eau",
                "ancien": 2592,
                "nouveau": 2598,
                "periode": "2022-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2022-07-28T11:02:57.954Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51294,
            "typeIndexe": "eau",
            "ancien": 4249,
            "nouveau": 4252,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 25,
            "idOccupation": 92,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 1,
            "montant": 110,
            "idIndexe": 51295,
            "typeIndexe": "energie",
            "ancien": 4249,
            "nouveau": 4250,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 25,
            "idOccupation": 92,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 92,
            "loyerBase": 35000,
            "caution": 35000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 499,
            "puEau": 110,
            "dureeBail": 0,
            "createdAt": "2023-09-28T20:21:03.948Z",
            "idLocataire": 411,
            "idLogement": 25,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 411,
                "nomLocataire": "NGO YOM",
                "prenomLocataire": "Nyeck",
                "cniLocataire": "",
                "tel": "691701585",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-09-28T19:51:55.476Z",
                "updatedAt": "2023-09-28T19:51:55.476Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                4252,
                4249
            ],
            "energie": [
                4250,
                4249
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                4252,
                4249
            ],
            "energie": [
                4250,
                4249
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51160,
                "typeIndexe": "eau",
                "ancien": 4246,
                "nouveau": 4249,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51017,
                "typeIndexe": "eau",
                "ancien": 4246,
                "nouveau": 4246,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49746,
                "typeIndexe": "eau",
                "ancien": 2622,
                "nouveau": 2629,
                "periode": "2022-11-01",
                "avance": 1790,
                "reste": 1703,
                "datePaiement": "2023-02-21T20:46:37.818Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49608,
                "typeIndexe": "eau",
                "ancien": 2617,
                "nouveau": 2622,
                "periode": "2022-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2022-11-29T11:44:30.231Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49412,
                "typeIndexe": "eau",
                "ancien": 2610,
                "nouveau": 2617,
                "periode": "2022-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2022-10-28T07:27:02.208Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49298,
                "typeIndexe": "eau",
                "ancien": 2604,
                "nouveau": 2611,
                "periode": "2022-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "1970-01-01T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49172,
                "typeIndexe": "eau",
                "ancien": 2598,
                "nouveau": 2604,
                "periode": "2022-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2022-08-25T09:42:04.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49035,
                "typeIndexe": "eau",
                "ancien": 2592,
                "nouveau": 2598,
                "periode": "2022-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2022-07-28T11:02:57.954Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51161,
                "typeIndexe": "energie",
                "ancien": 4240,
                "nouveau": 4249,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 92,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 49747,
                "typeIndexe": "energie",
                "ancien": 4289,
                "nouveau": 4300,
                "periode": "2022-11-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-02-21T20:46:20.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 49609,
                "typeIndexe": "energie",
                "ancien": 4276,
                "nouveau": 4289,
                "periode": "2022-10-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2022-11-29T11:44:30.235Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 49413,
                "typeIndexe": "energie",
                "ancien": 4249,
                "nouveau": 4276,
                "periode": "2022-09-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2022-10-28T07:27:02.213Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 2420,
                "idIndexe": 49299,
                "typeIndexe": "energie",
                "ancien": 4227,
                "nouveau": 4249,
                "periode": "2022-08-01",
                "avance": 2420,
                "reste": 0,
                "datePaiement": "2022-09-27T15:22:36.783Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 49173,
                "typeIndexe": "energie",
                "ancien": 4174,
                "nouveau": 4227,
                "periode": "2022-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2022-09-27T15:22:53.980Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 49036,
                "typeIndexe": "energie",
                "ancien": 4114,
                "nouveau": 4174,
                "periode": "2022-06-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2022-07-28T11:03:38.972Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 25,
                "idOccupation": 359,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -4252,
            "energie": -4250
        },
        "montant": {
            "eau": -2121748,
            "energie": -467500
        }
    },
    {
        "idLogement": 28,
        "refLogement": "B006",
        "descLogement": "Libéré le 29 Juillet 2017",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-06-28T16:31:46.386Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51296,
                "typeIndexe": "eau",
                "ancien": 83,
                "nouveau": 71,
                "periode": "2023-11-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-12-26T10:56:08.269Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 51297,
                "typeIndexe": "energie",
                "ancien": 2900,
                "nouveau": 2893,
                "periode": "2023-11-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-12-26T10:56:08.273Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51162,
                "typeIndexe": "eau",
                "ancien": 77,
                "nouveau": 83,
                "periode": "2023-10-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-25T07:54:07.900Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51163,
                "typeIndexe": "energie",
                "ancien": 2888,
                "nouveau": 2900,
                "periode": "2023-10-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-11-25T07:54:07.906Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51020,
                "typeIndexe": "eau",
                "ancien": 74,
                "nouveau": 77,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:02.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51021,
                "typeIndexe": "energie",
                "ancien": 2888,
                "nouveau": 2888,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:02.805Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50888,
                "typeIndexe": "eau",
                "ancien": 71,
                "nouveau": 74,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T06:20:39.041Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50889,
                "typeIndexe": "energie",
                "ancien": 2873,
                "nouveau": 2893,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:20:39.011Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50758,
                "typeIndexe": "eau",
                "ancien": 66,
                "nouveau": 71,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T19:42:55.090Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50759,
                "typeIndexe": "energie",
                "ancien": 2845,
                "nouveau": 2873,
                "periode": "2023-07-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-08-30T19:42:46.758Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 50721,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 4,
                "periode": "2023-06-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-07-26T13:09:11.155Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 499,
                "idIndexe": 50720,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 66,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:09:11.151Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41367,
                "typeIndexe": "eau",
                "ancien": 71,
                "nouveau": 74,
                "periode": "2021-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 48090,
                "typeIndexe": "energie",
                "ancien": 2811,
                "nouveau": 2845,
                "periode": "2021-09-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41281,
                "typeIndexe": "eau",
                "ancien": 65,
                "nouveau": 71,
                "periode": "2021-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 48104,
                "typeIndexe": "energie",
                "ancien": 2780,
                "nouveau": 2811,
                "periode": "2021-08-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 48025,
                "typeIndexe": "energie",
                "ancien": 2798,
                "nouveau": 2806,
                "periode": "2021-07-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41196,
                "typeIndexe": "eau",
                "ancien": 65,
                "nouveau": 71,
                "periode": "2021-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 47960,
                "typeIndexe": "energie",
                "ancien": 2789,
                "nouveau": 2798,
                "periode": "2021-06-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41127,
                "typeIndexe": "eau",
                "ancien": 93,
                "nouveau": 96,
                "periode": "2021-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 47888,
                "typeIndexe": "energie",
                "ancien": 2743,
                "nouveau": 2789,
                "periode": "2021-05-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 41088,
                "typeIndexe": "eau",
                "ancien": 91,
                "nouveau": 93,
                "periode": "2021-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 41013,
                "typeIndexe": "eau",
                "ancien": 91,
                "nouveau": 93,
                "periode": "2021-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 47818,
                "typeIndexe": "energie",
                "ancien": 2736,
                "nouveau": 2743,
                "periode": "2021-04-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 8,
            "montant": 3992,
            "idIndexe": 51296,
            "typeIndexe": "eau",
            "ancien": 83,
            "nouveau": 91,
            "periode": "2023-11-01",
            "avance": 3992,
            "reste": 0,
            "datePaiement": "2023-12-26T10:56:08.269Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 28,
            "idOccupation": 456,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 5,
            "montant": 550,
            "idIndexe": 51297,
            "typeIndexe": "energie",
            "ancien": 2900,
            "nouveau": 2905,
            "periode": "2023-11-01",
            "avance": 550,
            "reste": 0,
            "datePaiement": "2023-12-26T10:56:08.273Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 28,
            "idOccupation": 456,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 456,
            "loyerBase": 35000,
            "caution": 35000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-06-02T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-06-28T16:31:44.171Z",
            "idLocataire": 406,
            "idLogement": 28,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 406,
                "nomLocataire": "MIMBA",
                "prenomLocataire": "théophile",
                "cniLocataire": "",
                "tel": "673800891",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-06-28T16:01:49.810Z",
                "updatedAt": "2023-06-28T16:01:49.810Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                91,
                83
            ],
            "energie": [
                2905,
                2900
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                91,
                83
            ],
            "energie": [
                2905,
                2900
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51162,
                "typeIndexe": "eau",
                "ancien": 77,
                "nouveau": 83,
                "periode": "2023-10-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-11-25T07:54:07.900Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51020,
                "typeIndexe": "eau",
                "ancien": 74,
                "nouveau": 77,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:02.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50888,
                "typeIndexe": "eau",
                "ancien": 71,
                "nouveau": 74,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T06:20:39.041Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50758,
                "typeIndexe": "eau",
                "ancien": 66,
                "nouveau": 71,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T19:42:55.090Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 499,
                "idIndexe": 50720,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 66,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:09:11.151Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41367,
                "typeIndexe": "eau",
                "ancien": 71,
                "nouveau": 74,
                "periode": "2021-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41281,
                "typeIndexe": "eau",
                "ancien": 65,
                "nouveau": 71,
                "periode": "2021-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41196,
                "typeIndexe": "eau",
                "ancien": 65,
                "nouveau": 71,
                "periode": "2021-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 41127,
                "typeIndexe": "eau",
                "ancien": 93,
                "nouveau": 96,
                "periode": "2021-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 41088,
                "typeIndexe": "eau",
                "ancien": 91,
                "nouveau": 93,
                "periode": "2021-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 41013,
                "typeIndexe": "eau",
                "ancien": 91,
                "nouveau": 93,
                "periode": "2021-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51163,
                "typeIndexe": "energie",
                "ancien": 2888,
                "nouveau": 2900,
                "periode": "2023-10-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-11-25T07:54:07.906Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51021,
                "typeIndexe": "energie",
                "ancien": 2888,
                "nouveau": 2888,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:02.805Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50889,
                "typeIndexe": "energie",
                "ancien": 2873,
                "nouveau": 2893,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:20:39.011Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50759,
                "typeIndexe": "energie",
                "ancien": 2845,
                "nouveau": 2873,
                "periode": "2023-07-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-08-30T19:42:46.758Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 50721,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 4,
                "periode": "2023-06-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-07-26T13:09:11.155Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 28,
                "idOccupation": 456,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 48090,
                "typeIndexe": "energie",
                "ancien": 2811,
                "nouveau": 2845,
                "periode": "2021-09-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 31,
                "montant": 3410,
                "idIndexe": 48104,
                "typeIndexe": "energie",
                "ancien": 2780,
                "nouveau": 2811,
                "periode": "2021-08-01",
                "avance": 3410,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 48025,
                "typeIndexe": "energie",
                "ancien": 2798,
                "nouveau": 2806,
                "periode": "2021-07-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 47960,
                "typeIndexe": "energie",
                "ancien": 2789,
                "nouveau": 2798,
                "periode": "2021-06-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 47888,
                "typeIndexe": "energie",
                "ancien": 2743,
                "nouveau": 2789,
                "periode": "2021-05-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 47818,
                "typeIndexe": "energie",
                "ancien": 2736,
                "nouveau": 2743,
                "periode": "2021-04-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 28,
                "idOccupation": 209,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -91,
            "energie": -2905
        },
        "montant": {
            "eau": -45409,
            "energie": -319550
        }
    },
    {
        "idLogement": 29,
        "refLogement": "B007",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 30000,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-10-30T11:20:50.702Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51298,
                "typeIndexe": "eau",
                "ancien": 1577,
                "nouveau": 1579,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 51299,
                "typeIndexe": "energie",
                "ancien": 2085,
                "nouveau": 2090,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 51167,
                "typeIndexe": "energie",
                "ancien": 2081,
                "nouveau": 2085,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51165,
                "typeIndexe": "eau",
                "ancien": 1574,
                "nouveau": 1577,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 51023,
                "typeIndexe": "energie",
                "ancien": 2056,
                "nouveau": 2081,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51022,
                "typeIndexe": "eau",
                "ancien": 1570,
                "nouveau": 1574,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50890,
                "typeIndexe": "eau",
                "ancien": 1564,
                "nouveau": 1570,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50891,
                "typeIndexe": "energie",
                "ancien": 2002,
                "nouveau": 2056,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50760,
                "typeIndexe": "eau",
                "ancien": 1555,
                "nouveau": 1564,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50761,
                "typeIndexe": "energie",
                "ancien": 1918,
                "nouveau": 2002,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50623,
                "typeIndexe": "energie",
                "ancien": 1838,
                "nouveau": 1918,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50622,
                "typeIndexe": "eau",
                "ancien": 1551,
                "nouveau": 1555,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T13:10:51.396Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 8910,
                "idIndexe": 50486,
                "typeIndexe": "energie",
                "ancien": 1757,
                "nouveau": 1838,
                "periode": "2023-05-01",
                "avance": 5010,
                "reste": 3900,
                "datePaiement": "2023-07-26T13:11:10.825Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50485,
                "typeIndexe": "eau",
                "ancien": 1545,
                "nouveau": 1551,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T09:43:29.610Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50353,
                "typeIndexe": "eau",
                "ancien": 1541,
                "nouveau": 1545,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-05-30T09:55:13.839Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50354,
                "typeIndexe": "energie",
                "ancien": 1721,
                "nouveau": 1757,
                "periode": "2023-04-01",
                "avance": 3960,
                "reste": 0,
                "datePaiement": "2023-05-30T09:55:22.658Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1448,
                "montant": 4290,
                "idIndexe": 50225,
                "typeIndexe": "energie",
                "ancien": 1721,
                "nouveau": 3169,
                "periode": "2023-03-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-04-29T07:43:20.930Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50224,
                "typeIndexe": "eau",
                "ancien": 1537,
                "nouveau": 1541,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-29T07:43:20.922Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50100,
                "typeIndexe": "eau",
                "ancien": 1531,
                "nouveau": 1537,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:01:41.537Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50101,
                "typeIndexe": "energie",
                "ancien": 3178,
                "nouveau": 3198,
                "periode": "2023-02-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-03-29T08:01:41.540Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49984,
                "typeIndexe": "eau",
                "ancien": 1525,
                "nouveau": 1531,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.128Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 4290,
                "idIndexe": 49985,
                "typeIndexe": "energie",
                "ancien": 3139,
                "nouveau": 3178,
                "periode": "2023-01-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.132Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49868,
                "typeIndexe": "eau",
                "ancien": 1521,
                "nouveau": 1525,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.123Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 49869,
                "typeIndexe": "energie",
                "ancien": 3119,
                "nouveau": 3139,
                "periode": "2022-12-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.126Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51298,
            "typeIndexe": "eau",
            "ancien": 1577,
            "nouveau": 1579,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 29,
            "idOccupation": 97,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 5,
            "montant": 550,
            "idIndexe": 51299,
            "typeIndexe": "energie",
            "ancien": 2085,
            "nouveau": 2090,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 29,
            "idOccupation": 97,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 97,
            "loyerBase": 30000,
            "caution": 180000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-10-10T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-10-30T11:20:49.716Z",
            "idLocataire": 89,
            "idLogement": 29,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 89,
                "nomLocataire": "BANGA MBENKO",
                "prenomLocataire": "P",
                "cniLocataire": "",
                "tel": "655438879",
                "tel2": "679897854",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-10-30T11:01:11.065Z",
                "updatedAt": "2023-10-30T11:01:11.065Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1579,
                1577
            ],
            "energie": [
                2090,
                2085
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1579,
                1577
            ],
            "energie": [
                2090,
                2085
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51165,
                "typeIndexe": "eau",
                "ancien": 1574,
                "nouveau": 1577,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51022,
                "typeIndexe": "eau",
                "ancien": 1570,
                "nouveau": 1574,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50890,
                "typeIndexe": "eau",
                "ancien": 1564,
                "nouveau": 1570,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50760,
                "typeIndexe": "eau",
                "ancien": 1555,
                "nouveau": 1564,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50622,
                "typeIndexe": "eau",
                "ancien": 1551,
                "nouveau": 1555,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T13:10:51.396Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50485,
                "typeIndexe": "eau",
                "ancien": 1545,
                "nouveau": 1551,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T09:43:29.610Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50353,
                "typeIndexe": "eau",
                "ancien": 1541,
                "nouveau": 1545,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-05-30T09:55:13.839Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50224,
                "typeIndexe": "eau",
                "ancien": 1537,
                "nouveau": 1541,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-29T07:43:20.922Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50100,
                "typeIndexe": "eau",
                "ancien": 1531,
                "nouveau": 1537,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:01:41.537Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49984,
                "typeIndexe": "eau",
                "ancien": 1525,
                "nouveau": 1531,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.128Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49868,
                "typeIndexe": "eau",
                "ancien": 1521,
                "nouveau": 1525,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.123Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 51167,
                "typeIndexe": "energie",
                "ancien": 2081,
                "nouveau": 2085,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 97,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 51023,
                "typeIndexe": "energie",
                "ancien": 2056,
                "nouveau": 2081,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50891,
                "typeIndexe": "energie",
                "ancien": 2002,
                "nouveau": 2056,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50761,
                "typeIndexe": "energie",
                "ancien": 1918,
                "nouveau": 2002,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50623,
                "typeIndexe": "energie",
                "ancien": 1838,
                "nouveau": 1918,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 81,
                "montant": 8910,
                "idIndexe": 50486,
                "typeIndexe": "energie",
                "ancien": 1757,
                "nouveau": 1838,
                "periode": "2023-05-01",
                "avance": 5010,
                "reste": 3900,
                "datePaiement": "2023-07-26T13:11:10.825Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50354,
                "typeIndexe": "energie",
                "ancien": 1721,
                "nouveau": 1757,
                "periode": "2023-04-01",
                "avance": 3960,
                "reste": 0,
                "datePaiement": "2023-05-30T09:55:22.658Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1448,
                "montant": 4290,
                "idIndexe": 50225,
                "typeIndexe": "energie",
                "ancien": 1721,
                "nouveau": 3169,
                "periode": "2023-03-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-04-29T07:43:20.930Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50101,
                "typeIndexe": "energie",
                "ancien": 3178,
                "nouveau": 3198,
                "periode": "2023-02-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-03-29T08:01:41.540Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 4290,
                "idIndexe": 49985,
                "typeIndexe": "energie",
                "ancien": 3139,
                "nouveau": 3178,
                "periode": "2023-01-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.132Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 49869,
                "typeIndexe": "energie",
                "ancien": 3119,
                "nouveau": 3139,
                "periode": "2022-12-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-02-23T06:42:59.126Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 29,
                "idOccupation": 361,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1579,
            "energie": -2090
        },
        "montant": {
            "eau": -787921,
            "energie": -229900
        }
    },
    {
        "idLogement": 30,
        "refLogement": "B008",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 25000,
        "prixMax": 30000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.459Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51301,
                "typeIndexe": "energie",
                "ancien": 5053,
                "nouveau": 5055,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51300,
                "typeIndexe": "eau",
                "ancien": 776,
                "nouveau": 779,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51171,
                "typeIndexe": "energie",
                "ancien": 5052,
                "nouveau": 5053,
                "periode": "2023-10-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.525Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51170,
                "typeIndexe": "eau",
                "ancien": 773,
                "nouveau": 776,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.523Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51024,
                "typeIndexe": "eau",
                "ancien": 771,
                "nouveau": 773,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.516Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51025,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5052,
                "periode": "2023-09-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.520Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50893,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5051,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50892,
                "typeIndexe": "eau",
                "ancien": 770,
                "nouveau": 771,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.591Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50762,
                "typeIndexe": "eau",
                "ancien": 768,
                "nouveau": 770,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.586Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50763,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5051,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50624,
                "typeIndexe": "eau",
                "ancien": 766,
                "nouveau": 768,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50625,
                "typeIndexe": "energie",
                "ancien": 5050,
                "nouveau": 5051,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.471Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50487,
                "typeIndexe": "eau",
                "ancien": 764,
                "nouveau": 766,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50490,
                "typeIndexe": "energie",
                "ancien": 5049,
                "nouveau": 5050,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.450Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50489,
                "typeIndexe": "energie",
                "ancien": 5049,
                "nouveau": 5050,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.444Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50488,
                "typeIndexe": "eau",
                "ancien": 764,
                "nouveau": 766,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.448Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50355,
                "typeIndexe": "eau",
                "ancien": 762,
                "nouveau": 764,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.436Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50356,
                "typeIndexe": "energie",
                "ancien": 5032,
                "nouveau": 5049,
                "periode": "2023-04-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.439Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 162,
                "montant": 1870,
                "idIndexe": 50227,
                "typeIndexe": "energie",
                "ancien": 5032,
                "nouveau": 5194,
                "periode": "2023-03-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.898Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50226,
                "typeIndexe": "eau",
                "ancien": 761,
                "nouveau": 762,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.894Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50102,
                "typeIndexe": "eau",
                "ancien": 759,
                "nouveau": 761,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50103,
                "typeIndexe": "energie",
                "ancien": 5162,
                "nouveau": 5177,
                "periode": "2023-02-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.892Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49986,
                "typeIndexe": "eau",
                "ancien": 758,
                "nouveau": 759,
                "periode": "2023-01-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-23T06:43:34.358Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 49987,
                "typeIndexe": "energie",
                "ancien": 5146,
                "nouveau": 5162,
                "periode": "2023-01-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-02-23T06:43:34.360Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51300,
            "typeIndexe": "eau",
            "ancien": 776,
            "nouveau": 779,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 30,
            "idOccupation": 28,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 2,
            "montant": 220,
            "idIndexe": 51301,
            "typeIndexe": "energie",
            "ancien": 5053,
            "nouveau": 5055,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 30,
            "idOccupation": 28,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 28,
            "loyerBase": 30000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.458Z",
            "idLocataire": 28,
            "idLogement": 30,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 28,
                "nomLocataire": "NOUBEU NGOUOKA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "679505767",
                "tel2": "699000758",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:43.585Z",
                "updatedAt": "2022-03-02T11:54:43.585Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                779,
                776
            ],
            "energie": [
                5055,
                5053
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                779,
                776
            ],
            "energie": [
                5055,
                5053
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51170,
                "typeIndexe": "eau",
                "ancien": 773,
                "nouveau": 776,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.523Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51024,
                "typeIndexe": "eau",
                "ancien": 771,
                "nouveau": 773,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.516Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50892,
                "typeIndexe": "eau",
                "ancien": 770,
                "nouveau": 771,
                "periode": "2023-08-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.591Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50762,
                "typeIndexe": "eau",
                "ancien": 768,
                "nouveau": 770,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.586Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50624,
                "typeIndexe": "eau",
                "ancien": 766,
                "nouveau": 768,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50487,
                "typeIndexe": "eau",
                "ancien": 764,
                "nouveau": 766,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50488,
                "typeIndexe": "eau",
                "ancien": 764,
                "nouveau": 766,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.448Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50355,
                "typeIndexe": "eau",
                "ancien": 762,
                "nouveau": 764,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.436Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50226,
                "typeIndexe": "eau",
                "ancien": 761,
                "nouveau": 762,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.894Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50102,
                "typeIndexe": "eau",
                "ancien": 759,
                "nouveau": 761,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49986,
                "typeIndexe": "eau",
                "ancien": 758,
                "nouveau": 759,
                "periode": "2023-01-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-23T06:43:34.358Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51171,
                "typeIndexe": "energie",
                "ancien": 5052,
                "nouveau": 5053,
                "periode": "2023-10-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.525Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51025,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5052,
                "periode": "2023-09-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-11-27T15:32:36.520Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50893,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5051,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50763,
                "typeIndexe": "energie",
                "ancien": 5051,
                "nouveau": 5051,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50625,
                "typeIndexe": "energie",
                "ancien": 5050,
                "nouveau": 5051,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-09-28T06:21:25.471Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50490,
                "typeIndexe": "energie",
                "ancien": 5049,
                "nouveau": 5050,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.450Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50489,
                "typeIndexe": "energie",
                "ancien": 5049,
                "nouveau": 5050,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.444Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50356,
                "typeIndexe": "energie",
                "ancien": 5032,
                "nouveau": 5049,
                "periode": "2023-04-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-06-27T09:44:55.439Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 162,
                "montant": 1870,
                "idIndexe": 50227,
                "typeIndexe": "energie",
                "ancien": 5032,
                "nouveau": 5194,
                "periode": "2023-03-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.898Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50103,
                "typeIndexe": "energie",
                "ancien": 5162,
                "nouveau": 5177,
                "periode": "2023-02-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-04-29T07:56:21.892Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 49987,
                "typeIndexe": "energie",
                "ancien": 5146,
                "nouveau": 5162,
                "periode": "2023-01-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-02-23T06:43:34.360Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 30,
                "idOccupation": 28,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -779,
            "energie": -5055
        },
        "montant": {
            "eau": -388721,
            "energie": -556050
        }
    },
    {
        "idLogement": 31,
        "refLogement": "B009",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 25000,
        "prixMax": 30000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-05-03T11:22:12.711Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 51303,
                "typeIndexe": "energie",
                "ancien": 2701,
                "nouveau": 2755,
                "periode": "2023-11-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:00.787Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51302,
                "typeIndexe": "eau",
                "ancien": 748,
                "nouveau": 757,
                "periode": "2023-11-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:00.784Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51172,
                "typeIndexe": "eau",
                "ancien": 740,
                "nouveau": 748,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T09:18:20.313Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 51173,
                "typeIndexe": "energie",
                "ancien": 2643,
                "nouveau": 2701,
                "periode": "2023-10-01",
                "avance": 6380,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:15.669Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51026,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 740,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:51.129Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51027,
                "typeIndexe": "energie",
                "ancien": 2587,
                "nouveau": 2643,
                "periode": "2023-09-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-10-28T14:40:21.863Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50894,
                "typeIndexe": "eau",
                "ancien": 719,
                "nouveau": 730,
                "periode": "2023-08-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-09-28T06:22:37.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50895,
                "typeIndexe": "energie",
                "ancien": 2587,
                "nouveau": 2587,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50764,
                "typeIndexe": "eau",
                "ancien": 709,
                "nouveau": 719,
                "periode": "2023-07-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:26.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50765,
                "typeIndexe": "energie",
                "ancien": 2560,
                "nouveau": 2587,
                "periode": "2023-07-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:26.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50627,
                "typeIndexe": "energie",
                "ancien": 2515,
                "nouveau": 2560,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:39.276Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50626,
                "typeIndexe": "eau",
                "ancien": 702,
                "nouveau": 709,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-26T13:11:42.962Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 50492,
                "typeIndexe": "energie",
                "ancien": 2480,
                "nouveau": 2515,
                "periode": "2023-05-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-06-27T09:47:47.549Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50491,
                "typeIndexe": "eau",
                "ancien": 698,
                "nouveau": 702,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T09:47:47.545Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50357,
                "typeIndexe": "eau",
                "ancien": 693,
                "nouveau": 698,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T09:48:20.998Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50358,
                "typeIndexe": "energie",
                "ancien": 2437,
                "nouveau": 2480,
                "periode": "2023-04-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-05-30T09:57:20.550Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 26,
                "montant": 2860,
                "idIndexe": 50229,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2437,
                "periode": "2023-03-01",
                "avance": 2860,
                "reste": 0,
                "datePaiement": "2023-05-30T09:57:13.686Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50228,
                "typeIndexe": "eau",
                "ancien": 688,
                "nouveau": 693,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T14:20:09.135Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50105,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50104,
                "typeIndexe": "eau",
                "ancien": 640,
                "nouveau": 650,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-03-29T08:02:18.053Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49988,
                "typeIndexe": "eau",
                "ancien": 634,
                "nouveau": 640,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T06:44:34.124Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49989,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49872,
                "typeIndexe": "eau",
                "ancien": 631,
                "nouveau": 634,
                "periode": "2022-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-22T11:16:57.392Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49873,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 9,
            "montant": 4491,
            "idIndexe": 51302,
            "typeIndexe": "eau",
            "ancien": 748,
            "nouveau": 757,
            "periode": "2023-11-01",
            "avance": 4491,
            "reste": 0,
            "datePaiement": "2023-12-26T10:57:00.784Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 31,
            "idOccupation": 383,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 54,
            "montant": 5940,
            "idIndexe": 51303,
            "typeIndexe": "energie",
            "ancien": 2701,
            "nouveau": 2755,
            "periode": "2023-11-01",
            "avance": 5940,
            "reste": 0,
            "datePaiement": "2023-12-26T10:57:00.787Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 31,
            "idOccupation": 383,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 383,
            "loyerBase": 30000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-04-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-05-03T11:22:12.661Z",
            "idLocataire": 350,
            "idLogement": 31,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 350,
                "nomLocataire": "MOUSSA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "693525064",
                "tel2": "677358443",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-05-03T11:20:13.040Z",
                "updatedAt": "2022-06-27T15:50:32.725Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                757,
                748
            ],
            "energie": [
                2755,
                2701
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                757,
                748
            ],
            "energie": [
                2755,
                2701
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51172,
                "typeIndexe": "eau",
                "ancien": 740,
                "nouveau": 748,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T09:18:20.313Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51026,
                "typeIndexe": "eau",
                "ancien": 730,
                "nouveau": 740,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T14:39:51.129Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50894,
                "typeIndexe": "eau",
                "ancien": 719,
                "nouveau": 730,
                "periode": "2023-08-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-09-28T06:22:37.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50764,
                "typeIndexe": "eau",
                "ancien": 709,
                "nouveau": 719,
                "periode": "2023-07-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:26.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50626,
                "typeIndexe": "eau",
                "ancien": 702,
                "nouveau": 709,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-26T13:11:42.962Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50491,
                "typeIndexe": "eau",
                "ancien": 698,
                "nouveau": 702,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T09:47:47.545Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50357,
                "typeIndexe": "eau",
                "ancien": 693,
                "nouveau": 698,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T09:48:20.998Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50228,
                "typeIndexe": "eau",
                "ancien": 688,
                "nouveau": 693,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T14:20:09.135Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50104,
                "typeIndexe": "eau",
                "ancien": 640,
                "nouveau": 650,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-03-29T08:02:18.053Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49988,
                "typeIndexe": "eau",
                "ancien": 634,
                "nouveau": 640,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T06:44:34.124Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49872,
                "typeIndexe": "eau",
                "ancien": 631,
                "nouveau": 634,
                "periode": "2022-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-22T11:16:57.392Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 51173,
                "typeIndexe": "energie",
                "ancien": 2643,
                "nouveau": 2701,
                "periode": "2023-10-01",
                "avance": 6380,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:15.669Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51027,
                "typeIndexe": "energie",
                "ancien": 2587,
                "nouveau": 2643,
                "periode": "2023-09-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-10-28T14:40:21.863Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50895,
                "typeIndexe": "energie",
                "ancien": 2587,
                "nouveau": 2587,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50765,
                "typeIndexe": "energie",
                "ancien": 2560,
                "nouveau": 2587,
                "periode": "2023-07-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:26.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50627,
                "typeIndexe": "energie",
                "ancien": 2515,
                "nouveau": 2560,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-08-30T06:32:39.276Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 50492,
                "typeIndexe": "energie",
                "ancien": 2480,
                "nouveau": 2515,
                "periode": "2023-05-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-06-27T09:47:47.549Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50358,
                "typeIndexe": "energie",
                "ancien": 2437,
                "nouveau": 2480,
                "periode": "2023-04-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-05-30T09:57:20.550Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 26,
                "montant": 2860,
                "idIndexe": 50229,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2437,
                "periode": "2023-03-01",
                "avance": 2860,
                "reste": 0,
                "datePaiement": "2023-05-30T09:57:13.686Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50105,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49989,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49873,
                "typeIndexe": "energie",
                "ancien": 2411,
                "nouveau": 2411,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 31,
                "idOccupation": 383,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -757,
            "energie": -2755
        },
        "montant": {
            "eau": -377743,
            "energie": -303050
        }
    },
    {
        "idLogement": 32,
        "refLogement": "B010",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 25000,
        "prixMax": 30000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-09-28T13:04:52.445Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51304,
                "typeIndexe": "eau",
                "ancien": 1068,
                "nouveau": 1072,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51305,
                "typeIndexe": "energie",
                "ancien": 647,
                "nouveau": 649,
                "periode": "2023-11-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.520Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51175,
                "typeIndexe": "energie",
                "ancien": 638,
                "nouveau": 647,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51174,
                "typeIndexe": "eau",
                "ancien": 1062,
                "nouveau": 1068,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51028,
                "typeIndexe": "eau",
                "ancien": 1059,
                "nouveau": 1062,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 51029,
                "typeIndexe": "energie",
                "ancien": 632,
                "nouveau": 638,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50897,
                "typeIndexe": "energie",
                "ancien": 621,
                "nouveau": 632,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50896,
                "typeIndexe": "eau",
                "ancien": 1055,
                "nouveau": 1059,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50767,
                "typeIndexe": "energie",
                "ancien": 609,
                "nouveau": 621,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50766,
                "typeIndexe": "eau",
                "ancien": 1051,
                "nouveau": 1055,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50629,
                "typeIndexe": "energie",
                "ancien": 606,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.518Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50628,
                "typeIndexe": "eau",
                "ancien": 1050,
                "nouveau": 1051,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.512Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50630,
                "typeIndexe": "energie",
                "ancien": 606,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.515Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50493,
                "typeIndexe": "eau",
                "ancien": 1046,
                "nouveau": 1050,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.510Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50494,
                "typeIndexe": "energie",
                "ancien": 588,
                "nouveau": 606,
                "periode": "2023-05-01",
                "avance": 77,
                "reste": 1903,
                "datePaiement": "2023-12-26T10:58:12.036Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50359,
                "typeIndexe": "eau",
                "ancien": 1043,
                "nouveau": 1046,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.506Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50360,
                "typeIndexe": "energie",
                "ancien": 581,
                "nouveau": 588,
                "periode": "2023-04-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-11-27T15:33:42.555Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50230,
                "typeIndexe": "eau",
                "ancien": 1038,
                "nouveau": 1043,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.501Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50231,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 581,
                "periode": "2023-03-01",
                "avance": 98,
                "reste": 4412,
                "datePaiement": "2023-11-27T15:34:05.296Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50232,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 581,
                "periode": "2023-03-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.504Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50106,
                "typeIndexe": "eau",
                "ancien": 1034,
                "nouveau": 1038,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.499Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50107,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 540,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49990,
                "typeIndexe": "eau",
                "ancien": 1029,
                "nouveau": 1034,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-27T15:33:42.551Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 55,
                "montant": 6050,
                "idIndexe": 49991,
                "typeIndexe": "energie",
                "ancien": 745,
                "nouveau": 800,
                "periode": "2023-01-01",
                "avance": 6050,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.495Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51304,
            "typeIndexe": "eau",
            "ancien": 1068,
            "nouveau": 1072,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 32,
            "idOccupation": 357,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 2,
            "montant": 220,
            "idIndexe": 51305,
            "typeIndexe": "energie",
            "ancien": 647,
            "nouveau": 649,
            "periode": "2023-11-01",
            "avance": 220,
            "reste": 0,
            "datePaiement": "2023-12-26T10:57:38.520Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 32,
            "idOccupation": 357,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 357,
            "loyerBase": 30000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2021-09-30T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.241Z",
            "idLocataire": 324,
            "idLogement": 32,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 324,
                "nomLocataire": "OLOKO",
                "prenomLocataire": "Yannick",
                "cniLocataire": "",
                "tel": "655217263",
                "tel2": "655217263",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.257Z",
                "updatedAt": "2022-05-03T11:40:25.562Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1072,
                1068
            ],
            "energie": [
                649,
                647
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1072,
                1068
            ],
            "energie": [
                649,
                647
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51174,
                "typeIndexe": "eau",
                "ancien": 1062,
                "nouveau": 1068,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51028,
                "typeIndexe": "eau",
                "ancien": 1059,
                "nouveau": 1062,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50896,
                "typeIndexe": "eau",
                "ancien": 1055,
                "nouveau": 1059,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50766,
                "typeIndexe": "eau",
                "ancien": 1051,
                "nouveau": 1055,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50628,
                "typeIndexe": "eau",
                "ancien": 1050,
                "nouveau": 1051,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.512Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50493,
                "typeIndexe": "eau",
                "ancien": 1046,
                "nouveau": 1050,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.510Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50359,
                "typeIndexe": "eau",
                "ancien": 1043,
                "nouveau": 1046,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.506Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50230,
                "typeIndexe": "eau",
                "ancien": 1038,
                "nouveau": 1043,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.501Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50106,
                "typeIndexe": "eau",
                "ancien": 1034,
                "nouveau": 1038,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.499Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49990,
                "typeIndexe": "eau",
                "ancien": 1029,
                "nouveau": 1034,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-27T15:33:42.551Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 51175,
                "typeIndexe": "energie",
                "ancien": 638,
                "nouveau": 647,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 51029,
                "typeIndexe": "energie",
                "ancien": 632,
                "nouveau": 638,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50897,
                "typeIndexe": "energie",
                "ancien": 621,
                "nouveau": 632,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50767,
                "typeIndexe": "energie",
                "ancien": 609,
                "nouveau": 621,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50629,
                "typeIndexe": "energie",
                "ancien": 606,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.518Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50630,
                "typeIndexe": "energie",
                "ancien": 606,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.515Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50494,
                "typeIndexe": "energie",
                "ancien": 588,
                "nouveau": 606,
                "periode": "2023-05-01",
                "avance": 77,
                "reste": 1903,
                "datePaiement": "2023-12-26T10:58:12.036Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50360,
                "typeIndexe": "energie",
                "ancien": 581,
                "nouveau": 588,
                "periode": "2023-04-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-11-27T15:33:42.555Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50231,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 581,
                "periode": "2023-03-01",
                "avance": 98,
                "reste": 4412,
                "datePaiement": "2023-11-27T15:34:05.296Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50232,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 581,
                "periode": "2023-03-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.504Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50107,
                "typeIndexe": "energie",
                "ancien": 540,
                "nouveau": 540,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 55,
                "montant": 6050,
                "idIndexe": 49991,
                "typeIndexe": "energie",
                "ancien": 745,
                "nouveau": 800,
                "periode": "2023-01-01",
                "avance": 6050,
                "reste": 0,
                "datePaiement": "2023-12-26T10:57:38.495Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 32,
                "idOccupation": 357,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1072,
            "energie": -649
        },
        "montant": {
            "eau": -534928,
            "energie": -71390
        }
    },
    {
        "idLogement": 33,
        "refLogement": "B011",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 25000,
        "prixMax": 30000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-05-30T14:58:51.009Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51306,
                "typeIndexe": "eau",
                "ancien": 670,
                "nouveau": 672,
                "periode": "2023-11-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T10:58:40.788Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51307,
                "typeIndexe": "energie",
                "ancien": 9763,
                "nouveau": 9764,
                "periode": "2023-11-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-12-26T10:58:40.792Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51177,
                "typeIndexe": "energie",
                "ancien": 9763,
                "nouveau": 9763,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51176,
                "typeIndexe": "eau",
                "ancien": 666,
                "nouveau": 670,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:14.250Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51031,
                "typeIndexe": "energie",
                "ancien": 9762,
                "nouveau": 9763,
                "periode": "2023-09-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-10-28T14:41:31.038Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51030,
                "typeIndexe": "eau",
                "ancien": 660,
                "nouveau": 666,
                "periode": "2023-09-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:41:31.034Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50898,
                "typeIndexe": "eau",
                "ancien": 650,
                "nouveau": 660,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T06:23:22.614Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50899,
                "typeIndexe": "energie",
                "ancien": 9762,
                "nouveau": 9762,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50768,
                "typeIndexe": "eau",
                "ancien": 645,
                "nouveau": 650,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:34:59.378Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50769,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9762,
                "periode": "2023-07-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:34:59.385Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50632,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50631,
                "typeIndexe": "eau",
                "ancien": 644,
                "nouveau": 645,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:20:33.739Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50495,
                "typeIndexe": "eau",
                "ancien": 641,
                "nouveau": 644,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T13:20:33.734Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50496,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50362,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50361,
                "typeIndexe": "eau",
                "ancien": 640,
                "nouveau": 641,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50233,
                "typeIndexe": "eau",
                "ancien": 636,
                "nouveau": 640,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50234,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9761,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50109,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50108,
                "typeIndexe": "eau",
                "ancien": 633,
                "nouveau": 636,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49992,
                "typeIndexe": "eau",
                "ancien": 629,
                "nouveau": 633,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49993,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49877,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49876,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 629,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51306,
            "typeIndexe": "eau",
            "ancien": 670,
            "nouveau": 672,
            "periode": "2023-11-01",
            "avance": 998,
            "reste": 0,
            "datePaiement": "2023-12-26T10:58:40.788Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 33,
            "idOccupation": 453,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 1,
            "montant": 110,
            "idIndexe": 51307,
            "typeIndexe": "energie",
            "ancien": 9763,
            "nouveau": 9764,
            "periode": "2023-11-01",
            "avance": 110,
            "reste": 0,
            "datePaiement": "2023-12-26T10:58:40.792Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 33,
            "idOccupation": 453,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 453,
            "loyerBase": 30000,
            "caution": 30000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-05-11T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-05-30T14:58:48.832Z",
            "idLocataire": 405,
            "idLogement": 33,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 405,
                "nomLocataire": "PATIO TCHOKOTE",
                "prenomLocataire": "marco Steven",
                "cniLocataire": "",
                "tel": "655338824",
                "tel2": "654723484",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-05-30T14:55:58.546Z",
                "updatedAt": "2023-08-30T06:36:18.338Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                672,
                670
            ],
            "energie": [
                9764,
                9763
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                672,
                670
            ],
            "energie": [
                9764,
                9763
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51176,
                "typeIndexe": "eau",
                "ancien": 666,
                "nouveau": 670,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:14.250Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51030,
                "typeIndexe": "eau",
                "ancien": 660,
                "nouveau": 666,
                "periode": "2023-09-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:41:31.034Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50898,
                "typeIndexe": "eau",
                "ancien": 650,
                "nouveau": 660,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T06:23:22.614Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50768,
                "typeIndexe": "eau",
                "ancien": 645,
                "nouveau": 650,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:34:59.378Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50631,
                "typeIndexe": "eau",
                "ancien": 644,
                "nouveau": 645,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:20:33.739Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50495,
                "typeIndexe": "eau",
                "ancien": 641,
                "nouveau": 644,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T13:20:33.734Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50361,
                "typeIndexe": "eau",
                "ancien": 640,
                "nouveau": 641,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50233,
                "typeIndexe": "eau",
                "ancien": 636,
                "nouveau": 640,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50108,
                "typeIndexe": "eau",
                "ancien": 633,
                "nouveau": 636,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49992,
                "typeIndexe": "eau",
                "ancien": 629,
                "nouveau": 633,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49876,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 629,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51177,
                "typeIndexe": "energie",
                "ancien": 9763,
                "nouveau": 9763,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51031,
                "typeIndexe": "energie",
                "ancien": 9762,
                "nouveau": 9763,
                "periode": "2023-09-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-10-28T14:41:31.038Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50899,
                "typeIndexe": "energie",
                "ancien": 9762,
                "nouveau": 9762,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50769,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9762,
                "periode": "2023-07-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:34:59.385Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50632,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50496,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 453,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50362,
                "typeIndexe": "energie",
                "ancien": 9761,
                "nouveau": 9761,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50234,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9761,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50109,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49993,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49877,
                "typeIndexe": "energie",
                "ancien": 9754,
                "nouveau": 9754,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 33,
                "idOccupation": 367,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -672,
            "energie": -9764
        },
        "montant": {
            "eau": -335328,
            "energie": -1074040
        }
    },
    {
        "idLogement": 34,
        "refLogement": "B012",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 25000,
        "prixMax": 30000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-11-30T08:36:30.445Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51309,
                "typeIndexe": "energie",
                "ancien": 14,
                "nouveau": 5,
                "periode": "2023-11-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-12-26T11:00:26.126Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51308,
                "typeIndexe": "eau",
                "ancien": 628,
                "nouveau": 630,
                "periode": "2023-11-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T11:00:41.728Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51179,
                "typeIndexe": "energie",
                "ancien": 12,
                "nouveau": 14,
                "periode": "2023-10-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-25T07:53:30.828Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51178,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 628,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-25T07:53:30.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51032,
                "typeIndexe": "eau",
                "ancien": 623,
                "nouveau": 624,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:44:30.101Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 51033,
                "typeIndexe": "energie",
                "ancien": 8,
                "nouveau": 12,
                "periode": "2023-09-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-10-28T14:44:30.106Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50900,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 623,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:24:02.989Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50901,
                "typeIndexe": "energie",
                "ancien": 2,
                "nouveau": 8,
                "periode": "2023-08-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T06:24:03.040Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50771,
                "typeIndexe": "energie",
                "ancien": 1,
                "nouveau": 2,
                "periode": "2023-07-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:30.037Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50770,
                "typeIndexe": "eau",
                "ancien": 619,
                "nouveau": 621,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:30.033Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50633,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 619,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T13:24:16.858Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50634,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:45.640Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50497,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 617,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50498,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50363,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 617,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50364,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50235,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 617,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T09:51:36.820Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50236,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 998,
                "idIndexe": 50110,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 616,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:04.192Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50111,
                "typeIndexe": "energie",
                "ancien": 6328,
                "nouveau": 6358,
                "periode": "2023-02-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:04.195Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49994,
                "typeIndexe": "eau",
                "ancien": 613,
                "nouveau": 616,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-23T06:50:09.337Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 49995,
                "typeIndexe": "energie",
                "ancien": 6293,
                "nouveau": 6328,
                "periode": "2023-01-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-02-23T06:50:09.340Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49878,
                "typeIndexe": "eau",
                "ancien": 611,
                "nouveau": 613,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:21:11.506Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 49879,
                "typeIndexe": "energie",
                "ancien": 6203,
                "nouveau": 6293,
                "periode": "2022-12-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-02-23T06:51:01.774Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51308,
            "typeIndexe": "eau",
            "ancien": 628,
            "nouveau": 630,
            "periode": "2023-11-01",
            "avance": 998,
            "reste": 0,
            "datePaiement": "2023-12-26T11:00:41.728Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 34,
            "idOccupation": 440,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 1,
            "montant": 110,
            "idIndexe": 51309,
            "typeIndexe": "energie",
            "ancien": 14,
            "nouveau": 15,
            "periode": "2023-11-01",
            "avance": 110,
            "reste": 0,
            "datePaiement": "2023-12-26T11:00:26.126Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 34,
            "idOccupation": 440,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 440,
            "loyerBase": 30000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-10-05T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-11-30T08:36:30.370Z",
            "idLocataire": 393,
            "idLogement": 34,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 393,
                "nomLocataire": "MAHAMA",
                "prenomLocataire": "Jean",
                "cniLocataire": "",
                "tel": "698305337",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-11-29T15:10:52.147Z",
                "updatedAt": "2022-11-30T08:20:34.432Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                630,
                628
            ],
            "energie": [
                15,
                14
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                630,
                628
            ],
            "energie": [
                15,
                14
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51178,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 628,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-11-25T07:53:30.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51032,
                "typeIndexe": "eau",
                "ancien": 623,
                "nouveau": 624,
                "periode": "2023-09-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-10-28T14:44:30.101Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50900,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 623,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:24:02.989Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50770,
                "typeIndexe": "eau",
                "ancien": 619,
                "nouveau": 621,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:30.033Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50633,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 619,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T13:24:16.858Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50497,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 617,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50363,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 617,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50235,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 617,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T09:51:36.820Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 998,
                "idIndexe": 50110,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 616,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:04.192Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 49994,
                "typeIndexe": "eau",
                "ancien": 613,
                "nouveau": 616,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-02-23T06:50:09.337Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49878,
                "typeIndexe": "eau",
                "ancien": 611,
                "nouveau": 613,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:21:11.506Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51179,
                "typeIndexe": "energie",
                "ancien": 12,
                "nouveau": 14,
                "periode": "2023-10-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-25T07:53:30.828Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 51033,
                "typeIndexe": "energie",
                "ancien": 8,
                "nouveau": 12,
                "periode": "2023-09-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-10-28T14:44:30.106Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50901,
                "typeIndexe": "energie",
                "ancien": 2,
                "nouveau": 8,
                "periode": "2023-08-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T06:24:03.040Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50771,
                "typeIndexe": "energie",
                "ancien": 1,
                "nouveau": 2,
                "periode": "2023-07-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:30.037Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50634,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1,
                "periode": "2023-06-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-08-30T06:37:45.640Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50111,
                "typeIndexe": "energie",
                "ancien": 6328,
                "nouveau": 6358,
                "periode": "2023-02-01",
                "avance": 3300,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:04.195Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 49995,
                "typeIndexe": "energie",
                "ancien": 6293,
                "nouveau": 6328,
                "periode": "2023-01-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-02-23T06:50:09.340Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 49879,
                "typeIndexe": "energie",
                "ancien": 6203,
                "nouveau": 6293,
                "periode": "2022-12-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2023-02-23T06:51:01.774Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 34,
                "idOccupation": 440,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -630,
            "energie": -15
        },
        "montant": {
            "eau": -314370,
            "energie": -1650
        }
    },
    {
        "idLogement": 35,
        "refLogement": "B013",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-06-28T11:38:04.249Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51310,
                "typeIndexe": "eau",
                "ancien": 2914,
                "nouveau": 2918,
                "periode": "2023-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:05:17.534Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 71,
                "montant": 7810,
                "idIndexe": 51311,
                "typeIndexe": "energie",
                "ancien": 16180,
                "nouveau": 16251,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51180,
                "typeIndexe": "eau",
                "ancien": 2908,
                "nouveau": 2914,
                "periode": "2023-10-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-26T11:05:17.492Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 51181,
                "typeIndexe": "energie",
                "ancien": 16097,
                "nouveau": 16180,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 51038,
                "typeIndexe": "eau",
                "ancien": 2892,
                "nouveau": 2908,
                "periode": "2023-09-01",
                "avance": 1210,
                "reste": 6774,
                "datePaiement": "2023-12-26T11:05:35.685Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51039,
                "typeIndexe": "energie",
                "ancien": 16015,
                "nouveau": 16097,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50902,
                "typeIndexe": "eau",
                "ancien": 2887,
                "nouveau": 2892,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-27T15:37:57.946Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50903,
                "typeIndexe": "energie",
                "ancien": 15935,
                "nouveau": 16015,
                "periode": "2023-08-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-12-26T11:05:17.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50773,
                "typeIndexe": "energie",
                "ancien": 15856,
                "nouveau": 15935,
                "periode": "2023-07-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-09-28T06:26:30.433Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50772,
                "typeIndexe": "eau",
                "ancien": 2881,
                "nouveau": 2887,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T06:38:27.683Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50635,
                "typeIndexe": "eau",
                "ancien": 2876,
                "nouveau": 2881,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.507Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50636,
                "typeIndexe": "energie",
                "ancien": 15811,
                "nouveau": 15856,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-08-30T06:38:27.680Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50499,
                "typeIndexe": "eau",
                "ancien": 2871,
                "nouveau": 2876,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.501Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50500,
                "typeIndexe": "energie",
                "ancien": 15806,
                "nouveau": 15811,
                "periode": "2023-05-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.504Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 50366,
                "typeIndexe": "energie",
                "ancien": 15793,
                "nouveau": 15806,
                "periode": "2023-04-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2023-04-30T15:31:43.990Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50365,
                "typeIndexe": "eau",
                "ancien": 2868,
                "nouveau": 2871,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-30T15:32:19.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 12475,
                "idIndexe": 50237,
                "typeIndexe": "eau",
                "ancien": 2843,
                "nouveau": 2868,
                "periode": "2023-03-01",
                "avance": 12475,
                "reste": 0,
                "datePaiement": "2023-09-28T06:26:22.168Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50239,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15794,
                "periode": "2023-03-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-03-29T13:04:10.031Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50238,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15794,
                "periode": "2023-03-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-03-29T13:04:03.291Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50240,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15793,
                "periode": "2023-03-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.498Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50112,
                "typeIndexe": "eau",
                "ancien": 2839,
                "nouveau": 2843,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:39.080Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50113,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15766,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49997,
                "typeIndexe": "energie",
                "ancien": 13726,
                "nouveau": 13726,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49996,
                "typeIndexe": "eau",
                "ancien": 2832,
                "nouveau": 2839,
                "periode": "2023-01-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:39.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51310,
            "typeIndexe": "eau",
            "ancien": 2914,
            "nouveau": 2918,
            "periode": "2023-11-01",
            "avance": 1996,
            "reste": 0,
            "datePaiement": "2023-12-26T11:05:17.534Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 35,
            "idOccupation": 395,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 71,
            "montant": 7810,
            "idIndexe": 51311,
            "typeIndexe": "energie",
            "ancien": 16180,
            "nouveau": 16251,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 35,
            "idOccupation": 395,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 395,
            "loyerBase": 60000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-06-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-06-28T11:38:04.171Z",
            "idLocataire": 360,
            "idLogement": 35,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 360,
                "nomLocataire": "MBARGA MEKONG",
                "prenomLocataire": "Josephine",
                "cniLocataire": "",
                "tel": "696227974",
                "tel2": "696227974",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-06-27T17:09:39.812Z",
                "updatedAt": "2022-06-27T17:09:39.812Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2918,
                2914
            ],
            "energie": [
                16251,
                16180
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2918,
                2914
            ],
            "energie": [
                16251,
                16180
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51180,
                "typeIndexe": "eau",
                "ancien": 2908,
                "nouveau": 2914,
                "periode": "2023-10-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-26T11:05:17.492Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 51038,
                "typeIndexe": "eau",
                "ancien": 2892,
                "nouveau": 2908,
                "periode": "2023-09-01",
                "avance": 1210,
                "reste": 6774,
                "datePaiement": "2023-12-26T11:05:35.685Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50902,
                "typeIndexe": "eau",
                "ancien": 2887,
                "nouveau": 2892,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-27T15:37:57.946Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50772,
                "typeIndexe": "eau",
                "ancien": 2881,
                "nouveau": 2887,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T06:38:27.683Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50635,
                "typeIndexe": "eau",
                "ancien": 2876,
                "nouveau": 2881,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.507Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50499,
                "typeIndexe": "eau",
                "ancien": 2871,
                "nouveau": 2876,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.501Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50365,
                "typeIndexe": "eau",
                "ancien": 2868,
                "nouveau": 2871,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-30T15:32:19.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 12475,
                "idIndexe": 50237,
                "typeIndexe": "eau",
                "ancien": 2843,
                "nouveau": 2868,
                "periode": "2023-03-01",
                "avance": 12475,
                "reste": 0,
                "datePaiement": "2023-09-28T06:26:22.168Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50112,
                "typeIndexe": "eau",
                "ancien": 2839,
                "nouveau": 2843,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:39.080Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49996,
                "typeIndexe": "eau",
                "ancien": 2832,
                "nouveau": 2839,
                "periode": "2023-01-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-03-29T08:04:39.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 51181,
                "typeIndexe": "energie",
                "ancien": 16097,
                "nouveau": 16180,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51039,
                "typeIndexe": "energie",
                "ancien": 16015,
                "nouveau": 16097,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50903,
                "typeIndexe": "energie",
                "ancien": 15935,
                "nouveau": 16015,
                "periode": "2023-08-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-12-26T11:05:17.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50773,
                "typeIndexe": "energie",
                "ancien": 15856,
                "nouveau": 15935,
                "periode": "2023-07-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-09-28T06:26:30.433Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50636,
                "typeIndexe": "energie",
                "ancien": 15811,
                "nouveau": 15856,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-08-30T06:38:27.680Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 50500,
                "typeIndexe": "energie",
                "ancien": 15806,
                "nouveau": 15811,
                "periode": "2023-05-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.504Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 50366,
                "typeIndexe": "energie",
                "ancien": 15793,
                "nouveau": 15806,
                "periode": "2023-04-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2023-04-30T15:31:43.990Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50239,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15794,
                "periode": "2023-03-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-03-29T13:04:10.031Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50238,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15794,
                "periode": "2023-03-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-03-29T13:04:03.291Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50240,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15793,
                "periode": "2023-03-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2023-07-26T13:25:06.498Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50113,
                "typeIndexe": "energie",
                "ancien": 15766,
                "nouveau": 15766,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49997,
                "typeIndexe": "energie",
                "ancien": 13726,
                "nouveau": 13726,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 35,
                "idOccupation": 395,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2918,
            "energie": -16251
        },
        "montant": {
            "eau": -1456082,
            "energie": -1787610
        }
    },
    {
        "idLogement": 36,
        "refLogement": "B014",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 80000,
        "prixMax": 90000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-09-28T13:10:29.787Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 51313,
                "typeIndexe": "energie",
                "ancien": 38,
                "nouveau": 3832,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51312,
                "typeIndexe": "eau",
                "ancien": 137,
                "nouveau": 1898,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51182,
                "typeIndexe": "eau",
                "ancien": 135,
                "nouveau": 137,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 51183,
                "typeIndexe": "energie",
                "ancien": 28,
                "nouveau": 38,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51040,
                "typeIndexe": "eau",
                "ancien": 134,
                "nouveau": 135,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51041,
                "typeIndexe": "energie",
                "ancien": 28,
                "nouveau": 28,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50905,
                "typeIndexe": "energie",
                "ancien": 26,
                "nouveau": 28,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50904,
                "typeIndexe": "eau",
                "ancien": 133,
                "nouveau": 134,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50775,
                "typeIndexe": "energie",
                "ancien": 25,
                "nouveau": 26,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50774,
                "typeIndexe": "eau",
                "ancien": 132,
                "nouveau": 133,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50637,
                "typeIndexe": "eau",
                "ancien": 127,
                "nouveau": 132,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50639,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 25,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50638,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50581,
                "typeIndexe": "eau",
                "ancien": 121,
                "nouveau": 127,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 2310,
                "idIndexe": 50368,
                "typeIndexe": "energie",
                "ancien": 3798,
                "nouveau": 3819,
                "periode": "2023-04-01",
                "avance": 2310,
                "reste": 0,
                "datePaiement": "2023-04-30T15:41:33.105Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50367,
                "typeIndexe": "eau",
                "ancien": 1892,
                "nouveau": 1893,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-30T15:36:31.081Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 0,
                "idIndexe": 50244,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50242,
                "typeIndexe": "eau",
                "ancien": 1892,
                "nouveau": 1892,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50241,
                "typeIndexe": "eau",
                "ancien": 1889,
                "nouveau": 1892,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T16:23:23.264Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50243,
                "typeIndexe": "energie",
                "ancien": 3798,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50245,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 8800,
                "idIndexe": 50115,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3718,
                "periode": "2023-02-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50114,
                "typeIndexe": "eau",
                "ancien": 1883,
                "nouveau": 1889,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.730Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49998,
                "typeIndexe": "eau",
                "ancien": 1870,
                "nouveau": 1883,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.721Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51312,
            "typeIndexe": "eau",
            "ancien": 137,
            "nouveau": 139,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 36,
            "idOccupation": 421,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 10,
            "montant": 1100,
            "idIndexe": 51313,
            "typeIndexe": "energie",
            "ancien": 38,
            "nouveau": 48,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 36,
            "idOccupation": 421,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 421,
            "loyerBase": 75000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-09-28T13:10:29.660Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-09-28T13:10:29.710Z",
            "idLocataire": 400,
            "idLogement": 36,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 400,
                "nomLocataire": "NTOU",
                "prenomLocataire": "Brandon rodrigue",
                "cniLocataire": "20190663326810220",
                "tel": "658386856",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": "2001-06-06T00:00:00.000Z",
                "lieuNaiss": "yaounde",
                "statutLocataire": true,
                "createdAt": "2023-03-27T10:05:59.358Z",
                "updatedAt": "2023-03-27T10:05:59.358Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                139,
                137
            ],
            "energie": [
                48,
                38
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                139,
                137
            ],
            "energie": [
                48,
                38
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51182,
                "typeIndexe": "eau",
                "ancien": 135,
                "nouveau": 137,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51040,
                "typeIndexe": "eau",
                "ancien": 134,
                "nouveau": 135,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50904,
                "typeIndexe": "eau",
                "ancien": 133,
                "nouveau": 134,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50774,
                "typeIndexe": "eau",
                "ancien": 132,
                "nouveau": 133,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50637,
                "typeIndexe": "eau",
                "ancien": 127,
                "nouveau": 132,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50581,
                "typeIndexe": "eau",
                "ancien": 121,
                "nouveau": 127,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50367,
                "typeIndexe": "eau",
                "ancien": 1892,
                "nouveau": 1893,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-04-30T15:36:31.081Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50242,
                "typeIndexe": "eau",
                "ancien": 1892,
                "nouveau": 1892,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50241,
                "typeIndexe": "eau",
                "ancien": 1889,
                "nouveau": 1892,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T16:23:23.264Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50114,
                "typeIndexe": "eau",
                "ancien": 1883,
                "nouveau": 1889,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.730Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49998,
                "typeIndexe": "eau",
                "ancien": 1870,
                "nouveau": 1883,
                "periode": "2023-01-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.721Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 51183,
                "typeIndexe": "energie",
                "ancien": 28,
                "nouveau": 38,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51041,
                "typeIndexe": "energie",
                "ancien": 28,
                "nouveau": 28,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50905,
                "typeIndexe": "energie",
                "ancien": 26,
                "nouveau": 28,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50775,
                "typeIndexe": "energie",
                "ancien": 25,
                "nouveau": 26,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50639,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 25,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 2310,
                "idIndexe": 50368,
                "typeIndexe": "energie",
                "ancien": 3798,
                "nouveau": 3819,
                "periode": "2023-04-01",
                "avance": 2310,
                "reste": 0,
                "datePaiement": "2023-04-30T15:41:33.105Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 0,
                "idIndexe": 50244,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50243,
                "typeIndexe": "energie",
                "ancien": 3798,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50245,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3798,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 8800,
                "idIndexe": 50115,
                "typeIndexe": "energie",
                "ancien": 3718,
                "nouveau": 3718,
                "periode": "2023-02-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-03-29T08:05:48.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 36,
                "idOccupation": 421,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -139,
            "energie": -48
        },
        "montant": {
            "eau": -69361,
            "energie": -5280
        }
    },
    {
        "idLogement": 37,
        "refLogement": "B015",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-02-22T16:27:14.343Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51315,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51314,
                "typeIndexe": "eau",
                "ancien": 548,
                "nouveau": 598,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T11:08:15.317Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51185,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51184,
                "typeIndexe": "eau",
                "ancien": 547,
                "nouveau": 548,
                "periode": "2023-10-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-11-27T15:39:47.807Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51043,
                "typeIndexe": "eau",
                "ancien": 538,
                "nouveau": 547,
                "periode": "2023-09-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:37.902Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51044,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51045,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51042,
                "typeIndexe": "eau",
                "ancien": 538,
                "nouveau": 547,
                "periode": "2023-09-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:33.665Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50907,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50906,
                "typeIndexe": "eau",
                "ancien": 532,
                "nouveau": 538,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:28.655Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50776,
                "typeIndexe": "eau",
                "ancien": 528,
                "nouveau": 532,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:42:32.558Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50777,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50640,
                "typeIndexe": "eau",
                "ancien": 524,
                "nouveau": 528,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T13:45:23.602Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50641,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50502,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50501,
                "typeIndexe": "eau",
                "ancien": 519,
                "nouveau": 524,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T09:55:56.226Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50369,
                "typeIndexe": "eau",
                "ancien": 519,
                "nouveau": 519,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50370,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50246,
                "typeIndexe": "eau",
                "ancien": 514,
                "nouveau": 519,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:03:25.899Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50247,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50116,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 5,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:03:16.808Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50118,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -291,
                "montant": 0,
                "idIndexe": 50064,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50117,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51314,
            "typeIndexe": "eau",
            "ancien": 548,
            "nouveau": 549,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T11:08:15.317Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 37,
            "idOccupation": 445,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51315,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 37,
            "idOccupation": 445,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 445,
            "loyerBase": 50000,
            "caution": 50000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-01-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-02-22T16:27:12.252Z",
            "idLocataire": 398,
            "idLogement": 37,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 398,
                "nomLocataire": "TANGA",
                "prenomLocataire": "leonce hervé",
                "cniLocataire": "",
                "tel": "674714298",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-02-22T16:13:36.363Z",
                "updatedAt": "2023-02-22T16:13:36.363Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                549,
                548
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                549,
                548
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51184,
                "typeIndexe": "eau",
                "ancien": 547,
                "nouveau": 548,
                "periode": "2023-10-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-11-27T15:39:47.807Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51043,
                "typeIndexe": "eau",
                "ancien": 538,
                "nouveau": 547,
                "periode": "2023-09-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:37.902Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51042,
                "typeIndexe": "eau",
                "ancien": 538,
                "nouveau": 547,
                "periode": "2023-09-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:33.665Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50906,
                "typeIndexe": "eau",
                "ancien": 532,
                "nouveau": 538,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:56:28.655Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50776,
                "typeIndexe": "eau",
                "ancien": 528,
                "nouveau": 532,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T06:42:32.558Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50640,
                "typeIndexe": "eau",
                "ancien": 524,
                "nouveau": 528,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T13:45:23.602Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50501,
                "typeIndexe": "eau",
                "ancien": 519,
                "nouveau": 524,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T09:55:56.226Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50369,
                "typeIndexe": "eau",
                "ancien": 519,
                "nouveau": 519,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50246,
                "typeIndexe": "eau",
                "ancien": 514,
                "nouveau": 519,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:03:25.899Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50116,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 5,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:03:16.808Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 37,
                "idOccupation": 445,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [],
        "conso": {
            "eau": -549,
            "energie": 0
        },
        "montant": {
            "eau": -273951,
            "energie": 0
        }
    },
    {
        "idLogement": 38,
        "refLogement": "B016",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.184Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51316,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51317,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51187,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51186,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51046,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51047,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50909,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50908,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50778,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50779,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50643,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50642,
                "typeIndexe": "eau",
                "ancien": 2459,
                "nouveau": 2461,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 23952,
                "idIndexe": 50503,
                "typeIndexe": "eau",
                "ancien": 2411,
                "nouveau": 2459,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50504,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50438,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50437,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 42914,
                "idIndexe": 50435,
                "typeIndexe": "eau",
                "ancien": 2325,
                "nouveau": 2411,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 10479,
                "idIndexe": 50248,
                "typeIndexe": "eau",
                "ancien": 2304,
                "nouveau": 2325,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50249,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50120,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 19960,
                "idIndexe": 50119,
                "typeIndexe": "eau",
                "ancien": 2064,
                "nouveau": 2104,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50000,
                "typeIndexe": "eau",
                "ancien": 2064,
                "nouveau": 2064,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50001,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49884,
                "typeIndexe": "eau",
                "ancien": 2063,
                "nouveau": 2064,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51316,
            "typeIndexe": "eau",
            "ancien": 2461,
            "nouveau": 2461,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 38,
            "idOccupation": 36,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51317,
            "typeIndexe": "energie",
            "ancien": 1821,
            "nouveau": 1821,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 38,
            "idOccupation": 36,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 36,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.181Z",
            "idLocataire": 36,
            "idLogement": 38,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 36,
                "nomLocataire": "FOUMANE AKONO",
                "prenomLocataire": "Didier",
                "cniLocataire": "",
                "tel": "672317146",
                "tel2": "697860136",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.245Z",
                "updatedAt": "2022-03-02T11:54:44.245Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2461,
                2461
            ],
            "energie": [
                1821,
                1821
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2461,
                2461
            ],
            "energie": [
                1821,
                1821
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51186,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51046,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50908,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50778,
                "typeIndexe": "eau",
                "ancien": 2461,
                "nouveau": 2461,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50642,
                "typeIndexe": "eau",
                "ancien": 2459,
                "nouveau": 2461,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 23952,
                "idIndexe": 50503,
                "typeIndexe": "eau",
                "ancien": 2411,
                "nouveau": 2459,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 42914,
                "idIndexe": 50435,
                "typeIndexe": "eau",
                "ancien": 2325,
                "nouveau": 2411,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 10479,
                "idIndexe": 50248,
                "typeIndexe": "eau",
                "ancien": 2304,
                "nouveau": 2325,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 19960,
                "idIndexe": 50119,
                "typeIndexe": "eau",
                "ancien": 2064,
                "nouveau": 2104,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50000,
                "typeIndexe": "eau",
                "ancien": 2064,
                "nouveau": 2064,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49884,
                "typeIndexe": "eau",
                "ancien": 2063,
                "nouveau": 2064,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51187,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51047,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50909,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50779,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50643,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50504,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50438,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50437,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50249,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50120,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50001,
                "typeIndexe": "energie",
                "ancien": 1821,
                "nouveau": 1821,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 38,
                "idOccupation": 36,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2461,
            "energie": -1821
        },
        "montant": {
            "eau": -1228039,
            "energie": -200310
        }
    },
    {
        "idLogement": 39,
        "refLogement": "B017",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-09-28T13:20:57.086Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51318,
                "typeIndexe": "eau",
                "ancien": 1423,
                "nouveau": 1427,
                "periode": "2023-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:08:39.035Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51319,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51189,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51188,
                "typeIndexe": "eau",
                "ancien": 1418,
                "nouveau": 1423,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:19:12.929Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51049,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51048,
                "typeIndexe": "eau",
                "ancien": 1412,
                "nouveau": 1418,
                "periode": "2023-09-01",
                "avance": 2058,
                "reste": 936,
                "datePaiement": "2023-12-26T11:08:56.138Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50910,
                "typeIndexe": "eau",
                "ancien": 1407,
                "nouveau": 1412,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T14:57:24.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50911,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50780,
                "typeIndexe": "eau",
                "ancien": 1399,
                "nouveau": 1407,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T08:19:52.458Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50781,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50644,
                "typeIndexe": "eau",
                "ancien": 1394,
                "nouveau": 1399,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:43:53.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50645,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50505,
                "typeIndexe": "eau",
                "ancien": 1388,
                "nouveau": 1394,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:57:40.622Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50506,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50372,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50371,
                "typeIndexe": "eau",
                "ancien": 1381,
                "nouveau": 1388,
                "periode": "2023-04-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-31T12:48:43.597Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50251,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50250,
                "typeIndexe": "eau",
                "ancien": 1370,
                "nouveau": 1381,
                "periode": "2023-03-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-06-27T09:58:43.234Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50122,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50121,
                "typeIndexe": "eau",
                "ancien": 1363,
                "nouveau": 1370,
                "periode": "2023-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-04-28T14:25:22.739Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50002,
                "typeIndexe": "eau",
                "ancien": 1354,
                "nouveau": 1363,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-27T10:52:12.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50003,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49886,
                "typeIndexe": "eau",
                "ancien": 1347,
                "nouveau": 1354,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-27T10:52:06.607Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49887,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51318,
            "typeIndexe": "eau",
            "ancien": 1423,
            "nouveau": 1427,
            "periode": "2023-11-01",
            "avance": 1996,
            "reste": 0,
            "datePaiement": "2023-12-26T11:08:39.035Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 39,
            "idOccupation": 410,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51319,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 39,
            "idOccupation": 410,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 410,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-08-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-08-26T10:54:15.259Z",
            "idLocataire": 372,
            "idLogement": 39,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 372,
                "nomLocataire": "TOMLA DENPAN",
                "prenomLocataire": "Tylareng",
                "cniLocataire": "",
                "tel": "680337424",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-08-25T10:23:15.879Z",
                "updatedAt": "2022-08-25T10:23:15.879Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1427,
                1423
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1427,
                1423
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51188,
                "typeIndexe": "eau",
                "ancien": 1418,
                "nouveau": 1423,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T08:19:12.929Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51048,
                "typeIndexe": "eau",
                "ancien": 1412,
                "nouveau": 1418,
                "periode": "2023-09-01",
                "avance": 2058,
                "reste": 936,
                "datePaiement": "2023-12-26T11:08:56.138Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50910,
                "typeIndexe": "eau",
                "ancien": 1407,
                "nouveau": 1412,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T14:57:24.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50780,
                "typeIndexe": "eau",
                "ancien": 1399,
                "nouveau": 1407,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T08:19:52.458Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50644,
                "typeIndexe": "eau",
                "ancien": 1394,
                "nouveau": 1399,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:43:53.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50505,
                "typeIndexe": "eau",
                "ancien": 1388,
                "nouveau": 1394,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:57:40.622Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50371,
                "typeIndexe": "eau",
                "ancien": 1381,
                "nouveau": 1388,
                "periode": "2023-04-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-31T12:48:43.597Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50250,
                "typeIndexe": "eau",
                "ancien": 1370,
                "nouveau": 1381,
                "periode": "2023-03-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-06-27T09:58:43.234Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50121,
                "typeIndexe": "eau",
                "ancien": 1363,
                "nouveau": 1370,
                "periode": "2023-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-04-28T14:25:22.739Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50002,
                "typeIndexe": "eau",
                "ancien": 1354,
                "nouveau": 1363,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-27T10:52:12.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49886,
                "typeIndexe": "eau",
                "ancien": 1347,
                "nouveau": 1354,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-27T10:52:06.607Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 39,
                "idOccupation": 410,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [],
        "conso": {
            "eau": -1427,
            "energie": 0
        },
        "montant": {
            "eau": -712073,
            "energie": 0
        }
    },
    {
        "idLogement": 40,
        "refLogement": "B018",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:20.293Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51321,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51320,
                "typeIndexe": "eau",
                "ancien": 1357,
                "nouveau": 1359,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51191,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51190,
                "typeIndexe": "eau",
                "ancien": 1353,
                "nouveau": 1357,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51051,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51050,
                "typeIndexe": "eau",
                "ancien": 1348,
                "nouveau": 1353,
                "periode": "2023-09-01",
                "avance": 2019,
                "reste": 476,
                "datePaiement": "2023-10-28T14:59:07.849Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50912,
                "typeIndexe": "eau",
                "ancien": 1342,
                "nouveau": 1348,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:58:53.146Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50913,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50782,
                "typeIndexe": "eau",
                "ancien": 1337,
                "nouveau": 1342,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:46:46.257Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50783,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50646,
                "typeIndexe": "eau",
                "ancien": 1331,
                "nouveau": 1337,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T13:46:47.522Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50647,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50509,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50510,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50508,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1331,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-06-27T09:59:49.639Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50507,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1331,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-05-30T19:25:32.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50374,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50373,
                "typeIndexe": "eau",
                "ancien": 1322,
                "nouveau": 1324,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T10:07:12.802Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50252,
                "typeIndexe": "eau",
                "ancien": 1319,
                "nouveau": 1322,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:28:33.002Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50253,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50123,
                "typeIndexe": "eau",
                "ancien": 1317,
                "nouveau": 1319,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T08:09:19.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50124,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50004,
                "typeIndexe": "eau",
                "ancien": 1314,
                "nouveau": 1317,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:09:19.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50005,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51320,
            "typeIndexe": "eau",
            "ancien": 1357,
            "nouveau": 1359,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 40,
            "idOccupation": 368,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51321,
            "typeIndexe": "energie",
            "ancien": 1429,
            "nouveau": 1429,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 40,
            "idOccupation": 368,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 368,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2021-12-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.292Z",
            "idLocataire": 334,
            "idLogement": 40,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 334,
                "nomLocataire": "NGA OWONA",
                "prenomLocataire": "LISE DORIS",
                "cniLocataire": "",
                "tel": "656173601",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.127Z",
                "updatedAt": "2022-03-02T11:54:45.127Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1359,
                1357
            ],
            "energie": [
                1429,
                1429
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1359,
                1357
            ],
            "energie": [
                1429,
                1429
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51190,
                "typeIndexe": "eau",
                "ancien": 1353,
                "nouveau": 1357,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51050,
                "typeIndexe": "eau",
                "ancien": 1348,
                "nouveau": 1353,
                "periode": "2023-09-01",
                "avance": 2019,
                "reste": 476,
                "datePaiement": "2023-10-28T14:59:07.849Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50912,
                "typeIndexe": "eau",
                "ancien": 1342,
                "nouveau": 1348,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T14:58:53.146Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50782,
                "typeIndexe": "eau",
                "ancien": 1337,
                "nouveau": 1342,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:46:46.257Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50646,
                "typeIndexe": "eau",
                "ancien": 1331,
                "nouveau": 1337,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T13:46:47.522Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50508,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1331,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-06-27T09:59:49.639Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50507,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1331,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-05-30T19:25:32.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50373,
                "typeIndexe": "eau",
                "ancien": 1322,
                "nouveau": 1324,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T10:07:12.802Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50252,
                "typeIndexe": "eau",
                "ancien": 1319,
                "nouveau": 1322,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:28:33.002Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50123,
                "typeIndexe": "eau",
                "ancien": 1317,
                "nouveau": 1319,
                "periode": "2023-02-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-03-29T08:09:19.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50004,
                "typeIndexe": "eau",
                "ancien": 1314,
                "nouveau": 1317,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:09:19.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51191,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51051,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50913,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50783,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50647,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50509,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50510,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50374,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50253,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50124,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50005,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1429,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 40,
                "idOccupation": 368,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1359,
            "energie": -1429
        },
        "montant": {
            "eau": -678141,
            "energie": -157190
        }
    },
    {
        "idLogement": 41,
        "refLogement": "B019",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-09-28T13:31:16.841Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51383,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51382,
                "typeIndexe": "eau",
                "ancien": 1429,
                "nouveau": 1433,
                "periode": "2023-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:09:56.952Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51192,
                "typeIndexe": "eau",
                "ancien": 1426,
                "nouveau": 1429,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-25T10:16:51.398Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51193,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51052,
                "typeIndexe": "eau",
                "ancien": 1424,
                "nouveau": 1426,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-10-28T15:00:20.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51053,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50915,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50914,
                "typeIndexe": "eau",
                "ancien": 1422,
                "nouveau": 1424,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:33:07.661Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50785,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 390,
                "montant": 499,
                "idIndexe": 50784,
                "typeIndexe": "eau",
                "ancien": 1032,
                "nouveau": 1422,
                "periode": "2023-07-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:30.557Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50649,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50648,
                "typeIndexe": "eau",
                "ancien": 1030,
                "nouveau": 1032,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:30.553Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50512,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50511,
                "typeIndexe": "eau",
                "ancien": 1025,
                "nouveau": 1030,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:50.651Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50375,
                "typeIndexe": "eau",
                "ancien": 1020,
                "nouveau": 1025,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:02:08.885Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50376,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50254,
                "typeIndexe": "eau",
                "ancien": 1017,
                "nouveau": 1020,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:29:58.045Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50255,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50126,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 1497,
                "idIndexe": 50125,
                "typeIndexe": "eau",
                "ancien": 1017,
                "nouveau": 1017,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:11:02.659Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50006,
                "typeIndexe": "eau",
                "ancien": 832,
                "nouveau": 836,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:11:02.656Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50007,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49890,
                "typeIndexe": "eau",
                "ancien": 832,
                "nouveau": 832,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49891,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51382,
            "typeIndexe": "eau",
            "ancien": 1429,
            "nouveau": 1433,
            "periode": "2023-11-01",
            "avance": 1996,
            "reste": 0,
            "datePaiement": "2023-12-26T11:09:56.952Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 41,
            "idOccupation": 423,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51383,
            "typeIndexe": "energie",
            "ancien": 10154,
            "nouveau": 10154,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 41,
            "idOccupation": 423,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 423,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-10-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-09-28T13:31:16.753Z",
            "idLocataire": 377,
            "idLogement": 41,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 377,
                "nomLocataire": "NGOUFO NAKO",
                "prenomLocataire": "Christelle laura",
                "cniLocataire": "",
                "tel": "696874786",
                "tel2": "673177882",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-09-28T10:44:36.653Z",
                "updatedAt": "2022-09-28T10:44:36.653Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1433,
                1429
            ],
            "energie": [
                10154,
                10154
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1433,
                1429
            ],
            "energie": [
                10154,
                10154
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51192,
                "typeIndexe": "eau",
                "ancien": 1426,
                "nouveau": 1429,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-25T10:16:51.398Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51052,
                "typeIndexe": "eau",
                "ancien": 1424,
                "nouveau": 1426,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-10-28T15:00:20.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50914,
                "typeIndexe": "eau",
                "ancien": 1422,
                "nouveau": 1424,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:33:07.661Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 390,
                "montant": 499,
                "idIndexe": 50784,
                "typeIndexe": "eau",
                "ancien": 1032,
                "nouveau": 1422,
                "periode": "2023-07-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:30.557Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50648,
                "typeIndexe": "eau",
                "ancien": 1030,
                "nouveau": 1032,
                "periode": "2023-06-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:30.553Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50511,
                "typeIndexe": "eau",
                "ancien": 1025,
                "nouveau": 1030,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T06:49:50.651Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50375,
                "typeIndexe": "eau",
                "ancien": 1020,
                "nouveau": 1025,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:02:08.885Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50254,
                "typeIndexe": "eau",
                "ancien": 1017,
                "nouveau": 1020,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:29:58.045Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 1497,
                "idIndexe": 50125,
                "typeIndexe": "eau",
                "ancien": 1017,
                "nouveau": 1017,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:11:02.659Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50006,
                "typeIndexe": "eau",
                "ancien": 832,
                "nouveau": 836,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:11:02.656Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49890,
                "typeIndexe": "eau",
                "ancien": 832,
                "nouveau": 832,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51193,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51053,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50915,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50785,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50649,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50512,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50376,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50255,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50126,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50007,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49891,
                "typeIndexe": "energie",
                "ancien": 10154,
                "nouveau": 10154,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 41,
                "idOccupation": 423,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1433,
            "energie": -10154
        },
        "montant": {
            "eau": -715067,
            "energie": -1116940
        }
    },
    {
        "idLogement": 186,
        "refLogement": "B021",
        "descLogement": "il s'agit d'un logement spécifié par M. Fongang comme libre de facon préliminaire sans autre indication",
        "superficie": 85,
        "prixMin": 75000,
        "prixMax": 85000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2023-04-29T19:31:04.863Z",
        "updatedAt": "2023-04-29T19:31:04.863Z",
        "idBatiment": 2,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51323,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51322,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51195,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51194,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51054,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51055,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50916,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50917,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50786,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50787,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50650,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50651,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50514,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50513,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50449,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50450,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 186,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51322,
            "typeIndexe": "eau",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 186,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51323,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 186,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                0,
                0
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                0,
                0
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 43,
        "refLogement": "C001",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 32500,
        "prixMax": 35000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.514Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51325,
                "typeIndexe": "energie",
                "ancien": 1364,
                "nouveau": 1341,
                "periode": "2023-11-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-12-26T11:10:59.243Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51324,
                "typeIndexe": "eau",
                "ancien": 1627,
                "nouveau": 1618,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T11:10:59.247Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51196,
                "typeIndexe": "eau",
                "ancien": 1620,
                "nouveau": 1627,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.546Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 51197,
                "typeIndexe": "energie",
                "ancien": 1354,
                "nouveau": 1364,
                "periode": "2023-10-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.550Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51057,
                "typeIndexe": "energie",
                "ancien": 1352,
                "nouveau": 1354,
                "periode": "2023-09-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.543Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51056,
                "typeIndexe": "eau",
                "ancien": 1618,
                "nouveau": 1620,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.539Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50918,
                "typeIndexe": "eau",
                "ancien": 1615,
                "nouveau": 1618,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.531Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50919,
                "typeIndexe": "energie",
                "ancien": 1332,
                "nouveau": 1352,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.536Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50789,
                "typeIndexe": "energie",
                "ancien": 1326,
                "nouveau": 1332,
                "periode": "2023-07-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50788,
                "typeIndexe": "eau",
                "ancien": 1615,
                "nouveau": 1615,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 50653,
                "typeIndexe": "energie",
                "ancien": 1316,
                "nouveau": 1326,
                "periode": "2023-06-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-07-26T13:49:44.948Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50652,
                "typeIndexe": "eau",
                "ancien": 1614,
                "nouveau": 1615,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:49:44.944Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 50516,
                "typeIndexe": "energie",
                "ancien": 1308,
                "nouveau": 1316,
                "periode": "2023-05-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50515,
                "typeIndexe": "eau",
                "ancien": 1613,
                "nouveau": 1614,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.730Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50377,
                "typeIndexe": "eau",
                "ancien": 1612,
                "nouveau": 1613,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50378,
                "typeIndexe": "energie",
                "ancien": 1299,
                "nouveau": 1308,
                "periode": "2023-04-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50256,
                "typeIndexe": "eau",
                "ancien": 1612,
                "nouveau": 1612,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50257,
                "typeIndexe": "energie",
                "ancien": 1274,
                "nouveau": 1299,
                "periode": "2023-03-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:39.776Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -157,
                "montant": 0,
                "idIndexe": 50128,
                "typeIndexe": "energie",
                "ancien": 1431,
                "nouveau": 1274,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": "2023-02-27T09:10:41.634Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50127,
                "typeIndexe": "eau",
                "ancien": 1611,
                "nouveau": 1612,
                "periode": "2023-02-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-27T09:10:41.634Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49944,
                "typeIndexe": "eau",
                "ancien": 1609,
                "nouveau": 1611,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:29:12.435Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49945,
                "typeIndexe": "energie",
                "ancien": 1406,
                "nouveau": 1431,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-22T11:29:12.438Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49892,
                "typeIndexe": "eau",
                "ancien": 1608,
                "nouveau": 1609,
                "periode": "2022-12-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-22T11:24:46.935Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49893,
                "typeIndexe": "energie",
                "ancien": 1381,
                "nouveau": 1406,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-22T11:24:31.142Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51324,
            "typeIndexe": "eau",
            "ancien": 1627,
            "nouveau": 1628,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T11:10:59.247Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 43,
            "idOccupation": 40,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 2,
            "montant": 220,
            "idIndexe": 51325,
            "typeIndexe": "energie",
            "ancien": 1364,
            "nouveau": 1366,
            "periode": "2023-11-01",
            "avance": 220,
            "reste": 0,
            "datePaiement": "2023-12-26T11:10:59.243Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 43,
            "idOccupation": 40,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 40,
            "loyerBase": 35000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.513Z",
            "idLocataire": 40,
            "idLogement": 43,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 40,
                "nomLocataire": "MVOGO",
                "prenomLocataire": "Jean",
                "cniLocataire": "",
                "tel": "679121073",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.191Z",
                "updatedAt": "2022-03-02T11:54:44.191Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1628,
                1627
            ],
            "energie": [
                1366,
                1364
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1628,
                1627
            ],
            "energie": [
                1366,
                1364
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51196,
                "typeIndexe": "eau",
                "ancien": 1620,
                "nouveau": 1627,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.546Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51056,
                "typeIndexe": "eau",
                "ancien": 1618,
                "nouveau": 1620,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.539Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50918,
                "typeIndexe": "eau",
                "ancien": 1615,
                "nouveau": 1618,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.531Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50788,
                "typeIndexe": "eau",
                "ancien": 1615,
                "nouveau": 1615,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50652,
                "typeIndexe": "eau",
                "ancien": 1614,
                "nouveau": 1615,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-07-26T13:49:44.944Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50515,
                "typeIndexe": "eau",
                "ancien": 1613,
                "nouveau": 1614,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.730Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50377,
                "typeIndexe": "eau",
                "ancien": 1612,
                "nouveau": 1613,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50256,
                "typeIndexe": "eau",
                "ancien": 1612,
                "nouveau": 1612,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50127,
                "typeIndexe": "eau",
                "ancien": 1611,
                "nouveau": 1612,
                "periode": "2023-02-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-27T09:10:41.634Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49944,
                "typeIndexe": "eau",
                "ancien": 1609,
                "nouveau": 1611,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:29:12.435Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 49892,
                "typeIndexe": "eau",
                "ancien": 1608,
                "nouveau": 1609,
                "periode": "2022-12-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-02-22T11:24:46.935Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 51197,
                "typeIndexe": "energie",
                "ancien": 1354,
                "nouveau": 1364,
                "periode": "2023-10-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.550Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51057,
                "typeIndexe": "energie",
                "ancien": 1352,
                "nouveau": 1354,
                "periode": "2023-09-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-27T15:43:25.543Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50919,
                "typeIndexe": "energie",
                "ancien": 1332,
                "nouveau": 1352,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.536Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50789,
                "typeIndexe": "energie",
                "ancien": 1326,
                "nouveau": 1332,
                "periode": "2023-07-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T06:34:15.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 50653,
                "typeIndexe": "energie",
                "ancien": 1316,
                "nouveau": 1326,
                "periode": "2023-06-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-07-26T13:49:44.948Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 880,
                "idIndexe": 50516,
                "typeIndexe": "energie",
                "ancien": 1308,
                "nouveau": 1316,
                "periode": "2023-05-01",
                "avance": 880,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50378,
                "typeIndexe": "energie",
                "ancien": 1299,
                "nouveau": 1308,
                "periode": "2023-04-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:14.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 50257,
                "typeIndexe": "energie",
                "ancien": 1274,
                "nouveau": 1299,
                "periode": "2023-03-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-06-27T10:03:39.776Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -157,
                "montant": 0,
                "idIndexe": 50128,
                "typeIndexe": "energie",
                "ancien": 1431,
                "nouveau": 1274,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": "2023-02-27T09:10:41.634Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49945,
                "typeIndexe": "energie",
                "ancien": 1406,
                "nouveau": 1431,
                "periode": "2023-01-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-22T11:29:12.438Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49893,
                "typeIndexe": "energie",
                "ancien": 1381,
                "nouveau": 1406,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-02-22T11:24:31.142Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 43,
                "idOccupation": 40,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1628,
            "energie": -1366
        },
        "montant": {
            "eau": -812372,
            "energie": -150260
        }
    },
    {
        "idLogement": 47,
        "refLogement": "C005",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 75000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-02-22T16:34:24.426Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51326,
                "typeIndexe": "eau",
                "ancien": 1981,
                "nouveau": 3089,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51327,
                "typeIndexe": "energie",
                "ancien": 3788,
                "nouveau": 3688,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51198,
                "typeIndexe": "eau",
                "ancien": 1971,
                "nouveau": 1981,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51199,
                "typeIndexe": "energie",
                "ancien": 3738,
                "nouveau": 3788,
                "periode": "2023-10-01",
                "avance": 1227,
                "reste": 4273,
                "datePaiement": "2023-11-27T15:46:53.718Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 51059,
                "typeIndexe": "energie",
                "ancien": 3710,
                "nouveau": 3738,
                "periode": "2023-09-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51058,
                "typeIndexe": "eau",
                "ancien": 1964,
                "nouveau": 1971,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.306Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50921,
                "typeIndexe": "energie",
                "ancien": 3690,
                "nouveau": 3710,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.304Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50920,
                "typeIndexe": "eau",
                "ancien": 1954,
                "nouveau": 1964,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T15:04:40.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50790,
                "typeIndexe": "eau",
                "ancien": 1946,
                "nouveau": 1954,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-10-28T15:04:40.372Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50791,
                "typeIndexe": "energie",
                "ancien": 3662,
                "nouveau": 3690,
                "periode": "2023-07-01",
                "avance": 100,
                "reste": 2980,
                "datePaiement": "2023-09-28T06:38:31.381Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50665,
                "typeIndexe": "energie",
                "ancien": 3617,
                "nouveau": 3662,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-09-28T06:38:02.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50664,
                "typeIndexe": "eau",
                "ancien": 1937,
                "nouveau": 1946,
                "periode": "2023-06-01",
                "avance": 3098,
                "reste": 1393,
                "datePaiement": "2023-10-28T15:04:53.302Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50517,
                "typeIndexe": "eau",
                "ancien": 1928,
                "nouveau": 1937,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-05-30T10:23:03.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50518,
                "typeIndexe": "energie",
                "ancien": 3572,
                "nouveau": 3617,
                "periode": "2023-05-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-09-28T06:38:02.798Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50380,
                "typeIndexe": "energie",
                "ancien": 3572,
                "nouveau": 3572,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50379,
                "typeIndexe": "eau",
                "ancien": 1928,
                "nouveau": 1928,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50320,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3572,
                "periode": "2023-03-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-08-30T06:53:40.482Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50319,
                "typeIndexe": "energie",
                "ancien": 3472,
                "nouveau": 3572,
                "periode": "2023-03-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-07-26T13:58:19.659Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50318,
                "typeIndexe": "eau",
                "ancien": 1918,
                "nouveau": 1928,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-05-30T10:28:33.613Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 50066,
                "typeIndexe": "energie",
                "ancien": 42,
                "nouveau": 100,
                "periode": "2023-02-01",
                "avance": 519,
                "reste": 5861,
                "datePaiement": "2023-05-30T10:28:55.748Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1617,
                "montant": 3992,
                "idIndexe": 50065,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 1908,
                "periode": "2023-02-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-03-29T08:14:13.900Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 41421,
                "typeIndexe": "eau",
                "ancien": 1740,
                "nouveau": 1740,
                "periode": "2021-10-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2021-10-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 360,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48216,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1900,
                "periode": "2021-10-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2021-10-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 360,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 46298,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1900,
                "periode": "2019-06-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 155,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 10,
            "montant": 4990,
            "idIndexe": 51326,
            "typeIndexe": "eau",
            "ancien": 1981,
            "nouveau": 3090,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 47,
            "idOccupation": 446,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 50,
            "montant": 5500,
            "idIndexe": 51327,
            "typeIndexe": "energie",
            "ancien": 3788,
            "nouveau": 3838,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 47,
            "idOccupation": 446,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 446,
            "loyerBase": 70000,
            "caution": 70000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-02-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-01-01T16:34:22.308Z",
            "idLocataire": 399,
            "idLogement": 47,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 399,
                "nomLocataire": "ESSONO",
                "prenomLocataire": "pascal roger",
                "cniLocataire": "",
                "tel": "656145690",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-02-22T16:14:28.605Z",
                "updatedAt": "2023-02-22T16:14:28.605Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1991,
                1981
            ],
            "energie": [
                3838,
                3788
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1991,
                1981
            ],
            "energie": [
                3838,
                3788
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51198,
                "typeIndexe": "eau",
                "ancien": 1971,
                "nouveau": 1981,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51058,
                "typeIndexe": "eau",
                "ancien": 1964,
                "nouveau": 1971,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.306Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50920,
                "typeIndexe": "eau",
                "ancien": 1954,
                "nouveau": 1964,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T15:04:40.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50790,
                "typeIndexe": "eau",
                "ancien": 1946,
                "nouveau": 1954,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-10-28T15:04:40.372Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50664,
                "typeIndexe": "eau",
                "ancien": 1937,
                "nouveau": 1946,
                "periode": "2023-06-01",
                "avance": 3098,
                "reste": 1393,
                "datePaiement": "2023-10-28T15:04:53.302Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50517,
                "typeIndexe": "eau",
                "ancien": 1928,
                "nouveau": 1937,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-05-30T10:23:03.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50379,
                "typeIndexe": "eau",
                "ancien": 1928,
                "nouveau": 1928,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50318,
                "typeIndexe": "eau",
                "ancien": 1918,
                "nouveau": 1928,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-05-30T10:28:33.613Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1617,
                "montant": 3992,
                "idIndexe": 50065,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 1908,
                "periode": "2023-02-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-03-29T08:14:13.900Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 41421,
                "typeIndexe": "eau",
                "ancien": 1740,
                "nouveau": 1740,
                "periode": "2021-10-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2021-10-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 360,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51199,
                "typeIndexe": "energie",
                "ancien": 3738,
                "nouveau": 3788,
                "periode": "2023-10-01",
                "avance": 1227,
                "reste": 4273,
                "datePaiement": "2023-11-27T15:46:53.718Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 51059,
                "typeIndexe": "energie",
                "ancien": 3710,
                "nouveau": 3738,
                "periode": "2023-09-01",
                "avance": 3080,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50921,
                "typeIndexe": "energie",
                "ancien": 3690,
                "nouveau": 3710,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-11-27T15:44:15.304Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 28,
                "montant": 3080,
                "idIndexe": 50791,
                "typeIndexe": "energie",
                "ancien": 3662,
                "nouveau": 3690,
                "periode": "2023-07-01",
                "avance": 100,
                "reste": 2980,
                "datePaiement": "2023-09-28T06:38:31.381Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50665,
                "typeIndexe": "energie",
                "ancien": 3617,
                "nouveau": 3662,
                "periode": "2023-06-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-09-28T06:38:02.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50518,
                "typeIndexe": "energie",
                "ancien": 3572,
                "nouveau": 3617,
                "periode": "2023-05-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-09-28T06:38:02.798Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50380,
                "typeIndexe": "energie",
                "ancien": 3572,
                "nouveau": 3572,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50320,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3572,
                "periode": "2023-03-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-08-30T06:53:40.482Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 50319,
                "typeIndexe": "energie",
                "ancien": 3472,
                "nouveau": 3572,
                "periode": "2023-03-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2023-07-26T13:58:19.659Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 50066,
                "typeIndexe": "energie",
                "ancien": 42,
                "nouveau": 100,
                "periode": "2023-02-01",
                "avance": 519,
                "reste": 5861,
                "datePaiement": "2023-05-30T10:28:55.748Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 47,
                "idOccupation": 446,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48216,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1900,
                "periode": "2021-10-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2021-10-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 360,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 46298,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1900,
                "periode": "2019-06-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 47,
                "idOccupation": 155,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1991,
            "energie": -3838
        },
        "montant": {
            "eau": -993509,
            "energie": -422180
        }
    },
    {
        "idLogement": 48,
        "refLogement": "C006",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 75000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-09-28T20:26:57.797Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 51329,
                "typeIndexe": "energie",
                "ancien": 3701,
                "nouveau": 3568,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51328,
                "typeIndexe": "eau",
                "ancien": 1534,
                "nouveau": 1542,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 76,
                "montant": 8360,
                "idIndexe": 51201,
                "typeIndexe": "energie",
                "ancien": 3625,
                "nouveau": 3701,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 51200,
                "typeIndexe": "eau",
                "ancien": 1521,
                "nouveau": 1534,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51060,
                "typeIndexe": "eau",
                "ancien": 1519,
                "nouveau": 1521,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 0,
                "idIndexe": 51061,
                "typeIndexe": "energie",
                "ancien": 3585,
                "nouveau": 3625,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50922,
                "typeIndexe": "eau",
                "ancien": 1518,
                "nouveau": 1519,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50923,
                "typeIndexe": "energie",
                "ancien": 3584,
                "nouveau": 3585,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50793,
                "typeIndexe": "energie",
                "ancien": 3584,
                "nouveau": 3584,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 0,
                "idIndexe": 50792,
                "typeIndexe": "eau",
                "ancien": 1515,
                "nouveau": 1518,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50654,
                "typeIndexe": "eau",
                "ancien": 1510,
                "nouveau": 1515,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.182Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 4290,
                "idIndexe": 50655,
                "typeIndexe": "energie",
                "ancien": 3545,
                "nouveau": 3584,
                "periode": "2023-06-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.175Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50521,
                "typeIndexe": "energie",
                "ancien": 3544,
                "nouveau": 3545,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.137Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50519,
                "typeIndexe": "eau",
                "ancien": 1504,
                "nouveau": 1510,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.149Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50520,
                "typeIndexe": "eau",
                "ancien": 1504,
                "nouveau": 1510,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.171Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50522,
                "typeIndexe": "energie",
                "ancien": 3544,
                "nouveau": 3545,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.141Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50382,
                "typeIndexe": "energie",
                "ancien": 3542,
                "nouveau": 3544,
                "periode": "2023-04-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-04-30T15:54:45.826Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50381,
                "typeIndexe": "eau",
                "ancien": 1498,
                "nouveau": 1504,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-04-30T15:54:11.455Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50312,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3542,
                "periode": "2023-03-01",
                "avance": 4314,
                "reste": 306,
                "datePaiement": "2023-07-26T14:37:40.393Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50313,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3542,
                "periode": "2023-03-01",
                "avance": 4620,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.143Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50311,
                "typeIndexe": "eau",
                "ancien": 1494,
                "nouveau": 1498,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.134Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -451,
                "montant": 0,
                "idIndexe": 50130,
                "typeIndexe": "energie",
                "ancien": 3951,
                "nouveau": 3500,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50129,
                "typeIndexe": "eau",
                "ancien": 1489,
                "nouveau": 1494,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.139Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50009,
                "typeIndexe": "energie",
                "ancien": 3909,
                "nouveau": 3951,
                "periode": "2023-01-01",
                "avance": 4620,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.132Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 8,
            "montant": 3992,
            "idIndexe": 51328,
            "typeIndexe": "eau",
            "ancien": 1534,
            "nouveau": 1542,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 48,
            "idOccupation": 93,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 19,
            "montant": 2090,
            "idIndexe": 51329,
            "typeIndexe": "energie",
            "ancien": 3701,
            "nouveau": 3720,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 48,
            "idOccupation": 93,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 93,
            "loyerBase": 70000,
            "caution": 70000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-09-26T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 499,
            "puEau": 110,
            "dureeBail": 0,
            "createdAt": "2023-09-28T20:26:56.977Z",
            "idLocataire": 412,
            "idLogement": 48,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 412,
                "nomLocataire": "ATEBA",
                "prenomLocataire": "Geraldin eric",
                "cniLocataire": "",
                "tel": "656013154",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-09-28T19:52:59.952Z",
                "updatedAt": "2023-09-28T19:52:59.952Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1542,
                1534
            ],
            "energie": [
                3720,
                3701
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1542,
                1534
            ],
            "energie": [
                3720,
                3701
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 51200,
                "typeIndexe": "eau",
                "ancien": 1521,
                "nouveau": 1534,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51060,
                "typeIndexe": "eau",
                "ancien": 1519,
                "nouveau": 1521,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50922,
                "typeIndexe": "eau",
                "ancien": 1518,
                "nouveau": 1519,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 0,
                "idIndexe": 50792,
                "typeIndexe": "eau",
                "ancien": 1515,
                "nouveau": 1518,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50654,
                "typeIndexe": "eau",
                "ancien": 1510,
                "nouveau": 1515,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.182Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50519,
                "typeIndexe": "eau",
                "ancien": 1504,
                "nouveau": 1510,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.149Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50520,
                "typeIndexe": "eau",
                "ancien": 1504,
                "nouveau": 1510,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.171Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50381,
                "typeIndexe": "eau",
                "ancien": 1498,
                "nouveau": 1504,
                "periode": "2023-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-04-30T15:54:11.455Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50311,
                "typeIndexe": "eau",
                "ancien": 1494,
                "nouveau": 1498,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.134Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50129,
                "typeIndexe": "eau",
                "ancien": 1489,
                "nouveau": 1494,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.139Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 76,
                "montant": 8360,
                "idIndexe": 51201,
                "typeIndexe": "energie",
                "ancien": 3625,
                "nouveau": 3701,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 0,
                "idIndexe": 51061,
                "typeIndexe": "energie",
                "ancien": 3585,
                "nouveau": 3625,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 93,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50923,
                "typeIndexe": "energie",
                "ancien": 3584,
                "nouveau": 3585,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50793,
                "typeIndexe": "energie",
                "ancien": 3584,
                "nouveau": 3584,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 39,
                "montant": 4290,
                "idIndexe": 50655,
                "typeIndexe": "energie",
                "ancien": 3545,
                "nouveau": 3584,
                "periode": "2023-06-01",
                "avance": 4290,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.175Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50521,
                "typeIndexe": "energie",
                "ancien": 3544,
                "nouveau": 3545,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.137Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 50522,
                "typeIndexe": "energie",
                "ancien": 3544,
                "nouveau": 3545,
                "periode": "2023-05-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.141Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 50382,
                "typeIndexe": "energie",
                "ancien": 3542,
                "nouveau": 3544,
                "periode": "2023-04-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-04-30T15:54:45.826Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50312,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3542,
                "periode": "2023-03-01",
                "avance": 4314,
                "reste": 306,
                "datePaiement": "2023-07-26T14:37:40.393Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50313,
                "typeIndexe": "energie",
                "ancien": 3500,
                "nouveau": 3542,
                "periode": "2023-03-01",
                "avance": 4620,
                "reste": 0,
                "datePaiement": "2023-07-26T14:01:34.143Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -451,
                "montant": 0,
                "idIndexe": 50130,
                "typeIndexe": "energie",
                "ancien": 3951,
                "nouveau": 3500,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 4620,
                "idIndexe": 50009,
                "typeIndexe": "energie",
                "ancien": 3909,
                "nouveau": 3951,
                "periode": "2023-01-01",
                "avance": 4620,
                "reste": 0,
                "datePaiement": "2023-06-27T10:07:25.132Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 48,
                "idOccupation": 291,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1542,
            "energie": -3720
        },
        "montant": {
            "eau": -769458,
            "energie": -409200
        }
    },
    {
        "idLogement": 49,
        "refLogement": "C007",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 75000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.881Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51330,
                "typeIndexe": "eau",
                "ancien": 1485,
                "nouveau": 1491,
                "periode": "2023-11-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-26T11:16:19.583Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 51331,
                "typeIndexe": "energie",
                "ancien": 3000,
                "nouveau": 2519,
                "periode": "2023-11-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-12-26T11:16:19.587Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51202,
                "typeIndexe": "eau",
                "ancien": 1478,
                "nouveau": 1485,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:47:43.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 51203,
                "typeIndexe": "energie",
                "ancien": 2920,
                "nouveau": 3000,
                "periode": "2023-10-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-11-27T15:47:43.754Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 51113,
                "typeIndexe": "energie",
                "ancien": 2850,
                "nouveau": 2920,
                "periode": "2023-09-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-10-28T15:05:26.485Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51112,
                "typeIndexe": "eau",
                "ancien": 1468,
                "nouveau": 1478,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T15:05:26.482Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50924,
                "typeIndexe": "eau",
                "ancien": 1460,
                "nouveau": 1468,
                "periode": "2023-08-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 9460,
                "idIndexe": 50925,
                "typeIndexe": "energie",
                "ancien": 2764,
                "nouveau": 2850,
                "periode": "2023-08-01",
                "avance": 9460,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.415Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50794,
                "typeIndexe": "eau",
                "ancien": 1456,
                "nouveau": 1460,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.404Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50795,
                "typeIndexe": "energie",
                "ancien": 2679,
                "nouveau": 2764,
                "periode": "2023-07-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.407Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50657,
                "typeIndexe": "energie",
                "ancien": 2599,
                "nouveau": 2679,
                "periode": "2023-06-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50656,
                "typeIndexe": "eau",
                "ancien": 1451,
                "nouveau": 1456,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.745Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50523,
                "typeIndexe": "eau",
                "ancien": 1442,
                "nouveau": 1451,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-27T10:08:14.698Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50524,
                "typeIndexe": "energie",
                "ancien": 2519,
                "nouveau": 2599,
                "periode": "2023-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.741Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50383,
                "typeIndexe": "eau",
                "ancien": 1434,
                "nouveau": 1442,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-04-30T16:02:06.900Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50384,
                "typeIndexe": "energie",
                "ancien": 2519,
                "nouveau": 2519,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50316,
                "typeIndexe": "eau",
                "ancien": 1424,
                "nouveau": 1434,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.615Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50317,
                "typeIndexe": "energie",
                "ancien": 2439,
                "nouveau": 2519,
                "periode": "2023-03-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.619Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50132,
                "typeIndexe": "energie",
                "ancien": 2439,
                "nouveau": 2439,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": "2023-02-23T06:53:38.157Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50131,
                "typeIndexe": "eau",
                "ancien": 1414,
                "nouveau": 1424,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.609Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50010,
                "typeIndexe": "eau",
                "ancien": 1403,
                "nouveau": 1414,
                "periode": "2023-01-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.154Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50011,
                "typeIndexe": "energie",
                "ancien": 3334,
                "nouveau": 3414,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49896,
                "typeIndexe": "eau",
                "ancien": 1390,
                "nouveau": 1403,
                "periode": "2022-12-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.148Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 49897,
                "typeIndexe": "energie",
                "ancien": 3249,
                "nouveau": 3334,
                "periode": "2022-12-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.151Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 6,
            "montant": 2994,
            "idIndexe": 51330,
            "typeIndexe": "eau",
            "ancien": 1485,
            "nouveau": 1491,
            "periode": "2023-11-01",
            "avance": 2994,
            "reste": 0,
            "datePaiement": "2023-12-26T11:16:19.583Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 49,
            "idOccupation": 281,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 80,
            "montant": 8800,
            "idIndexe": 51331,
            "typeIndexe": "energie",
            "ancien": 3000,
            "nouveau": 3080,
            "periode": "2023-11-01",
            "avance": 8800,
            "reste": 0,
            "datePaiement": "2023-12-26T11:16:19.587Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 49,
            "idOccupation": 281,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 281,
            "loyerBase": 70000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-10-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.880Z",
            "idLocataire": 259,
            "idLogement": 49,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 259,
                "nomLocataire": "NGONO MBIDA",
                "prenomLocataire": "Marie",
                "cniLocataire": "",
                "tel": "699993745",
                "tel2": "659204563",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.502Z",
                "updatedAt": "2022-03-02T11:54:45.502Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1491,
                1485
            ],
            "energie": [
                3080,
                3000
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1491,
                1485
            ],
            "energie": [
                3080,
                3000
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51202,
                "typeIndexe": "eau",
                "ancien": 1478,
                "nouveau": 1485,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:47:43.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51112,
                "typeIndexe": "eau",
                "ancien": 1468,
                "nouveau": 1478,
                "periode": "2023-09-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-10-28T15:05:26.482Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50924,
                "typeIndexe": "eau",
                "ancien": 1460,
                "nouveau": 1468,
                "periode": "2023-08-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50794,
                "typeIndexe": "eau",
                "ancien": 1456,
                "nouveau": 1460,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.404Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50656,
                "typeIndexe": "eau",
                "ancien": 1451,
                "nouveau": 1456,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.745Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50523,
                "typeIndexe": "eau",
                "ancien": 1442,
                "nouveau": 1451,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-27T10:08:14.698Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50383,
                "typeIndexe": "eau",
                "ancien": 1434,
                "nouveau": 1442,
                "periode": "2023-04-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-04-30T16:02:06.900Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50316,
                "typeIndexe": "eau",
                "ancien": 1424,
                "nouveau": 1434,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.615Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50131,
                "typeIndexe": "eau",
                "ancien": 1414,
                "nouveau": 1424,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.609Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50010,
                "typeIndexe": "eau",
                "ancien": 1403,
                "nouveau": 1414,
                "periode": "2023-01-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.154Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 49896,
                "typeIndexe": "eau",
                "ancien": 1390,
                "nouveau": 1403,
                "periode": "2022-12-01",
                "avance": 6487,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.148Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 51203,
                "typeIndexe": "energie",
                "ancien": 2920,
                "nouveau": 3000,
                "periode": "2023-10-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-11-27T15:47:43.754Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 51113,
                "typeIndexe": "energie",
                "ancien": 2850,
                "nouveau": 2920,
                "periode": "2023-09-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-10-28T15:05:26.485Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 86,
                "montant": 9460,
                "idIndexe": 50925,
                "typeIndexe": "energie",
                "ancien": 2764,
                "nouveau": 2850,
                "periode": "2023-08-01",
                "avance": 9460,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.415Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50795,
                "typeIndexe": "energie",
                "ancien": 2679,
                "nouveau": 2764,
                "periode": "2023-07-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-09-28T06:39:14.407Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50657,
                "typeIndexe": "energie",
                "ancien": 2599,
                "nouveau": 2679,
                "periode": "2023-06-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50524,
                "typeIndexe": "energie",
                "ancien": 2519,
                "nouveau": 2599,
                "periode": "2023-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-07-26T14:38:22.741Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50384,
                "typeIndexe": "energie",
                "ancien": 2519,
                "nouveau": 2519,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50317,
                "typeIndexe": "energie",
                "ancien": 2439,
                "nouveau": 2519,
                "periode": "2023-03-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-04-28T14:32:09.619Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50132,
                "typeIndexe": "energie",
                "ancien": 2439,
                "nouveau": 2439,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": "2023-02-23T06:53:38.157Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50011,
                "typeIndexe": "energie",
                "ancien": 3334,
                "nouveau": 3414,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 49897,
                "typeIndexe": "energie",
                "ancien": 3249,
                "nouveau": 3334,
                "periode": "2022-12-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-02-23T06:53:38.151Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 49,
                "idOccupation": 281,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1491,
            "energie": -3080
        },
        "montant": {
            "eau": -744009,
            "energie": -338800
        }
    },
    {
        "idLogement": 51,
        "refLogement": "C009",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 55000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.531Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51332,
                "typeIndexe": "eau",
                "ancien": 2052,
                "nouveau": 2031,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51333,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 16966,
                "idIndexe": 51204,
                "typeIndexe": "eau",
                "ancien": 2018,
                "nouveau": 2052,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51205,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51062,
                "typeIndexe": "eau",
                "ancien": 2013,
                "nouveau": 2018,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51063,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50927,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50926,
                "typeIndexe": "eau",
                "ancien": 2002,
                "nouveau": 2013,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50796,
                "typeIndexe": "eau",
                "ancien": 1999,
                "nouveau": 2002,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50797,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50659,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50658,
                "typeIndexe": "eau",
                "ancien": 1993,
                "nouveau": 1999,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50525,
                "typeIndexe": "eau",
                "ancien": 1982,
                "nouveau": 1993,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50526,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50385,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1982,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50386,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50314,
                "typeIndexe": "eau",
                "ancien": 1967,
                "nouveau": 1975,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50315,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50134,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50133,
                "typeIndexe": "eau",
                "ancien": 1959,
                "nouveau": 1967,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50012,
                "typeIndexe": "eau",
                "ancien": 1951,
                "nouveau": 1959,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50013,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49898,
                "typeIndexe": "eau",
                "ancien": 1944,
                "nouveau": 1951,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49899,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 6,
            "montant": 2994,
            "idIndexe": 51332,
            "typeIndexe": "eau",
            "ancien": 2052,
            "nouveau": 2058,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 51,
            "idOccupation": 47,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51333,
            "typeIndexe": "energie",
            "ancien": 6210,
            "nouveau": 6210,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 51,
            "idOccupation": 47,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 47,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.530Z",
            "idLocataire": 47,
            "idLogement": 51,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 47,
                "nomLocataire": "BEKONO",
                "prenomLocataire": "Yves",
                "cniLocataire": "",
                "tel": "677787456",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.676Z",
                "updatedAt": "2022-03-02T11:54:44.676Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2058,
                2052
            ],
            "energie": [
                6210,
                6210
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2058,
                2052
            ],
            "energie": [
                6210,
                6210
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 34,
                "montant": 16966,
                "idIndexe": 51204,
                "typeIndexe": "eau",
                "ancien": 2018,
                "nouveau": 2052,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51062,
                "typeIndexe": "eau",
                "ancien": 2013,
                "nouveau": 2018,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50926,
                "typeIndexe": "eau",
                "ancien": 2002,
                "nouveau": 2013,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50796,
                "typeIndexe": "eau",
                "ancien": 1999,
                "nouveau": 2002,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50658,
                "typeIndexe": "eau",
                "ancien": 1993,
                "nouveau": 1999,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50525,
                "typeIndexe": "eau",
                "ancien": 1982,
                "nouveau": 1993,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50385,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1982,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50314,
                "typeIndexe": "eau",
                "ancien": 1967,
                "nouveau": 1975,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50133,
                "typeIndexe": "eau",
                "ancien": 1959,
                "nouveau": 1967,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50012,
                "typeIndexe": "eau",
                "ancien": 1951,
                "nouveau": 1959,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49898,
                "typeIndexe": "eau",
                "ancien": 1944,
                "nouveau": 1951,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51205,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51063,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50927,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50797,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50659,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50526,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50386,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50315,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50134,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50013,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49899,
                "typeIndexe": "energie",
                "ancien": 6210,
                "nouveau": 6210,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 51,
                "idOccupation": 47,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2058,
            "energie": -6210
        },
        "montant": {
            "eau": -1026942,
            "energie": -683100
        }
    },
    {
        "idLogement": 53,
        "refLogement": "C011",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 55000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.032Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 65,
                "montant": 7150,
                "idIndexe": 51335,
                "typeIndexe": "energie",
                "ancien": 8967,
                "nouveau": 9032,
                "periode": "2023-11-01",
                "avance": 7150,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.972Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51334,
                "typeIndexe": "eau",
                "ancien": 1800,
                "nouveau": 1808,
                "periode": "2023-11-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.969Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51209,
                "typeIndexe": "energie",
                "ancien": 8899,
                "nouveau": 8967,
                "periode": "2023-10-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51208,
                "typeIndexe": "eau",
                "ancien": 1793,
                "nouveau": 1800,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:13.886Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 59,
                "montant": 6490,
                "idIndexe": 51068,
                "typeIndexe": "energie",
                "ancien": 8840,
                "nouveau": 8899,
                "periode": "2023-09-01",
                "avance": 6490,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51066,
                "typeIndexe": "eau",
                "ancien": 1788,
                "nouveau": 1793,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:53.134Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50930,
                "typeIndexe": "eau",
                "ancien": 1782,
                "nouveau": 1788,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:09:40.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50931,
                "typeIndexe": "energie",
                "ancien": 8773,
                "nouveau": 8840,
                "periode": "2023-08-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.960Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50800,
                "typeIndexe": "eau",
                "ancien": 1774,
                "nouveau": 1782,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:55:35.141Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50801,
                "typeIndexe": "energie",
                "ancien": 8713,
                "nouveau": 8773,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-10-28T15:09:40.305Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50663,
                "typeIndexe": "energie",
                "ancien": 8646,
                "nouveau": 8713,
                "periode": "2023-06-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-12-26T11:20:00.183Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50662,
                "typeIndexe": "eau",
                "ancien": 1768,
                "nouveau": 1774,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T06:55:35.134Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50529,
                "typeIndexe": "eau",
                "ancien": 1762,
                "nouveau": 1768,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.214Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50530,
                "typeIndexe": "energie",
                "ancien": 8578,
                "nouveau": 8646,
                "periode": "2023-05-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-07-26T14:42:18.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50389,
                "typeIndexe": "eau",
                "ancien": 1757,
                "nouveau": 1762,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.205Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50390,
                "typeIndexe": "energie",
                "ancien": 8510,
                "nouveau": 8578,
                "periode": "2023-04-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.210Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50260,
                "typeIndexe": "eau",
                "ancien": 1753,
                "nouveau": 1757,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.201Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50261,
                "typeIndexe": "energie",
                "ancien": 8428,
                "nouveau": 8510,
                "periode": "2023-03-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.197Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50137,
                "typeIndexe": "eau",
                "ancien": 1748,
                "nouveau": 1753,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:35.409Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50138,
                "typeIndexe": "energie",
                "ancien": 8368,
                "nouveau": 8428,
                "periode": "2023-02-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-07-26T14:42:35.717Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50016,
                "typeIndexe": "eau",
                "ancien": 1742,
                "nouveau": 1748,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:17:59.750Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50017,
                "typeIndexe": "energie",
                "ancien": 8300,
                "nouveau": 8368,
                "periode": "2023-01-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:15.319Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49902,
                "typeIndexe": "eau",
                "ancien": 1734,
                "nouveau": 1742,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:27.287Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 49903,
                "typeIndexe": "energie",
                "ancien": 8244,
                "nouveau": 8300,
                "periode": "2022-12-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:07.191Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 8,
            "montant": 3992,
            "idIndexe": 51334,
            "typeIndexe": "eau",
            "ancien": 1800,
            "nouveau": 1808,
            "periode": "2023-11-01",
            "avance": 3992,
            "reste": 0,
            "datePaiement": "2023-12-26T11:19:37.969Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 53,
            "idOccupation": 143,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 65,
            "montant": 7150,
            "idIndexe": 51335,
            "typeIndexe": "energie",
            "ancien": 8967,
            "nouveau": 9032,
            "periode": "2023-11-01",
            "avance": 7150,
            "reste": 0,
            "datePaiement": "2023-12-26T11:19:37.972Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 53,
            "idOccupation": 143,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 143,
            "loyerBase": 55000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-09-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.031Z",
            "idLocataire": 32,
            "idLogement": 53,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 32,
                "nomLocataire": "NKOULOU",
                "prenomLocataire": "Emile Roy",
                "cniLocataire": "",
                "tel": "695170746",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.404Z",
                "updatedAt": "2022-03-02T11:54:45.404Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1808,
                1800
            ],
            "energie": [
                9032,
                8967
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1808,
                1800
            ],
            "energie": [
                9032,
                8967
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51208,
                "typeIndexe": "eau",
                "ancien": 1793,
                "nouveau": 1800,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:13.886Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51066,
                "typeIndexe": "eau",
                "ancien": 1788,
                "nouveau": 1793,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:53.134Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50930,
                "typeIndexe": "eau",
                "ancien": 1782,
                "nouveau": 1788,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:09:40.309Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50800,
                "typeIndexe": "eau",
                "ancien": 1774,
                "nouveau": 1782,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:55:35.141Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50662,
                "typeIndexe": "eau",
                "ancien": 1768,
                "nouveau": 1774,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T06:55:35.134Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50529,
                "typeIndexe": "eau",
                "ancien": 1762,
                "nouveau": 1768,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.214Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50389,
                "typeIndexe": "eau",
                "ancien": 1757,
                "nouveau": 1762,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.205Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50260,
                "typeIndexe": "eau",
                "ancien": 1753,
                "nouveau": 1757,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.201Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50137,
                "typeIndexe": "eau",
                "ancien": 1748,
                "nouveau": 1753,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:35.409Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50016,
                "typeIndexe": "eau",
                "ancien": 1742,
                "nouveau": 1748,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:17:59.750Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49902,
                "typeIndexe": "eau",
                "ancien": 1734,
                "nouveau": 1742,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:27.287Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51209,
                "typeIndexe": "energie",
                "ancien": 8899,
                "nouveau": 8967,
                "periode": "2023-10-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 59,
                "montant": 6490,
                "idIndexe": 51068,
                "typeIndexe": "energie",
                "ancien": 8840,
                "nouveau": 8899,
                "periode": "2023-09-01",
                "avance": 6490,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50931,
                "typeIndexe": "energie",
                "ancien": 8773,
                "nouveau": 8840,
                "periode": "2023-08-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-12-26T11:19:37.960Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50801,
                "typeIndexe": "energie",
                "ancien": 8713,
                "nouveau": 8773,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-10-28T15:09:40.305Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50663,
                "typeIndexe": "energie",
                "ancien": 8646,
                "nouveau": 8713,
                "periode": "2023-06-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-12-26T11:20:00.183Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50530,
                "typeIndexe": "energie",
                "ancien": 8578,
                "nouveau": 8646,
                "periode": "2023-05-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-07-26T14:42:18.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50390,
                "typeIndexe": "energie",
                "ancien": 8510,
                "nouveau": 8578,
                "periode": "2023-04-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.210Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50261,
                "typeIndexe": "energie",
                "ancien": 8428,
                "nouveau": 8510,
                "periode": "2023-03-01",
                "avance": 9020,
                "reste": 0,
                "datePaiement": "2023-06-27T10:16:48.197Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50138,
                "typeIndexe": "energie",
                "ancien": 8368,
                "nouveau": 8428,
                "periode": "2023-02-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-07-26T14:42:35.717Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50017,
                "typeIndexe": "energie",
                "ancien": 8300,
                "nouveau": 8368,
                "periode": "2023-01-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:15.319Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 49903,
                "typeIndexe": "energie",
                "ancien": 8244,
                "nouveau": 8300,
                "periode": "2022-12-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-05-30T10:38:07.191Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 53,
                "idOccupation": 143,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1808,
            "energie": -9032
        },
        "montant": {
            "eau": -902192,
            "energie": -993520
        }
    },
    {
        "idLogement": 54,
        "refLogement": "C012",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 75000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-06-28T12:01:18.375Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 51336,
                "typeIndexe": "eau",
                "ancien": 1985,
                "nouveau": 1999,
                "periode": "2023-11-01",
                "avance": 1388,
                "reste": 5598,
                "datePaiement": "2023-12-26T11:21:30.569Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 133,
                "montant": 14630,
                "idIndexe": 51337,
                "typeIndexe": "energie",
                "ancien": 530,
                "nouveau": 663,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51210,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1985,
                "periode": "2023-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 118,
                "montant": 12980,
                "idIndexe": 51211,
                "typeIndexe": "energie",
                "ancien": 412,
                "nouveau": 530,
                "periode": "2023-10-01",
                "avance": 12980,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.693Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 106,
                "montant": 11660,
                "idIndexe": 51071,
                "typeIndexe": "energie",
                "ancien": 306,
                "nouveau": 412,
                "periode": "2023-09-01",
                "avance": 11660,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.690Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 51070,
                "typeIndexe": "eau",
                "ancien": 1957,
                "nouveau": 1975,
                "periode": "2023-09-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:00.459Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 136,
                "montant": 14960,
                "idIndexe": 50977,
                "typeIndexe": "energie",
                "ancien": 170,
                "nouveau": 306,
                "periode": "2023-08-01",
                "avance": 14960,
                "reste": 0,
                "datePaiement": "2023-09-28T06:44:29.803Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50976,
                "typeIndexe": "eau",
                "ancien": 1947,
                "nouveau": 1957,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T06:44:29.783Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50803,
                "typeIndexe": "energie",
                "ancien": 87,
                "nouveau": 170,
                "periode": "2023-07-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50802,
                "typeIndexe": "eau",
                "ancien": 1945,
                "nouveau": 1947,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.965Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50667,
                "typeIndexe": "eau",
                "ancien": 1938,
                "nouveau": 1945,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.956Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 50669,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 87,
                "periode": "2023-06-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.959Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50666,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50668,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49097,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 10978,
                "idIndexe": 49096,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1846,
                "periode": "2022-07-01",
                "avance": 10978,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.932Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49073,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49074,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48950,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48949,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48790,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48791,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48686,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48687,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 14,
            "montant": 6986,
            "idIndexe": 51336,
            "typeIndexe": "eau",
            "ancien": 1985,
            "nouveau": 1999,
            "periode": "2023-11-01",
            "avance": 1388,
            "reste": 5598,
            "datePaiement": "2023-12-26T11:21:30.569Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 54,
            "idOccupation": 397,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 133,
            "montant": 14630,
            "idIndexe": 51337,
            "typeIndexe": "energie",
            "ancien": 530,
            "nouveau": 663,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 54,
            "idOccupation": 397,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 397,
            "loyerBase": 70000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-07-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-06-28T12:01:18.301Z",
            "idLocataire": 362,
            "idLogement": 54,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 362,
                "nomLocataire": "ESSOGO epse NKE",
                "prenomLocataire": "Marie Louise",
                "cniLocataire": "",
                "tel": "696337990",
                "tel2": "651980672",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-06-27T17:12:55.779Z",
                "updatedAt": "2022-07-03T12:20:27.351Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1999,
                1985
            ],
            "energie": [
                663,
                530
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1999,
                1985
            ],
            "energie": [
                663,
                530
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51210,
                "typeIndexe": "eau",
                "ancien": 1975,
                "nouveau": 1985,
                "periode": "2023-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.696Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 51070,
                "typeIndexe": "eau",
                "ancien": 1957,
                "nouveau": 1975,
                "periode": "2023-09-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:00.459Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50976,
                "typeIndexe": "eau",
                "ancien": 1947,
                "nouveau": 1957,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T06:44:29.783Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50802,
                "typeIndexe": "eau",
                "ancien": 1945,
                "nouveau": 1947,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.965Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50667,
                "typeIndexe": "eau",
                "ancien": 1938,
                "nouveau": 1945,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.956Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 10978,
                "idIndexe": 49096,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1846,
                "periode": "2022-07-01",
                "avance": 10978,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.932Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49073,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48949,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48790,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48686,
                "typeIndexe": "eau",
                "ancien": 1824,
                "nouveau": 1824,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 118,
                "montant": 12980,
                "idIndexe": 51211,
                "typeIndexe": "energie",
                "ancien": 412,
                "nouveau": 530,
                "periode": "2023-10-01",
                "avance": 12980,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.693Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 106,
                "montant": 11660,
                "idIndexe": 51071,
                "typeIndexe": "energie",
                "ancien": 306,
                "nouveau": 412,
                "periode": "2023-09-01",
                "avance": 11660,
                "reste": 0,
                "datePaiement": "2023-12-26T11:21:17.690Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 136,
                "montant": 14960,
                "idIndexe": 50977,
                "typeIndexe": "energie",
                "ancien": 170,
                "nouveau": 306,
                "periode": "2023-08-01",
                "avance": 14960,
                "reste": 0,
                "datePaiement": "2023-09-28T06:44:29.803Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50803,
                "typeIndexe": "energie",
                "ancien": 87,
                "nouveau": 170,
                "periode": "2023-07-01",
                "avance": 9130,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 50669,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 87,
                "periode": "2023-06-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-08-30T06:57:36.959Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49097,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": 397,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49074,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48950,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48791,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 48687,
                "typeIndexe": "energie",
                "ancien": 8162,
                "nouveau": 8162,
                "periode": "2022-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 54,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1999,
            "energie": -663
        },
        "montant": {
            "eau": -997501,
            "energie": -72930
        }
    },
    {
        "idLogement": 55,
        "refLogement": "C013",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 55000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:20.160Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51338,
                "typeIndexe": "eau",
                "ancien": 648,
                "nouveau": 650,
                "periode": "2023-11-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T11:22:09.177Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 44,
                "montant": 4840,
                "idIndexe": 51339,
                "typeIndexe": "energie",
                "ancien": 1556,
                "nouveau": 1600,
                "periode": "2023-11-01",
                "avance": 4002,
                "reste": 838,
                "datePaiement": "2023-12-26T11:22:21.840Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51213,
                "typeIndexe": "energie",
                "ancien": 1500,
                "nouveau": 1556,
                "periode": "2023-10-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51212,
                "typeIndexe": "eau",
                "ancien": 645,
                "nouveau": 648,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.538Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51072,
                "typeIndexe": "eau",
                "ancien": 642,
                "nouveau": 645,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:10:26.755Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 51073,
                "typeIndexe": "energie",
                "ancien": 1455,
                "nouveau": 1500,
                "periode": "2023-09-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.535Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50933,
                "typeIndexe": "energie",
                "ancien": 1392,
                "nouveau": 1455,
                "periode": "2023-08-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.533Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50932,
                "typeIndexe": "eau",
                "ancien": 628,
                "nouveau": 642,
                "periode": "2023-08-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-11-27T15:50:18.986Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50804,
                "typeIndexe": "eau",
                "ancien": 620,
                "nouveau": 628,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:59:55.952Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50805,
                "typeIndexe": "energie",
                "ancien": 1344,
                "nouveau": 1392,
                "periode": "2023-07-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.530Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50687,
                "typeIndexe": "energie",
                "ancien": 1295,
                "nouveau": 1344,
                "periode": "2023-06-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50686,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 620,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:00:11.258Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50532,
                "typeIndexe": "energie",
                "ancien": 1241,
                "nouveau": 1295,
                "periode": "2023-05-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.523Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50531,
                "typeIndexe": "eau",
                "ancien": 608,
                "nouveau": 617,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-26T14:43:20.196Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50440,
                "typeIndexe": "energie",
                "ancien": 1193,
                "nouveau": 1241,
                "periode": "2023-04-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-11-27T15:50:26.365Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50439,
                "typeIndexe": "eau",
                "ancien": 606,
                "nouveau": 608,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T13:47:40.722Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50263,
                "typeIndexe": "energie",
                "ancien": 1150,
                "nouveau": 1193,
                "periode": "2023-03-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-07-26T14:43:20.189Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50262,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 606,
                "periode": "2023-03-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T13:47:30.009Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50139,
                "typeIndexe": "eau",
                "ancien": 601,
                "nouveau": 604,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:20:26.380Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50140,
                "typeIndexe": "energie",
                "ancien": 1105,
                "nouveau": 1150,
                "periode": "2023-02-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-04-28T14:40:31.972Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50018,
                "typeIndexe": "eau",
                "ancien": 598,
                "nouveau": 601,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:20:26.373Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50019,
                "typeIndexe": "energie",
                "ancien": 1052,
                "nouveau": 1105,
                "periode": "2023-01-01",
                "avance": 3198,
                "reste": 2632,
                "datePaiement": "2023-11-27T15:52:50.373Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49905,
                "typeIndexe": "energie",
                "ancien": 990,
                "nouveau": 1052,
                "periode": "2022-12-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2023-02-22T11:40:24.553Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49904,
                "typeIndexe": "eau",
                "ancien": 593,
                "nouveau": 598,
                "periode": "2022-12-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-22T11:40:01.087Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 2,
            "montant": 998,
            "idIndexe": 51338,
            "typeIndexe": "eau",
            "ancien": 648,
            "nouveau": 650,
            "periode": "2023-11-01",
            "avance": 998,
            "reste": 0,
            "datePaiement": "2023-12-26T11:22:09.177Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 55,
            "idOccupation": 340,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 44,
            "montant": 4840,
            "idIndexe": 51339,
            "typeIndexe": "energie",
            "ancien": 1556,
            "nouveau": 1600,
            "periode": "2023-11-01",
            "avance": 4002,
            "reste": 838,
            "datePaiement": "2023-12-26T11:22:21.840Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 55,
            "idOccupation": 340,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 340,
            "loyerBase": 55000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2021-03-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.158Z",
            "idLocataire": 309,
            "idLogement": 55,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 309,
                "nomLocataire": "NGALA",
                "prenomLocataire": "ETIENNE NASKSE",
                "cniLocataire": "",
                "tel": "670688966",
                "tel2": "690805390",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.102Z",
                "updatedAt": "2022-03-02T11:54:45.102Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                650,
                648
            ],
            "energie": [
                1600,
                1556
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                650,
                648
            ],
            "energie": [
                1600,
                1556
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51212,
                "typeIndexe": "eau",
                "ancien": 645,
                "nouveau": 648,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.538Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51072,
                "typeIndexe": "eau",
                "ancien": 642,
                "nouveau": 645,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:10:26.755Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50932,
                "typeIndexe": "eau",
                "ancien": 628,
                "nouveau": 642,
                "periode": "2023-08-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-11-27T15:50:18.986Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50804,
                "typeIndexe": "eau",
                "ancien": 620,
                "nouveau": 628,
                "periode": "2023-07-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-08-30T06:59:55.952Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50686,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 620,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:00:11.258Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50531,
                "typeIndexe": "eau",
                "ancien": 608,
                "nouveau": 617,
                "periode": "2023-05-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-26T14:43:20.196Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50439,
                "typeIndexe": "eau",
                "ancien": 606,
                "nouveau": 608,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T13:47:40.722Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50262,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 606,
                "periode": "2023-03-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-05-30T13:47:30.009Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50139,
                "typeIndexe": "eau",
                "ancien": 601,
                "nouveau": 604,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:20:26.380Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50018,
                "typeIndexe": "eau",
                "ancien": 598,
                "nouveau": 601,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:20:26.373Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49904,
                "typeIndexe": "eau",
                "ancien": 593,
                "nouveau": 598,
                "periode": "2022-12-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-22T11:40:01.087Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51213,
                "typeIndexe": "energie",
                "ancien": 1500,
                "nouveau": 1556,
                "periode": "2023-10-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.541Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 51073,
                "typeIndexe": "energie",
                "ancien": 1455,
                "nouveau": 1500,
                "periode": "2023-09-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.535Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50933,
                "typeIndexe": "energie",
                "ancien": 1392,
                "nouveau": 1455,
                "periode": "2023-08-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.533Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50805,
                "typeIndexe": "energie",
                "ancien": 1344,
                "nouveau": 1392,
                "periode": "2023-07-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.530Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50687,
                "typeIndexe": "energie",
                "ancien": 1295,
                "nouveau": 1344,
                "periode": "2023-06-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.527Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50532,
                "typeIndexe": "energie",
                "ancien": 1241,
                "nouveau": 1295,
                "periode": "2023-05-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-11-27T15:49:56.523Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50440,
                "typeIndexe": "energie",
                "ancien": 1193,
                "nouveau": 1241,
                "periode": "2023-04-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-11-27T15:50:26.365Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50263,
                "typeIndexe": "energie",
                "ancien": 1150,
                "nouveau": 1193,
                "periode": "2023-03-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-07-26T14:43:20.189Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 50140,
                "typeIndexe": "energie",
                "ancien": 1105,
                "nouveau": 1150,
                "periode": "2023-02-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-04-28T14:40:31.972Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50019,
                "typeIndexe": "energie",
                "ancien": 1052,
                "nouveau": 1105,
                "periode": "2023-01-01",
                "avance": 3198,
                "reste": 2632,
                "datePaiement": "2023-11-27T15:52:50.373Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49905,
                "typeIndexe": "energie",
                "ancien": 990,
                "nouveau": 1052,
                "periode": "2022-12-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2023-02-22T11:40:24.553Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 55,
                "idOccupation": 340,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -650,
            "energie": -1600
        },
        "montant": {
            "eau": -324350,
            "energie": -176000
        }
    },
    {
        "idLogement": 57,
        "refLogement": "C015",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.964Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 145,
                "montant": 15950,
                "idIndexe": 51341,
                "typeIndexe": "energie",
                "ancien": 7515,
                "nouveau": 7660,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51340,
                "typeIndexe": "eau",
                "ancien": 1346,
                "nouveau": 1353,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 175,
                "montant": 19250,
                "idIndexe": 51215,
                "typeIndexe": "energie",
                "ancien": 7340,
                "nouveau": 7515,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51214,
                "typeIndexe": "eau",
                "ancien": 1336,
                "nouveau": 1346,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51074,
                "typeIndexe": "eau",
                "ancien": 1329,
                "nouveau": 1336,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 145,
                "montant": 15950,
                "idIndexe": 51075,
                "typeIndexe": "energie",
                "ancien": 7195,
                "nouveau": 7340,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 166,
                "montant": 18260,
                "idIndexe": 50935,
                "typeIndexe": "energie",
                "ancien": 7029,
                "nouveau": 7195,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50934,
                "typeIndexe": "eau",
                "ancien": 1322,
                "nouveau": 1329,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50806,
                "typeIndexe": "eau",
                "ancien": 1315,
                "nouveau": 1322,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 148,
                "montant": 16280,
                "idIndexe": 50807,
                "typeIndexe": "energie",
                "ancien": 6881,
                "nouveau": 7029,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 153,
                "montant": 16830,
                "idIndexe": 50671,
                "typeIndexe": "energie",
                "ancien": 6728,
                "nouveau": 6881,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50670,
                "typeIndexe": "eau",
                "ancien": 1309,
                "nouveau": 1315,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50534,
                "typeIndexe": "eau",
                "ancien": 1300,
                "nouveau": 1309,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 155,
                "montant": 17050,
                "idIndexe": 50535,
                "typeIndexe": "energie",
                "ancien": 6573,
                "nouveau": 6728,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 155,
                "montant": 17050,
                "idIndexe": 50536,
                "typeIndexe": "energie",
                "ancien": 6573,
                "nouveau": 6728,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50533,
                "typeIndexe": "eau",
                "ancien": 1300,
                "nouveau": 1309,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 153,
                "montant": 16830,
                "idIndexe": 50392,
                "typeIndexe": "energie",
                "ancien": 6420,
                "nouveau": 6573,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50391,
                "typeIndexe": "eau",
                "ancien": 1292,
                "nouveau": 1300,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 352,
                "montant": 38720,
                "idIndexe": 50265,
                "typeIndexe": "energie",
                "ancien": 6068,
                "nouveau": 6420,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50264,
                "typeIndexe": "eau",
                "ancien": 1280,
                "nouveau": 1292,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 109,
                "montant": 11990,
                "idIndexe": 50142,
                "typeIndexe": "energie",
                "ancien": 5959,
                "nouveau": 6068,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50141,
                "typeIndexe": "eau",
                "ancien": 1362,
                "nouveau": 1378,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50021,
                "typeIndexe": "energie",
                "ancien": 5932,
                "nouveau": 5959,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 14970,
                "idIndexe": 50020,
                "typeIndexe": "eau",
                "ancien": 1364,
                "nouveau": 1394,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 7,
            "montant": 3493,
            "idIndexe": 51340,
            "typeIndexe": "eau",
            "ancien": 1346,
            "nouveau": 1353,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 57,
            "idOccupation": 299,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 145,
            "montant": 15950,
            "idIndexe": 51341,
            "typeIndexe": "energie",
            "ancien": 7515,
            "nouveau": 7660,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 57,
            "idOccupation": 299,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 299,
            "loyerBase": 80000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-02-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.963Z",
            "idLocataire": 51,
            "idLogement": 57,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 51,
                "nomLocataire": "AMOUGOU",
                "prenomLocataire": "Pierre",
                "cniLocataire": "",
                "tel": "697885650",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.630Z",
                "updatedAt": "2022-03-02T11:54:45.630Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1353,
                1346
            ],
            "energie": [
                7660,
                7515
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1353,
                1346
            ],
            "energie": [
                7660,
                7515
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51214,
                "typeIndexe": "eau",
                "ancien": 1336,
                "nouveau": 1346,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51074,
                "typeIndexe": "eau",
                "ancien": 1329,
                "nouveau": 1336,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50934,
                "typeIndexe": "eau",
                "ancien": 1322,
                "nouveau": 1329,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50806,
                "typeIndexe": "eau",
                "ancien": 1315,
                "nouveau": 1322,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50670,
                "typeIndexe": "eau",
                "ancien": 1309,
                "nouveau": 1315,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50534,
                "typeIndexe": "eau",
                "ancien": 1300,
                "nouveau": 1309,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50533,
                "typeIndexe": "eau",
                "ancien": 1300,
                "nouveau": 1309,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50391,
                "typeIndexe": "eau",
                "ancien": 1292,
                "nouveau": 1300,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50264,
                "typeIndexe": "eau",
                "ancien": 1280,
                "nouveau": 1292,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50141,
                "typeIndexe": "eau",
                "ancien": 1362,
                "nouveau": 1378,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 14970,
                "idIndexe": 50020,
                "typeIndexe": "eau",
                "ancien": 1364,
                "nouveau": 1394,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 175,
                "montant": 19250,
                "idIndexe": 51215,
                "typeIndexe": "energie",
                "ancien": 7340,
                "nouveau": 7515,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 145,
                "montant": 15950,
                "idIndexe": 51075,
                "typeIndexe": "energie",
                "ancien": 7195,
                "nouveau": 7340,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 166,
                "montant": 18260,
                "idIndexe": 50935,
                "typeIndexe": "energie",
                "ancien": 7029,
                "nouveau": 7195,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 148,
                "montant": 16280,
                "idIndexe": 50807,
                "typeIndexe": "energie",
                "ancien": 6881,
                "nouveau": 7029,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 153,
                "montant": 16830,
                "idIndexe": 50671,
                "typeIndexe": "energie",
                "ancien": 6728,
                "nouveau": 6881,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 155,
                "montant": 17050,
                "idIndexe": 50535,
                "typeIndexe": "energie",
                "ancien": 6573,
                "nouveau": 6728,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 155,
                "montant": 17050,
                "idIndexe": 50536,
                "typeIndexe": "energie",
                "ancien": 6573,
                "nouveau": 6728,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 153,
                "montant": 16830,
                "idIndexe": 50392,
                "typeIndexe": "energie",
                "ancien": 6420,
                "nouveau": 6573,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 352,
                "montant": 38720,
                "idIndexe": 50265,
                "typeIndexe": "energie",
                "ancien": 6068,
                "nouveau": 6420,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 109,
                "montant": 11990,
                "idIndexe": 50142,
                "typeIndexe": "energie",
                "ancien": 5959,
                "nouveau": 6068,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 50021,
                "typeIndexe": "energie",
                "ancien": 5932,
                "nouveau": 5959,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 57,
                "idOccupation": 299,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1353,
            "energie": -7660
        },
        "montant": {
            "eau": -675147,
            "energie": -842600
        }
    },
    {
        "idLogement": 58,
        "refLogement": "C016",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T15:03:03.450Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51343,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51342,
                "typeIndexe": "eau",
                "ancien": 1459,
                "nouveau": 1462,
                "periode": "2023-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:25:20.570Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51216,
                "typeIndexe": "eau",
                "ancien": 1456,
                "nouveau": 1459,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:58:32.338Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51217,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51077,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51076,
                "typeIndexe": "eau",
                "ancien": 1453,
                "nouveau": 1456,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:11:24.747Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50937,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50936,
                "typeIndexe": "eau",
                "ancien": 1451,
                "nouveau": 1453,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:06.970Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50809,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50808,
                "typeIndexe": "eau",
                "ancien": 1448,
                "nouveau": 1451,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:01:24.114Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50672,
                "typeIndexe": "eau",
                "ancien": 1445,
                "nouveau": 1448,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T14:45:29.528Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50673,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50538,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50537,
                "typeIndexe": "eau",
                "ancien": 1443,
                "nouveau": 1445,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-29T15:50:17.400Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50394,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50393,
                "typeIndexe": "eau",
                "ancien": 1440,
                "nouveau": 1443,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T10:48:56.670Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50266,
                "typeIndexe": "eau",
                "ancien": 1437,
                "nouveau": 1440,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:42:18.325Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50267,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50143,
                "typeIndexe": "eau",
                "ancien": 1434,
                "nouveau": 1437,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:21:53.798Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50144,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50023,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50022,
                "typeIndexe": "eau",
                "ancien": 1430,
                "nouveau": 1434,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:06:16.661Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49909,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49908,
                "typeIndexe": "eau",
                "ancien": 1422,
                "nouveau": 1430,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-02-22T11:42:01.563Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51342,
            "typeIndexe": "eau",
            "ancien": 1459,
            "nouveau": 1462,
            "periode": "2023-11-01",
            "avance": 1497,
            "reste": 0,
            "datePaiement": "2023-12-26T11:25:20.570Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 58,
            "idOccupation": 346,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51343,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 58,
            "idOccupation": 346,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 346,
            "loyerBase": 80000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-05-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.193Z",
            "idLocataire": 17,
            "idLogement": 58,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 17,
                "nomLocataire": "EKOMENA OBAMA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "677602051",
                "tel2": "696033234",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.548Z",
                "updatedAt": "2022-03-02T11:54:45.548Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1462,
                1459
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1462,
                1459
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51216,
                "typeIndexe": "eau",
                "ancien": 1456,
                "nouveau": 1459,
                "periode": "2023-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-11-27T15:58:32.338Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51076,
                "typeIndexe": "eau",
                "ancien": 1453,
                "nouveau": 1456,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:11:24.747Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50936,
                "typeIndexe": "eau",
                "ancien": 1451,
                "nouveau": 1453,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:06.970Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50808,
                "typeIndexe": "eau",
                "ancien": 1448,
                "nouveau": 1451,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:01:24.114Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50672,
                "typeIndexe": "eau",
                "ancien": 1445,
                "nouveau": 1448,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T14:45:29.528Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50537,
                "typeIndexe": "eau",
                "ancien": 1443,
                "nouveau": 1445,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-06-29T15:50:17.400Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50393,
                "typeIndexe": "eau",
                "ancien": 1440,
                "nouveau": 1443,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T10:48:56.670Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50266,
                "typeIndexe": "eau",
                "ancien": 1437,
                "nouveau": 1440,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T14:42:18.325Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50143,
                "typeIndexe": "eau",
                "ancien": 1434,
                "nouveau": 1437,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-03-29T08:21:53.798Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50022,
                "typeIndexe": "eau",
                "ancien": 1430,
                "nouveau": 1434,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:06:16.661Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49908,
                "typeIndexe": "eau",
                "ancien": 1422,
                "nouveau": 1430,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-02-22T11:42:01.563Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 58,
                "idOccupation": 346,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [],
        "conso": {
            "eau": -1462,
            "energie": 0
        },
        "montant": {
            "eau": -729538,
            "energie": 0
        }
    },
    {
        "idLogement": 60,
        "refLogement": "C018",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.737Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 51344,
                "typeIndexe": "eau",
                "ancien": 2598,
                "nouveau": 2609,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 73,
                "montant": 8030,
                "idIndexe": 51345,
                "typeIndexe": "energie",
                "ancien": 3619,
                "nouveau": 3692,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 51218,
                "typeIndexe": "eau",
                "ancien": 2587,
                "nouveau": 2598,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 51219,
                "typeIndexe": "energie",
                "ancien": 3526,
                "nouveau": 3619,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 51078,
                "typeIndexe": "eau",
                "ancien": 2574,
                "nouveau": 2587,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 51079,
                "typeIndexe": "energie",
                "ancien": 3447,
                "nouveau": 3526,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 50939,
                "typeIndexe": "energie",
                "ancien": 3369,
                "nouveau": 3447,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50938,
                "typeIndexe": "eau",
                "ancien": 2557,
                "nouveau": 2574,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50810,
                "typeIndexe": "eau",
                "ancien": 2551,
                "nouveau": 2557,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:43.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 50811,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3369,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50675,
                "typeIndexe": "energie",
                "ancien": 3253,
                "nouveau": 3319,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50674,
                "typeIndexe": "eau",
                "ancien": 2541,
                "nouveau": 2551,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50540,
                "typeIndexe": "energie",
                "ancien": 3171,
                "nouveau": 3253,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 50539,
                "typeIndexe": "eau",
                "ancien": 2526,
                "nouveau": 2541,
                "periode": "2023-05-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-12-26T11:25:46.113Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50395,
                "typeIndexe": "eau",
                "ancien": 2514,
                "nouveau": 2526,
                "periode": "2023-04-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:43.452Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 50396,
                "typeIndexe": "energie",
                "ancien": 3097,
                "nouveau": 3171,
                "periode": "2023-04-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2023-11-25T09:20:04.568Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50268,
                "typeIndexe": "eau",
                "ancien": 2501,
                "nouveau": 2514,
                "periode": "2023-03-01",
                "avance": 1922,
                "reste": 4565,
                "datePaiement": "2023-09-28T06:51:04.252Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50269,
                "typeIndexe": "energie",
                "ancien": 3027,
                "nouveau": 3097,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-08-30T07:02:36.800Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 50146,
                "typeIndexe": "energie",
                "ancien": 2950,
                "nouveau": 3027,
                "periode": "2023-02-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-06-27T10:24:15.022Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50145,
                "typeIndexe": "eau",
                "ancien": 2487,
                "nouveau": 2501,
                "periode": "2023-02-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-07-26T14:46:01.798Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50024,
                "typeIndexe": "eau",
                "ancien": 2473,
                "nouveau": 2487,
                "periode": "2023-01-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-05-30T10:52:13.017Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 98,
                "montant": 10780,
                "idIndexe": 50025,
                "typeIndexe": "energie",
                "ancien": 2852,
                "nouveau": 2950,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 49910,
                "typeIndexe": "eau",
                "ancien": 2459,
                "nouveau": 2473,
                "periode": "2022-12-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-04-28T14:42:51.452Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 49911,
                "typeIndexe": "energie",
                "ancien": 2742,
                "nouveau": 2852,
                "periode": "2022-12-01",
                "avance": 8220,
                "reste": 3880,
                "datePaiement": "2023-11-25T09:20:57.157Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 11,
            "montant": 5489,
            "idIndexe": 51344,
            "typeIndexe": "eau",
            "ancien": 2598,
            "nouveau": 2609,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 60,
            "idOccupation": 257,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 73,
            "montant": 8030,
            "idIndexe": 51345,
            "typeIndexe": "energie",
            "ancien": 3619,
            "nouveau": 3692,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 60,
            "idOccupation": 257,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 257,
            "loyerBase": 70000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-03-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.736Z",
            "idLocataire": 237,
            "idLogement": 60,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 237,
                "nomLocataire": "KEUBENG MEVOGTAGA",
                "prenomLocataire": "Cedric",
                "cniLocataire": "",
                "tel": "675343148",
                "tel2": "696369947",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.995Z",
                "updatedAt": "2022-03-02T11:54:45.995Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2609,
                2598
            ],
            "energie": [
                3692,
                3619
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2609,
                2598
            ],
            "energie": [
                3692,
                3619
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 51218,
                "typeIndexe": "eau",
                "ancien": 2587,
                "nouveau": 2598,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 51078,
                "typeIndexe": "eau",
                "ancien": 2574,
                "nouveau": 2587,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50938,
                "typeIndexe": "eau",
                "ancien": 2557,
                "nouveau": 2574,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50810,
                "typeIndexe": "eau",
                "ancien": 2551,
                "nouveau": 2557,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:43.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50674,
                "typeIndexe": "eau",
                "ancien": 2541,
                "nouveau": 2551,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 50539,
                "typeIndexe": "eau",
                "ancien": 2526,
                "nouveau": 2541,
                "periode": "2023-05-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-12-26T11:25:46.113Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 50395,
                "typeIndexe": "eau",
                "ancien": 2514,
                "nouveau": 2526,
                "periode": "2023-04-01",
                "avance": 5988,
                "reste": 0,
                "datePaiement": "2023-09-28T06:50:43.452Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50268,
                "typeIndexe": "eau",
                "ancien": 2501,
                "nouveau": 2514,
                "periode": "2023-03-01",
                "avance": 1922,
                "reste": 4565,
                "datePaiement": "2023-09-28T06:51:04.252Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50145,
                "typeIndexe": "eau",
                "ancien": 2487,
                "nouveau": 2501,
                "periode": "2023-02-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-07-26T14:46:01.798Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50024,
                "typeIndexe": "eau",
                "ancien": 2473,
                "nouveau": 2487,
                "periode": "2023-01-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-05-30T10:52:13.017Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 49910,
                "typeIndexe": "eau",
                "ancien": 2459,
                "nouveau": 2473,
                "periode": "2022-12-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-04-28T14:42:51.452Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 51219,
                "typeIndexe": "energie",
                "ancien": 3526,
                "nouveau": 3619,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 51079,
                "typeIndexe": "energie",
                "ancien": 3447,
                "nouveau": 3526,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 50939,
                "typeIndexe": "energie",
                "ancien": 3369,
                "nouveau": 3447,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 50811,
                "typeIndexe": "energie",
                "ancien": 3319,
                "nouveau": 3369,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50675,
                "typeIndexe": "energie",
                "ancien": 3253,
                "nouveau": 3319,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 50540,
                "typeIndexe": "energie",
                "ancien": 3171,
                "nouveau": 3253,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 50396,
                "typeIndexe": "energie",
                "ancien": 3097,
                "nouveau": 3171,
                "periode": "2023-04-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2023-11-25T09:20:04.568Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50269,
                "typeIndexe": "energie",
                "ancien": 3027,
                "nouveau": 3097,
                "periode": "2023-03-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-08-30T07:02:36.800Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 77,
                "montant": 8470,
                "idIndexe": 50146,
                "typeIndexe": "energie",
                "ancien": 2950,
                "nouveau": 3027,
                "periode": "2023-02-01",
                "avance": 8470,
                "reste": 0,
                "datePaiement": "2023-06-27T10:24:15.022Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 98,
                "montant": 10780,
                "idIndexe": 50025,
                "typeIndexe": "energie",
                "ancien": 2852,
                "nouveau": 2950,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 49911,
                "typeIndexe": "energie",
                "ancien": 2742,
                "nouveau": 2852,
                "periode": "2022-12-01",
                "avance": 8220,
                "reste": 3880,
                "datePaiement": "2023-11-25T09:20:57.157Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 60,
                "idOccupation": 257,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2609,
            "energie": -3692
        },
        "montant": {
            "eau": -1301891,
            "energie": -406120
        }
    },
    {
        "idLogement": 61,
        "refLogement": "C019",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-05-02T17:24:12.392Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51347,
                "typeIndexe": "energie",
                "ancien": 6464,
                "nouveau": 6520,
                "periode": "2023-11-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.863Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51346,
                "typeIndexe": "eau",
                "ancien": 2756,
                "nouveau": 2760,
                "periode": "2023-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.858Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51220,
                "typeIndexe": "eau",
                "ancien": 2751,
                "nouveau": 2756,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.852Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 51221,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6464,
                "periode": "2023-10-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.855Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51081,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51080,
                "typeIndexe": "eau",
                "ancien": 2747,
                "nouveau": 2751,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.850Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50940,
                "typeIndexe": "eau",
                "ancien": 2742,
                "nouveau": 2747,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.846Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50941,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50813,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50812,
                "typeIndexe": "eau",
                "ancien": 2738,
                "nouveau": 2742,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.605Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50677,
                "typeIndexe": "energie",
                "ancien": 6386,
                "nouveau": 6401,
                "periode": "2023-06-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.602Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50676,
                "typeIndexe": "eau",
                "ancien": 2735,
                "nouveau": 2738,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.598Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50541,
                "typeIndexe": "eau",
                "ancien": 2728,
                "nouveau": 2735,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.592Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50542,
                "typeIndexe": "energie",
                "ancien": 6323,
                "nouveau": 6386,
                "periode": "2023-05-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.595Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50397,
                "typeIndexe": "eau",
                "ancien": 2724,
                "nouveau": 2728,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-30T16:06:45.801Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50398,
                "typeIndexe": "energie",
                "ancien": 6274,
                "nouveau": 6323,
                "periode": "2023-04-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-04-30T16:10:15.402Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50270,
                "typeIndexe": "eau",
                "ancien": 2717,
                "nouveau": 2724,
                "periode": "2023-03-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.587Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50271,
                "typeIndexe": "energie",
                "ancien": 6226,
                "nouveau": 6274,
                "periode": "2023-03-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.590Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50148,
                "typeIndexe": "energie",
                "ancien": 6158,
                "nouveau": 6226,
                "periode": "2023-02-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.585Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50147,
                "typeIndexe": "eau",
                "ancien": 2711,
                "nouveau": 2717,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.582Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 50027,
                "typeIndexe": "energie",
                "ancien": 6065,
                "nouveau": 6158,
                "periode": "2023-01-01",
                "avance": 10230,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.577Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50026,
                "typeIndexe": "eau",
                "ancien": 2705,
                "nouveau": 2711,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 49913,
                "typeIndexe": "energie",
                "ancien": 5966,
                "nouveau": 6065,
                "periode": "2022-12-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.543Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 49912,
                "typeIndexe": "eau",
                "ancien": 2693,
                "nouveau": 2705,
                "periode": "2022-12-01",
                "avance": 186,
                "reste": 5802,
                "datePaiement": "2023-02-23T07:11:12.446Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51346,
            "typeIndexe": "eau",
            "ancien": 2756,
            "nouveau": 2759,
            "periode": "2023-11-01",
            "avance": 1497,
            "reste": 0,
            "datePaiement": "2023-12-26T11:27:37.858Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 61,
            "idOccupation": 381,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 56,
            "montant": 6160,
            "idIndexe": 51347,
            "typeIndexe": "energie",
            "ancien": 6464,
            "nouveau": 6520,
            "periode": "2023-11-01",
            "avance": 6160,
            "reste": 0,
            "datePaiement": "2023-12-26T11:27:37.863Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 61,
            "idOccupation": 381,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 381,
            "loyerBase": 80000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-04-30T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-05-02T17:24:12.343Z",
            "idLocataire": 349,
            "idLogement": 61,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 349,
                "nomLocataire": "Oyono Zang",
                "prenomLocataire": "Olivier",
                "cniLocataire": "",
                "tel": "695119258",
                "tel2": "680473334",
                "tel3": "680473334",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-05-02T17:22:29.642Z",
                "updatedAt": "2022-06-27T16:36:53.616Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2759,
                2756
            ],
            "energie": [
                6520,
                6464
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2759,
                2756
            ],
            "energie": [
                6520,
                6464
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51220,
                "typeIndexe": "eau",
                "ancien": 2751,
                "nouveau": 2756,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.852Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51080,
                "typeIndexe": "eau",
                "ancien": 2747,
                "nouveau": 2751,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.850Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50940,
                "typeIndexe": "eau",
                "ancien": 2742,
                "nouveau": 2747,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.846Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50812,
                "typeIndexe": "eau",
                "ancien": 2738,
                "nouveau": 2742,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.605Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50676,
                "typeIndexe": "eau",
                "ancien": 2735,
                "nouveau": 2738,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.598Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50541,
                "typeIndexe": "eau",
                "ancien": 2728,
                "nouveau": 2735,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.592Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50397,
                "typeIndexe": "eau",
                "ancien": 2724,
                "nouveau": 2728,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-30T16:06:45.801Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50270,
                "typeIndexe": "eau",
                "ancien": 2717,
                "nouveau": 2724,
                "periode": "2023-03-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.587Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50147,
                "typeIndexe": "eau",
                "ancien": 2711,
                "nouveau": 2717,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.582Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50026,
                "typeIndexe": "eau",
                "ancien": 2705,
                "nouveau": 2711,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 5988,
                "idIndexe": 49912,
                "typeIndexe": "eau",
                "ancien": 2693,
                "nouveau": 2705,
                "periode": "2022-12-01",
                "avance": 186,
                "reste": 5802,
                "datePaiement": "2023-02-23T07:11:12.446Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 51221,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6464,
                "periode": "2023-10-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-12-26T11:27:37.855Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51081,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50941,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50813,
                "typeIndexe": "energie",
                "ancien": 6401,
                "nouveau": 6401,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 50677,
                "typeIndexe": "energie",
                "ancien": 6386,
                "nouveau": 6401,
                "periode": "2023-06-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.602Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50542,
                "typeIndexe": "energie",
                "ancien": 6323,
                "nouveau": 6386,
                "periode": "2023-05-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.595Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50398,
                "typeIndexe": "energie",
                "ancien": 6274,
                "nouveau": 6323,
                "periode": "2023-04-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-04-30T16:10:15.402Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50271,
                "typeIndexe": "energie",
                "ancien": 6226,
                "nouveau": 6274,
                "periode": "2023-03-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.590Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50148,
                "typeIndexe": "energie",
                "ancien": 6158,
                "nouveau": 6226,
                "periode": "2023-02-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.585Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 93,
                "montant": 10230,
                "idIndexe": 50027,
                "typeIndexe": "energie",
                "ancien": 6065,
                "nouveau": 6158,
                "periode": "2023-01-01",
                "avance": 10230,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.577Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 99,
                "montant": 10890,
                "idIndexe": 49913,
                "typeIndexe": "energie",
                "ancien": 5966,
                "nouveau": 6065,
                "periode": "2022-12-01",
                "avance": 10890,
                "reste": 0,
                "datePaiement": "2023-10-28T15:14:35.543Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 61,
                "idOccupation": 381,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2759,
            "energie": -6520
        },
        "montant": {
            "eau": -1376741,
            "energie": -717200
        }
    },
    {
        "idLogement": 62,
        "refLogement": "C020",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 100000,
        "prixMax": 120000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-05-02T17:35:10.365Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51349,
                "typeIndexe": "energie",
                "ancien": 1511,
                "nouveau": 1579,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51348,
                "typeIndexe": "eau",
                "ancien": 2638,
                "nouveau": 2643,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.584Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51223,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1511,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51222,
                "typeIndexe": "eau",
                "ancien": 2634,
                "nouveau": 2638,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.581Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51082,
                "typeIndexe": "eau",
                "ancien": 2632,
                "nouveau": 2634,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.578Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 51083,
                "typeIndexe": "energie",
                "ancien": 1355,
                "nouveau": 1429,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50942,
                "typeIndexe": "eau",
                "ancien": 2629,
                "nouveau": 2632,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.575Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50943,
                "typeIndexe": "energie",
                "ancien": 1288,
                "nouveau": 1355,
                "periode": "2023-08-01",
                "avance": 398,
                "reste": 6972,
                "datePaiement": "2023-12-26T11:29:56.312Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50814,
                "typeIndexe": "eau",
                "ancien": 2626,
                "nouveau": 2629,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.571Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50815,
                "typeIndexe": "energie",
                "ancien": 1228,
                "nouveau": 1288,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:42.343Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50678,
                "typeIndexe": "eau",
                "ancien": 2623,
                "nouveau": 2626,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:50.932Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50679,
                "typeIndexe": "energie",
                "ancien": 1165,
                "nouveau": 1228,
                "periode": "2023-06-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:37.448Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50543,
                "typeIndexe": "eau",
                "ancien": 2620,
                "nouveau": 2623,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:50.078Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50544,
                "typeIndexe": "energie",
                "ancien": 1085,
                "nouveau": 1165,
                "periode": "2023-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:53.134Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 75,
                "montant": 8250,
                "idIndexe": 50400,
                "typeIndexe": "energie",
                "ancien": 1010,
                "nouveau": 1085,
                "periode": "2023-04-01",
                "avance": 8250,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:47.257Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50399,
                "typeIndexe": "eau",
                "ancien": 2616,
                "nouveau": 2620,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:36.149Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 71,
                "montant": 7810,
                "idIndexe": 50273,
                "typeIndexe": "energie",
                "ancien": 939,
                "nouveau": 1010,
                "periode": "2023-03-01",
                "avance": 7810,
                "reste": 0,
                "datePaiement": "2023-04-28T14:47:39.249Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50272,
                "typeIndexe": "eau",
                "ancien": 2612,
                "nouveau": 2616,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-28T14:44:24.752Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50150,
                "typeIndexe": "energie",
                "ancien": 869,
                "nouveau": 939,
                "periode": "2023-02-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-04-28T15:36:27.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 21457,
                "idIndexe": 50149,
                "typeIndexe": "eau",
                "ancien": 2569,
                "nouveau": 2612,
                "periode": "2023-02-01",
                "avance": 21457,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.756Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50029,
                "typeIndexe": "energie",
                "ancien": 835,
                "nouveau": 869,
                "periode": "2023-01-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50028,
                "typeIndexe": "eau",
                "ancien": 2565,
                "nouveau": 2569,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49914,
                "typeIndexe": "eau",
                "ancien": 2558,
                "nouveau": 2565,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-22T11:43:44.216Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 49915,
                "typeIndexe": "energie",
                "ancien": 778,
                "nouveau": 835,
                "periode": "2022-12-01",
                "avance": 6270,
                "reste": 0,
                "datePaiement": "2023-02-22T11:43:44.222Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51348,
            "typeIndexe": "eau",
            "ancien": 2638,
            "nouveau": 2643,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-26T11:29:36.584Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 62,
            "idOccupation": 382,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 68,
            "montant": 7480,
            "idIndexe": 51349,
            "typeIndexe": "energie",
            "ancien": 1511,
            "nouveau": 1579,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 62,
            "idOccupation": 382,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 382,
            "loyerBase": 110000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-03-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-05-02T17:35:10.306Z",
            "idLocataire": 346,
            "idLogement": 62,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 346,
                "nomLocataire": "MAYENKEU PASMA",
                "prenomLocataire": "Merveille",
                "cniLocataire": "",
                "tel": "691436053",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-05-02T14:29:33.814Z",
                "updatedAt": "2023-04-28T15:39:10.805Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2643,
                2638
            ],
            "energie": [
                1579,
                1511
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2643,
                2638
            ],
            "energie": [
                1579,
                1511
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51222,
                "typeIndexe": "eau",
                "ancien": 2634,
                "nouveau": 2638,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.581Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51082,
                "typeIndexe": "eau",
                "ancien": 2632,
                "nouveau": 2634,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.578Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50942,
                "typeIndexe": "eau",
                "ancien": 2629,
                "nouveau": 2632,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.575Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50814,
                "typeIndexe": "eau",
                "ancien": 2626,
                "nouveau": 2629,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:36.571Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50678,
                "typeIndexe": "eau",
                "ancien": 2623,
                "nouveau": 2626,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:29:50.932Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50543,
                "typeIndexe": "eau",
                "ancien": 2620,
                "nouveau": 2623,
                "periode": "2023-05-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:50.078Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50399,
                "typeIndexe": "eau",
                "ancien": 2616,
                "nouveau": 2620,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:36.149Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50272,
                "typeIndexe": "eau",
                "ancien": 2612,
                "nouveau": 2616,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-28T14:44:24.752Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 21457,
                "idIndexe": 50149,
                "typeIndexe": "eau",
                "ancien": 2569,
                "nouveau": 2612,
                "periode": "2023-02-01",
                "avance": 21457,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.756Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50028,
                "typeIndexe": "eau",
                "ancien": 2565,
                "nouveau": 2569,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.749Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 49914,
                "typeIndexe": "eau",
                "ancien": 2558,
                "nouveau": 2565,
                "periode": "2022-12-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-22T11:43:44.216Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 82,
                "montant": 9020,
                "idIndexe": 51223,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1511,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 51083,
                "typeIndexe": "energie",
                "ancien": 1355,
                "nouveau": 1429,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50943,
                "typeIndexe": "energie",
                "ancien": 1288,
                "nouveau": 1355,
                "periode": "2023-08-01",
                "avance": 398,
                "reste": 6972,
                "datePaiement": "2023-12-26T11:29:56.312Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50815,
                "typeIndexe": "energie",
                "ancien": 1228,
                "nouveau": 1288,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:42.343Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50679,
                "typeIndexe": "energie",
                "ancien": 1165,
                "nouveau": 1228,
                "periode": "2023-06-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-09-30T07:38:37.448Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 50544,
                "typeIndexe": "energie",
                "ancien": 1085,
                "nouveau": 1165,
                "periode": "2023-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:53.134Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 75,
                "montant": 8250,
                "idIndexe": 50400,
                "typeIndexe": "energie",
                "ancien": 1010,
                "nouveau": 1085,
                "periode": "2023-04-01",
                "avance": 8250,
                "reste": 0,
                "datePaiement": "2023-06-28T15:55:47.257Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 71,
                "montant": 7810,
                "idIndexe": 50273,
                "typeIndexe": "energie",
                "ancien": 939,
                "nouveau": 1010,
                "periode": "2023-03-01",
                "avance": 7810,
                "reste": 0,
                "datePaiement": "2023-04-28T14:47:39.249Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50150,
                "typeIndexe": "energie",
                "ancien": 869,
                "nouveau": 939,
                "periode": "2023-02-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-04-28T15:36:27.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50029,
                "typeIndexe": "energie",
                "ancien": 835,
                "nouveau": 869,
                "periode": "2023-01-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-03-29T08:25:28.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 49915,
                "typeIndexe": "energie",
                "ancien": 778,
                "nouveau": 835,
                "periode": "2022-12-01",
                "avance": 6270,
                "reste": 0,
                "datePaiement": "2023-02-22T11:43:44.222Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 62,
                "idOccupation": 382,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2643,
            "energie": -1579
        },
        "montant": {
            "eau": -1318857,
            "energie": -173690
        }
    },
    {
        "idLogement": 63,
        "refLogement": "C021",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 40000,
        "prixMax": 45000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.484Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 4,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 4,
            "libelleSousType": "Chambres modernes",
            "descSousType": "CHM",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51350,
                "typeIndexe": "eau",
                "ancien": 1900,
                "nouveau": 1915,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51351,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 7559,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 51224,
                "typeIndexe": "eau",
                "ancien": 1885,
                "nouveau": 1900,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51225,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 51084,
                "typeIndexe": "eau",
                "ancien": 1843,
                "nouveau": 1885,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51085,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50944,
                "typeIndexe": "eau",
                "ancien": 1843,
                "nouveau": 1843,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50945,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50817,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50816,
                "typeIndexe": "eau",
                "ancien": 1830,
                "nouveau": 1843,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50681,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 26,
                "montant": 12974,
                "idIndexe": 50680,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1830,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50545,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1804,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50546,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1804,
                "montant": 900196,
                "idIndexe": 50401,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 1804,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1878,
                "montant": 206580,
                "idIndexe": 50402,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1878,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50151,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50152,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50031,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50030,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49795,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 10978,
                "idIndexe": 49794,
                "typeIndexe": "eau",
                "ancien": 1698,
                "nouveau": 1720,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49656,
                "typeIndexe": "eau",
                "ancien": 1698,
                "nouveau": 1698,
                "periode": "2022-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49657,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2022-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51350,
            "typeIndexe": "eau",
            "ancien": 1900,
            "nouveau": 1900,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 63,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51351,
            "typeIndexe": "energie",
            "ancien": 1878,
            "nouveau": 1878,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 63,
            "idOccupation": null,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1900,
                1900
            ],
            "energie": [
                1878,
                1878
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1900,
                1900
            ],
            "energie": [
                1878,
                1878
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 51224,
                "typeIndexe": "eau",
                "ancien": 1885,
                "nouveau": 1900,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 51084,
                "typeIndexe": "eau",
                "ancien": 1843,
                "nouveau": 1885,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50944,
                "typeIndexe": "eau",
                "ancien": 1843,
                "nouveau": 1843,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 6487,
                "idIndexe": 50816,
                "typeIndexe": "eau",
                "ancien": 1830,
                "nouveau": 1843,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 26,
                "montant": 12974,
                "idIndexe": 50680,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1830,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50545,
                "typeIndexe": "eau",
                "ancien": 1804,
                "nouveau": 1804,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1804,
                "montant": 900196,
                "idIndexe": 50401,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 1804,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 10978,
                "idIndexe": 49794,
                "typeIndexe": "eau",
                "ancien": 1698,
                "nouveau": 1720,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49656,
                "typeIndexe": "eau",
                "ancien": 1698,
                "nouveau": 1698,
                "periode": "2022-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51225,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51085,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50945,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50817,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50681,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50546,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1878,
                "montant": 206580,
                "idIndexe": 50402,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1878,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49795,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49657,
                "typeIndexe": "energie",
                "ancien": 1878,
                "nouveau": 1878,
                "periode": "2022-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 63,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1900,
            "energie": -1878
        },
        "montant": {
            "eau": -948100,
            "energie": -206580
        }
    },
    {
        "idLogement": 64,
        "refLogement": "C022",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 80000,
        "prixMax": 90000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.913Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 22,
                "montant": 10978,
                "idIndexe": 51352,
                "typeIndexe": "eau",
                "ancien": 2123,
                "nouveau": 2517,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 51353,
                "typeIndexe": "energie",
                "ancien": 5328,
                "nouveau": 5376,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 51227,
                "typeIndexe": "energie",
                "ancien": 5264,
                "nouveau": 5328,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51226,
                "typeIndexe": "eau",
                "ancien": 2113,
                "nouveau": 2123,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 51087,
                "typeIndexe": "energie",
                "ancien": 5218,
                "nouveau": 5264,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 51086,
                "typeIndexe": "eau",
                "ancien": 2099,
                "nouveau": 2113,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50947,
                "typeIndexe": "energie",
                "ancien": 5146,
                "nouveau": 5218,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50946,
                "typeIndexe": "eau",
                "ancien": 2089,
                "nouveau": 2099,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.181Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50818,
                "typeIndexe": "eau",
                "ancien": 2069,
                "nouveau": 2089,
                "periode": "2023-07-01",
                "avance": 1700,
                "reste": 8280,
                "datePaiement": "2023-09-28T17:20:05.647Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50819,
                "typeIndexe": "energie",
                "ancien": 5062,
                "nouveau": 5146,
                "periode": "2023-07-01",
                "avance": 2730,
                "reste": 6510,
                "datePaiement": "2023-12-26T11:30:42.391Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50683,
                "typeIndexe": "energie",
                "ancien": 4983,
                "nouveau": 5062,
                "periode": "2023-06-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-12-26T11:30:25.818Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50682,
                "typeIndexe": "eau",
                "ancien": 2049,
                "nouveau": 2069,
                "periode": "2023-06-01",
                "avance": 1537,
                "reste": 8443,
                "datePaiement": "2023-11-27T16:02:39.220Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 50548,
                "typeIndexe": "energie",
                "ancien": 4905,
                "nouveau": 4983,
                "periode": "2023-05-01",
                "avance": 8580,
                "reste": 0,
                "datePaiement": "2023-12-26T11:30:25.814Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50547,
                "typeIndexe": "eau",
                "ancien": 2029,
                "nouveau": 2049,
                "periode": "2023-05-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-11-27T16:02:16.007Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50403,
                "typeIndexe": "eau",
                "ancien": 2012,
                "nouveau": 2029,
                "periode": "2023-04-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-11-27T16:02:16.003Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50404,
                "typeIndexe": "energie",
                "ancien": 4833,
                "nouveau": 4905,
                "periode": "2023-04-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.179Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50274,
                "typeIndexe": "eau",
                "ancien": 1996,
                "nouveau": 2012,
                "periode": "2023-03-01",
                "avance": 6954,
                "reste": 1030,
                "datePaiement": "2023-09-28T06:52:54.051Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50275,
                "typeIndexe": "energie",
                "ancien": 4784,
                "nouveau": 4833,
                "periode": "2023-03-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.148Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50153,
                "typeIndexe": "eau",
                "ancien": 1980,
                "nouveau": 1996,
                "periode": "2023-02-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-06-30T13:57:42.478Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50154,
                "typeIndexe": "energie",
                "ancien": 4741,
                "nouveau": 4784,
                "periode": "2023-02-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-08-30T07:04:41.689Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50032,
                "typeIndexe": "eau",
                "ancien": 960,
                "nouveau": 980,
                "periode": "2023-01-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.808Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50033,
                "typeIndexe": "energie",
                "ancien": 4692,
                "nouveau": 4741,
                "periode": "2023-01-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-09-28T06:52:36.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 49917,
                "typeIndexe": "energie",
                "ancien": 4640,
                "nouveau": 4692,
                "periode": "2022-12-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.804Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 49916,
                "typeIndexe": "eau",
                "ancien": 945,
                "nouveau": 960,
                "periode": "2022-12-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 22,
            "montant": 10978,
            "idIndexe": 51352,
            "typeIndexe": "eau",
            "ancien": 2123,
            "nouveau": 2145,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 64,
            "idOccupation": 289,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 48,
            "montant": 5280,
            "idIndexe": 51353,
            "typeIndexe": "energie",
            "ancien": 5328,
            "nouveau": 5376,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 64,
            "idOccupation": 289,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 289,
            "loyerBase": 80000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-12-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.912Z",
            "idLocataire": 21,
            "idLogement": 64,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 21,
                "nomLocataire": "MBARGA ONDOA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "671185821",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.366Z",
                "updatedAt": "2022-03-02T11:54:44.366Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                2145,
                2123
            ],
            "energie": [
                5376,
                5328
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                2145,
                2123
            ],
            "energie": [
                5376,
                5328
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51226,
                "typeIndexe": "eau",
                "ancien": 2113,
                "nouveau": 2123,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 51086,
                "typeIndexe": "eau",
                "ancien": 2099,
                "nouveau": 2113,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50946,
                "typeIndexe": "eau",
                "ancien": 2089,
                "nouveau": 2099,
                "periode": "2023-08-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.181Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50818,
                "typeIndexe": "eau",
                "ancien": 2069,
                "nouveau": 2089,
                "periode": "2023-07-01",
                "avance": 1700,
                "reste": 8280,
                "datePaiement": "2023-09-28T17:20:05.647Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50682,
                "typeIndexe": "eau",
                "ancien": 2049,
                "nouveau": 2069,
                "periode": "2023-06-01",
                "avance": 1537,
                "reste": 8443,
                "datePaiement": "2023-11-27T16:02:39.220Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50547,
                "typeIndexe": "eau",
                "ancien": 2029,
                "nouveau": 2049,
                "periode": "2023-05-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-11-27T16:02:16.007Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 8483,
                "idIndexe": 50403,
                "typeIndexe": "eau",
                "ancien": 2012,
                "nouveau": 2029,
                "periode": "2023-04-01",
                "avance": 8483,
                "reste": 0,
                "datePaiement": "2023-11-27T16:02:16.003Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50274,
                "typeIndexe": "eau",
                "ancien": 1996,
                "nouveau": 2012,
                "periode": "2023-03-01",
                "avance": 6954,
                "reste": 1030,
                "datePaiement": "2023-09-28T06:52:54.051Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50153,
                "typeIndexe": "eau",
                "ancien": 1980,
                "nouveau": 1996,
                "periode": "2023-02-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-06-30T13:57:42.478Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 9980,
                "idIndexe": 50032,
                "typeIndexe": "eau",
                "ancien": 960,
                "nouveau": 980,
                "periode": "2023-01-01",
                "avance": 9980,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.808Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 49916,
                "typeIndexe": "eau",
                "ancien": 945,
                "nouveau": 960,
                "periode": "2022-12-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.801Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 51227,
                "typeIndexe": "energie",
                "ancien": 5264,
                "nouveau": 5328,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 51087,
                "typeIndexe": "energie",
                "ancien": 5218,
                "nouveau": 5264,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50947,
                "typeIndexe": "energie",
                "ancien": 5146,
                "nouveau": 5218,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 84,
                "montant": 9240,
                "idIndexe": 50819,
                "typeIndexe": "energie",
                "ancien": 5062,
                "nouveau": 5146,
                "periode": "2023-07-01",
                "avance": 2730,
                "reste": 6510,
                "datePaiement": "2023-12-26T11:30:42.391Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50683,
                "typeIndexe": "energie",
                "ancien": 4983,
                "nouveau": 5062,
                "periode": "2023-06-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-12-26T11:30:25.818Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 50548,
                "typeIndexe": "energie",
                "ancien": 4905,
                "nouveau": 4983,
                "periode": "2023-05-01",
                "avance": 8580,
                "reste": 0,
                "datePaiement": "2023-12-26T11:30:25.814Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50404,
                "typeIndexe": "energie",
                "ancien": 4833,
                "nouveau": 4905,
                "periode": "2023-04-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.179Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50275,
                "typeIndexe": "energie",
                "ancien": 4784,
                "nouveau": 4833,
                "periode": "2023-03-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-09-28T17:19:38.148Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 50154,
                "typeIndexe": "energie",
                "ancien": 4741,
                "nouveau": 4784,
                "periode": "2023-02-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2023-08-30T07:04:41.689Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 5390,
                "idIndexe": 50033,
                "typeIndexe": "energie",
                "ancien": 4692,
                "nouveau": 4741,
                "periode": "2023-01-01",
                "avance": 5390,
                "reste": 0,
                "datePaiement": "2023-09-28T06:52:36.753Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 49917,
                "typeIndexe": "energie",
                "ancien": 4640,
                "nouveau": 4692,
                "periode": "2022-12-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-06-27T10:26:29.804Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 64,
                "idOccupation": 289,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -2145,
            "energie": -5376
        },
        "montant": {
            "eau": -1070355,
            "energie": -591360
        }
    },
    {
        "idLogement": 67,
        "refLogement": "C025",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 40000,
        "prixMax": 50000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:20.050Z",
        "idBatiment": 3,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51354,
                "typeIndexe": "eau",
                "ancien": 626,
                "nouveau": 631,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 51355,
                "typeIndexe": "energie",
                "ancien": 2053,
                "nouveau": 2132,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51228,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 626,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T10:12:11.082Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 51229,
                "typeIndexe": "energie",
                "ancien": 1974,
                "nouveau": 2053,
                "periode": "2023-10-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-11-27T14:26:39.083Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51089,
                "typeIndexe": "energie",
                "ancien": 1906,
                "nouveau": 1974,
                "periode": "2023-09-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:03.913Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51088,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 621,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:03.908Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 91,
                "montant": 10010,
                "idIndexe": 50949,
                "typeIndexe": "energie",
                "ancien": 1815,
                "nouveau": 1906,
                "periode": "2023-08-01",
                "avance": 8342,
                "reste": 1668,
                "datePaiement": "2023-11-27T18:56:00.139Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50948,
                "typeIndexe": "eau",
                "ancien": 612,
                "nouveau": 616,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.449Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50820,
                "typeIndexe": "eau",
                "ancien": 608,
                "nouveau": 612,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.444Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50821,
                "typeIndexe": "energie",
                "ancien": 1762,
                "nouveau": 1815,
                "periode": "2023-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50684,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 608,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.439Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50685,
                "typeIndexe": "energie",
                "ancien": 1683,
                "nouveau": 1762,
                "periode": "2023-06-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50550,
                "typeIndexe": "energie",
                "ancien": 1623,
                "nouveau": 1683,
                "periode": "2023-05-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50549,
                "typeIndexe": "eau",
                "ancien": 602,
                "nouveau": 604,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T10:12:31.867Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50405,
                "typeIndexe": "eau",
                "ancien": 598,
                "nouveau": 602,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:07:37.137Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50406,
                "typeIndexe": "energie",
                "ancien": 1559,
                "nouveau": 1623,
                "periode": "2023-04-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-08-30T07:07:37.140Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50280,
                "typeIndexe": "energie",
                "ancien": 1495,
                "nouveau": 1559,
                "periode": "2023-03-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-07-26T14:53:16.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50276,
                "typeIndexe": "eau",
                "ancien": 594,
                "nouveau": 598,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T14:53:16.962Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50156,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1495,
                "periode": "2023-02-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-06-27T10:34:17.643Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50155,
                "typeIndexe": "eau",
                "ancien": 589,
                "nouveau": 594,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:34:17.640Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 50035,
                "typeIndexe": "energie",
                "ancien": 1355,
                "nouveau": 1429,
                "periode": "2023-01-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.561Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50034,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 589,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.559Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49918,
                "typeIndexe": "eau",
                "ancien": 579,
                "nouveau": 584,
                "periode": "2022-12-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-22T11:47:29.674Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 75,
                "montant": 8250,
                "idIndexe": 49919,
                "typeIndexe": "energie",
                "ancien": 1280,
                "nouveau": 1355,
                "periode": "2022-12-01",
                "avance": 8250,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.556Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51354,
            "typeIndexe": "eau",
            "ancien": 626,
            "nouveau": 631,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 67,
            "idOccupation": 326,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 79,
            "montant": 8690,
            "idIndexe": 51355,
            "typeIndexe": "energie",
            "ancien": 2053,
            "nouveau": 2132,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 67,
            "idOccupation": 326,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 326,
            "loyerBase": 40000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-10-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.049Z",
            "idLocataire": 24,
            "idLogement": 67,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 24,
                "nomLocataire": "MAKOKOU",
                "prenomLocataire": "IYANGA",
                "cniLocataire": "",
                "tel": "696835568",
                "tel2": "676332755",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.398Z",
                "updatedAt": "2022-03-02T11:54:44.398Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                631,
                626
            ],
            "energie": [
                2132,
                2053
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                631,
                626
            ],
            "energie": [
                2132,
                2053
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51228,
                "typeIndexe": "eau",
                "ancien": 621,
                "nouveau": 626,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T10:12:11.082Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51088,
                "typeIndexe": "eau",
                "ancien": 616,
                "nouveau": 621,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:03.908Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50948,
                "typeIndexe": "eau",
                "ancien": 612,
                "nouveau": 616,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.449Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50820,
                "typeIndexe": "eau",
                "ancien": 608,
                "nouveau": 612,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.444Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50684,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 608,
                "periode": "2023-06-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.439Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50549,
                "typeIndexe": "eau",
                "ancien": 602,
                "nouveau": 604,
                "periode": "2023-05-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-11-25T10:12:31.867Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50405,
                "typeIndexe": "eau",
                "ancien": 598,
                "nouveau": 602,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:07:37.137Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50276,
                "typeIndexe": "eau",
                "ancien": 594,
                "nouveau": 598,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T14:53:16.962Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50155,
                "typeIndexe": "eau",
                "ancien": 589,
                "nouveau": 594,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:34:17.640Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50034,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 589,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.559Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 49918,
                "typeIndexe": "eau",
                "ancien": 579,
                "nouveau": 584,
                "periode": "2022-12-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-02-22T11:47:29.674Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 51229,
                "typeIndexe": "energie",
                "ancien": 1974,
                "nouveau": 2053,
                "periode": "2023-10-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-11-27T14:26:39.083Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51089,
                "typeIndexe": "energie",
                "ancien": 1906,
                "nouveau": 1974,
                "periode": "2023-09-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:03.913Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 91,
                "montant": 10010,
                "idIndexe": 50949,
                "typeIndexe": "energie",
                "ancien": 1815,
                "nouveau": 1906,
                "periode": "2023-08-01",
                "avance": 8342,
                "reste": 1668,
                "datePaiement": "2023-11-27T18:56:00.139Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 53,
                "montant": 5830,
                "idIndexe": 50821,
                "typeIndexe": "energie",
                "ancien": 1762,
                "nouveau": 1815,
                "periode": "2023-07-01",
                "avance": 5830,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50685,
                "typeIndexe": "energie",
                "ancien": 1683,
                "nouveau": 1762,
                "periode": "2023-06-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.442Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50550,
                "typeIndexe": "energie",
                "ancien": 1623,
                "nouveau": 1683,
                "periode": "2023-05-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-09-28T06:56:48.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50406,
                "typeIndexe": "energie",
                "ancien": 1559,
                "nouveau": 1623,
                "periode": "2023-04-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-08-30T07:07:37.140Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50280,
                "typeIndexe": "energie",
                "ancien": 1495,
                "nouveau": 1559,
                "periode": "2023-03-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-07-26T14:53:16.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50156,
                "typeIndexe": "energie",
                "ancien": 1429,
                "nouveau": 1495,
                "periode": "2023-02-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-06-27T10:34:17.643Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 74,
                "montant": 8140,
                "idIndexe": 50035,
                "typeIndexe": "energie",
                "ancien": 1355,
                "nouveau": 1429,
                "periode": "2023-01-01",
                "avance": 8140,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.561Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 75,
                "montant": 8250,
                "idIndexe": 49919,
                "typeIndexe": "energie",
                "ancien": 1280,
                "nouveau": 1355,
                "periode": "2022-12-01",
                "avance": 8250,
                "reste": 0,
                "datePaiement": "2023-02-27T19:23:28.556Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 67,
                "idOccupation": 326,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -631,
            "energie": -2132
        },
        "montant": {
            "eau": -314869,
            "energie": -234520
        }
    },
    {
        "idLogement": 109,
        "refLogement": "D001",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T15:04:59.443Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 5,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 5,
            "libelleSousType": "Studios simples",
            "descSousType": "STD",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 51357,
                "typeIndexe": "energie",
                "ancien": 479,
                "nouveau": 536,
                "periode": "2023-11-01",
                "avance": 6270,
                "reste": 0,
                "datePaiement": "2023-12-26T11:32:25.707Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51356,
                "typeIndexe": "eau",
                "ancien": 1485,
                "nouveau": 1475,
                "periode": "2023-11-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:32:25.703Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 51231,
                "typeIndexe": "energie",
                "ancien": 401,
                "nouveau": 479,
                "periode": "2023-10-01",
                "avance": 8580,
                "reste": 0,
                "datePaiement": "2023-12-26T11:32:38.040Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51230,
                "typeIndexe": "eau",
                "ancien": 1478,
                "nouveau": 1485,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-25T08:26:25.422Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51091,
                "typeIndexe": "energie",
                "ancien": 333,
                "nouveau": 401,
                "periode": "2023-09-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:54.215Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51090,
                "typeIndexe": "eau",
                "ancien": 1471,
                "nouveau": 1478,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:54.211Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50950,
                "typeIndexe": "eau",
                "ancien": 1464,
                "nouveau": 1471,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T06:57:40.660Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 76,
                "montant": 8360,
                "idIndexe": 50951,
                "typeIndexe": "energie",
                "ancien": 257,
                "nouveau": 333,
                "periode": "2023-08-01",
                "avance": 8360,
                "reste": 0,
                "datePaiement": "2023-09-28T06:57:40.662Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50822,
                "typeIndexe": "eau",
                "ancien": 1450,
                "nouveau": 1464,
                "periode": "2023-07-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-08-30T07:09:04.497Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50823,
                "typeIndexe": "energie",
                "ancien": 189,
                "nouveau": 257,
                "periode": "2023-07-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-08-30T07:08:51.643Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50689,
                "typeIndexe": "energie",
                "ancien": 125,
                "nouveau": 189,
                "periode": "2023-06-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-07-26T14:56:31.250Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50688,
                "typeIndexe": "eau",
                "ancien": 1445,
                "nouveau": 1450,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:56:31.254Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 50552,
                "typeIndexe": "energie",
                "ancien": 68,
                "nouveau": 125,
                "periode": "2023-05-01",
                "avance": 6270,
                "reste": 0,
                "datePaiement": "2023-06-27T10:35:42.815Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50551,
                "typeIndexe": "eau",
                "ancien": 1437,
                "nouveau": 1445,
                "periode": "2023-05-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-06-27T10:35:42.811Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50407,
                "typeIndexe": "eau",
                "ancien": 1432,
                "nouveau": 1437,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T11:06:05.675Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 50408,
                "typeIndexe": "energie",
                "ancien": 16,
                "nouveau": 68,
                "periode": "2023-04-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-05-30T11:06:14.845Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50284,
                "typeIndexe": "eau",
                "ancien": 1428,
                "nouveau": 1432,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-28T15:43:47.213Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50285,
                "typeIndexe": "energie",
                "ancien": -16,
                "nouveau": 16,
                "periode": "2023-03-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-04-28T15:43:47.217Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50157,
                "typeIndexe": "eau",
                "ancien": 1420,
                "nouveau": 1428,
                "periode": "2023-02-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-03-29T08:28:58.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 8690,
                "idIndexe": 50158,
                "typeIndexe": "energie",
                "ancien": 4001,
                "nouveau": 4001,
                "periode": "2023-02-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-03-29T08:28:58.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50037,
                "typeIndexe": "energie",
                "ancien": 3922,
                "nouveau": 4001,
                "periode": "2023-01-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-02-23T07:16:19.732Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50036,
                "typeIndexe": "eau",
                "ancien": 1411,
                "nouveau": 1420,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-23T07:16:19.729Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49920,
                "typeIndexe": "eau",
                "ancien": 1403,
                "nouveau": 1411,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-02-22T11:48:29.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 49921,
                "typeIndexe": "energie",
                "ancien": 3872,
                "nouveau": 3922,
                "periode": "2022-12-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-02-22T11:48:29.378Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51356,
            "typeIndexe": "eau",
            "ancien": 1485,
            "nouveau": 1489,
            "periode": "2023-11-01",
            "avance": 1996,
            "reste": 0,
            "datePaiement": "2023-12-26T11:32:25.703Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 109,
            "idOccupation": 288,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 57,
            "montant": 6270,
            "idIndexe": 51357,
            "typeIndexe": "energie",
            "ancien": 479,
            "nouveau": 536,
            "periode": "2023-11-01",
            "avance": 6270,
            "reste": 0,
            "datePaiement": "2023-12-26T11:32:25.707Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 109,
            "idOccupation": 288,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 288,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-11-29T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.929Z",
            "idLocataire": 203,
            "idLogement": 109,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 203,
                "nomLocataire": "FOUSSA",
                "prenomLocataire": "Yannick",
                "cniLocataire": "",
                "tel": "694150967",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.810Z",
                "updatedAt": "2022-03-02T11:54:45.810Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1489,
                1485
            ],
            "energie": [
                536,
                479
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1489,
                1485
            ],
            "energie": [
                536,
                479
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51230,
                "typeIndexe": "eau",
                "ancien": 1478,
                "nouveau": 1485,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-25T08:26:25.422Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51090,
                "typeIndexe": "eau",
                "ancien": 1471,
                "nouveau": 1478,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:54.211Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50950,
                "typeIndexe": "eau",
                "ancien": 1464,
                "nouveau": 1471,
                "periode": "2023-08-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-09-28T06:57:40.660Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50822,
                "typeIndexe": "eau",
                "ancien": 1450,
                "nouveau": 1464,
                "periode": "2023-07-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-08-30T07:09:04.497Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50688,
                "typeIndexe": "eau",
                "ancien": 1445,
                "nouveau": 1450,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T14:56:31.254Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50551,
                "typeIndexe": "eau",
                "ancien": 1437,
                "nouveau": 1445,
                "periode": "2023-05-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-06-27T10:35:42.811Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50407,
                "typeIndexe": "eau",
                "ancien": 1432,
                "nouveau": 1437,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T11:06:05.675Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50284,
                "typeIndexe": "eau",
                "ancien": 1428,
                "nouveau": 1432,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-04-28T15:43:47.213Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50157,
                "typeIndexe": "eau",
                "ancien": 1420,
                "nouveau": 1428,
                "periode": "2023-02-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-03-29T08:28:58.725Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50036,
                "typeIndexe": "eau",
                "ancien": 1411,
                "nouveau": 1420,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-02-23T07:16:19.729Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 49920,
                "typeIndexe": "eau",
                "ancien": 1403,
                "nouveau": 1411,
                "periode": "2022-12-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-02-22T11:48:29.375Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 78,
                "montant": 8580,
                "idIndexe": 51231,
                "typeIndexe": "energie",
                "ancien": 401,
                "nouveau": 479,
                "periode": "2023-10-01",
                "avance": 8580,
                "reste": 0,
                "datePaiement": "2023-12-26T11:32:38.040Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 51091,
                "typeIndexe": "energie",
                "ancien": 333,
                "nouveau": 401,
                "periode": "2023-09-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-10-28T15:17:54.215Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 76,
                "montant": 8360,
                "idIndexe": 50951,
                "typeIndexe": "energie",
                "ancien": 257,
                "nouveau": 333,
                "periode": "2023-08-01",
                "avance": 8360,
                "reste": 0,
                "datePaiement": "2023-09-28T06:57:40.662Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50823,
                "typeIndexe": "energie",
                "ancien": 189,
                "nouveau": 257,
                "periode": "2023-07-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-08-30T07:08:51.643Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 64,
                "montant": 7040,
                "idIndexe": 50689,
                "typeIndexe": "energie",
                "ancien": 125,
                "nouveau": 189,
                "periode": "2023-06-01",
                "avance": 7040,
                "reste": 0,
                "datePaiement": "2023-07-26T14:56:31.250Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 57,
                "montant": 6270,
                "idIndexe": 50552,
                "typeIndexe": "energie",
                "ancien": 68,
                "nouveau": 125,
                "periode": "2023-05-01",
                "avance": 6270,
                "reste": 0,
                "datePaiement": "2023-06-27T10:35:42.815Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 52,
                "montant": 5720,
                "idIndexe": 50408,
                "typeIndexe": "energie",
                "ancien": 16,
                "nouveau": 68,
                "periode": "2023-04-01",
                "avance": 5720,
                "reste": 0,
                "datePaiement": "2023-05-30T11:06:14.845Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50285,
                "typeIndexe": "energie",
                "ancien": -16,
                "nouveau": 16,
                "periode": "2023-03-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-04-28T15:43:47.217Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 8690,
                "idIndexe": 50158,
                "typeIndexe": "energie",
                "ancien": 4001,
                "nouveau": 4001,
                "periode": "2023-02-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-03-29T08:28:58.727Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 79,
                "montant": 8690,
                "idIndexe": 50037,
                "typeIndexe": "energie",
                "ancien": 3922,
                "nouveau": 4001,
                "periode": "2023-01-01",
                "avance": 8690,
                "reste": 0,
                "datePaiement": "2023-02-23T07:16:19.732Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 49921,
                "typeIndexe": "energie",
                "ancien": 3872,
                "nouveau": 3922,
                "periode": "2022-12-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-02-22T11:48:29.378Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 109,
                "idOccupation": 288,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1489,
            "energie": -536
        },
        "montant": {
            "eau": -743011,
            "energie": -58960
        }
    },
    {
        "idLogement": 87,
        "refLogement": "D002",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-09-28T20:32:04.586Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 51359,
                "typeIndexe": "energie",
                "ancien": 739,
                "nouveau": 785,
                "periode": "2023-11-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": "2023-12-26T11:34:17.059Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 110,
                "idIndexe": 51358,
                "typeIndexe": "eau",
                "ancien": 1033,
                "nouveau": 1034,
                "periode": "2023-11-01",
                "avance": 110,
                "reste": 0,
                "datePaiement": "2023-12-26T11:33:17.065Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51232,
                "typeIndexe": "eau",
                "ancien": 1031,
                "nouveau": 1033,
                "periode": "2023-10-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-25T08:38:50.061Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 51233,
                "typeIndexe": "energie",
                "ancien": 688,
                "nouveau": 739,
                "periode": "2023-10-01",
                "avance": 5610,
                "reste": 0,
                "datePaiement": "2023-11-25T08:40:04.222Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 2420,
                "idIndexe": 51093,
                "typeIndexe": "energie",
                "ancien": 666,
                "nouveau": 688,
                "periode": "2023-09-01",
                "avance": 2420,
                "reste": 0,
                "datePaiement": "2023-10-28T15:35:25.369Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51092,
                "typeIndexe": "eau",
                "ancien": 1019,
                "nouveau": 1031,
                "periode": "2023-09-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-10-28T15:33:40.998Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50952,
                "typeIndexe": "eau",
                "ancien": 1015,
                "nouveau": 1019,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50953,
                "typeIndexe": "energie",
                "ancien": 652,
                "nouveau": 666,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50824,
                "typeIndexe": "eau",
                "ancien": 1009,
                "nouveau": 1015,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50825,
                "typeIndexe": "energie",
                "ancien": 611,
                "nouveau": 652,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 109,
                "montant": 11990,
                "idIndexe": 50691,
                "typeIndexe": "energie",
                "ancien": 502,
                "nouveau": 611,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50690,
                "typeIndexe": "eau",
                "ancien": 1002,
                "nouveau": 1009,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50553,
                "typeIndexe": "eau",
                "ancien": 993,
                "nouveau": 1002,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 139,
                "montant": 15290,
                "idIndexe": 50554,
                "typeIndexe": "energie",
                "ancien": 363,
                "nouveau": 502,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50409,
                "typeIndexe": "eau",
                "ancien": 985,
                "nouveau": 993,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 139,
                "montant": 15290,
                "idIndexe": 50410,
                "typeIndexe": "energie",
                "ancien": 224,
                "nouveau": 363,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50287,
                "typeIndexe": "energie",
                "ancien": 190,
                "nouveau": 224,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50286,
                "typeIndexe": "eau",
                "ancien": 977,
                "nouveau": 985,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50159,
                "typeIndexe": "eau",
                "ancien": 971,
                "nouveau": 977,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.286Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50160,
                "typeIndexe": "energie",
                "ancien": 160,
                "nouveau": 190,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50038,
                "typeIndexe": "eau",
                "ancien": 962,
                "nouveau": 971,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.279Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50039,
                "typeIndexe": "energie",
                "ancien": 2939,
                "nouveau": 2980,
                "periode": "2023-01-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.282Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 49922,
                "typeIndexe": "eau",
                "ancien": 953,
                "nouveau": 962,
                "periode": "2022-12-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-27T10:39:35.057Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49923,
                "typeIndexe": "energie",
                "ancien": 2914,
                "nouveau": 2939,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.276Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 110,
            "idIndexe": 51358,
            "typeIndexe": "eau",
            "ancien": 1033,
            "nouveau": 1034,
            "periode": "2023-11-01",
            "avance": 110,
            "reste": 0,
            "datePaiement": "2023-12-26T11:33:17.065Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 87,
            "idOccupation": 94,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 46,
            "montant": 5060,
            "idIndexe": 51359,
            "typeIndexe": "energie",
            "ancien": 739,
            "nouveau": 785,
            "periode": "2023-11-01",
            "avance": 5060,
            "reste": 0,
            "datePaiement": "2023-12-26T11:34:17.059Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 87,
            "idOccupation": 94,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 94,
            "loyerBase": 50000,
            "caution": 150000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-09-09T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 499,
            "puEau": 110,
            "dureeBail": 0,
            "createdAt": "2023-09-28T20:32:03.799Z",
            "idLocataire": 413,
            "idLogement": 87,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 413,
                "nomLocataire": "MASCHUER",
                "prenomLocataire": "Rodrigue Charly",
                "cniLocataire": "",
                "tel": "697886766",
                "tel2": "692607154",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-09-28T19:54:21.444Z",
                "updatedAt": "2023-09-28T19:54:21.444Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1034,
                1033
            ],
            "energie": [
                785,
                739
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1034,
                1033
            ],
            "energie": [
                785,
                739
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 2,
                "montant": 220,
                "idIndexe": 51232,
                "typeIndexe": "eau",
                "ancien": 1031,
                "nouveau": 1033,
                "periode": "2023-10-01",
                "avance": 220,
                "reste": 0,
                "datePaiement": "2023-11-25T08:38:50.061Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 51092,
                "typeIndexe": "eau",
                "ancien": 1019,
                "nouveau": 1031,
                "periode": "2023-09-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-10-28T15:33:40.998Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50952,
                "typeIndexe": "eau",
                "ancien": 1015,
                "nouveau": 1019,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50824,
                "typeIndexe": "eau",
                "ancien": 1009,
                "nouveau": 1015,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50690,
                "typeIndexe": "eau",
                "ancien": 1002,
                "nouveau": 1009,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50553,
                "typeIndexe": "eau",
                "ancien": 993,
                "nouveau": 1002,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50409,
                "typeIndexe": "eau",
                "ancien": 985,
                "nouveau": 993,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 50286,
                "typeIndexe": "eau",
                "ancien": 977,
                "nouveau": 985,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50159,
                "typeIndexe": "eau",
                "ancien": 971,
                "nouveau": 977,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.286Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 50038,
                "typeIndexe": "eau",
                "ancien": 962,
                "nouveau": 971,
                "periode": "2023-01-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.279Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 49922,
                "typeIndexe": "eau",
                "ancien": 953,
                "nouveau": 962,
                "periode": "2022-12-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-06-27T10:39:35.057Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 51,
                "montant": 5610,
                "idIndexe": 51233,
                "typeIndexe": "energie",
                "ancien": 688,
                "nouveau": 739,
                "periode": "2023-10-01",
                "avance": 5610,
                "reste": 0,
                "datePaiement": "2023-11-25T08:40:04.222Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 22,
                "montant": 2420,
                "idIndexe": 51093,
                "typeIndexe": "energie",
                "ancien": 666,
                "nouveau": 688,
                "periode": "2023-09-01",
                "avance": 2420,
                "reste": 0,
                "datePaiement": "2023-10-28T15:35:25.369Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 94,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 1540,
                "idIndexe": 50953,
                "typeIndexe": "energie",
                "ancien": 652,
                "nouveau": 666,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50825,
                "typeIndexe": "energie",
                "ancien": 611,
                "nouveau": 652,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 109,
                "montant": 11990,
                "idIndexe": 50691,
                "typeIndexe": "energie",
                "ancien": 502,
                "nouveau": 611,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 139,
                "montant": 15290,
                "idIndexe": 50554,
                "typeIndexe": "energie",
                "ancien": 363,
                "nouveau": 502,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 139,
                "montant": 15290,
                "idIndexe": 50410,
                "typeIndexe": "energie",
                "ancien": 224,
                "nouveau": 363,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 50287,
                "typeIndexe": "energie",
                "ancien": 190,
                "nouveau": 224,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50160,
                "typeIndexe": "energie",
                "ancien": 160,
                "nouveau": 190,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 41,
                "montant": 4510,
                "idIndexe": 50039,
                "typeIndexe": "energie",
                "ancien": 2939,
                "nouveau": 2980,
                "periode": "2023-01-01",
                "avance": 4510,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.282Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 25,
                "montant": 2750,
                "idIndexe": 49923,
                "typeIndexe": "energie",
                "ancien": 2914,
                "nouveau": 2939,
                "periode": "2022-12-01",
                "avance": 2750,
                "reste": 0,
                "datePaiement": "2023-07-31T11:20:52.276Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 87,
                "idOccupation": 286,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1034,
            "energie": -785
        },
        "montant": {
            "eau": -515966,
            "energie": -86350
        }
    },
    {
        "idLogement": 86,
        "refLogement": "D003",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T15:06:10.521Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 27,
                "montant": 2970,
                "idIndexe": 51361,
                "typeIndexe": "energie",
                "ancien": 6947,
                "nouveau": 6974,
                "periode": "2023-11-01",
                "avance": 2970,
                "reste": 0,
                "datePaiement": "2023-12-26T11:35:44.313Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51360,
                "typeIndexe": "eau",
                "ancien": 940,
                "nouveau": 943,
                "periode": "2023-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:35:44.316Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 51235,
                "typeIndexe": "energie",
                "ancien": 6913,
                "nouveau": 6947,
                "periode": "2023-10-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-11-25T07:49:42.734Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51234,
                "typeIndexe": "eau",
                "ancien": 935,
                "nouveau": 940,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T07:49:42.731Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 51095,
                "typeIndexe": "energie",
                "ancien": 6889,
                "nouveau": 6913,
                "periode": "2023-09-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:08.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51094,
                "typeIndexe": "eau",
                "ancien": 930,
                "nouveau": 935,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:08.074Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50979,
                "typeIndexe": "energie",
                "ancien": 6872,
                "nouveau": 6889,
                "periode": "2023-08-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.136Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50978,
                "typeIndexe": "eau",
                "ancien": 928,
                "nouveau": 930,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.133Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50826,
                "typeIndexe": "eau",
                "ancien": 926,
                "nouveau": 928,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.125Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50827,
                "typeIndexe": "energie",
                "ancien": 6865,
                "nouveau": 6872,
                "periode": "2023-07-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.129Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50692,
                "typeIndexe": "eau",
                "ancien": 921,
                "nouveau": 926,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T15:05:56.593Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50693,
                "typeIndexe": "energie",
                "ancien": 6825,
                "nouveau": 6865,
                "periode": "2023-06-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-07-26T15:05:56.596Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50555,
                "typeIndexe": "eau",
                "ancien": 916,
                "nouveau": 921,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:41:43.820Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50556,
                "typeIndexe": "energie",
                "ancien": 6793,
                "nouveau": 6825,
                "periode": "2023-05-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-06-27T10:41:43.824Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 50412,
                "typeIndexe": "energie",
                "ancien": 6777,
                "nouveau": 6793,
                "periode": "2023-04-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-05-30T11:08:34.555Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50411,
                "typeIndexe": "eau",
                "ancien": 911,
                "nouveau": 916,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T11:08:23.160Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50288,
                "typeIndexe": "eau",
                "ancien": 906,
                "nouveau": 911,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T15:46:33.017Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50289,
                "typeIndexe": "energie",
                "ancien": 6770,
                "nouveau": 6777,
                "periode": "2023-03-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-04-28T15:46:33.020Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50161,
                "typeIndexe": "eau",
                "ancien": 902,
                "nouveau": 906,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:35:26.394Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50162,
                "typeIndexe": "energie",
                "ancien": 6758,
                "nouveau": 6770,
                "periode": "2023-02-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-03-29T08:35:26.398Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50040,
                "typeIndexe": "eau",
                "ancien": 898,
                "nouveau": 902,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:18:29.870Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50041,
                "typeIndexe": "energie",
                "ancien": 6821,
                "nouveau": 6840,
                "periode": "2023-01-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-02-23T07:18:29.874Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49924,
                "typeIndexe": "eau",
                "ancien": 894,
                "nouveau": 898,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-22T11:49:50.108Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 49925,
                "typeIndexe": "energie",
                "ancien": 6803,
                "nouveau": 6821,
                "periode": "2022-12-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-02-22T11:49:50.111Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51360,
            "typeIndexe": "eau",
            "ancien": 940,
            "nouveau": 943,
            "periode": "2023-11-01",
            "avance": 1497,
            "reste": 0,
            "datePaiement": "2023-12-26T11:35:44.316Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 86,
            "idOccupation": 327,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 27,
            "montant": 2970,
            "idIndexe": 51361,
            "typeIndexe": "energie",
            "ancien": 6947,
            "nouveau": 6974,
            "periode": "2023-11-01",
            "avance": 2970,
            "reste": 0,
            "datePaiement": "2023-12-26T11:35:44.313Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 86,
            "idOccupation": 327,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 327,
            "loyerBase": 60000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-10-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.055Z",
            "idLocataire": 296,
            "idLogement": 86,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 296,
                "nomLocataire": "ANGO OWONA",
                "prenomLocataire": "Simon",
                "cniLocataire": "",
                "tel": "675649520",
                "tel2": "697054874",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.583Z",
                "updatedAt": "2022-03-02T11:54:44.583Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                943,
                940
            ],
            "energie": [
                6974,
                6947
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                943,
                940
            ],
            "energie": [
                6974,
                6947
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51234,
                "typeIndexe": "eau",
                "ancien": 935,
                "nouveau": 940,
                "periode": "2023-10-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-11-25T07:49:42.731Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51094,
                "typeIndexe": "eau",
                "ancien": 930,
                "nouveau": 935,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:08.074Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50978,
                "typeIndexe": "eau",
                "ancien": 928,
                "nouveau": 930,
                "periode": "2023-08-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.133Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50826,
                "typeIndexe": "eau",
                "ancien": 926,
                "nouveau": 928,
                "periode": "2023-07-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.125Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50692,
                "typeIndexe": "eau",
                "ancien": 921,
                "nouveau": 926,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T15:05:56.593Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50555,
                "typeIndexe": "eau",
                "ancien": 916,
                "nouveau": 921,
                "periode": "2023-05-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-06-27T10:41:43.820Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50411,
                "typeIndexe": "eau",
                "ancien": 911,
                "nouveau": 916,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-05-30T11:08:23.160Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50288,
                "typeIndexe": "eau",
                "ancien": 906,
                "nouveau": 911,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T15:46:33.017Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50161,
                "typeIndexe": "eau",
                "ancien": 902,
                "nouveau": 906,
                "periode": "2023-02-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-03-29T08:35:26.394Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50040,
                "typeIndexe": "eau",
                "ancien": 898,
                "nouveau": 902,
                "periode": "2023-01-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:18:29.870Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49924,
                "typeIndexe": "eau",
                "ancien": 894,
                "nouveau": 898,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-22T11:49:50.108Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 34,
                "montant": 3740,
                "idIndexe": 51235,
                "typeIndexe": "energie",
                "ancien": 6913,
                "nouveau": 6947,
                "periode": "2023-10-01",
                "avance": 3740,
                "reste": 0,
                "datePaiement": "2023-11-25T07:49:42.734Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 24,
                "montant": 2640,
                "idIndexe": 51095,
                "typeIndexe": "energie",
                "ancien": 6889,
                "nouveau": 6913,
                "periode": "2023-09-01",
                "avance": 2640,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:08.078Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 17,
                "montant": 1870,
                "idIndexe": 50979,
                "typeIndexe": "energie",
                "ancien": 6872,
                "nouveau": 6889,
                "periode": "2023-08-01",
                "avance": 1870,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.136Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50827,
                "typeIndexe": "energie",
                "ancien": 6865,
                "nouveau": 6872,
                "periode": "2023-07-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-09-28T06:58:32.129Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 40,
                "montant": 4400,
                "idIndexe": 50693,
                "typeIndexe": "energie",
                "ancien": 6825,
                "nouveau": 6865,
                "periode": "2023-06-01",
                "avance": 4400,
                "reste": 0,
                "datePaiement": "2023-07-26T15:05:56.596Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50556,
                "typeIndexe": "energie",
                "ancien": 6793,
                "nouveau": 6825,
                "periode": "2023-05-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-06-27T10:41:43.824Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 1760,
                "idIndexe": 50412,
                "typeIndexe": "energie",
                "ancien": 6777,
                "nouveau": 6793,
                "periode": "2023-04-01",
                "avance": 1760,
                "reste": 0,
                "datePaiement": "2023-05-30T11:08:34.555Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 50289,
                "typeIndexe": "energie",
                "ancien": 6770,
                "nouveau": 6777,
                "periode": "2023-03-01",
                "avance": 770,
                "reste": 0,
                "datePaiement": "2023-04-28T15:46:33.020Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 12,
                "montant": 1320,
                "idIndexe": 50162,
                "typeIndexe": "energie",
                "ancien": 6758,
                "nouveau": 6770,
                "periode": "2023-02-01",
                "avance": 1320,
                "reste": 0,
                "datePaiement": "2023-03-29T08:35:26.398Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50041,
                "typeIndexe": "energie",
                "ancien": 6821,
                "nouveau": 6840,
                "periode": "2023-01-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-02-23T07:18:29.874Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 49925,
                "typeIndexe": "energie",
                "ancien": 6803,
                "nouveau": 6821,
                "periode": "2022-12-01",
                "avance": 1980,
                "reste": 0,
                "datePaiement": "2023-02-22T11:49:50.111Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 86,
                "idOccupation": 327,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -943,
            "energie": -6974
        },
        "montant": {
            "eau": -470557,
            "energie": -767140
        }
    },
    {
        "idLogement": 85,
        "refLogement": "D004",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.808Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 51362,
                "typeIndexe": "eau",
                "ancien": 1368,
                "nouveau": 1419,
                "periode": "2023-11-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-12-26T11:36:12.962Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 51363,
                "typeIndexe": "energie",
                "ancien": 1304,
                "nouveau": 1317,
                "periode": "2023-11-01",
                "avance": 1401,
                "reste": 29,
                "datePaiement": "2023-12-26T11:36:28.979Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51236,
                "typeIndexe": "eau",
                "ancien": 1359,
                "nouveau": 1368,
                "periode": "2023-10-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-11-27T16:03:22.089Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 51237,
                "typeIndexe": "energie",
                "ancien": 1291,
                "nouveau": 1304,
                "periode": "2023-10-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2023-11-27T16:03:22.092Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51096,
                "typeIndexe": "eau",
                "ancien": 1357,
                "nouveau": 1359,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:50.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 51097,
                "typeIndexe": "energie",
                "ancien": 1286,
                "nouveau": 1291,
                "periode": "2023-09-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:50.969Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50980,
                "typeIndexe": "eau",
                "ancien": 1341,
                "nouveau": 1357,
                "periode": "2023-08-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-09-28T07:00:58.562Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50981,
                "typeIndexe": "energie",
                "ancien": 1266,
                "nouveau": 1286,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T07:00:58.564Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50828,
                "typeIndexe": "eau",
                "ancien": 1336,
                "nouveau": 1341,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:11:33.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50829,
                "typeIndexe": "energie",
                "ancien": 1263,
                "nouveau": 1266,
                "periode": "2023-07-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-08-30T07:11:33.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50695,
                "typeIndexe": "energie",
                "ancien": 1257,
                "nouveau": 1263,
                "periode": "2023-06-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T07:01:12.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50694,
                "typeIndexe": "eau",
                "ancien": 1326,
                "nouveau": 1336,
                "periode": "2023-06-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-07-26T15:56:17.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 50558,
                "typeIndexe": "energie",
                "ancien": 1247,
                "nouveau": 1257,
                "periode": "2023-05-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-07-26T15:56:38.605Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50557,
                "typeIndexe": "eau",
                "ancien": 1325,
                "nouveau": 1326,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:42:54.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50414,
                "typeIndexe": "energie",
                "ancien": 1236,
                "nouveau": 1247,
                "periode": "2023-04-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-05-30T11:32:54.638Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50413,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1325,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-05-30T11:32:45.636Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50290,
                "typeIndexe": "eau",
                "ancien": 1323,
                "nouveau": 1324,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.935Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50291,
                "typeIndexe": "energie",
                "ancien": 1230,
                "nouveau": 1236,
                "periode": "2023-03-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.938Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50164,
                "typeIndexe": "energie",
                "ancien": 1211,
                "nouveau": 1230,
                "periode": "2023-02-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-04-28T15:49:49.397Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 50163,
                "typeIndexe": "eau",
                "ancien": 1308,
                "nouveau": 1323,
                "periode": "2023-02-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.931Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50043,
                "typeIndexe": "energie",
                "ancien": 1192,
                "nouveau": 1211,
                "periode": "2023-01-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-02-23T07:19:48.587Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50042,
                "typeIndexe": "eau",
                "ancien": 1306,
                "nouveau": 1308,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T07:19:48.584Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 49927,
                "typeIndexe": "energie",
                "ancien": 1147,
                "nouveau": 1192,
                "periode": "2022-12-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-02-22T11:50:20.431Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49926,
                "typeIndexe": "eau",
                "ancien": 1304,
                "nouveau": 1306,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:50:20.428Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 1,
            "montant": 499,
            "idIndexe": 51362,
            "typeIndexe": "eau",
            "ancien": 1368,
            "nouveau": 1369,
            "periode": "2023-11-01",
            "avance": 499,
            "reste": 0,
            "datePaiement": "2023-12-26T11:36:12.962Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 85,
            "idOccupation": 268,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 13,
            "montant": 1430,
            "idIndexe": 51363,
            "typeIndexe": "energie",
            "ancien": 1304,
            "nouveau": 1317,
            "periode": "2023-11-01",
            "avance": 1401,
            "reste": 29,
            "datePaiement": "2023-12-26T11:36:28.979Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 85,
            "idOccupation": 268,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 268,
            "loyerBase": 60000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2019-05-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.807Z",
            "idLocataire": 242,
            "idLogement": 85,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 242,
                "nomLocataire": "MENGUE",
                "prenomLocataire": "LYRETTE",
                "cniLocataire": "",
                "tel": "690469267",
                "tel2": "671817009",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.479Z",
                "updatedAt": "2022-03-02T11:54:45.479Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1369,
                1368
            ],
            "energie": [
                1317,
                1304
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1369,
                1368
            ],
            "energie": [
                1317,
                1304
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 9,
                "montant": 4491,
                "idIndexe": 51236,
                "typeIndexe": "eau",
                "ancien": 1359,
                "nouveau": 1368,
                "periode": "2023-10-01",
                "avance": 4491,
                "reste": 0,
                "datePaiement": "2023-11-27T16:03:22.089Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 51096,
                "typeIndexe": "eau",
                "ancien": 1357,
                "nouveau": 1359,
                "periode": "2023-09-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:50.966Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 16,
                "montant": 7984,
                "idIndexe": 50980,
                "typeIndexe": "eau",
                "ancien": 1341,
                "nouveau": 1357,
                "periode": "2023-08-01",
                "avance": 7984,
                "reste": 0,
                "datePaiement": "2023-09-28T07:00:58.562Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50828,
                "typeIndexe": "eau",
                "ancien": 1336,
                "nouveau": 1341,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:11:33.411Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50694,
                "typeIndexe": "eau",
                "ancien": 1326,
                "nouveau": 1336,
                "periode": "2023-06-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-07-26T15:56:17.889Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50557,
                "typeIndexe": "eau",
                "ancien": 1325,
                "nouveau": 1326,
                "periode": "2023-05-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-06-27T10:42:54.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50413,
                "typeIndexe": "eau",
                "ancien": 1324,
                "nouveau": 1325,
                "periode": "2023-04-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-05-30T11:32:45.636Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50290,
                "typeIndexe": "eau",
                "ancien": 1323,
                "nouveau": 1324,
                "periode": "2023-03-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.935Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 15,
                "montant": 7485,
                "idIndexe": 50163,
                "typeIndexe": "eau",
                "ancien": 1308,
                "nouveau": 1323,
                "periode": "2023-02-01",
                "avance": 7485,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.931Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50042,
                "typeIndexe": "eau",
                "ancien": 1306,
                "nouveau": 1308,
                "periode": "2023-01-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-23T07:19:48.584Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49926,
                "typeIndexe": "eau",
                "ancien": 1304,
                "nouveau": 1306,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-02-22T11:50:20.428Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 13,
                "montant": 1430,
                "idIndexe": 51237,
                "typeIndexe": "energie",
                "ancien": 1291,
                "nouveau": 1304,
                "periode": "2023-10-01",
                "avance": 1430,
                "reste": 0,
                "datePaiement": "2023-11-27T16:03:22.092Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 550,
                "idIndexe": 51097,
                "typeIndexe": "energie",
                "ancien": 1286,
                "nouveau": 1291,
                "periode": "2023-09-01",
                "avance": 550,
                "reste": 0,
                "datePaiement": "2023-10-28T15:36:50.969Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 50981,
                "typeIndexe": "energie",
                "ancien": 1266,
                "nouveau": 1286,
                "periode": "2023-08-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-09-28T07:00:58.564Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 330,
                "idIndexe": 50829,
                "typeIndexe": "energie",
                "ancien": 1263,
                "nouveau": 1266,
                "periode": "2023-07-01",
                "avance": 330,
                "reste": 0,
                "datePaiement": "2023-08-30T07:11:33.414Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50695,
                "typeIndexe": "energie",
                "ancien": 1257,
                "nouveau": 1263,
                "periode": "2023-06-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-09-28T07:01:12.032Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 1100,
                "idIndexe": 50558,
                "typeIndexe": "energie",
                "ancien": 1247,
                "nouveau": 1257,
                "periode": "2023-05-01",
                "avance": 1100,
                "reste": 0,
                "datePaiement": "2023-07-26T15:56:38.605Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50414,
                "typeIndexe": "energie",
                "ancien": 1236,
                "nouveau": 1247,
                "periode": "2023-04-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-05-30T11:32:54.638Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 660,
                "idIndexe": 50291,
                "typeIndexe": "energie",
                "ancien": 1230,
                "nouveau": 1236,
                "periode": "2023-03-01",
                "avance": 660,
                "reste": 0,
                "datePaiement": "2023-03-29T11:13:30.938Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50164,
                "typeIndexe": "energie",
                "ancien": 1211,
                "nouveau": 1230,
                "periode": "2023-02-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-04-28T15:49:49.397Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 19,
                "montant": 2090,
                "idIndexe": 50043,
                "typeIndexe": "energie",
                "ancien": 1192,
                "nouveau": 1211,
                "periode": "2023-01-01",
                "avance": 2090,
                "reste": 0,
                "datePaiement": "2023-02-23T07:19:48.587Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 45,
                "montant": 4950,
                "idIndexe": 49927,
                "typeIndexe": "energie",
                "ancien": 1147,
                "nouveau": 1192,
                "periode": "2022-12-01",
                "avance": 4950,
                "reste": 0,
                "datePaiement": "2023-02-22T11:50:20.431Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 85,
                "idOccupation": 268,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1369,
            "energie": -1317
        },
        "montant": {
            "eau": -683131,
            "energie": -144870
        }
    },
    {
        "idLogement": 84,
        "refLogement": "D005",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.615Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51365,
                "typeIndexe": "energie",
                "ancien": 5652,
                "nouveau": 5371,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51364,
                "typeIndexe": "eau",
                "ancien": 837,
                "nouveau": 840,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51238,
                "typeIndexe": "eau",
                "ancien": 834,
                "nouveau": 837,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51239,
                "typeIndexe": "energie",
                "ancien": 5602,
                "nouveau": 5652,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 51099,
                "typeIndexe": "energie",
                "ancien": 5541,
                "nouveau": 5602,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51098,
                "typeIndexe": "eau",
                "ancien": 830,
                "nouveau": 834,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50955,
                "typeIndexe": "energie",
                "ancien": 5471,
                "nouveau": 5541,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50954,
                "typeIndexe": "eau",
                "ancien": 826,
                "nouveau": 830,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50831,
                "typeIndexe": "energie",
                "ancien": 5401,
                "nouveau": 5471,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50830,
                "typeIndexe": "eau",
                "ancien": 822,
                "nouveau": 826,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50697,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5401,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50696,
                "typeIndexe": "eau",
                "ancien": 819,
                "nouveau": 822,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:36:55.954Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50559,
                "typeIndexe": "eau",
                "ancien": 815,
                "nouveau": 819,
                "periode": "2023-05-01",
                "avance": 1480,
                "reste": 516,
                "datePaiement": "2023-08-30T07:14:47.250Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50560,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5371,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50416,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5371,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50415,
                "typeIndexe": "eau",
                "ancien": 813,
                "nouveau": 815,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-04-30T16:13:34.433Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50293,
                "typeIndexe": "energie",
                "ancien": 5335,
                "nouveau": 5371,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50292,
                "typeIndexe": "eau",
                "ancien": 795,
                "nouveau": 813,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50294,
                "typeIndexe": "energie",
                "ancien": 5335,
                "nouveau": 5371,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50165,
                "typeIndexe": "eau",
                "ancien": 791,
                "nouveau": 795,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 50166,
                "typeIndexe": "energie",
                "ancien": 5331,
                "nouveau": 5335,
                "periode": "2023-02-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-04-28T15:58:20.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50045,
                "typeIndexe": "energie",
                "ancien": 5287,
                "nouveau": 5370,
                "periode": "2023-01-01",
                "avance": 43,
                "reste": 9087,
                "datePaiement": "2023-12-26T11:37:11.225Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50044,
                "typeIndexe": "eau",
                "ancien": 784,
                "nouveau": 791,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49928,
                "typeIndexe": "eau",
                "ancien": 778,
                "nouveau": 784,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51364,
            "typeIndexe": "eau",
            "ancien": 837,
            "nouveau": 840,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 84,
            "idOccupation": 73,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 50,
            "montant": 5500,
            "idIndexe": 51365,
            "typeIndexe": "energie",
            "ancien": 5652,
            "nouveau": 5702,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 84,
            "idOccupation": 73,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 73,
            "loyerBase": 60000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.613Z",
            "idLocataire": 73,
            "idLogement": 84,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 73,
                "nomLocataire": "ATANGANA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "695382204",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:43.765Z",
                "updatedAt": "2022-03-02T11:54:43.765Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                840,
                837
            ],
            "energie": [
                5702,
                5652
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                840,
                837
            ],
            "energie": [
                5702,
                5652
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51238,
                "typeIndexe": "eau",
                "ancien": 834,
                "nouveau": 837,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51098,
                "typeIndexe": "eau",
                "ancien": 830,
                "nouveau": 834,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50954,
                "typeIndexe": "eau",
                "ancien": 826,
                "nouveau": 830,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50830,
                "typeIndexe": "eau",
                "ancien": 822,
                "nouveau": 826,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50696,
                "typeIndexe": "eau",
                "ancien": 819,
                "nouveau": 822,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:36:55.954Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50559,
                "typeIndexe": "eau",
                "ancien": 815,
                "nouveau": 819,
                "periode": "2023-05-01",
                "avance": 1480,
                "reste": 516,
                "datePaiement": "2023-08-30T07:14:47.250Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50415,
                "typeIndexe": "eau",
                "ancien": 813,
                "nouveau": 815,
                "periode": "2023-04-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-04-30T16:13:34.433Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50292,
                "typeIndexe": "eau",
                "ancien": 795,
                "nouveau": 813,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50165,
                "typeIndexe": "eau",
                "ancien": 791,
                "nouveau": 795,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50044,
                "typeIndexe": "eau",
                "ancien": 784,
                "nouveau": 791,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49928,
                "typeIndexe": "eau",
                "ancien": 778,
                "nouveau": 784,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51239,
                "typeIndexe": "energie",
                "ancien": 5602,
                "nouveau": 5652,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 51099,
                "typeIndexe": "energie",
                "ancien": 5541,
                "nouveau": 5602,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50955,
                "typeIndexe": "energie",
                "ancien": 5471,
                "nouveau": 5541,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50831,
                "typeIndexe": "energie",
                "ancien": 5401,
                "nouveau": 5471,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 30,
                "montant": 3300,
                "idIndexe": 50697,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5401,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50560,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5371,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50416,
                "typeIndexe": "energie",
                "ancien": 5371,
                "nouveau": 5371,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50293,
                "typeIndexe": "energie",
                "ancien": 5335,
                "nouveau": 5371,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50294,
                "typeIndexe": "energie",
                "ancien": 5335,
                "nouveau": 5371,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 440,
                "idIndexe": 50166,
                "typeIndexe": "energie",
                "ancien": 5331,
                "nouveau": 5335,
                "periode": "2023-02-01",
                "avance": 440,
                "reste": 0,
                "datePaiement": "2023-04-28T15:58:20.455Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 83,
                "montant": 9130,
                "idIndexe": 50045,
                "typeIndexe": "energie",
                "ancien": 5287,
                "nouveau": 5370,
                "periode": "2023-01-01",
                "avance": 43,
                "reste": 9087,
                "datePaiement": "2023-12-26T11:37:11.225Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 84,
                "idOccupation": 73,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -840,
            "energie": -5702
        },
        "montant": {
            "eau": -419160,
            "energie": -627220
        }
    },
    {
        "idLogement": 81,
        "refLogement": "D008",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-03-29T09:31:56.622Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51366,
                "typeIndexe": "eau",
                "ancien": 959,
                "nouveau": 964,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:39:30.251Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 51367,
                "typeIndexe": "energie",
                "ancien": 2107,
                "nouveau": 1970,
                "periode": "2023-11-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-12-26T11:39:30.255Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51240,
                "typeIndexe": "eau",
                "ancien": 952,
                "nouveau": 959,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:53.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 51241,
                "typeIndexe": "energie",
                "ancien": 2075,
                "nouveau": 2107,
                "periode": "2023-10-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:53.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51100,
                "typeIndexe": "eau",
                "ancien": 945,
                "nouveau": 952,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:25.714Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3800,
                "idIndexe": 51101,
                "typeIndexe": "energie",
                "ancien": 2040,
                "nouveau": 2075,
                "periode": "2023-09-01",
                "avance": 3800,
                "reste": 0,
                "datePaiement": "2023-10-28T15:40:52.693Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50958,
                "typeIndexe": "eau",
                "ancien": 940,
                "nouveau": 945,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:35:39.597Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 5350,
                "idIndexe": 50959,
                "typeIndexe": "energie",
                "ancien": 2005,
                "nouveau": 2040,
                "periode": "2023-08-01",
                "avance": 5350,
                "reste": 0,
                "datePaiement": "2023-09-28T07:36:07.613Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50835,
                "typeIndexe": "energie",
                "ancien": 2005,
                "nouveau": 2005,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50834,
                "typeIndexe": "eau",
                "ancien": 926,
                "nouveau": 940,
                "periode": "2023-07-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-08-30T07:16:40.760Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 50701,
                "typeIndexe": "energie",
                "ancien": 1970,
                "nouveau": 2005,
                "periode": "2023-06-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:05.608Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50700,
                "typeIndexe": "eau",
                "ancien": 919,
                "nouveau": 926,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-31T11:26:00.475Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50562,
                "typeIndexe": "energie",
                "ancien": 1970,
                "nouveau": 1970,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50561,
                "typeIndexe": "eau",
                "ancien": 915,
                "nouveau": 919,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T16:10:50.512Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50187,
                "typeIndexe": "eau",
                "ancien": 910,
                "nouveau": 915,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T16:10:50.508Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50188,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1970,
                "periode": "2023-04-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:25.711Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50170,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50172,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 39145,
                "typeIndexe": "eau",
                "ancien": 629,
                "nouveau": 629,
                "periode": "2019-04-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2019-05-01T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 263,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 46087,
                "typeIndexe": "energie",
                "ancien": 1963,
                "nouveau": 1963,
                "periode": "2019-04-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2019-05-01T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 263,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 39120,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 629,
                "periode": "2019-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 46022,
                "typeIndexe": "energie",
                "ancien": 1925,
                "nouveau": 1963,
                "periode": "2019-03-01",
                "avance": 4179,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 45967,
                "typeIndexe": "energie",
                "ancien": 1882,
                "nouveau": 1925,
                "periode": "2019-02-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 39119,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 624,
                "periode": "2019-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51366,
            "typeIndexe": "eau",
            "ancien": 959,
            "nouveau": 964,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-26T11:39:30.251Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 81,
            "idOccupation": 449,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 32,
            "montant": 3520,
            "idIndexe": 51367,
            "typeIndexe": "energie",
            "ancien": 2107,
            "nouveau": 2139,
            "periode": "2023-11-01",
            "avance": 3520,
            "reste": 0,
            "datePaiement": "2023-12-26T11:39:30.255Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 81,
            "idOccupation": 449,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 449,
            "loyerBase": 65000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2023-04-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-03-29T09:31:54.615Z",
            "idLocataire": 402,
            "idLogement": 81,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 402,
                "nomLocataire": "TCHOUTA",
                "prenomLocataire": "Serge Bertin",
                "cniLocataire": "",
                "tel": "699473197",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-03-29T09:28:33.297Z",
                "updatedAt": "2023-03-29T09:28:33.297Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                964,
                959
            ],
            "energie": [
                2139,
                2107
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                964,
                959
            ],
            "energie": [
                2139,
                2107
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51240,
                "typeIndexe": "eau",
                "ancien": 952,
                "nouveau": 959,
                "periode": "2023-10-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:53.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51100,
                "typeIndexe": "eau",
                "ancien": 945,
                "nouveau": 952,
                "periode": "2023-09-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:25.714Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50958,
                "typeIndexe": "eau",
                "ancien": 940,
                "nouveau": 945,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:35:39.597Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 14,
                "montant": 6986,
                "idIndexe": 50834,
                "typeIndexe": "eau",
                "ancien": 926,
                "nouveau": 940,
                "periode": "2023-07-01",
                "avance": 6986,
                "reste": 0,
                "datePaiement": "2023-08-30T07:16:40.760Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50700,
                "typeIndexe": "eau",
                "ancien": 919,
                "nouveau": 926,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-07-31T11:26:00.475Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50561,
                "typeIndexe": "eau",
                "ancien": 915,
                "nouveau": 919,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T16:10:50.512Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50187,
                "typeIndexe": "eau",
                "ancien": 910,
                "nouveau": 915,
                "periode": "2023-04-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T16:10:50.508Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 39145,
                "typeIndexe": "eau",
                "ancien": 629,
                "nouveau": 629,
                "periode": "2019-04-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2019-05-01T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 263,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 39120,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 629,
                "periode": "2019-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 39119,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 624,
                "periode": "2019-02-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 51241,
                "typeIndexe": "energie",
                "ancien": 2075,
                "nouveau": 2107,
                "periode": "2023-10-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:53.999Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3800,
                "idIndexe": 51101,
                "typeIndexe": "energie",
                "ancien": 2040,
                "nouveau": 2075,
                "periode": "2023-09-01",
                "avance": 3800,
                "reste": 0,
                "datePaiement": "2023-10-28T15:40:52.693Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 5350,
                "idIndexe": 50959,
                "typeIndexe": "energie",
                "ancien": 2005,
                "nouveau": 2040,
                "periode": "2023-08-01",
                "avance": 5350,
                "reste": 0,
                "datePaiement": "2023-09-28T07:36:07.613Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50835,
                "typeIndexe": "energie",
                "ancien": 2005,
                "nouveau": 2005,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 35,
                "montant": 3850,
                "idIndexe": 50701,
                "typeIndexe": "energie",
                "ancien": 1970,
                "nouveau": 2005,
                "periode": "2023-06-01",
                "avance": 3850,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:05.608Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50562,
                "typeIndexe": "energie",
                "ancien": 1970,
                "nouveau": 1970,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 70,
                "montant": 7700,
                "idIndexe": 50188,
                "typeIndexe": "energie",
                "ancien": 1900,
                "nouveau": 1970,
                "periode": "2023-04-01",
                "avance": 7700,
                "reste": 0,
                "datePaiement": "2023-11-27T16:05:25.711Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 81,
                "idOccupation": 449,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 46087,
                "typeIndexe": "energie",
                "ancien": 1963,
                "nouveau": 1963,
                "periode": "2019-04-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2019-05-01T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 263,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 38,
                "montant": 4180,
                "idIndexe": 46022,
                "typeIndexe": "energie",
                "ancien": 1925,
                "nouveau": 1963,
                "periode": "2019-03-01",
                "avance": 4179,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 43,
                "montant": 4730,
                "idIndexe": 45967,
                "typeIndexe": "energie",
                "ancien": 1882,
                "nouveau": 1925,
                "periode": "2019-02-01",
                "avance": 4730,
                "reste": 0,
                "datePaiement": "2019-03-30T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 81,
                "idOccupation": 174,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -964,
            "energie": -2139
        },
        "montant": {
            "eau": -481036,
            "energie": -235290
        }
    },
    {
        "idLogement": 80,
        "refLogement": "D009",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-04-29T15:50:34.113Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 51369,
                "typeIndexe": "energie",
                "ancien": 1493,
                "nouveau": 1547,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51368,
                "typeIndexe": "eau",
                "ancien": 45,
                "nouveau": 80,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:40:41.907Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 51243,
                "typeIndexe": "energie",
                "ancien": 1433,
                "nouveau": 1547,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51242,
                "typeIndexe": "eau",
                "ancien": 35,
                "nouveau": 45,
                "periode": "2023-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-12-26T11:40:41.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51102,
                "typeIndexe": "eau",
                "ancien": 30,
                "nouveau": 35,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:40:41.901Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 51103,
                "typeIndexe": "energie",
                "ancien": 1375,
                "nouveau": 1433,
                "periode": "2023-09-01",
                "avance": 595,
                "reste": 5785,
                "datePaiement": "2023-12-26T11:41:00.311Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50960,
                "typeIndexe": "eau",
                "ancien": 25,
                "nouveau": 30,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:41:19.867Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50961,
                "typeIndexe": "energie",
                "ancien": 1312,
                "nouveau": 1375,
                "periode": "2023-08-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-10-28T15:41:19.871Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 50837,
                "typeIndexe": "energie",
                "ancien": 1256,
                "nouveau": 1312,
                "periode": "2023-07-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:37.946Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50836,
                "typeIndexe": "eau",
                "ancien": 22,
                "nouveau": 25,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:37.943Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50702,
                "typeIndexe": "eau",
                "ancien": 17,
                "nouveau": 22,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:45.994Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50703,
                "typeIndexe": "energie",
                "ancien": 1190,
                "nouveau": 1256,
                "periode": "2023-06-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:34.629Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50564,
                "typeIndexe": "energie",
                "ancien": 1136,
                "nouveau": 1190,
                "periode": "2023-05-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:30.245Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50563,
                "typeIndexe": "eau",
                "ancien": 11,
                "nouveau": 17,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:40.474Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50420,
                "typeIndexe": "energie",
                "ancien": 1100,
                "nouveau": 1136,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50448,
                "typeIndexe": "energie",
                "ancien": 1125,
                "nouveau": 1136,
                "periode": "2023-04-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-05-30T11:43:03.634Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50447,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 11,
                "periode": "2023-04-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-05-30T11:42:55.054Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50419,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 11,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50171,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50169,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 49812,
                "typeIndexe": "eau",
                "ancien": 1059,
                "nouveau": 1070,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49813,
                "typeIndexe": "energie",
                "ancien": 1020,
                "nouveau": 1082,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49675,
                "typeIndexe": "energie",
                "ancien": 958,
                "nouveau": 1020,
                "periode": "2022-10-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2022-11-29T12:23:15.563Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49674,
                "typeIndexe": "eau",
                "ancien": 1049,
                "nouveau": 1059,
                "periode": "2022-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2022-11-29T12:23:15.567Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51368,
            "typeIndexe": "eau",
            "ancien": 45,
            "nouveau": 50,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-26T11:40:41.907Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 80,
            "idOccupation": 452,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 54,
            "montant": 5940,
            "idIndexe": 51369,
            "typeIndexe": "energie",
            "ancien": 1493,
            "nouveau": 1547,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 80,
            "idOccupation": 452,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 452,
            "loyerBase": 60000,
            "caution": 60000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-04-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-04-29T15:50:32.067Z",
            "idLocataire": 404,
            "idLogement": 80,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 404,
                "nomLocataire": "ATOKO YAKAN",
                "prenomLocataire": "Daniel",
                "cniLocataire": "",
                "tel": "679169507",
                "tel2": "656343155",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-04-29T15:46:52.520Z",
                "updatedAt": "2023-07-26T16:41:51.086Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                50,
                45
            ],
            "energie": [
                1547,
                1493
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                50,
                45
            ],
            "energie": [
                1547,
                1493
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 51242,
                "typeIndexe": "eau",
                "ancien": 35,
                "nouveau": 45,
                "periode": "2023-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-12-26T11:40:41.905Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51102,
                "typeIndexe": "eau",
                "ancien": 30,
                "nouveau": 35,
                "periode": "2023-09-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:40:41.901Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50960,
                "typeIndexe": "eau",
                "ancien": 25,
                "nouveau": 30,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:41:19.867Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50836,
                "typeIndexe": "eau",
                "ancien": 22,
                "nouveau": 25,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:37.943Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50702,
                "typeIndexe": "eau",
                "ancien": 17,
                "nouveau": 22,
                "periode": "2023-06-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:45.994Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50563,
                "typeIndexe": "eau",
                "ancien": 11,
                "nouveau": 17,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:40.474Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50447,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 11,
                "periode": "2023-04-01",
                "avance": 5489,
                "reste": 0,
                "datePaiement": "2023-05-30T11:42:55.054Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 50419,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 11,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 5489,
                "idIndexe": 49812,
                "typeIndexe": "eau",
                "ancien": 1059,
                "nouveau": 1070,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 49674,
                "typeIndexe": "eau",
                "ancien": 1049,
                "nouveau": 1059,
                "periode": "2022-10-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2022-11-29T12:23:15.567Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 51243,
                "typeIndexe": "energie",
                "ancien": 1433,
                "nouveau": 1493,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 51103,
                "typeIndexe": "energie",
                "ancien": 1375,
                "nouveau": 1433,
                "periode": "2023-09-01",
                "avance": 595,
                "reste": 5785,
                "datePaiement": "2023-12-26T11:41:00.311Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50961,
                "typeIndexe": "energie",
                "ancien": 1312,
                "nouveau": 1375,
                "periode": "2023-08-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-10-28T15:41:19.871Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 50837,
                "typeIndexe": "energie",
                "ancien": 1256,
                "nouveau": 1312,
                "periode": "2023-07-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-08-30T07:17:37.946Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 66,
                "montant": 7260,
                "idIndexe": 50703,
                "typeIndexe": "energie",
                "ancien": 1190,
                "nouveau": 1256,
                "periode": "2023-06-01",
                "avance": 7260,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:34.629Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50564,
                "typeIndexe": "energie",
                "ancien": 1136,
                "nouveau": 1190,
                "periode": "2023-05-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-07-31T11:27:30.245Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 36,
                "montant": 3960,
                "idIndexe": 50420,
                "typeIndexe": "energie",
                "ancien": 1100,
                "nouveau": 1136,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50448,
                "typeIndexe": "energie",
                "ancien": 1125,
                "nouveau": 1136,
                "periode": "2023-04-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-05-30T11:43:03.634Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 452,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49813,
                "typeIndexe": "energie",
                "ancien": 1020,
                "nouveau": 1082,
                "periode": "2022-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 62,
                "montant": 6820,
                "idIndexe": 49675,
                "typeIndexe": "energie",
                "ancien": 958,
                "nouveau": 1020,
                "periode": "2022-10-01",
                "avance": 6820,
                "reste": 0,
                "datePaiement": "2022-11-29T12:23:15.563Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 80,
                "idOccupation": 351,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -50,
            "energie": -1547
        },
        "montant": {
            "eau": -24950,
            "energie": -170170
        }
    },
    {
        "idLogement": 79,
        "refLogement": "D010",
        "descLogement": "modification du prix min de 0 vers 45000 et prix max de 0 vers 50000",
        "superficie": 0,
        "prixMin": 45000,
        "prixMax": 50000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-09-29T14:53:26.400Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51370,
                "typeIndexe": "eau",
                "ancien": 650,
                "nouveau": 655,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 21,
                "montant": 2310,
                "idIndexe": 51371,
                "typeIndexe": "energie",
                "ancien": 3332,
                "nouveau": 3353,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51244,
                "typeIndexe": "eau",
                "ancien": 642,
                "nouveau": 650,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 51245,
                "typeIndexe": "energie",
                "ancien": 3300,
                "nouveau": 3332,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -1356,
                "montant": 0,
                "idIndexe": 51122,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 642,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 51104,
                "typeIndexe": "eau",
                "ancien": 605,
                "nouveau": 647,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -13496,
                "montant": 0,
                "idIndexe": 51123,
                "typeIndexe": "energie",
                "ancien": 13496,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51105,
                "typeIndexe": "energie",
                "ancien": 3308,
                "nouveau": 3315,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50962,
                "typeIndexe": "eau",
                "ancien": 599,
                "nouveau": 605,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.657Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50963,
                "typeIndexe": "energie",
                "ancien": 3297,
                "nouveau": 3308,
                "periode": "2023-08-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.662Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50839,
                "typeIndexe": "energie",
                "ancien": 3288,
                "nouveau": 3297,
                "periode": "2023-07-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.654Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50838,
                "typeIndexe": "eau",
                "ancien": 581,
                "nouveau": 599,
                "periode": "2023-07-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.651Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50704,
                "typeIndexe": "eau",
                "ancien": 580,
                "nouveau": 581,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.648Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50705,
                "typeIndexe": "energie",
                "ancien": 3270,
                "nouveau": 3288,
                "periode": "2023-06-01",
                "avance": 150,
                "reste": 1830,
                "datePaiement": "2023-08-30T07:19:38.923Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50566,
                "typeIndexe": "energie",
                "ancien": 3222,
                "nouveau": 3270,
                "periode": "2023-05-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.493Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50565,
                "typeIndexe": "eau",
                "ancien": 576,
                "nouveau": 580,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50421,
                "typeIndexe": "eau",
                "ancien": 573,
                "nouveau": 576,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50422,
                "typeIndexe": "energie",
                "ancien": 3159,
                "nouveau": 3222,
                "periode": "2023-04-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.489Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50298,
                "typeIndexe": "energie",
                "ancien": 3105,
                "nouveau": 3159,
                "periode": "2023-03-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.471Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50297,
                "typeIndexe": "eau",
                "ancien": 569,
                "nouveau": 573,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.467Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50173,
                "typeIndexe": "eau",
                "ancien": 566,
                "nouveau": 569,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.460Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50174,
                "typeIndexe": "energie",
                "ancien": 3051,
                "nouveau": 3105,
                "periode": "2023-02-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.464Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50048,
                "typeIndexe": "eau",
                "ancien": 561,
                "nouveau": 566,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.454Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50049,
                "typeIndexe": "energie",
                "ancien": 2990,
                "nouveau": 3051,
                "periode": "2023-01-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.457Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51370,
            "typeIndexe": "eau",
            "ancien": 650,
            "nouveau": 653,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 79,
            "idOccupation": 96,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 21,
            "montant": 2310,
            "idIndexe": 51371,
            "typeIndexe": "energie",
            "ancien": 3332,
            "nouveau": 3353,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 79,
            "idOccupation": 96,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 96,
            "loyerBase": 45000,
            "caution": 90000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-09-29T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-09-29T14:53:25.529Z",
            "idLocataire": 310,
            "idLogement": 79,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 310,
                "nomLocataire": "KEIMBA",
                "prenomLocataire": "TABITHA HANNICH",
                "cniLocataire": "",
                "tel": "693940031",
                "tel2": "673302259",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.110Z",
                "updatedAt": "2022-03-02T11:54:45.110Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                653,
                650
            ],
            "energie": [
                3353,
                3332
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                653,
                650
            ],
            "energie": [
                3353,
                3332
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51244,
                "typeIndexe": "eau",
                "ancien": 642,
                "nouveau": 650,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -1356,
                "montant": 0,
                "idIndexe": 51122,
                "typeIndexe": "eau",
                "ancien": 1998,
                "nouveau": 642,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 42,
                "montant": 20958,
                "idIndexe": 51104,
                "typeIndexe": "eau",
                "ancien": 605,
                "nouveau": 647,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50962,
                "typeIndexe": "eau",
                "ancien": 599,
                "nouveau": 605,
                "periode": "2023-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.657Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 8982,
                "idIndexe": 50838,
                "typeIndexe": "eau",
                "ancien": 581,
                "nouveau": 599,
                "periode": "2023-07-01",
                "avance": 8982,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.651Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1,
                "montant": 499,
                "idIndexe": 50704,
                "typeIndexe": "eau",
                "ancien": 580,
                "nouveau": 581,
                "periode": "2023-06-01",
                "avance": 499,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.648Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50565,
                "typeIndexe": "eau",
                "ancien": 576,
                "nouveau": 580,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50421,
                "typeIndexe": "eau",
                "ancien": 573,
                "nouveau": 576,
                "periode": "2023-04-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.487Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50297,
                "typeIndexe": "eau",
                "ancien": 569,
                "nouveau": 573,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.467Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50173,
                "typeIndexe": "eau",
                "ancien": 566,
                "nouveau": 569,
                "periode": "2023-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.460Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50048,
                "typeIndexe": "eau",
                "ancien": 561,
                "nouveau": 566,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.454Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 51245,
                "typeIndexe": "energie",
                "ancien": 3300,
                "nouveau": 3332,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 96,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 770,
                "idIndexe": 51105,
                "typeIndexe": "energie",
                "ancien": 3308,
                "nouveau": 3315,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 11,
                "montant": 1210,
                "idIndexe": 50963,
                "typeIndexe": "energie",
                "ancien": 3297,
                "nouveau": 3308,
                "periode": "2023-08-01",
                "avance": 1210,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.662Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 9,
                "montant": 990,
                "idIndexe": 50839,
                "typeIndexe": "energie",
                "ancien": 3288,
                "nouveau": 3297,
                "periode": "2023-07-01",
                "avance": 990,
                "reste": 0,
                "datePaiement": "2023-09-28T07:40:24.654Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 18,
                "montant": 1980,
                "idIndexe": 50705,
                "typeIndexe": "energie",
                "ancien": 3270,
                "nouveau": 3288,
                "periode": "2023-06-01",
                "avance": 150,
                "reste": 1830,
                "datePaiement": "2023-08-30T07:19:38.923Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 48,
                "montant": 5280,
                "idIndexe": 50566,
                "typeIndexe": "energie",
                "ancien": 3222,
                "nouveau": 3270,
                "periode": "2023-05-01",
                "avance": 5280,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.493Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 63,
                "montant": 6930,
                "idIndexe": 50422,
                "typeIndexe": "energie",
                "ancien": 3159,
                "nouveau": 3222,
                "periode": "2023-04-01",
                "avance": 6930,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.489Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50298,
                "typeIndexe": "energie",
                "ancien": 3105,
                "nouveau": 3159,
                "periode": "2023-03-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.471Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 54,
                "montant": 5940,
                "idIndexe": 50174,
                "typeIndexe": "energie",
                "ancien": 3051,
                "nouveau": 3105,
                "periode": "2023-02-01",
                "avance": 5940,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.464Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 61,
                "montant": 6710,
                "idIndexe": 50049,
                "typeIndexe": "energie",
                "ancien": 2990,
                "nouveau": 3051,
                "periode": "2023-01-01",
                "avance": 6710,
                "reste": 0,
                "datePaiement": "2023-08-30T07:19:20.457Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 79,
                "idOccupation": 338,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -653,
            "energie": -3353
        },
        "montant": {
            "eau": -325847,
            "energie": -368830
        }
    },
    {
        "idLogement": 78,
        "refLogement": "D011",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 70000,
        "prixMax": 80000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-11-27T16:37:06.557Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 2,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 2,
            "libelleSousType": "Appartements modernes",
            "descSousType": "APPM",
            "idType": 1
        },
        "indexes": [
            {
                "consommation": 15,
                "montant": 1650,
                "idIndexe": 51373,
                "typeIndexe": "energie",
                "ancien": 1390,
                "nouveau": 1405,
                "periode": "2023-11-01",
                "avance": 1650,
                "reste": 0,
                "datePaiement": "2023-12-26T11:42:19.814Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 99,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51372,
                "typeIndexe": "eau",
                "ancien": 1130,
                "nouveau": 1133,
                "periode": "2023-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-12-26T11:42:19.811Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 99,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1390,
                "montant": 152900,
                "idIndexe": 51247,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1390,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1130,
                "montant": 563870,
                "idIndexe": 51246,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 1130,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51106,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51107,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50965,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50964,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50841,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50840,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50706,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50707,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50567,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50568,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50423,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50424,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50426,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50425,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50300,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50299,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50175,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50176,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50051,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50053,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": 342,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 3,
            "montant": 1497,
            "idIndexe": 51372,
            "typeIndexe": "eau",
            "ancien": 1130,
            "nouveau": 1133,
            "periode": "2023-11-01",
            "avance": 1497,
            "reste": 0,
            "datePaiement": "2023-12-26T11:42:19.811Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 78,
            "idOccupation": 99,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 15,
            "montant": 1650,
            "idIndexe": 51373,
            "typeIndexe": "energie",
            "ancien": 1390,
            "nouveau": 1405,
            "periode": "2023-11-01",
            "avance": 1650,
            "reste": 0,
            "datePaiement": "2023-12-26T11:42:19.814Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 78,
            "idOccupation": 99,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 99,
            "loyerBase": 35000,
            "caution": 35000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-10-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-11-27T16:37:05.702Z",
            "idLocataire": 92,
            "idLogement": 78,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 92,
                "nomLocataire": "ABOMO",
                "prenomLocataire": "marthe",
                "cniLocataire": "",
                "tel": "691762507",
                "tel2": "682904407",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-11-27T16:33:41.778Z",
                "updatedAt": "2023-11-27T16:33:41.778Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                1133,
                1130
            ],
            "energie": [
                1405,
                1390
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                1133,
                1130
            ],
            "energie": [
                1405,
                1390
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 1130,
                "montant": 563870,
                "idIndexe": 51246,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 1130,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 1390,
                "montant": 152900,
                "idIndexe": 51247,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 1390,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 78,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -1133,
            "energie": -1405
        },
        "montant": {
            "eau": -565367,
            "energie": -154550
        }
    },
    {
        "idLogement": 74,
        "refLogement": "D015",
        "descLogement": "modification du prix min de 50000 vers 45000",
        "superficie": 0,
        "prixMin": 45000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-10-30T11:51:07.641Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51374,
                "typeIndexe": "eau",
                "ancien": 12478,
                "nouveau": 12723,
                "periode": "2023-11-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:09.281Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51375,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51248,
                "typeIndexe": "eau",
                "ancien": 12470,
                "nouveau": 12478,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:13.000Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51249,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1745,
                "montant": 870755,
                "idIndexe": 51110,
                "typeIndexe": "eau",
                "ancien": 10725,
                "nouveau": 12470,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51111,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50968,
                "typeIndexe": "eau",
                "ancien": 10720,
                "nouveau": 10725,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:44:49.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50969,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50842,
                "typeIndexe": "eau",
                "ancien": 10715,
                "nouveau": 10720,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.582Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50843,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50708,
                "typeIndexe": "eau",
                "ancien": 10708,
                "nouveau": 10715,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.579Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50709,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50569,
                "typeIndexe": "eau",
                "ancien": 10701,
                "nouveau": 10708,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.577Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50570,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50427,
                "typeIndexe": "eau",
                "ancien": 10697,
                "nouveau": 10701,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50428,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50302,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50301,
                "typeIndexe": "eau",
                "ancien": 10693,
                "nouveau": 10697,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.571Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50177,
                "typeIndexe": "eau",
                "ancien": 10687,
                "nouveau": 10693,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.444Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50178,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50054,
                "typeIndexe": "eau",
                "ancien": 8718,
                "nouveau": 8725,
                "periode": "2023-01-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.441Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50055,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49935,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49934,
                "typeIndexe": "eau",
                "ancien": 8712,
                "nouveau": 8718,
                "periode": "2022-12-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 6,
            "montant": 2994,
            "idIndexe": 51374,
            "typeIndexe": "eau",
            "ancien": 12478,
            "nouveau": 12484,
            "periode": "2023-11-01",
            "avance": 2994,
            "reste": 0,
            "datePaiement": "2023-12-26T11:44:09.281Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 74,
            "idOccupation": 98,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51375,
            "typeIndexe": "energie",
            "ancien": 3515,
            "nouveau": 3515,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 74,
            "idOccupation": 98,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 98,
            "loyerBase": 50000,
            "caution": 75000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-10-11T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-10-30T11:51:06.791Z",
            "idLocataire": 91,
            "idLogement": 74,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 91,
                "nomLocataire": "ONDI",
                "prenomLocataire": "JENETT Kendem",
                "cniLocataire": "",
                "tel": "678971871",
                "tel2": "620314915",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "M",
                "profession": "",
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2023-10-30T11:13:00.068Z",
                "updatedAt": "2023-10-30T11:13:00.068Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                12484,
                12478
            ],
            "energie": [
                3515,
                3515
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                12484,
                12478
            ],
            "energie": [
                3515,
                3515
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51248,
                "typeIndexe": "eau",
                "ancien": 12470,
                "nouveau": 12478,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:13.000Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 1745,
                "montant": 870755,
                "idIndexe": 51110,
                "typeIndexe": "eau",
                "ancien": 10725,
                "nouveau": 12470,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50968,
                "typeIndexe": "eau",
                "ancien": 10720,
                "nouveau": 10725,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:44:49.393Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50842,
                "typeIndexe": "eau",
                "ancien": 10715,
                "nouveau": 10720,
                "periode": "2023-07-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.582Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50708,
                "typeIndexe": "eau",
                "ancien": 10708,
                "nouveau": 10715,
                "periode": "2023-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.579Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50569,
                "typeIndexe": "eau",
                "ancien": 10701,
                "nouveau": 10708,
                "periode": "2023-05-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.577Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50427,
                "typeIndexe": "eau",
                "ancien": 10697,
                "nouveau": 10701,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.574Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50301,
                "typeIndexe": "eau",
                "ancien": 10693,
                "nouveau": 10697,
                "periode": "2023-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:21:37.571Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50177,
                "typeIndexe": "eau",
                "ancien": 10687,
                "nouveau": 10693,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.444Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50054,
                "typeIndexe": "eau",
                "ancien": 8718,
                "nouveau": 8725,
                "periode": "2023-01-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.441Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49934,
                "typeIndexe": "eau",
                "ancien": 8712,
                "nouveau": 8718,
                "periode": "2022-12-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-27T09:30:28.420Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51249,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 98,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51111,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50969,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50843,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50709,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50570,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50428,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50302,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50178,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50055,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49935,
                "typeIndexe": "energie",
                "ancien": 3515,
                "nouveau": 3515,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 74,
                "idOccupation": 364,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -12484,
            "energie": -3515
        },
        "montant": {
            "eau": -6229516,
            "energie": -386650
        }
    },
    {
        "idLogement": 73,
        "refLogement": "D016",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:20.222Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51377,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1993,
                "periode": "2023-11-01",
                "avance": 2009,
                "reste": 3491,
                "datePaiement": "2023-12-26T11:44:58.539Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51376,
                "typeIndexe": "eau",
                "ancien": 10849,
                "nouveau": 10854,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:42.227Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51251,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51250,
                "typeIndexe": "eau",
                "ancien": 10845,
                "nouveau": 10849,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:53.884Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51114,
                "typeIndexe": "eau",
                "ancien": 10842,
                "nouveau": 10845,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:44:06.331Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51115,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50970,
                "typeIndexe": "eau",
                "ancien": 10839,
                "nouveau": 10842,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T07:45:29.412Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50971,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50844,
                "typeIndexe": "eau",
                "ancien": 10835,
                "nouveau": 10839,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:22:20.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50845,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50711,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50710,
                "typeIndexe": "eau",
                "ancien": 10832,
                "nouveau": 10835,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:48.554Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50572,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50571,
                "typeIndexe": "eau",
                "ancien": 10828,
                "nouveau": 10832,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T17:03:15.436Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50430,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50429,
                "typeIndexe": "eau",
                "ancien": 10824,
                "nouveau": 10828,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:57:02.697Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50303,
                "typeIndexe": "eau",
                "ancien": 10821,
                "nouveau": 10824,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T16:01:29.641Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50304,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50180,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50179,
                "typeIndexe": "eau",
                "ancien": 10816,
                "nouveau": 10821,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T17:03:15.431Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50057,
                "typeIndexe": "energie",
                "ancien": 1699,
                "nouveau": 1731,
                "periode": "2023-01-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:54.822Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50056,
                "typeIndexe": "eau",
                "ancien": 10811,
                "nouveau": 10816,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:39.237Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 49937,
                "typeIndexe": "energie",
                "ancien": 1679,
                "nouveau": 1699,
                "periode": "2022-12-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-03-29T08:47:26.204Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49936,
                "typeIndexe": "eau",
                "ancien": 10807,
                "nouveau": 10811,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:24:40.206Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51376,
            "typeIndexe": "eau",
            "ancien": 10849,
            "nouveau": 10854,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-26T11:44:42.227Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 73,
            "idOccupation": 352,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 50,
            "montant": 5500,
            "idIndexe": 51377,
            "typeIndexe": "energie",
            "ancien": 1731,
            "nouveau": 1781,
            "periode": "2023-11-01",
            "avance": 2009,
            "reste": 3491,
            "datePaiement": "2023-12-26T11:44:58.539Z",
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 73,
            "idOccupation": 352,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 352,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2021-08-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:20.220Z",
            "idLocataire": 319,
            "idLogement": 73,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 319,
                "nomLocataire": "NGA",
                "prenomLocataire": "Marlyse Celine",
                "cniLocataire": "",
                "tel": "696757648",
                "tel2": "677129918",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mme",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.175Z",
                "updatedAt": "2022-03-02T11:54:45.175Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                10854,
                10849
            ],
            "energie": [
                1781,
                1731
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                10854,
                10849
            ],
            "energie": [
                1781,
                1731
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51250,
                "typeIndexe": "eau",
                "ancien": 10845,
                "nouveau": 10849,
                "periode": "2023-10-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-12-26T11:44:53.884Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 51114,
                "typeIndexe": "eau",
                "ancien": 10842,
                "nouveau": 10845,
                "periode": "2023-09-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-10-28T15:44:06.331Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50970,
                "typeIndexe": "eau",
                "ancien": 10839,
                "nouveau": 10842,
                "periode": "2023-08-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-09-28T07:45:29.412Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50844,
                "typeIndexe": "eau",
                "ancien": 10835,
                "nouveau": 10839,
                "periode": "2023-07-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-08-30T07:22:20.822Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50710,
                "typeIndexe": "eau",
                "ancien": 10832,
                "nouveau": 10835,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:48.554Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50571,
                "typeIndexe": "eau",
                "ancien": 10828,
                "nouveau": 10832,
                "periode": "2023-05-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-07-26T17:03:15.436Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50429,
                "typeIndexe": "eau",
                "ancien": 10824,
                "nouveau": 10828,
                "periode": "2023-04-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-06-27T10:57:02.697Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50303,
                "typeIndexe": "eau",
                "ancien": 10821,
                "nouveau": 10824,
                "periode": "2023-03-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-04-28T16:01:29.641Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50179,
                "typeIndexe": "eau",
                "ancien": 10816,
                "nouveau": 10821,
                "periode": "2023-02-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-26T17:03:15.431Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50056,
                "typeIndexe": "eau",
                "ancien": 10811,
                "nouveau": 10816,
                "periode": "2023-01-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:39.237Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 49936,
                "typeIndexe": "eau",
                "ancien": 10807,
                "nouveau": 10811,
                "periode": "2022-12-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-02-23T07:24:40.206Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51251,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51115,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50971,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50845,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50711,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50572,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50430,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50304,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50180,
                "typeIndexe": "energie",
                "ancien": 1731,
                "nouveau": 1731,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 32,
                "montant": 3520,
                "idIndexe": 50057,
                "typeIndexe": "energie",
                "ancien": 1699,
                "nouveau": 1731,
                "periode": "2023-01-01",
                "avance": 3520,
                "reste": 0,
                "datePaiement": "2023-07-31T17:24:54.822Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 20,
                "montant": 2200,
                "idIndexe": 49937,
                "typeIndexe": "energie",
                "ancien": 1679,
                "nouveau": 1699,
                "periode": "2022-12-01",
                "avance": 2200,
                "reste": 0,
                "datePaiement": "2023-03-29T08:47:26.204Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 73,
                "idOccupation": 352,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -10854,
            "energie": -1781
        },
        "montant": {
            "eau": -5416146,
            "energie": -195910
        }
    },
    {
        "idLogement": 72,
        "refLogement": "D017",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:18.803Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 48145,
                "typeIndexe": "energie",
                "ancien": 4158,
                "nouveau": 4238,
                "periode": "2021-08-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-09-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41350,
                "typeIndexe": "eau",
                "ancien": 774,
                "nouveau": 780,
                "periode": "2021-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-09-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41273,
                "typeIndexe": "eau",
                "ancien": 768,
                "nouveau": 774,
                "periode": "2021-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-08-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 48065,
                "typeIndexe": "energie",
                "ancien": 4068,
                "nouveau": 4158,
                "periode": "2021-07-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2021-08-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 47994,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4158,
                "periode": "2021-06-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 41205,
                "typeIndexe": "eau",
                "ancien": 786,
                "nouveau": 793,
                "periode": "2021-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47930,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4138,
                "periode": "2021-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41139,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 786,
                "periode": "2021-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47903,
                "typeIndexe": "energie",
                "ancien": 3978,
                "nouveau": 4058,
                "periode": "2021-04-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41028,
                "typeIndexe": "eau",
                "ancien": 774,
                "nouveau": 780,
                "periode": "2021-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 47850,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4158,
                "periode": "2021-03-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 40994,
                "typeIndexe": "eau",
                "ancien": 770,
                "nouveau": 774,
                "periode": "2021-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40919,
                "typeIndexe": "eau",
                "ancien": 767,
                "nouveau": 770,
                "periode": "2021-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 120,
                "montant": 13200,
                "idIndexe": 47785,
                "typeIndexe": "energie",
                "ancien": 3938,
                "nouveau": 4058,
                "periode": "2021-02-01",
                "avance": 13200,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47713,
                "typeIndexe": "energie",
                "ancien": 4178,
                "nouveau": 4258,
                "periode": "2021-01-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 40918,
                "typeIndexe": "eau",
                "ancien": 761,
                "nouveau": 767,
                "periode": "2021-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40839,
                "typeIndexe": "eau",
                "ancien": 758,
                "nouveau": 761,
                "periode": "2020-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47634,
                "typeIndexe": "energie",
                "ancien": 4178,
                "nouveau": 4258,
                "periode": "2020-12-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40766,
                "typeIndexe": "eau",
                "ancien": 755,
                "nouveau": 758,
                "periode": "2020-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47560,
                "typeIndexe": "energie",
                "ancien": 4168,
                "nouveau": 4248,
                "periode": "2020-11-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47487,
                "typeIndexe": "energie",
                "ancien": 4088,
                "nouveau": 4168,
                "periode": "2020-10-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40663,
                "typeIndexe": "eau",
                "ancien": 752,
                "nouveau": 755,
                "periode": "2020-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 47476,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4168,
                "periode": "2020-09-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 40662,
                "typeIndexe": "eau",
                "ancien": 748,
                "nouveau": 752,
                "periode": "2020-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 6,
            "montant": 2994,
            "idIndexe": 41350,
            "typeIndexe": "eau",
            "ancien": 774,
            "nouveau": 780,
            "periode": "2021-08-01",
            "avance": 2994,
            "reste": 0,
            "datePaiement": "2021-09-29T00:00:00.000Z",
            "observation": "Complet",
            "createdBy": null,
            "updatedBy": null,
            "idLogement": 72,
            "idOccupation": 110,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 80,
            "montant": 8800,
            "idIndexe": 48145,
            "typeIndexe": "energie",
            "ancien": 4158,
            "nouveau": 4238,
            "periode": "2021-08-01",
            "avance": 8800,
            "reste": 0,
            "datePaiement": "2021-09-29T00:00:00.000Z",
            "observation": "Complet",
            "createdBy": null,
            "updatedBy": null,
            "idLogement": 72,
            "idOccupation": 110,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 110,
            "loyerBase": 50000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2015-08-31T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:18.801Z",
            "idLocataire": 110,
            "idLogement": 72,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 110,
                "nomLocataire": "BIAKON",
                "prenomLocataire": "Pierre Antoine",
                "cniLocataire": "",
                "tel": "696002826",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.252Z",
                "updatedAt": "2022-03-02T11:54:44.252Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41350,
                "typeIndexe": "eau",
                "ancien": 774,
                "nouveau": 780,
                "periode": "2021-08-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-09-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41273,
                "typeIndexe": "eau",
                "ancien": 768,
                "nouveau": 774,
                "periode": "2021-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-08-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 41205,
                "typeIndexe": "eau",
                "ancien": 786,
                "nouveau": 793,
                "periode": "2021-06-01",
                "avance": 3493,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41139,
                "typeIndexe": "eau",
                "ancien": 780,
                "nouveau": 786,
                "periode": "2021-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 41028,
                "typeIndexe": "eau",
                "ancien": 774,
                "nouveau": 780,
                "periode": "2021-04-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 40994,
                "typeIndexe": "eau",
                "ancien": 770,
                "nouveau": 774,
                "periode": "2021-03-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40919,
                "typeIndexe": "eau",
                "ancien": 767,
                "nouveau": 770,
                "periode": "2021-02-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 40918,
                "typeIndexe": "eau",
                "ancien": 761,
                "nouveau": 767,
                "periode": "2021-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40839,
                "typeIndexe": "eau",
                "ancien": 758,
                "nouveau": 761,
                "periode": "2020-12-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40766,
                "typeIndexe": "eau",
                "ancien": 755,
                "nouveau": 758,
                "periode": "2020-11-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 40663,
                "typeIndexe": "eau",
                "ancien": 752,
                "nouveau": 755,
                "periode": "2020-10-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 40662,
                "typeIndexe": "eau",
                "ancien": 748,
                "nouveau": 752,
                "periode": "2020-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 48145,
                "typeIndexe": "energie",
                "ancien": 4158,
                "nouveau": 4238,
                "periode": "2021-08-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-09-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 90,
                "montant": 9900,
                "idIndexe": 48065,
                "typeIndexe": "energie",
                "ancien": 4068,
                "nouveau": 4158,
                "periode": "2021-07-01",
                "avance": 9900,
                "reste": 0,
                "datePaiement": "2021-08-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 47994,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4158,
                "periode": "2021-06-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47930,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4138,
                "periode": "2021-05-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-08-02T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47903,
                "typeIndexe": "energie",
                "ancien": 3978,
                "nouveau": 4058,
                "periode": "2021-04-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 100,
                "montant": 11000,
                "idIndexe": 47850,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4158,
                "periode": "2021-03-01",
                "avance": 11000,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 120,
                "montant": 13200,
                "idIndexe": 47785,
                "typeIndexe": "energie",
                "ancien": 3938,
                "nouveau": 4058,
                "periode": "2021-02-01",
                "avance": 13200,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47713,
                "typeIndexe": "energie",
                "ancien": 4178,
                "nouveau": 4258,
                "periode": "2021-01-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47634,
                "typeIndexe": "energie",
                "ancien": 4178,
                "nouveau": 4258,
                "periode": "2020-12-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47560,
                "typeIndexe": "energie",
                "ancien": 4168,
                "nouveau": 4248,
                "periode": "2020-11-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2021-06-29T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 80,
                "montant": 8800,
                "idIndexe": 47487,
                "typeIndexe": "energie",
                "ancien": 4088,
                "nouveau": 4168,
                "periode": "2020-10-01",
                "avance": 8800,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 47476,
                "typeIndexe": "energie",
                "ancien": 4058,
                "nouveau": 4168,
                "periode": "2020-09-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2020-11-28T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 72,
                "idOccupation": 110,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 71,
        "refLogement": "D018",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 55000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2023-02-22T17:21:55.467Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51385,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51384,
                "typeIndexe": "eau",
                "ancien": 546,
                "nouveau": 511,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51253,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51252,
                "typeIndexe": "eau",
                "ancien": 540,
                "nouveau": 546,
                "periode": "2023-10-01",
                "avance": 2000,
                "reste": 994,
                "datePaiement": "2023-12-26T11:46:40.153Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51117,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51116,
                "typeIndexe": "eau",
                "ancien": 534,
                "nouveau": 540,
                "periode": "2023-09-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:46:10.463Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50972,
                "typeIndexe": "eau",
                "ancien": 529,
                "nouveau": 534,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:46:49.112Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50973,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50847,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50846,
                "typeIndexe": "eau",
                "ancien": 523,
                "nouveau": 529,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T07:25:05.115Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50712,
                "typeIndexe": "eau",
                "ancien": 517,
                "nouveau": 523,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T17:04:35.776Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50713,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50574,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50573,
                "typeIndexe": "eau",
                "ancien": 511,
                "nouveau": 517,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T10:59:54.732Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50442,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50441,
                "typeIndexe": "eau",
                "ancien": 511,
                "nouveau": 511,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50305,
                "typeIndexe": "eau",
                "ancien": 506,
                "nouveau": 511,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T16:08:54.086Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50306,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": null,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50181,
                "typeIndexe": "eau",
                "ancien": 500,
                "nouveau": 506,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:49:06.178Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50182,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50058,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50059,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": -291,
                "montant": 0,
                "idIndexe": 50067,
                "typeIndexe": "eau",
                "ancien": 291,
                "nouveau": 0,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49938,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": null,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51384,
            "typeIndexe": "eau",
            "ancien": 546,
            "nouveau": 551,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 71,
            "idOccupation": 447,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51385,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 71,
            "idOccupation": 447,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 447,
            "loyerBase": 55000,
            "caution": 55000,
            "modePaiement": "prepayer",
            "dateDeb": "2023-01-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2023-02-22T17:21:53.141Z",
            "idLocataire": 326,
            "idLogement": 71,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 326,
                "nomLocataire": "DJIOKA NGUETSA",
                "prenomLocataire": "STEVE VANEL",
                "cniLocataire": "",
                "tel": "698725617",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:46.495Z",
                "updatedAt": "2022-03-02T11:54:46.495Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                551,
                546
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                551,
                546
            ],
            "energie": [
                0,
                0
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51252,
                "typeIndexe": "eau",
                "ancien": 540,
                "nouveau": 546,
                "periode": "2023-10-01",
                "avance": 2000,
                "reste": 994,
                "datePaiement": "2023-12-26T11:46:40.153Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 51116,
                "typeIndexe": "eau",
                "ancien": 534,
                "nouveau": 540,
                "periode": "2023-09-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-10-28T15:46:10.463Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50972,
                "typeIndexe": "eau",
                "ancien": 529,
                "nouveau": 534,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-09-28T07:46:49.112Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50846,
                "typeIndexe": "eau",
                "ancien": 523,
                "nouveau": 529,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T07:25:05.115Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50712,
                "typeIndexe": "eau",
                "ancien": 517,
                "nouveau": 523,
                "periode": "2023-06-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-07-26T17:04:35.776Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50573,
                "typeIndexe": "eau",
                "ancien": 511,
                "nouveau": 517,
                "periode": "2023-05-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-06-27T10:59:54.732Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50441,
                "typeIndexe": "eau",
                "ancien": 511,
                "nouveau": 511,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50305,
                "typeIndexe": "eau",
                "ancien": 506,
                "nouveau": 511,
                "periode": "2023-03-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-04-28T16:08:54.086Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50181,
                "typeIndexe": "eau",
                "ancien": 500,
                "nouveau": 506,
                "periode": "2023-02-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-03-29T08:49:06.178Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 71,
                "idOccupation": 447,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [],
        "conso": {
            "eau": -551,
            "energie": 0
        },
        "montant": {
            "eau": -274949,
            "energie": 0
        }
    },
    {
        "idLogement": 70,
        "refLogement": "D019",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-05-28T09:38:32.931Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51378,
                "typeIndexe": "eau",
                "ancien": 629,
                "nouveau": 633,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51379,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-11-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51255,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51254,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 629,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51119,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51118,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 624,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50975,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50974,
                "typeIndexe": "eau",
                "ancien": 612,
                "nouveau": 617,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:47:34.169Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50849,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50848,
                "typeIndexe": "eau",
                "ancien": 609,
                "nouveau": 612,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:25:56.645Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50715,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50714,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 1005,
                "reste": 1490,
                "datePaiement": "2023-10-28T15:47:41.173Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50576,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50575,
                "typeIndexe": "eau",
                "ancien": 597,
                "nouveau": 604,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50432,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50431,
                "typeIndexe": "eau",
                "ancien": 590,
                "nouveau": 597,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50308,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50307,
                "typeIndexe": "eau",
                "ancien": 588,
                "nouveau": 590,
                "periode": "2023-03-01",
                "avance": 3,
                "reste": 995,
                "datePaiement": "2023-08-30T07:26:01.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50183,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 588,
                "periode": "2023-02-01",
                "avance": 1502,
                "reste": 494,
                "datePaiement": "2023-07-26T17:05:25.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50184,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50060,
                "typeIndexe": "eau",
                "ancien": 581,
                "nouveau": 584,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T12:08:38.332Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50061,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49940,
                "typeIndexe": "eau",
                "ancien": 579,
                "nouveau": 581,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T17:05:20.479Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49941,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 4,
            "montant": 1996,
            "idIndexe": 51378,
            "typeIndexe": "eau",
            "ancien": 629,
            "nouveau": 633,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Incomplet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 70,
            "idOccupation": 391,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 51379,
            "typeIndexe": "energie",
            "ancien": 8670,
            "nouveau": 8670,
            "periode": "2023-11-01",
            "avance": 0,
            "reste": null,
            "datePaiement": null,
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 70,
            "idOccupation": 391,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 391,
            "loyerBase": 65000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2022-05-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "forfait",
            "modeEau": "index",
            "puEnergie": 0,
            "puEau": 499,
            "dureeBail": 0,
            "createdAt": "2022-05-28T09:38:32.856Z",
            "idLocataire": 61,
            "idLogement": 70,
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 61,
                "nomLocataire": "PINLAP",
                "prenomLocataire": "Arsene",
                "cniLocataire": "",
                "tel": "699703418",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": null,
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:44.594Z",
                "updatedAt": "2022-03-02T11:54:44.594Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                633,
                629
            ],
            "energie": [
                8670,
                8670
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                633,
                629
            ],
            "energie": [
                8670,
                8670
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51254,
                "typeIndexe": "eau",
                "ancien": 624,
                "nouveau": 629,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 51118,
                "typeIndexe": "eau",
                "ancien": 617,
                "nouveau": 624,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50974,
                "typeIndexe": "eau",
                "ancien": 612,
                "nouveau": 617,
                "periode": "2023-08-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-10-28T15:47:34.169Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50848,
                "typeIndexe": "eau",
                "ancien": 609,
                "nouveau": 612,
                "periode": "2023-07-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-08-30T07:25:56.645Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 50714,
                "typeIndexe": "eau",
                "ancien": 604,
                "nouveau": 609,
                "periode": "2023-06-01",
                "avance": 1005,
                "reste": 1490,
                "datePaiement": "2023-10-28T15:47:41.173Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50575,
                "typeIndexe": "eau",
                "ancien": 597,
                "nouveau": 604,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 7,
                "montant": 3493,
                "idIndexe": 50431,
                "typeIndexe": "eau",
                "ancien": 590,
                "nouveau": 597,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 50307,
                "typeIndexe": "eau",
                "ancien": 588,
                "nouveau": 590,
                "periode": "2023-03-01",
                "avance": 3,
                "reste": 995,
                "datePaiement": "2023-08-30T07:26:01.649Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50183,
                "typeIndexe": "eau",
                "ancien": 584,
                "nouveau": 588,
                "periode": "2023-02-01",
                "avance": 1502,
                "reste": 494,
                "datePaiement": "2023-07-26T17:05:25.889Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50060,
                "typeIndexe": "eau",
                "ancien": 581,
                "nouveau": 584,
                "periode": "2023-01-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-05-30T12:08:38.332Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 2,
                "montant": 998,
                "idIndexe": 49940,
                "typeIndexe": "eau",
                "ancien": 579,
                "nouveau": 581,
                "periode": "2022-12-01",
                "avance": 998,
                "reste": 0,
                "datePaiement": "2023-07-26T17:05:20.479Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51255,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-10-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 51119,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-09-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50975,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-08-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50849,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-07-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50715,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-06-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50576,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-05-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50432,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-04-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50308,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-03-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50184,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-02-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 50061,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2023-01-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 49941,
                "typeIndexe": "energie",
                "ancien": 8670,
                "nouveau": 8670,
                "periode": "2022-12-01",
                "avance": 0,
                "reste": null,
                "datePaiement": null,
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 70,
                "idOccupation": 391,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -633,
            "energie": -8670
        },
        "montant": {
            "eau": -315867,
            "energie": -953700
        }
    },
    {
        "idLogement": 112,
        "refLogement": "D022",
        "descLogement": " ",
        "superficie": 0,
        "prixMin": 55000,
        "prixMax": 60000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-09-28T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.581Z",
        "idBatiment": 4,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 6,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 6,
            "libelleSousType": "Studios modernes",
            "descSousType": "STDM",
            "idType": 3
        },
        "indexes": [
            {
                "consommation": 5,
                "montant": 2495,
                "idIndexe": 51380,
                "typeIndexe": "eau",
                "ancien": 274,
                "nouveau": 239,
                "periode": "2023-11-01",
                "avance": 2495,
                "reste": 0,
                "datePaiement": "2023-12-26T11:48:41.468Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 58,
                "montant": 6380,
                "idIndexe": 51381,
                "typeIndexe": "energie",
                "ancien": 4025,
                "nouveau": 3507,
                "periode": "2023-11-01",
                "avance": 6380,
                "reste": 0,
                "datePaiement": "2023-12-26T11:48:41.471Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51256,
                "typeIndexe": "eau",
                "ancien": 266,
                "nouveau": 274,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:53.959Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51257,
                "typeIndexe": "energie",
                "ancien": 3975,
                "nouveau": 4025,
                "periode": "2023-10-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:53.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51120,
                "typeIndexe": "eau",
                "ancien": 262,
                "nouveau": 266,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:41.024Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51121,
                "typeIndexe": "energie",
                "ancien": 3919,
                "nouveau": 3975,
                "periode": "2023-09-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:53.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 37,
                "montant": 4070,
                "idIndexe": 50983,
                "typeIndexe": "energie",
                "ancien": 3882,
                "nouveau": 3919,
                "periode": "2023-08-01",
                "avance": 4070,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:41.020Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50982,
                "typeIndexe": "eau",
                "ancien": 258,
                "nouveau": 262,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T07:48:44.161Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50851,
                "typeIndexe": "energie",
                "ancien": 3822,
                "nouveau": 3882,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-08-30T07:28:16.993Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50850,
                "typeIndexe": "eau",
                "ancien": 252,
                "nouveau": 258,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T07:28:16.990Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50716,
                "typeIndexe": "eau",
                "ancien": 249,
                "nouveau": 252,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T17:06:48.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50717,
                "typeIndexe": "energie",
                "ancien": 3776,
                "nouveau": 3822,
                "periode": "2023-06-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": "2023-07-26T17:06:48.450Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50577,
                "typeIndexe": "eau",
                "ancien": 239,
                "nouveau": 249,
                "periode": "2023-05-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-06-27T11:04:10.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50578,
                "typeIndexe": "energie",
                "ancien": 3709,
                "nouveau": 3776,
                "periode": "2023-05-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-10-28T15:53:16.005Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 50434,
                "typeIndexe": "energie",
                "ancien": 3599,
                "nouveau": 3709,
                "periode": "2023-04-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2023-10-28T15:53:11.375Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50433,
                "typeIndexe": "eau",
                "ancien": 229,
                "nouveau": 239,
                "periode": "2023-04-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-05-30T12:23:13.806Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 4990,
                "idIndexe": 50309,
                "typeIndexe": "eau",
                "ancien": 229,
                "nouveau": 278,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T16:16:36.190Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50310,
                "typeIndexe": "energie",
                "ancien": 2963,
                "nouveau": 3035,
                "periode": "2023-03-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-04-29T06:34:53.024Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 4990,
                "idIndexe": 50185,
                "typeIndexe": "eau",
                "ancien": 268,
                "nouveau": 268,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-03-29T08:59:01.264Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50186,
                "typeIndexe": "energie",
                "ancien": 2895,
                "nouveau": 2963,
                "periode": "2023-02-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-04-28T16:17:02.866Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50063,
                "typeIndexe": "energie",
                "ancien": 2810,
                "nouveau": 2895,
                "periode": "2023-01-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-02-23T07:27:12.891Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50062,
                "typeIndexe": "eau",
                "ancien": 262,
                "nouveau": 268,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T07:27:12.887Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 49943,
                "typeIndexe": "energie",
                "ancien": 2723,
                "nouveau": 2810,
                "periode": "2022-12-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-02-22T11:59:03.490Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49942,
                "typeIndexe": "eau",
                "ancien": 256,
                "nouveau": 262,
                "periode": "2022-12-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-22T11:59:03.488Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 5,
            "montant": 2495,
            "idIndexe": 51380,
            "typeIndexe": "eau",
            "ancien": 274,
            "nouveau": 279,
            "periode": "2023-11-01",
            "avance": 2495,
            "reste": 0,
            "datePaiement": "2023-12-26T11:48:41.468Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 112,
            "idOccupation": 231,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 58,
            "montant": 6380,
            "idIndexe": 51381,
            "typeIndexe": "energie",
            "ancien": 4025,
            "nouveau": 4083,
            "periode": "2023-11-01",
            "avance": 6380,
            "reste": 0,
            "datePaiement": "2023-12-26T11:48:41.471Z",
            "observation": "Complet",
            "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
            "idLogement": 112,
            "idOccupation": 231,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 231,
            "loyerBase": 55000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2018-06-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.580Z",
            "idLocataire": 213,
            "idLogement": 112,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 213,
                "nomLocataire": "SALASSA",
                "prenomLocataire": "",
                "cniLocataire": "",
                "tel": "696373453",
                "tel2": "676905338",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.884Z",
                "updatedAt": "2022-03-02T11:54:45.884Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                279,
                274
            ],
            "energie": [
                4083,
                4025
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                279,
                274
            ],
            "energie": [
                4083,
                4025
            ],
            "periode": "2023-11-01"
        },
        "iEau": [
            {
                "consommation": 8,
                "montant": 3992,
                "idIndexe": 51256,
                "typeIndexe": "eau",
                "ancien": 266,
                "nouveau": 274,
                "periode": "2023-10-01",
                "avance": 3992,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:53.959Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 51120,
                "typeIndexe": "eau",
                "ancien": 262,
                "nouveau": 266,
                "periode": "2023-09-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:41.024Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 4,
                "montant": 1996,
                "idIndexe": 50982,
                "typeIndexe": "eau",
                "ancien": 258,
                "nouveau": 262,
                "periode": "2023-08-01",
                "avance": 1996,
                "reste": 0,
                "datePaiement": "2023-09-28T07:48:44.161Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50850,
                "typeIndexe": "eau",
                "ancien": 252,
                "nouveau": 258,
                "periode": "2023-07-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-08-30T07:28:16.990Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 3,
                "montant": 1497,
                "idIndexe": 50716,
                "typeIndexe": "eau",
                "ancien": 249,
                "nouveau": 252,
                "periode": "2023-06-01",
                "avance": 1497,
                "reste": 0,
                "datePaiement": "2023-07-26T17:06:48.446Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50577,
                "typeIndexe": "eau",
                "ancien": 239,
                "nouveau": 249,
                "periode": "2023-05-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-06-27T11:04:10.722Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 10,
                "montant": 4990,
                "idIndexe": 50433,
                "typeIndexe": "eau",
                "ancien": 229,
                "nouveau": 239,
                "periode": "2023-04-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-05-30T12:23:13.806Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 49,
                "montant": 4990,
                "idIndexe": 50309,
                "typeIndexe": "eau",
                "ancien": 229,
                "nouveau": 278,
                "periode": "2023-03-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-04-28T16:16:36.190Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 4990,
                "idIndexe": 50185,
                "typeIndexe": "eau",
                "ancien": 268,
                "nouveau": 268,
                "periode": "2023-02-01",
                "avance": 4990,
                "reste": 0,
                "datePaiement": "2023-03-29T08:59:01.264Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 50062,
                "typeIndexe": "eau",
                "ancien": 262,
                "nouveau": 268,
                "periode": "2023-01-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-23T07:27:12.887Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 6,
                "montant": 2994,
                "idIndexe": 49942,
                "typeIndexe": "eau",
                "ancien": 256,
                "nouveau": 262,
                "periode": "2022-12-01",
                "avance": 2994,
                "reste": 0,
                "datePaiement": "2023-02-22T11:59:03.488Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "iEnergie": [
            {
                "consommation": 50,
                "montant": 5500,
                "idIndexe": 51257,
                "typeIndexe": "energie",
                "ancien": 3975,
                "nouveau": 4025,
                "periode": "2023-10-01",
                "avance": 5500,
                "reste": 0,
                "datePaiement": "2023-11-25T08:21:53.963Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 56,
                "montant": 6160,
                "idIndexe": 51121,
                "typeIndexe": "energie",
                "ancien": 3919,
                "nouveau": 3975,
                "periode": "2023-09-01",
                "avance": 6160,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:53.491Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 37,
                "montant": 4070,
                "idIndexe": 50983,
                "typeIndexe": "energie",
                "ancien": 3882,
                "nouveau": 3919,
                "periode": "2023-08-01",
                "avance": 4070,
                "reste": 0,
                "datePaiement": "2023-10-28T15:52:41.020Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 60,
                "montant": 6600,
                "idIndexe": 50851,
                "typeIndexe": "energie",
                "ancien": 3822,
                "nouveau": 3882,
                "periode": "2023-07-01",
                "avance": 6600,
                "reste": 0,
                "datePaiement": "2023-08-30T07:28:16.993Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 46,
                "montant": 5060,
                "idIndexe": 50717,
                "typeIndexe": "energie",
                "ancien": 3776,
                "nouveau": 3822,
                "periode": "2023-06-01",
                "avance": 5060,
                "reste": 0,
                "datePaiement": "2023-07-26T17:06:48.450Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 67,
                "montant": 7370,
                "idIndexe": 50578,
                "typeIndexe": "energie",
                "ancien": 3709,
                "nouveau": 3776,
                "periode": "2023-05-01",
                "avance": 7370,
                "reste": 0,
                "datePaiement": "2023-10-28T15:53:16.005Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 110,
                "montant": 12100,
                "idIndexe": 50434,
                "typeIndexe": "energie",
                "ancien": 3599,
                "nouveau": 3709,
                "periode": "2023-04-01",
                "avance": 12100,
                "reste": 0,
                "datePaiement": "2023-10-28T15:53:11.375Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 72,
                "montant": 7920,
                "idIndexe": 50310,
                "typeIndexe": "energie",
                "ancien": 2963,
                "nouveau": 3035,
                "periode": "2023-03-01",
                "avance": 7920,
                "reste": 0,
                "datePaiement": "2023-04-29T06:34:53.024Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 68,
                "montant": 7480,
                "idIndexe": 50186,
                "typeIndexe": "energie",
                "ancien": 2895,
                "nouveau": 2963,
                "periode": "2023-02-01",
                "avance": 7480,
                "reste": 0,
                "datePaiement": "2023-04-28T16:17:02.866Z",
                "observation": "Incomplet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 85,
                "montant": 9350,
                "idIndexe": 50063,
                "typeIndexe": "energie",
                "ancien": 2810,
                "nouveau": 2895,
                "periode": "2023-01-01",
                "avance": 9350,
                "reste": 0,
                "datePaiement": "2023-02-23T07:27:12.891Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 87,
                "montant": 9570,
                "idIndexe": 49943,
                "typeIndexe": "energie",
                "ancien": 2723,
                "nouveau": 2810,
                "periode": "2022-12-01",
                "avance": 9570,
                "reste": 0,
                "datePaiement": "2023-02-22T11:59:03.490Z",
                "observation": "Complet",
                "createdBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "updatedBy": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "idLogement": 112,
                "idOccupation": 231,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "conso": {
            "eau": -279,
            "energie": -4083
        },
        "montant": {
            "eau": -139221,
            "energie": -449130
        }
    },
    {
        "idLogement": 88,
        "refLogement": "E001",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.283Z",
        "idBatiment": 5,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 90,
        "refLogement": "E003",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.677Z",
        "idBatiment": 5,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 248,
            "loyerBase": 20000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2018-11-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.676Z",
            "idLocataire": 228,
            "idLogement": 90,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 228,
                "nomLocataire": "MFOMO EFA",
                "prenomLocataire": "JULIEN",
                "cniLocataire": "",
                "tel": "690667449",
                "tel2": "",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:45.947Z",
                "updatedAt": "2022-03-02T11:54:45.947Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 91,
        "refLogement": "E004",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.261Z",
        "idBatiment": 5,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 100,
        "refLogement": "E013",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 0,
        "prixMax": 0,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.265Z",
        "idBatiment": 5,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 1,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 1,
            "libelleSousType": "Appartements simples",
            "descSousType": "APP",
            "idType": 1
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 103,
        "refLogement": "F001",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 17000,
        "prixMax": 20000,
        "etatLogement": true,
        "statutLogement": true,
        "createdAt": "2015-08-31T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:55:19.941Z",
        "idBatiment": 6,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 3,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 3,
            "libelleSousType": "chambres simples",
            "descSousType": "CH",
            "idType": 2
        },
        "indexes": [
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 46830,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2019-12-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2020-01-31T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 294,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 39944,
                "typeIndexe": "eau",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2019-12-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2020-01-31T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 294,
                "idParametre": 6,
                "parametre": {
                    "idParametre": 6,
                    "entretienEau": 0,
                    "entretienEnergie": 0,
                    "puEau": 499,
                    "puEnergie": 110,
                    "tva": 0,
                    "statutParametre": true,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 42074,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2015-04-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2015-08-31T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 42073,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2015-03-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2015-08-31T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 42072,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2015-02-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2015-08-31T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 42071,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2015-01-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2015-08-31T00:00:00.000Z",
                "observation": "Incomplet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 41839,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2014-12-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2015-08-31T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            },
            {
                "consommation": 0,
                "montant": 0,
                "idIndexe": 41740,
                "typeIndexe": "energie",
                "ancien": 0,
                "nouveau": 0,
                "periode": "2014-11-01",
                "avance": 0,
                "reste": 0,
                "datePaiement": "2014-12-01T00:00:00.000Z",
                "observation": "Complet",
                "createdBy": null,
                "updatedBy": null,
                "idLogement": 103,
                "idOccupation": 86,
                "idParametre": 1,
                "parametre": {
                    "idParametre": 1,
                    "entretienEau": 1005,
                    "entretienEnergie": 0,
                    "puEau": 364,
                    "puEnergie": 99,
                    "tva": 19,
                    "statutParametre": false,
                    "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                    "createdBy": null,
                    "updatedBy": null
                }
            }
        ],
        "lastIndexEau": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 39944,
            "typeIndexe": "eau",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2019-12-01",
            "avance": 0,
            "reste": 0,
            "datePaiement": "2020-01-31T00:00:00.000Z",
            "observation": "Complet",
            "createdBy": null,
            "updatedBy": null,
            "idLogement": 103,
            "idOccupation": 294,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "lastIndexEnergie": {
            "consommation": 0,
            "montant": 0,
            "idIndexe": 46830,
            "typeIndexe": "energie",
            "ancien": 0,
            "nouveau": 0,
            "periode": "2019-12-01",
            "avance": 0,
            "reste": 0,
            "datePaiement": "2020-01-31T00:00:00.000Z",
            "observation": "Complet",
            "createdBy": null,
            "updatedBy": null,
            "idLogement": 103,
            "idOccupation": 294,
            "idParametre": 6,
            "parametre": {
                "idParametre": 6,
                "entretienEau": 0,
                "entretienEnergie": 0,
                "puEau": 499,
                "puEnergie": 110,
                "tva": 0,
                "statutParametre": true,
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null
            }
        },
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {
            "idOccupation": 294,
            "loyerBase": 20000,
            "caution": 0,
            "modePaiement": "prepayer",
            "dateDeb": "2020-02-01T00:00:00.000Z",
            "dateFin": null,
            "modeEnergie": "index",
            "modeEau": "index",
            "puEnergie": 110,
            "puEau": 499,
            "dureeBail": 1,
            "createdAt": "2022-03-02T11:55:19.940Z",
            "idLocataire": 269,
            "idLogement": 103,
            "createdBy": null,
            "updatedBy": null,
            "locataire": {
                "avatar": "http://169.254.123.114:4002/static/avatars/default.jpg",
                "cniRecto": "",
                "cniVerso": "",
                "idLocataire": 269,
                "nomLocataire": "ABAKAR",
                "prenomLocataire": "LAOUAL",
                "cniLocataire": "",
                "tel": "699874862",
                "tel2": "682564686",
                "tel3": "",
                "tel4": "",
                "email": "",
                "email2": "",
                "titre": "Mr",
                "profession": null,
                "dateNaiss": null,
                "lieuNaiss": "",
                "statutLocataire": true,
                "createdAt": "2022-03-02T11:54:46.309Z",
                "updatedAt": "2023-07-26T17:13:40.574Z",
                "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
                "createdBy": null,
                "updatedBy": null,
                "idUtilisateur": null
            }
        },
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 117,
        "refLogement": "H001",
        "descLogement": "motel Global",
        "superficie": 0,
        "prixMin": 200000,
        "prixMax": 200000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.673Z",
        "idBatiment": 9,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 9,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 9,
            "libelleSousType": "Motel",
            "descSousType": "MOT",
            "idType": 6
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 120,
        "refLogement": "J002",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 50000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.701Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 8,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 8,
            "libelleSousType": "Boutique",
            "descSousType": "BTQ",
            "idType": 5
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 121,
        "refLogement": "J003",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 60000,
        "prixMax": 60000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.712Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 8,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 8,
            "libelleSousType": "Boutique",
            "descSousType": "BTQ",
            "idType": 5
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 122,
        "refLogement": "J004",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 50000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.709Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 8,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 8,
            "libelleSousType": "Boutique",
            "descSousType": "BTQ",
            "idType": 5
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 123,
        "refLogement": "J005",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 50000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.706Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 8,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 8,
            "libelleSousType": "Boutique",
            "descSousType": "BTQ",
            "idType": 5
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 124,
        "refLogement": "J006",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 50000,
        "prixMax": 50000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.711Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 8,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 8,
            "libelleSousType": "Boutique",
            "descSousType": "BTQ",
            "idType": 5
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    },
    {
        "idLogement": 125,
        "refLogement": "J007",
        "descLogement": "",
        "superficie": 0,
        "prixMin": 60000,
        "prixMax": 60000,
        "etatLogement": false,
        "statutLogement": true,
        "createdAt": "2020-08-27T00:00:00.000Z",
        "updatedAt": "2022-03-02T11:52:22.704Z",
        "idBatiment": 11,
        "idEntreprise": "0d5d7482-a502-4fe1-93e5-b588d7270823",
        "createdBy": null,
        "updatedBy": null,
        "idSousType": 9,
        "idModele": null,
        "sousTypeLogement": {
            "image": "http://169.254.123.114:4002/static/typeslogements/default.jpg",
            "idSousType": 9,
            "libelleSousType": "Motel",
            "descSousType": "MOT",
            "idType": 6
        },
        "indexes": [],
        "currentIndexEau": null,
        "currentIndexEnergie": null,
        "occupation": {},
        "indexeMois": {
            "eau": null,
            "energie": null
        },
        "indexePassee": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "indexePasseeTab": {
            "eau": [
                null,
                null
            ],
            "energie": [
                null,
                null
            ],
            "periode": "2023-11-01"
        },
        "iEau": [],
        "iEnergie": [],
        "conso": {
            "eau": 0,
            "energie": 0
        },
        "montant": {
            "eau": 0,
            "energie": 0
        }
    }
]
export default myLogements;
