<template>
  <div class="container-fluid">
    <!--page header start -->
        <div class="row">
            <div class="col-md-12">
                <div class="mb-2 clearfix">
                    <div class="d-flex justify-content-between align-items-center display-options" id="displayOptions">               
                        <div class="d-block d-md-inline-block">
                            <div class="btn-group d-inline-block float-md-left mr-1 mb-1 ">
                                <b-form-select v-model="filtre_displayed_indexe"  style="height: 40px; width: 260px; border-radius: 5px; box-shadow: none">
                                    <b-form-select-option value="non-renseigner">{{$t('data.indexes_non_renseigner')}}</b-form-select-option>
                                    <b-form-select-option value="deja-renseigner">{{$t('data.indexes_deja_renseigner')}}</b-form-select-option>
                                    <b-form-select-option value="tous">{{$t('data.indexes_renseigner_et_non')}}</b-form-select-option>
                                </b-form-select>
                            </div>
                            <SearchForm v-model="search" />
                            <div class="btn-group">
                                <button type="button" class="btn mt-1 btn-outline-danger btn-lg dropdown-toggle" style="height: auto;" data-toggle="dropdown"><i class="fa fa-cog fa-lg"></i> Plus d'actions</button>
                                <div class="dropdown-menu dropdown-menu-right">
                                    <button class="dropdown-item" type="button" @click="$bvModal.show('releve-indexes')">Fiche de relevés d'indexes</button>
                                    <button class="dropdown-item" type="button" @click="$bvModal.show('fiche-consommations')">Fiche de consommation</button>
                                </div>
                            </div>
                              <!-- <b-button :disabled="allDataSet" v-b-modal.modal-xl variant="outline-secondary"  style="height: 40px; width: 260px; border-radius: 5px; box-shadow: none"><b-spinner class="ml-2" small v-if="showSpinner" style="margin-left: 5px;"></b-spinner>Relevés Énergétiques</b-button> -->
                        </div>
                        <div class="float-md-right d-flex">
                            <!--<paginatorTop :offset="offset" :libelle='$t("data.logements")' :total="trueItems.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />-->
                            <paginatorTop :offset="offset" :total="trueItems.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" class="mr-2 d-flex justify-content-center align-items-center" />
                            <div class="d-flex ">
                                <b-form-select class="form-control" size="sm" @input="getLogements" v-model="periode.mois" :options="mois"></b-form-select>
                                <b-form-select class="form-control" size="sm" @input="getLogements" v-model="periode.annee" :options="annees"></b-form-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="separator mb-20"></div>
                <b-overlay :show="showOverlay" rounded="sm">
                    <b-alert variant="info" class="text-center" show v-if="!trueLogements.length">
                        <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                        <span class="h4 d-inline-flex ml-2">{{$t('data.indexes_pas_de_indexes')}}</span>
                    </b-alert> 
                    <b-alert variant="info" class="text-center" show v-else-if="!trueItems.length">
                        <i class="fa fa-exclamation-triangle fa-3x"></i> <br>
                        <span class="h4 d-inline-flex ml-2">{{$t('data.indexes_pas_de_indexes')}}</span>
                    </b-alert> 
                    <b-row v-else>
                        <b-col lg="3" md="6" sm="12" v-for="logement in items" :key="logement.idLogement" > 
                            <b-card :header="logement.sousTypeLogement.libelleSousType" :title="logement.refLogement" :sub-title="findLocataire(logement)" class="position-relative">
                                <div class="border pt-0 rounded" style="/*background: #191c22;*/">
                                    <p class="text-center mb-1">{{$t('data.indexes_recap_consommation')}} {{ $dayjs(logement.indexePassee.periode).format('MMMM YYYY') }}</p>
                                    <b-table-simple small borderless>
                                        <b-thead>
                                            <b-tr>
                                                <b-td></b-td>
                                                <b-th>{{$t('data.indexes_ancien')}}</b-th>
                                                <b-th>{{$t('data.indexes_nouveau')}}</b-th>
                                                <b-th>{{$t('data.indexes_consommation')}}</b-th>
                                            </b-tr>
                                        </b-thead>
                                        <b-tbody>
                                            <b-tr>
                                                <b-td>{{ $t('data.indexes_eau') }}</b-td>
                                                <b-th>{{ logement.indexePasseeTab.eau[1] }}</b-th>
                                                <b-th>{{ logement.indexePasseeTab.eau[0] }}</b-th>
                                                <b-th>{{ logement.indexePasseeTab.eau[0] - logement.indexePasseeTab.eau[1] }}</b-th>
                                            </b-tr>
                                            <b-tr>
                                                <b-td>{{ $t('data.indexes_electricite') }}</b-td>
                                                <b-th>{{ logement.indexePasseeTab.energie[1] }}</b-th>
                                                <b-th>{{ logement.indexePasseeTab.energie[0] }}</b-th>
                                                <b-th>{{ logement.indexePasseeTab.energie[0] - logement.indexePasseeTab.energie[1] }}</b-th>
                                            </b-tr>
                                        </b-tbody>
                                    </b-table-simple>
                                </div>
                                <hr>
                                <h6 class="font-weight-bold">{{$t('data.indexes_entrez_nouveaux_indexes')}}</h6>
                                <b-form-group :label="$t('data.indexes_indexes_eau')">
                                    <b-input-group>
                                        <b-form-input size="sm" type="number" min="0" v-model="logement.indexeMois.eau" @change="updateConso(logement.idLogement)" />
                                        <b-form-input disabled :value="logement.conso.eau" />
                                        <b-input-group-append>
                                            <b-button size="sm" variant="outline-danger" :id="'popover-eau-'+logement.idLogement"><i class="fa fa-info"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                <b-form-group :label="$t('data.indexes_indexes_electricite')" style="margin-top: -25px;">
                                    <b-input-group>
                                        <b-form-input type="number" min="0" v-model="logement.indexeMois.energie" @change="updateConso(logement.idLogement)" />
                                        <b-form-input disabled :value="logement.conso.energie" />
                                        <b-input-group-append>
                                            <b-button variant="outline-danger" :id="'popover-energie-'+logement.idLogement"><i class="fa fa-info"></i></b-button>
                                        </b-input-group-append>
                                    </b-input-group>
                                </b-form-group>
                                 <b-button variant="danger" :disabled="!canAddSingleindex"  @click.prevent="submitSingleIndex(logement.idLogement)">{{$t('data.batiment_form_wizard_bouton_enregistrer')}}</b-button>
                                
                                <b-popover title="Indexes précedents" :target="'popover-eau-'+logement.idLogement" triggers="click blur" placement="auto">
                                    <div style="overflow-y:auto; overflow-x: hidden; max-height: 15em">
                                        <div v-for="(indexe, i) in logement.iEau" :key="i" class="text-muted mb-1">
                                            <h6 class="border-bottom small">{{ $dayjs(indexe.periode).format('MMMM YYYY') }}</h6>
                                            <b-row>
                                                <b-col class="truncate">N: <b>{{ indexe.nouveau }}</b></b-col>
                                                <b-col class="truncate">A: <b>{{ indexe.ancien }}</b></b-col>
                                                <b-col class="truncate">C: <b>{{ indexe.nouveau - indexe.ancien }}</b> m<sup>3</sup></b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div class="jumbotron pt-10 pb-1 px-2 mb-0 mt-2">
                                        <b-form-group :label="$t('data.indexes_mois_passe') +' ('+$dayjs(logement.indexePassee.periode).format('MMMM YYYY')+')'">
                                            <b-input-group class="my-0 py-0">
                                                <b-form-input size="sm" min="0" type="number" v-model="logement.indexePassee.eau[0]" />
                                                <b-input-group-append>
                                                    <b-button variant="danger" :disabled="!canEditLastIndex" @click.prevent="editLastIndex(logement, 'eau')">Editer</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                </b-popover>
                                <b-popover :title="$t('data.indexes_popover_indexe_precedent')" :target="'popover-energie-'+logement.idLogement" triggers="click blur" placement="auto">
                                    <div style="overflow-y:auto; overflow-x: hidden; max-height: 15em">
                                        <div v-for="(indexe, i) in logement.iEnergie" :key="i" class="text-muted mb-1">
                                            <h6 class="border-bottom small">{{ $dayjs(indexe.periode).format('MMMM YYYY') }}</h6>
                                            <b-row>
                                                <b-col class="truncate">N: <b>{{ indexe.nouveau }}</b></b-col>
                                                <b-col class="truncate">A: <b>{{ indexe.ancien }}</b></b-col>
                                                <b-col class="truncate">C: <b>{{ indexe.nouveau - indexe.ancien }}</b> kw</b-col>
                                            </b-row>
                                        </div>
                                    </div>
                                    <div class="jumbotron pt-10 pb-1 px-2 mb-0 mt-2">
                                        <b-form-group :label="$t('data.indexes_mois_passe') +'('+$dayjs(logement.indexePassee.periode).format('MMMM YYYY')+')'">
                                            <b-input-group class="my-0 py-0">
                                                <b-form-input size="sm" min="0" type="number" v-model="logement.indexePassee.energie[0]" />
                                                <b-input-group-append>
                                                    <b-button :disabled="!canEditLastIndex" variant="danger" @click.prevent="editLastIndex(logement, 'energie')">Editer</b-button>
                                                </b-input-group-append>
                                            </b-input-group>
                                        </b-form-group>
                                    </div>
                                </b-popover>
                            </b-card>
                        </b-col>
                    </b-row>
                    <hr>
                    <div class="d-flex justify-content-between align-items-start">
                        <paginator v-if="trueItems.length > 5" :offset="offset" :total="trueItems.length" :limit="perPage" :page="currentPage" @pageChanged="(page) => {currentPage = page}" @limitChanged="(limit) => {perPage = limit}" /> 
                        <b-button :disabled="!canAddIndexesBulk || submitted" class="position-fixed validate btn-icon" variant="danger"  @click.prevent="submitForm" v-b-tooltip.left="$t('data.cite_valider_cite')"><b-spinner small v-if="submitted" /><i v-else class="fa fa-check fa-lg"></i></b-button>
                    </div>
                </b-overlay>
            </div>
        </div>

        <b-modal id="fiche-consommations" size="xl" title="Fiche de consommations" no-close-on-backdrop :cancel-title="$t('data.logement_form_fermer')" ok-variant="danger" ok-title="Imprimer" @ok="$refs.pdfFicheConso.generatePdf()">
            <b-form-group label="Sélectionnez les batiments à utiliser lors de l'opération">
                <v-select label="nomBatiment" multiple :options="batiments.list" v-model="batiments.selected" class="w-100" :reduce="batiment => batiment.idBatiment">
                    <template #option="{ nomBatiment, refBatiment, cite}">
                        {{ nomBatiment }} ({{ refBatiment }})
                        <br />
                        <span class="small text-muted">{{ cite.nomCite }}</span>
                    </template>
                </v-select>
            </b-form-group>
            <hr/>
            <h3 class="text-center mb-3">Fiche de consommation en énergie du mois de <b>{{ $dayjs(this.periodeCourante).format('MMMM YYYY') }}</b></h3>
            <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                <b-thead>
                    <b-tr>
                        <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                        <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                        <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 16%;">NOMS & PRENOMS</b-th>
                        <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                        <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                        <b-th rowspan="2" class="border m-0 p-0 text-center">SOMMES</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                        <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                        <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                        <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                        <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(logement, i) in printedLogements" :key="i">
                        <b-td class="border p-0">
                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++i }}</span>    
                        </b-td>
                        <b-td class="border p-0 px-2">
                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                        </b-td>

                        <b-td class="border py-0">
                            <span class="d-inline-block font-weight-bold">{{ findLocataire(logement) }}</span>    
                        </b-td>
                        <b-td class="border py-0 text-center">{{ logement.indexePassee.eau[0] }}</b-td>
                        <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.eau }}</b-td>
                        <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.eau }}</b-td>
                        <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.eau  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                        <b-td class="border py-0 text-center">{{ logement.indexePassee.energie[0] }}</b-td>
                        <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.energie }}</b-td>
                        <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.energie }}</b-td>
                        <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.energie  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                        <b-td class="border py-0 text-center font-weight-bold">{{ (logement.montant.energie + logement.montant.eau)  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                :htmlToPdfOptions="{...htmlToPdfOptions, filename: `fiche_consommation_${periodeCourante}`}"
                @beforeDownload="downloadFicheConso($event)"
                ref="pdfFicheConso"
            >
                <section slot="pdf-content">
                    <header class="d-flex border rounded px-3 py-1 align-items-center">
                        <img src="/img/logo-m0.png" class="header-brand-img" alt="lavalite" style="height: 5em; width: 5em">
                        <div style="flex: 1;" class="ml-1 text-center">
                            <h2 class="text-danger">Maisonier</h2>   
                            <p class="text-muted">{{$t('data.printer_definition_maisonier')}}</p>
                        </div> 
                    </header>
                    <h5 class="mb-1">Fiche de consommation en énergie du mois de <b>{{ $dayjs(this.periodeCourante).format('MMMM YYYY') }}</b></h5>
                    <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                        <b-thead>
                            <b-tr>
                                <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                                <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                                <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 16%;">NOMS & PRENOMS</b-th>
                                <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                                <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                                <b-th rowspan="2" class="border m-0 p-0 text-center">SOMMES</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                                <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                                <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                                <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(logement, i) in firstPage" :key="i">
                                <b-td class="border p-0">
                                    <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++i }}</span>    
                                </b-td>
                                <b-td class="border p-0 px-2">
                                    <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                                </b-td>

                                <b-td class="border py-0">
                                    <span class="d-inline-block font-weight-bold">{{ findLocataire(logement) }}</span>    
                                </b-td>
                                <b-td class="border py-0 text-center">{{ logement.indexePassee.eau[0] }}</b-td>
                                <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.eau }}</b-td>
                                <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.eau }}</b-td>
                                <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.eau  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                <b-td class="border py-0 text-center">{{ logement.indexePassee.energie[0] }}</b-td>
                                <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.energie }}</b-td>
                                <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.energie }}</b-td>
                                <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.energie  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                <b-td class="border py-0 text-center font-weight-bold">{{ (logement.montant.energie + logement.montant.eau)  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <!--ETABLISSEMENT DU  BREAK-->
                    <div class="html2pdf__page-break p-0 m-0"></div>
                    <div v-for="(page, i) in pages" :key="i">
                            <!--DEBUT DEUXIEME PAGE-->
                            <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                                <b-thead style="display: none">
                                    <b-tr>
                                        <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                                        <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                                        <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 16%;">NOMS & PRENOMS</b-th>
                                        <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                                        <b-th colspan="4" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                                        <b-th rowspan="2" class="border m-0 p-0 text-center">SOMMES</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                        <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                                        <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                        <b-th class="border m-0 pr-1 text-center">CONS</b-th>
                                        <b-th class="border m-0 pr-1 text-center">MONTANT</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <!--<b-tr><td colspan="5">&nbsp;</td></b-tr>-->
                                    <b-tr v-for="(logement, j) in page" :key="j">
                                        <b-td class="border p-0">
                                            <span class="d-inline-block w-100 font-weight-bold text-center">{{ 23 + (++j) + ((i + 1) * printLimit - printLimit) }}</span>        
                                        </b-td>
                                        <b-td class="border p-0 px-2">
                                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                                        </b-td>

                                        <b-td class="border py-0">
                                            <span class="d-inline-block font-weight-bold">{{ findLocataire(logement) }}</span>    
                                        </b-td>
                                        <b-td class="border py-0 text-center">{{ logement.indexePassee.eau[0] }}</b-td>
                                        <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.eau }}</b-td>
                                        <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.eau }}</b-td>
                                        <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.eau  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                        <b-td class="border py-0 text-center">{{ logement.indexePassee.energie[0] }}</b-td>
                                        <b-td class="border py-0 text-center" style="width: 9%">{{ logement.indexeMois.energie }}</b-td>
                                        <b-td class="border py-0 text-center font-weight-bold">{{ logement.conso.energie }}</b-td>
                                        <b-td class="border py-0 text-center font-weight-bold" style="width: 16%">{{ logement.montant.energie  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                        <b-td class="border py-0 text-center font-weight-bold">{{ (logement.montant.energie + logement.montant.eau)  | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: '.'  }) }}</b-td>
                                    </b-tr>
                                    <b-tr v-if="i == pages.length - 1">
                                        <b-td class="border text-center" colspan="3">
                                            <span class="d-inline-block w-100 font-weight-bold">Totaux</span>    
                                        </b-td>
                                        <b-td class="border" colspan="3"></b-td>

                                        <b-td class="border text-center">
                                            <span class="d-inline-block w-100 font-weight-bold">{{ totalEaux | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>
                                        </b-td>
                                        <b-td class="border" colspan="3"></b-td>

                                        <b-td class="border text-center">
                                            <span class="d-inline-block w-100 font-weight-bold">{{ totalElectricite | currency('', 0,{  symbolOnLeft: false, spaceBetweenAmountAndSymbol: true, thousandsSeparator: ' '  }) }}</span>
                                        </b-td>
                                        <b-td class="border" colspan="3"></b-td>
                                    </b-tr>
                                </b-tbody>
                            </b-table-simple>
                            <!--ETABLISSEMENT DU DEUXIEME BREAK-->
                            <div class="html2pdf__page-break p-0 m-0" v-if="i != 0"></div>
                        </div>                       
                </section>
            </vue-html2pdf>
        </b-modal>
        <b-modal id="releve-indexes" size="xl" title="Rélévé d'indexes" no-close-on-backdrop :cancel-title="$t('data.logement_form_fermer')" ok-variant="danger" ok-title="Imprimer" @ok="$refs.pdfReleveIndexes.generatePdf()">
            <b-form-group label="Sélectionnez les batiments à utiliser lors de l'opération">
                <v-select label="nomBatiment" multiple :options="batiments.list" v-model="batiments.selected" class="w-100" :reduce="batiment => batiment.idBatiment">
                    <template #option="{ nomBatiment, refBatiment, cite}">
                        {{ nomBatiment }} ({{ refBatiment }})
                        <br />
                        <span class="small text-muted">{{ cite.nomCite }}</span>
                    </template>
                </v-select>
            </b-form-group>
            <hr/>
            <h3 class="text-center mb-3">Rélévé des indexes du mois de <b>{{ $dayjs(this.periodeCourante).format('MMMM YYYY') }}</b></h3>
            <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                <b-thead>
                    <b-tr>
                        <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                        <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                        <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 30%;">NOMS & PRENOMS</b-th>
                        <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                        <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                    </b-tr>
                    <b-tr>
                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                        <b-th class="border m-0 pr-1 text-center">NOUVEAU</b-th>
                        <b-th class="border m-0 pr-1 text-center" style="width: 15%">OBSERVATION</b-th>
                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                        <b-th class="border m-0 pr-1 text-center" style="width: 15%">OBSERVATION</b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-for="(logement, i) in printedLogements" :key="i">
                        <b-td class="border p-0">
                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++i }}</span>    
                        </b-td>
                        <b-td class="border p-0 px-2">
                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                        </b-td>

                        <b-td class="border py-0">
                            <span class="d-inline-block font-weight-bold">{{ findLocataire(logement) }}</span>    
                        </b-td>
                        <b-td class="border py-0 text-center">{{ logement.indexePassee.eau[0] }}</b-td>
                        <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                        <b-td class="border py-0 text-center"></b-td>
                        <b-td class="border py-0 text-center">{{ logement.indexePassee.energie[0] }}</b-td>
                        <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                        <b-td class="border py-0 text-center"></b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            <vue-html2pdf
                :show-layout="false"
                :float-layout="true"
                :enable-download="false"
                :preview-modal="false"
                :paginate-elements-by-height="1400"
                :pdf-quality="2"
                :manual-pagination="false"
                pdf-format="a4"
                pdf-orientation="landscape"
                pdf-content-width="100%"
                :htmlToPdfOptions="{...htmlToPdfOptions, filename: `releve_indexes_${periodeCourante}`}"
                @beforeDownload="downloadFicheConso($event)"
                ref="pdfReleveIndexes"
            >
                <section slot="pdf-content">
                    <header class="d-flex border rounded px-3 py-1 align-items-center">
                        <img src="/img/logo-m0.png" class="header-brand-img" alt="lavalite" style="height: 5em; width: 5em">
                        <div style="flex: 1;" class="ml-1 text-center">
                            <h2 class="text-danger">Maisonier</h2>   
                            <p class="text-muted">{{$t('data.printer_definition_maisonier')}}</p>
                        </div> 
                    </header>
                    <h5 class="mb-1">Fiche de relevé d'énergies du mois de <b>{{ $dayjs(this.periodeCourante).format('MMMM YYYY') }}</b></h5>
                    <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                        <b-thead>
                            <b-tr>
                                <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                                <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                                <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 30%;">NOMS & PRENOMS</b-th>
                                <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                                <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                            </b-tr>
                            <b-tr>
                                <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                <b-th class="border m-0 pr-1 text-center" style="width: 15%;">OBSERVATION</b-th>
                                <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                <b-th class="border m-0 pr-1 text-center" style="width: 15%;">OBSERVATION</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr v-for="(logement, i) in firstPage" :key="i">
                                <b-td class="border p-0">
                                    <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ ++i }}</span>    
                                </b-td>
                                <b-td class="border p-0 px-2">
                                    <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                                </b-td>

                                <b-td class="border py-0">
                                    <span class="d-inline-block font-weight-bold">{{ findLocataire(logement) }}</span>    
                                </b-td>
                                <b-td class="border py-0 text-center">{{ logement.indexePassee.eau[0] }}</b-td>
                                <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                                <b-td class="border py-0 text-center"></b-td>
                                <b-td class="border py-0 text-center">{{ logement.indexePassee.energie[0] }}</b-td>
                                <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                                <b-td class="border py-0 text-center"></b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                    <!--ETABLISSEMENT DU  BREAK-->
                    <div class="html2pdf__page-break p-0 m-0"></div>
                    <div v-for="(page, i) in pages" :key="i">
                            <!--DEBUT DEUXIEME PAGE-->
                            <b-table-simple class="m-0 p-0" hover small responsive style="border: 1px solid black!important">
                                <b-thead style="display: none">
                                    <b-tr>
                                        <b-th rowspan="2"  class="m-0 p-0 text-center">{{$t('data.detail_habitant_numero')}}</b-th>
                                        <b-th rowspan="2" class="border m-0 p-0 text-center">REF</b-th>
                                        <b-th rowspan="2" class="border m-0 p-0 text-center" style="width: 30%;">NOMS & PRENOMS</b-th>
                                        <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Eau/m3</b-th>
                                        <b-th colspan="3" class="border m-0 p-0 text-center" style="width: 16%">Electricite/kw</b-th>
                                    </b-tr>
                                    <b-tr>
                                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                        <b-th class="border m-0 pr-1 text-center" style="width: 15%;">OBSERVATION</b-th>
                                        <b-th class="border m-0 pr-1 text-center">ANCIEN</b-th>
                                        <b-th class="border m-0 pr-1 text-center">NOUVEL</b-th>
                                        <b-th class="border m-0 pr-1 text-center" style="width: 15%;">OBSERVATION</b-th>
                                    </b-tr>
                                </b-thead>
                                <b-tbody>
                                    <!--<b-tr><td colspan="5">&nbsp;</td></b-tr>-->
                                    <b-tr v-for="(logement, j) in page" :key="j">
                                        <b-td class="border p-0">
                                            <span class="d-inline-block w-100 font-weight-bold text-center">{{ 23 + (++j) + ((i + 1) * printLimit - printLimit) }}</span>        
                                        </b-td>
                                        <b-td class="border p-0 px-2">
                                            <span class="d-inline-block w-100 font-weight-bold" style="text-align:center">{{ logement.refLogement }}</span>
                                        </b-td>

                                        <b-td class="border py-0" style="width: 28%;">
                                            <span class="d-inline-block font-weight-bold w-100">{{ findLocataire(logement) }}</span>    
                                        </b-td>
                                        <b-td class="border py-0 text-center" style="width: 8%">{{ logement.indexePassee.eau[0] }}</b-td>
                                        <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                                        <b-td class="border py-0 text-center" style="width: 15%"></b-td>
                                        <b-td class="border py-0 text-center" style="width: 8%">{{ logement.indexePassee.energie[0] }}</b-td>
                                        <b-td class="border py-0 text-center" style="width: 9%"></b-td>
                                        <b-td class="border py-0 text-center" style="width: 15%"></b-td>
                                    </b-tr>
                                   
                                    
                                </b-tbody>
                            </b-table-simple>
                            <!--ETABLISSEMENT DU DEUXIEME BREAK-->
                            <div class="html2pdf__page-break p-0 m-0" v-if="i != 0"></div>
                        </div>                       
                </section>
            </vue-html2pdf>
        </b-modal>
  </div>
</template>
<script>
    import SearchForm from "@/components/parts/SearchForm.vue";
    import Multiselect from 'vue-multiselect'
    //import 'vue-multiselect/dist/vue-multiselect.min.css'
    const php  = require ( 'phpjs' ) ; 
    import { mapGetters} from "vuex"
    import myLogements from "./indexDec2023.js"
const {mois:_mois, annees:_annees, periodeCourante:_periodeCourante} = require('../../helpers/date')

export default {
  name: "releves-indexes",
  components: {
      SearchForm,
      Multiselect 
  },
  data: () => ({
    myLogements:myLogements,
    //propriétés fiche des indexes
    showRelevesData:false,
    showonsommationData:false,
    allDataSet:true,
    selected:[],
    printRecette: false,
    showSpinner:false,
    annee:0,
    printLimit: 36,

    htmlToPdfOptions: {
        margin: [7,5,10,5],
        
        // filename: `releves_indexes.pdf`,
        
        image: {
            type: 'jpeg', 
            quality: 2
        },
    
        enableLinks: false,
        
        html2canvas: {
            scale: 4, 
            logging: true,
            useCORS: false
        },
        
        jsPDF: {
            unit: 'mm',
            format: 'a4',
            orientation: 'landscape',
        }
    },
   
    search: null,
    filtre_displayed_indexe: 'non-renseigner', 
    showOverlayModal:false,
    showOverlay: true,
    submitted: false,
    currentPage: 1,
    perPage: 10,
    logements:[],
    batiments: {list: [], selected: []},
    trueLogements:[],
    periode: {annee: null, mois: null},
  }),
    watch: {
        search(value) {
            this.logements = !php.empty(value) ? this.trueLogements.filter(elt => elt.refLogement.toLowerCase().includes(value.toLowerCase())) : this.trueLogements
        }
    },
  computed: {
      ...mapGetters(['hasAccess']),
        totalEaux() {
            return this.logements.reduce((acc, curr) => acc + curr.montant.eau, 0)
        },
        totalElectricite() {
            return this.logements.reduce((acc, curr) => acc + curr.montant.energie, 0)
        },
      canEditLastIndex(){
            return this.hasAccess('edit_ancien_index')
      },
      canAddSingleindex(){
            return this.hasAccess('add_indexe')
      },
      canAddIndexesBulk(){
            return this.hasAccess('add_indexes_bulk')
      },
        eauConsommee(occupation){
            console.log('occupation',occupation);
            if(occupation.eau.nouvel !=''&&occupation.eau.nouvel!=null){
                return occupation.eau.nouvel - occupation.eau.ancien
            }
            else{
                return ' '
            }
            
        },
        energieConsommee(occupation){
            if(occupation.energie.nouvel !=''&&occupation.energie.nouvel!=null){
                return occupation.energie.nouvel - occupation.energie.ancien
            }
            else{
                return ' '
            }
        },
        montantEauConsommee(occupation){
            let qteEauConsommme=this.eauConsommee(occupation)
            if(qteEauConsommme !=''){
                return qteEauConsommme*499;
            }
        },
        montantElecConsommee(occupation){
            let qteElecConsommme=this.energieConsommee(occupation)
            if(qteElecConsommme !=''){
                return qteElecConsommme*110;
            }
        },
        sommeTotalConsommee(occupation){
            return this.montantEauConsommee(occupation) + this.montantElecConsommee(occupation) ;
        },
        /**
         * Elements affichés avec prise en charge de la pagination
         */
        items() { 
            return php.array_slice(this.trueItems, this.offset, this.perPage)
        },
        trueItems() {
            let items = this.logements
            if (this.filtre_displayed_indexe == 'non-renseigner') {
                items = items.filter(elt => (elt.indexeMois.eau == null || elt.indexeMois.energie == null))
            }
            if (this.filtre_displayed_indexe == 'deja-renseigner') {
                items = items.filter(elt => (elt.indexeMois.eau != null || elt.indexeMois.energie != null))
            }
            return items
        },
        offset() {
            return (this.currentPage * this.perPage) - this.perPage
        },

        mois() {
            return _mois(this.periode.annee).map(elt => ({...elt, text: php.ucfirst(elt.text)}))
        },
        periodeCourante() {
            return _periodeCourante(this.periode)
        },
        annees: () => _annees(),

        printedLogements() {
            return this.trueLogements.filter(elt => this.batiments.selected.includes(elt.idBatiment))
        },
        pages() {
            const pages = [] 
            pages.push(php.array_slice(this.printedLogements, 0, 23))
            const nbrPage = Math.ceil(this.printedLogements.length - 23 / this.printLimit)

            for (let i = 1; i <= nbrPage; i++) {
                pages.push(
                    php.array_slice(
                        this.printedLogements, 
                        ((i * this.printLimit) - this.printLimit) + 23, this.printLimit
                    )
                )
            }
            return pages.filter(elt => elt.length > 0)
        },
        firstPage() {
            const pages = [...this.pages]

            return this.pages.shift()
        },
    },

   async beforeMount() {
        this.periode = {
            mois: parseInt(this.$dayjs().format('M')) - 1,
            annee: parseInt(this.$dayjs().format('YYYY'))
        }
        await this.getLogements()
    },
    async mounted() {
       // this.updateDate();
    },
    methods: {
        async downloadFicheConso({ html2pdf, options, pdfContent }) {
            console.log('printedLogements ',this.printedLogements);
            await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
                const totalPages = pdf.internal.getNumberOfPages()
                for (let i = 1; i <= totalPages; i++) {
                    pdf.setPage(i)
                    pdf.setFontSize(12)
                    pdf.setTextColor(150)
                    pdf.text('Copyright © '+this.$dayjs().format('YYYY')+' By Flysoft engineering | All Rights Reserved.', 10, (pdf.internal.pageSize.getHeight() - 2.9))
                    pdf.text('Page ' + i + ' sur ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 2.9))
                } 
            }).save()
        },


        getBatiments() {
            axios.get('batiments/simple-list').then(response => response.result || []).then(batiments => {
                this.batiments.list = batiments.sort((a, b) => (a.refBatiment < b.refBatiment) ? -1 : 1)
                this.batiments.selected = batiments.map(elt => elt.idBatiment)
            })
        },

        showFicheIndexesModal(){
            this.showOverlayModal = true
        },
        showConsommationModal(){
            this.showonsommationData=true;
        },

        nameWithCity ({ nomBatiment, city }) {
            return `${nomBatiment} — [${city}]`
        },
        onClose() {
            this.popoverShow = false
        },

        //recupération de la liste des logements
        getLogements() {
            this.showOverlay = true
            
            axios.get('logements/get-indexes?periode='+this.periodeCourante).then(response => response.result || []).then(logements => {
                this.logements = this.trueLogements = logements.sort((a, b) => {
                    if (a.refLogement < b.refLogement) {
                        return -1
                    }
                    return 1
                })
                //this.logements = this.trueLogements = this.myLogements
                console.log('logements ',this.logements);
                this.showOverlay = false
                this.setIndexesMois(this.periode.mois, this.periode.annee)
                this.allDataSet=false
                this.getBatiments()
            })
        },
        submitSingleIndex(idLogement){
            this.submitForm(idLogement, true)
        },

        submitForm(idLogement= null, isSingle=false) {
            this.submitted = true 
            let data = { periode: this.periodeCourante, indexes: [] }

            if (isSingle){
                let single = this.trueItems.find(logement => logement.idLogement === idLogement)
                data = this.pushIndexe(data, single)
            }
            else{
                this.trueItems.forEach(elt => {
                    data = this.pushIndexe(data, elt)
                });
            }
            axios.post('indexes/add-multiple', data).then(response => {
                App.notifySuccess(response.message)
                //this.showOverlay = true 
                this.submitted = false
               // this.getLogements()
            }).catch(error => {
                this.submitted = false
                return App.alertError(error.message || 'Impossible de joindre le serveur')
            });
        },
        

        pushIndexe(data, elt) {
            if (null != elt.indexeMois.eau || null != elt.indexeMois.energie) {
                data.indexes.push({
                    idLogement: elt.idLogement,
                    eau: [elt.indexeMois.eau, elt.indexePassee.eau[0]],
                    energie: [elt.indexeMois.energie, elt.indexePassee.energie[0]]
                })
            }
            return data
        },

        editLastIndex(logement, typeIndexe) {
            const indexe = logement.indexePassee, data = { 
                periode: this.periodeCourante, 
                ancien: 0, 
                nouveau: 0, 
                idLogement: logement.idLogement,
                typeIndexe
            }
            if (typeIndexe == 'eau') {
                data.ancien = indexe.eau[1]
                data.nouveau = indexe.eau[0]
            }
            else {
                data.ancien = indexe.energie[1]
                data.nouveau = indexe.energie[0]
            }
                this.showOverlay = true 
            axios.put('indexes/update-previous', data).then(response => {
                this.showOverlay = false
                
            }).catch(error => {
                this.submitted = false
                return App.alertError(error.message || 'Impossible de joindre le serveur')
            });
        },

        
        setIndexesMois(mois, annee) {
            let periodeCourante = mois + 1
            if (periodeCourante < 10) {
                periodeCourante = '0'+periodeCourante
            }
            periodeCourante = annee + '-' +  periodeCourante + '-01'

            this.trueLogements = this.setIndexesPeriode(periodeCourante, this.trueLogements)
            this.logements = this.setIndexesPeriode(periodeCourante, this.logements)
        },
        setIndexesPeriode(periode, logements) {
            let periodePassee = this.$dayjs(periode).subtract(1, 'month').format('YYYY-MM')+'-01'

            return logements.map(elt => {
                let indexesCourants = elt.indexes.filter(e => this.$dayjs(periode).diff(e.periode, 'month') == 0).sort((a, b) => b.idIndexe - a.idIndexe),
                    iEau1 = indexesCourants.find(e => e.typeIndexe == 'eau'),
                    iEnergie1 = indexesCourants.find(e => e.typeIndexe == 'energie')
                
                elt.indexeMois = {
                    eau: php.empty(iEau1) ? null : iEau1.nouveau,
                    energie: php.empty(iEnergie1) ? null: iEnergie1.nouveau
                }

                indexesCourants = elt.indexes.filter(e => this.$dayjs(periodePassee).diff(e.periode, 'month') == 0).sort((a, b) => b.idIndexe - a.idIndexe)
                let iEau = indexesCourants.find(e => e.typeIndexe == 'eau')
                let iEnergie = indexesCourants.find(e => e.typeIndexe == 'energie')
                    
                elt.indexePassee = {
                    eau: !php.empty(iEau1) ? [iEau1.ancien, null] : (php.empty(iEau) ? [null, null] : [iEau.nouveau, iEau.ancien]),
                    energie: !php.empty(iEnergie1) ? [iEnergie1.ancien, null] : (php.empty(iEnergie) ? [null, null] : [iEnergie.nouveau, iEnergie.ancien]),
                    periode: periodePassee
                }
                elt.indexePasseeTab = {
                    eau: php.empty(iEau) ? [null, null] : [iEau.nouveau, iEau.ancien],
                    energie: php.empty(iEnergie) ? [null, null] : [iEnergie.nouveau, iEnergie.ancien],
                    periode: periodePassee
                }

                indexesCourants = elt.indexes.filter(e => (this.$dayjs(periodePassee).diff(e.periode, 'month') > 0 && e.nouveau > 0))
                elt.iEau = indexesCourants.filter(e => e.typeIndexe == 'eau')
                elt.iEnergie = indexesCourants.filter(e => e.typeIndexe == 'energie')

                elt.conso = { 
                    eau: elt.indexeMois.eau - elt.indexePassee.eau[0], 
                    energie: elt.indexeMois.energie - elt.indexePassee.energie[0]
                }

                const param = (elt.indexes.find(e => this.$dayjs(periodePassee).diff(e.periode, 'month') == 0) || {parametre: {}}).parametre
                elt.montant = {
                    eau: elt.conso.eau * (param.puEau || 499),
                    energie: elt.conso.energie * (param.puEnergie || 110),
                }
                
                return elt
            })
        },

        updateConso(idLogement) {
            this.trueLogements = this.trueLogements.map(elt => {
                if (elt.idLogement == idLogement) {
                    elt.conso = { 
                        eau: elt.indexeMois.eau - elt.indexePassee.eau[0], 
                        energie: elt.indexeMois.energie - elt.indexePassee.energie[0]
                    }
                }
                return elt
            })
            this.logements = this.logements.map(elt => {
                if (elt.idLogement == idLogement) {
                    elt.conso = { 
                        eau: elt.indexeMois.eau - elt.indexePassee.eau[0], 
                        energie: elt.indexeMois.energie - elt.indexePassee.energie[0]
                    }
                }
                return elt
            })
        },

        findLocataire(logement) {
            if (!php.empty(logement.occupation)) {
                return `${logement.occupation.locataire.nomLocataire} ${logement.occupation.locataire.prenomLocataire}`
            }
            return 'Libre'
        },

         _calcul_consommation(occupation, type)
        {
            const indexes = occupation.indexes.filter(elt => elt.typeIndexe == type),
                mode = { eau: occupation.modeEau, energie: occupation.modeEnergie },
                pu = { eau: occupation.puEau, energie: occupation.puEnergie }
            
            let indexeMois = this.getIndexesPeriode(this.periodeCourante, indexes)[0], consommation = 1        

            if (!indexeMois) {
                consommation = 0
            }
            else if (mode[type] == 'index') {
                consommation = indexeMois.nouveau  - indexeMois.ancien
            }

            return consommation * pu[type]
        },
        _display_consommation(occupation, type) 
        {
            const [consommation] = this._check_if_buy_consommation(occupation, type)


            return `<span>
                <b>${consommation}</b> F <br>
            </span>`
        },
         updateDate(){
           let date = new Date()
           let exact = 2000 + date.getYear() - 100
           this.annee = exact
           
        },
    },
    

};
</script>
<style scoped>
td.border, th.border {
    border: 1px solid #000 !important;
}
button.validate{
    bottom: 1em;
    right: 1em;
    width: 3em;
    height: 3em;
    box-shadow: 3px 1px 1px gray;
}
</style>